
import React from 'react';
import {Card, CardImg, CardText,CardTitle,Row,Col} from 'reactstrap';
import {Button} from 'reactstrap'
import '../../../Containers/Student/student.css'
import Progressbar from './Progressbar'

const MediaCard = ({title,text,image,type,btn ,Price,start,courseDetail}) => {
  return (
    <div>

                     <Card className="cardWp mediaCard" >
                     { type ==="Progressbar" ?
                        <Row>
                          <Col>
                            <CardImg top   src={image} alt="Card image cap" />
                            <CardTitle className="pl-3  pt-2 pr-3">{title}</CardTitle>
                              <CardText className="pl-3 pr-3 cardTextelipse">{text}</CardText>
                              <CardText className="mt-3 mb-3 pl-3 pr-3 pb-3"><Progressbar/></CardText>
                          </Col>
                        </Row>
                        : btn ==="startBtn" ?
                        <Row>
                      <Col>
                        <CardImg top  src={image} alt="Card image cap" />
                        <CardTitle className="pl-3  pt-2 pr-3">{title}</CardTitle>
                          <CardText className="pl-3 pr-3 ">{text}</CardText>
                          <CardText className="mt-3 mb-3 pl-3 pr-3 pb-3"><Progressbar/></CardText>
                          <Button variant="primary" className="btnblue w-100" onClick={start}>Start</Button>
                      </Col>
                    </Row>
                      
                     : Price === "price" ?
                     <Row>
                        <Col>
                          <CardImg top  src={image} alt="Card image cap" />
                            <CardTitle className="pl-3 pt-2 pr-3">{title}</CardTitle>
                            <CardText className="pl-3 pr-3 pb-3">{text}</CardText>
                            <CardText className="pl-3 pr-3 pb-3">{Price}</CardText>
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col>
                        <div  onClick={courseDetail}>
                          <CardImg top  src={image} alt="Card " />
                            <CardTitle className="pl-3 pt-2 pr-3">{title}</CardTitle>
                            <CardText className="pl-3 pr-3 pb-3">{text}</CardText>
                            </div>
                        </Col>
                      </Row>
                      
                    }
                    
                 </Card>
                   
                   
                   
              
      </div>
    );
};

export default MediaCard;
