import React from 'react'
import {Modal}from 'react-bootstrap'
import ReactPlayer from 'react-player'
import Player from '@vimeo/player';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import swal from 'sweetalert';
import Auth from '../../../ServerCall/Auth';
import Tooltip from '@material-ui/core/Tooltip'; 
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
  });

  const userRole = Auth.getUserRole();

const VideoPlayerModal = ({show, onHide, lessonTitle, videoUrl, courseType, isLessonComplete, showSwal}) => {

    // console.log({show, onHide, lessonTitle, videoUrl, courseType, isLessonComplete, showSwal});
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);
    const [videoDuration, setVideoDuration] = React.useState([])

    var player;

    React.useEffect(() => {
        if (userRole === "student" && show === true){
        videoPlayer();
        const timer = setInterval(() => {
        setProgress(async (oldProgress) => {
            if(oldProgress < 150){
            if (oldProgress === 100 && showSwal && courseType==="Prerecorded") {
            // return 0;
            swal("Completed","if you want to rewatch please click rewatch", {
                buttons: {
                  Rewatch: "Rewatch",
                  Complete: "Complete",
                },
              })
              .then(async(value) => {
                  if(value === "Rewatch"){
                    //   console.log("Rewatch");
                      swal.close();
                      await onRewatch();
                    //   return 0;
                  }else if(value === "Complete"){
                    //   console.log("Complete");
                      swal.close();
                      await onCompleteVideo();
                  }
                //   else{
                //       isLessonComplete(true, "video"); 
                //   }
              });
            }
            // console.log("counting is on ");

            // const diff = Math.random() * 10;
            return Math.min(oldProgress, 100);
        }
        });
        }, 800);

        return async () => {
        await clearInterval(timer);
        };
    }
    }, [show]);

    const onRewatch = () => {
        // isLessonComplete(false, "video");
        player.play();
        setProgress(0);
    }

    const onCompleteVideo = () => {
        setProgress(150);
        if(courseType==="Prerecorded"){
            isLessonComplete(true, "video");
        }
        onHide();
    }

    const onHideModal = () =>{        
        setProgress(0);
        if(courseType==="Prerecorded"){
            if(showSwal === true){
                isLessonComplete(false, "video");
            }else{
                isLessonComplete(true, "video");
            }
        }
        onHide();
    }



    
    const videoPlayer = () =>{

        var iframe = document.querySelector('iframe');
        var timeWatched = 0;

        player = new Player(iframe);

        // player.getCurrentTime().then(function(seconds) {
        //     // seconds = the current playback position
        //     console.log('time:', seconds);
        // }).catch(function(error) {
        //     // an error occurred
        // });

        let isSeeked = false;

        const seeked = (data) => {
            if (timeWatched < data.seconds) {
                player.setCurrentTime(timeWatched);
            }
            if (data.duration === data.seconds) {
                setProgress(0);
                isSeeked = true;
            }
            else{
                isSeeked = false;
            }
        }

        const timeupdate = (data) =>{
            if (data.seconds - 1 < timeWatched && data.seconds > timeWatched) {
                timeWatched = data.seconds;
            }
            // console.log("timeupdate", data);
            const percentage = data.seconds / data.duration * 100;
            // console.log(percentage)
            if(isSeeked === false){
                setProgress(percentage);
            }else{
                isSeeked = false 
            }
        }


        player.on("seeked", seeked);

        player.on('timeupdate', timeupdate);
        
    }
    

    return(
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
            centered className="modalWp videomodalWP" show={show} onHide={onHideModal} >
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    {lessonTitle} &nbsp;
                    {!showSwal
                    ? <Tooltip title="Completed" aria-label="info"><CheckCircleIcon fontSize="large" color="primary" /></Tooltip>
                    : null}
                </Modal.Title>
            </Modal.Header>
            {userRole === "student" && courseType === "Prerecorded"
            ? (
                <div className={classes.root}>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
                )
            : null}
            
            <Modal.Body>
            {/* <iframe width="100%" height="400px" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe> */}
            {/* <embed type="video/webm" src={videoUrl}  width="100%" height="100%"></embed> */}
                {/* <video width="100%" height="400px">
                    <source src={videoUrl} type="video/mp4"/>
                </video> */}
                {/* <embed type="video/webm" src={videoUrl} width="100%" height="100%"></embed> */}
                {/* <div id='handstick'></div> */}
                {/* <div data-vimeo-url="https://vimeo.com/76979871" id="playertwo"></div> */}
                {/* <div id="vimeo_player"> <iframe src="https://player.vimeo.com/video/76979871" id="video1" width="640" height="360" frameborder="0" ></iframe></div> */}
                <iframe type="video/webm" src={videoUrl} allowfullscreen width="100%" height="100%"></iframe>
            </Modal.Body>
        </Modal>
    )
}

export default VideoPlayerModal