import React from 'react';
import {Row,Col,Container,Button}from 'reactstrap';
import '../../../Containers/Student/student.css';
import moment from 'moment';
import Card from "react-bootstrap/Card";
import Select, { components } from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from  '../../../Components/Admin/Course/Table'
import {URL} from '../../../ServerCall/Config';
import Label from 'reactstrap/lib/Label';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth  from '../../../../src/ServerCall/Auth.js';
import CardTitle from 'reactstrap/lib/CardTitle';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';


const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}
export default  class ReleaseContent extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
          Coursedata:[],
          selectedCourse:'',
          courseId:'',
          chapterId:'',
          courses:[],
          batchData:[],
          selectedChapter:'',
          selectedDate:'',
          releaseDate:'',
          chapters:[],
          columns: [
            { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
            { title: "Modules", field: "chapterName"},
            { title: "releaseDate", field: "releaseDate"},
          ],
          batchId:'',
          selectedBatch:'',
          isRedirect: false,
          courseId:'',
          itemsPerPage : 8,
          page :1,
          noOfPages : null,
          filter:'',
          filteredCourseData:[],
      }
    }
    

    getCourseData () {
      let user_id = Auth.getUserId();
      // console.log(user_id)
      axiosApiInstance.get(`${URL}/courseByFaculty/${user_id}`)
      .then(res =>{
          const response = res.data.Course;
          let  CourseData = [];
          let courses=[];
          response&&response.map(data =>{
            if(data.courseType!="Prerecorded")
              {CourseData.push({Id:data._id,courseName:data.courseName,description:data.description,defaultimage:data.imageOrVideoPath.default})}
            })
            // console.log(CourseData);
          CourseData&&CourseData.map(data=>{
            courses.push({_id:data.Id,courseName:data.courseName})
          })
            let pages = Math.ceil(CourseData.length / this.state.itemsPerPage);
            this.setState({Coursedata: CourseData,courses:courses,noOfPages:pages});
        }).catch(err=>{
          console.log(err)
        });
    }

    clearStates()
    {
      this.setState({selectedChapter:'',selectedDate:'',selectedBatch:'',releaseDate:'',selectedCourse:''});
    }

    getChapters=()=>{
      let userData=JSON.parse(localStorage.getItem('userData'));
      axiosApiInstance.post(`${URL}/getChapterByCourseIdCoursescheduleIdFacultyId`,{courseId:this.state.courseId,courseScheduleId:this.state.batchId,facultyId:userData.user_id})
      .then(res=>{
        let chaptersData=[];
        res.data.data.chapter&& res.data.data.chapter.map(chapter=>{
          chaptersData.push(chapter)
        })
        this.setState({chapters:chaptersData})
        // this.returnChapters();
      })
        
      .catch((err) => {
          console.log(err);
        });
    }

    selectReleaseDate=(date)=>{
      debugger
      this.setState({releaseDate:date})
      let FormatedDate=moment(date).format('YYYY-MM-DD');
      // console.log(FormatedDate,typeof(FormatedDate))
      swal({
        title: "Are you sure?",
        text: "Do you want to set Release Date for this Module?",
        icon: "info",
        buttons: true,
      })
      .then((willProceede) => {
        if (willProceede) {
          axiosApiInstance.put(`${URL}/updateReleaseDate`,{courseId:this.state.courseId,courseScheduleId:this.state.batchId,chapterId:this.state.chapterId,releaseDate:FormatedDate})
          .then(res=>{
            // console.log(res)
            swal("Release Date Selected","","success");
            this.getChapters();
          })
          .catch(err=>{console.log(err)})
        }
        else
        {
          this.setState({releaseDate:''})
        }
        });
      
    }

    handleChapterChange = async(selectedChapter)=>{
      await this.setState({ selectedChapter: selectedChapter,chapterId:selectedChapter._id});
    }

    handleBatchChange = async (selectedBatch) => {
      this.setState({ selectedBatch: selectedBatch });
      await this.setState({ selectedChapter:'',batchId: selectedBatch._id,releaseDate:'' });
      // alert("Batch Name:",this.state.selectedBatch)
      this.getChapters();
    };


    handleChange = async (selectedCourse) => {
      this.setState({ selectedCourse: selectedCourse });
      await this.setState({ courseId: selectedCourse._id ,selectedChapter:'',selectedBatch:'',releaseDate:''});
      let id = this.state.courseId;
      // axiosApiInstance.get(`${URL}/getBatchNameList/${id}`)
      axiosApiInstance.get(`${URL}/getBatchByCourseId/${id}`)
    .then(res =>{
      this.setState({batchData:res.data.data});
      // console.log("Batch list: ",this.state.batchData);
    }).catch((err) => {
          console.log(err);
        });
    };

    componentDidMount (){
      this.getCourseData();
     }

      

      render(){
          
        return( 
                <Container fluid className="CardContainer CourseAllocated CourseWp">
                  <Card  className="shadow p-4 col-md-12 marginauto">
                  <Row className="mb-5  mt-4 pl-3">
                      <Col md={6}>
                          <h3 className="hdtxt">Release Content</h3>
                      </Col>  
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col ><Label>Select Course: </Label></Col>
                      </Row>
                      <Row>
                        <Col md={9}>
                          <Select 
                          options={this.state.courses}
                          name="selectCourses"
                          onChange={this.handleChange}
                          value={this.state.selectedCourse}
                          getOptionLabel={(x) => x.courseName}
                          getOptionValue={(x) => x._id}/>
                          </Col>
                      </Row>
                      </Col>
                      <Col md={6}>
                      <Row>
                        <Col >
                          <Label>Select Batch: </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={9}>
                          <Select 
                          options={this.state.batchData}
                          name="selectBatch"
                          onChange={this.handleBatchChange}
                          value={this.state.selectedBatch}
                          getOptionLabel={(x) => x.batchName}
                          getOptionValue={(x) => x._id}
                          />
                          </Col>
                      </Row></Col>
                  </Row>
                  <br/><br/>
                  {/* <Row>
                    <Col>Module list</Col>
                  </Row> */}
                  <Row >
                  <Col  md={6}>
                  <Row>
                        <Col ><Label>Select Module: </Label></Col>
                      </Row>
                    <Row>
                  
                  <Col md={9}>
                  <Select 
                  options={this.state.chapters}
                  name="selectModule"
                  onChange={this.handleChapterChange}
                  value={this.state.selectedChapter}
                  getOptionLabel={(x) => x.chapterName}
                  getOptionValue={(x) => x._id}
                  />
                  </Col>
                  </Row>
                  </Col>
                  <Col md={6}>
                  <Row>
                        <Col ><Label>Select Release Date: </Label></Col>
                      </Row>
                    <Row>
                      <Col md={9}>
                      <DatePicker
                      disabled={this.state.selectedChapter!=''?false:true}
                      dateFormat="MMMM d, yyyy"
                      selected={this.state.releaseDate}
                      onChange={(date) =>this.selectReleaseDate(date)}
                      disabledDays={{ before: new Date()}}
                      minDate={new Date()}
                    />
                      </Col>
                    </Row>
                  </Col>
                  </Row><br/><br/>
                    <Row>
                    <Col md={5}></Col>
                      <Col md={4}>
                        <Button color="danger" className="text-center clearBtn"  onClick={()=>{this.clearStates()}}>Clear</Button>
                      </Col>
                      <Col md={3}></Col>
                    </Row>
                  <br/><br/>
                  <Row >
                    <Col className=" mr-3 zindex0">
                    <Table 
                    data={this.state.chapters} 
                    pageSize={5} 
                    paging={true} columns={this.state.columns}
                    search={false} 
                    headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='300px' />
                    </Col>
                  </Row>
                    </Card>
                </Container>
        );
      }
}