import React from "react";
// import '../Course/CourseMaster.css'
import './ViewImage.css'
import {
  Form,
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
  Button,
} from "reactstrap";
import { Modal } from "react-bootstrap";

// const modalStyle = {
//     width:'600px'
// }

export default class ViewImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      show: false,
    };
  }
  viewHandler = (e) => {
    this.props.viewHandler(this.props.url);
    this.close();
  };
  close = async (show) => {
    this.props.close(show);
  };
  onHide = () => {
    this.setState({ show: false });
  };
  render() {
    const { show, url } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        animation={true}
        className="modalStyle"
      >
        <Modal.Body closeButton onClick={this.close}>
          <Row>
            <Col>
              <img src={this.props.url} id="image" style={{ width: "100%", height: "100%" } } />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
