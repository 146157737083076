import React from "react";
import {Modal,Form, Button,Container,Row,Col,FormGroup} from 'react-bootstrap'
import {Label,Input } from 'reactstrap'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';

    const ModuleModal =({
        show,
        onHide,
        onClickCancelButton,
        selectedModule,
        moduleData,
        startTestAlert,
        moduleId
        }) => {
        return(
        <Modal 
       // {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalWp"
        show={show}
        onHide={onHide}
        >
          <Modal.Header closeButton className="AddChaptermodalWp">
            <Modal.Title id="contained-modal-title-vcenter">
              Start Test
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container className="FormWp">
            <Row>
              <Col>
                <Form>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Form.Label>Select Module</Form.Label><span className="requiredFieldSpan">*</span>
                                <select id="moduleId" name="moduleId" className="form-control"  value={moduleId}
                                    onChange={selectedModule}
                                    >
                                    <option>Select Module</option> 
                                        {
                                        moduleData && moduleData.map(chapter => {
                                            return (
                                            <option 
                                                value={chapter._id}
                                            >
                                                {chapter.chapterName}
                                            </option> 
                                            )
                                        })
                                        }
                                </select>
                            </FormGroup>
                    </Col>
                </Row>
                  <Row>
                      <Col className="text-center mt-3">
                      <div  className="center-block text-center">
                        <Button className="text-center mr-3" onClick={startTestAlert} disabled={moduleId === null?true:false} >Start</Button>
                        <Button className="text-center " variant="danger"  onClick={onClickCancelButton}>Cancel</Button>
                      </div>
                      </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
          </Modal.Body>
        </Modal>
     
    )
  }

export default ModuleModal
