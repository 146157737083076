import React from "react";
import { Row,Col,Container,Card,CardText,CardTitle ,FormGroup,Input,Label} from 'reactstrap'

export default  class Lesson extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            AnnceMonth:"Jan",
            AnncemntDesc:''
        }
    
      }
      render(){
         
        return( 
                <Container fluid>
                       
                    <Row>
                        <Col md={8} sm={12} xs={12}>
                            <Card className="p-4">
                            <Row>
                                <Col>
                                    <h3 className="text-center headingText" >Announcement</h3>
                                </Col>
                            </Row>
                                <Row>
                                    <Col md={2} sm={4} xs={4}>
                                      <div className="monthcircleWp">
                                        <span>Jan</span>
                                      </div>
                                    </Col>
                                    <Col md={10} sm={8} xs={8}>
                                        <CardTitle>Jan</CardTitle>
                                        <CardText className="descrTxt">
                                                Free Learning Resources
                                                Dear students,<br/>

                                                I hope you're all doing fine these days!<br/>

                                                I would like to join others who offer their free learning resources to help you keep busy with some meaningful activities while staying home :)
                                                <br/>
                                                If you're into web development, you'll definitely want to check these articles:
                                                <br/>
                                                Docker for PHP Developers is a huge step-by-step guide where you will learn how to user Docker for web development. I show the whole setup including the deployment to Digital Ocean servers. I believe that if you're new to Docker, this will be a great start!
                                                <br/>
                                                Regular Expressions Explained is a short post packed with knowledge. You'll learn in only a few minutes how to create a universal rule for valid email addresses and how to check that the password meets specific requirements. You should definitely check it out during your morning coffee.
                                                <br/>
                                                Mastering SQL Basic is all about databases and how to manage them efficiently with MySQL CLI. I demonstrate the basics of SQL on the list of action movies and I think this bite-sized post will definitely suit you.
                                        </CardText>

                                        <FormGroup className="mt-4">
                                           <Input type="textarea" name="text" id="exampleText" placeholder="Enter Your Comment" className="txtareaclass"/>
                                       </FormGroup>
                                    </Col>
                                </Row>
                              
                            </Card>
                        </Col>
                    </Row>

                   
                </Container>
        );
      }
}