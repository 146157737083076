import React from 'react';
import {Row,Col,Container }from 'react-bootstrap';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {URL} from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { setProfile } from '../../../redux/action/profileAction';
import { connect } from 'react-redux';


const userName = Auth.getUserName();
const role = Auth.getUserRole();

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
  }));
const FacultyDashboard =(props) =>{
    const classes = useStyles();

    const [qs,setQs]=React.useState('');
    const [qsReply ,setQsReply] = React.useState(0);
    const [instruction,setInstruction]=React.useState(0);
    const [prerecorded,setPrerecorded]=React.useState(0);
    const [schedule ,setSchedule] = React.useState(0);
    const [enroll ,setEnroll] = React.useState(0);
    const [reply ,setReply] = React.useState(0);
    const [notReply ,setNotReply] = React.useState(0);
    const [name,setName] = React.useState('');
    const [email, setEmail] = React.useState('');

    function userDetail(){
        axiosApiInstance.get(`${URL}/getProfile`)
        .then(res=>{
        //   console.log(res);
          if(res.status === 200){
            let User = res.data.User;
            props.setProfile(User);
            setEmail(User.email);
            setName(User.fullName);
          }
        }).catch(err=>{
            console.log(err);
        })
    }

    function courseCount(){
        axiosApiInstance.get(`${URL}/getAssignCourseCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
                //debugger
               let User = res.data.Course;
               User&&User.map(i=>{
                    if(i._id === "Instruction based"){
                        setInstruction(i.count);
                    }
                    else if(i._id === "Prerecorded")
                    {
                        setPrerecorded(i.count);
                    }
                 }
                )
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }
   
    function scheduleCount(){
        axiosApiInstance.get(`${URL}/getCourseScheduleCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
                //debugger
               let scheduleCount = res.data.CourseSchedule;
               setSchedule(scheduleCount);

   
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function forumReply(){
        axiosApiInstance.get(`${URL}/getForumReplyCountByUser`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
              // debugger
               let Count = res.data.forumReplyCount;
               Count&&Count.map(i=>
                setReply(i.reply)
              )    
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function askQsCount(){
        axiosApiInstance.get(`${URL}/getAskQuestionCountByUser`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
              // debugger
               let Count = res.data.AskQuestion;
               setQs(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function replyQs(){
        axiosApiInstance.get(`${URL}/getAskQuestionAnswerCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
               let Count = res.data.AskQuestion;
               Count&&Count.map(i=>{
                if(i._id === true){
                    setQsReply(i.count);
                }
                else if(i._id === false)
                {
                    setNotReply(i.count);
                }
             }
            )
               ///setQsReply(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function userCount(){
        axiosApiInstance.get(`${URL}/getStudentCountByFacultyCourse`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
                //debugger
                var value = 0;
                let Count = res.data.enrollmentCount;
                for(let i = 0; i < Count.length; i++) {
                    value += Count[i].count;
                }
                // console.log(value);
                setEnroll(value)
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    React.useEffect(()=>{
        courseCount();
        askQsCount();
        scheduleCount();
        forumReply();
        replyQs();
        userCount();
        userDetail();
     }, []);



        return( 
            <Container fluid className=" pl-0 pr-0 adminDashWp">
                 <Row className="welcomeTextRow">
                    <Col style={{fontSize:'16px',fontWeight:'bold'}} className="mb-3 WelcomeName" > 
                        Welcome !<span className="blueText pl-2">{name}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc">{name}</h5>
                            {/* <CardText className="cardTextclass">{name}</CardText> */}
                        </div>
                        <div className="avtarWp">
                        <Avatar className={classes.orange}>
                             {userName !== undefined? userName.charAt(0)+" " 
                                               // +item.name.charAt(11)
                              :null}
                        </Avatar>
                        </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt">Role : Faculty</CardTitle>
                        <CardTitle className="subtiteltxt ">{email}</CardTitle>
                        </CardBody>
                    </Card>
                    </Col>
                    <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc"> Assigned Courses -{instruction+prerecorded} </h5>
                            {/* <CardText className="cardTextclass">Total Courses - {instruction+prerecorded}</CardText> */}
                        </div>
                        <div className="avtarWp">
                        {/* <Avatar className={classes.orange}>
                        </Avatar> */}
                        </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt ">Prerecorded Course - ({prerecorded})</CardTitle>
                        <CardTitle className="subtiteltxt "> Instructor Led Course - ({instruction})</CardTitle>
                        </CardBody>
                    </Card>
                    </Col>
                    <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc"> Student Enrolled -{enroll} </h5>
                            {/* <CardText className="cardTextclass">Total Student Enrolled in Course - {enroll}</CardText> */}
                        </div>
                        <div className="avtarWp">
                        {/* <Avatar className={classes.orange}>AB
                        </Avatar> */}
                        </div>
                        <CardBody>
                        {/* <CardTitle className="subtiteltxt pt-4"> Question Replied </CardTitle> */}
                        <CardTitle className="subtiteltxt ">Student Enrolled - ({enroll})</CardTitle>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc"> Forum Post Replied -{reply}</h5>
                            {/* <CardText className="cardTextclass">Total Post - {reply}</CardText> */}
                        </div>
                        <div className="avtarWp">
                        {/* <Avatar className={classes.orange}>AB
                        </Avatar> */}
                        </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt">Reply post on forum - ({reply}) </CardTitle>
                        {/* <CardTitle className="subtiteltxt "> - 20</CardTitle> */}
                        </CardBody>
                    </Card>
                    </Col>
                    {/* <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc"> Question Replied </h5>
                            <CardText className="cardTextclass">Total Question - {qs}</CardText>
                        </div>
                        <div className="avtarWp">
                        </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt pt-4"> Replied Answer - ({qsReply})</CardTitle>
                        <CardTitle className="subtiteltxt ">Not Replied Yet - ({notReply})</CardTitle>
                        </CardBody>
                    </Card>
                    </Col> */}
                </Row>
        </Container>
        );
    }

    const mapStoreToProps = (state) => {
        //debugger
        // console.log(state,"datadatadata");
        return{
          Profile: state.profile.profile
        }
      }
      
      const mapDispatchToProps = dispatch => ({
        setProfile:(profile)=>dispatch(setProfile(profile))
      })
      export default connect(mapStoreToProps, mapDispatchToProps)(FacultyDashboard);