import React from 'react';
import { Col,Row} from 'react-bootstrap';
import Container from 'reactstrap/lib/Container';
const Refund = (props)=>{
    return(
        <Container>
        <Row >
            <Row>
            <h1>Refund/Cancellation Policy</h1></Row>
            <p style={{textIndent: "40px"}}>
    Softlabs Academy strives to add value to professional and personal life by delivering quality training solutions. We appreciate you choosing us for your training needs and thank you for trusting us. We have created this policy which details what we will do should we fail to meet your expectations, if you request a refund and cancel the training you were enrolled into.</p>
    <p style={{textIndent: "40px"}}>
In the event of cancellation of admission by the participant for which the payment has been made and received confirmation from the organisation on the amount credited, the participant must notify Softlabs Academy his/her inability to proceed with the admission through email : (info@softlabsgroup.in)</p>
<b><p style={{textIndent: "40px"}}><u>The organisation will follow the below refund structure</u> :</p></b>
<Row>
    <ol>
    <li>	Softlabs Academy can hold the payment and wait for your availability for the program. In such a circumstance, you are also protected from further increase in prices as the service on offer has already been committed.</li>
    <li>	If you are certain, you will not be able to participate in the program in the future (and have not undergone any sessions), you are eligible for a full refund only if you cancel a week before the commencement of the program.</li>
    <li>	Kindly note, if you opt for a full refund, price protection is not offered, and you might have to purchase the same service at a higher price later on.</li>
    <li>	The whole process of issuing the refund takes about 30 days, from the date you make the request to the date on which the money is credited into your account.</li>
    <li>	Participants opting for refund upon payment of complete fees and registration will be refunded the whole amount after tax deductions. This will be applicable if the participant has not attended any sessions other than the demo session or webinar.</li>
    <li>	Once you have commenced a training course and attended a session no refund is applicable, </li>
    <li>	The refunded amount is subject to the following deductions:
            <ol>
<li>	vendor certificate processing fee, vendor labs fees & payment gateway charges.</li>
<li>	Taxes as applicable locally such as value added tax or government service tax, which we are liable to follow.</li>
<li>	The candidate will have to make an application for refund along with the transaction number and original payment receipt if any generated at the time of making payment.</li>
<li>	The request for cancellation of admission should be sent through email.</li>
<li>	The request will be processed manually and after verification, if the claim is found valid, the amount will be refunded by Softlabs Academy, through electronic media in the favour of the applicant and confirmation will be sent to the mailing address given in the online registration form within a period of 15 calendar days on the receipt of cancellation.</li>
<li>	In case of any queries please call helpdesk on <b>+27 11 282 0650</b>  / <b>+27 67 418 8228</b> or write to us on <b>info@softlabsgroup.in</b>          </li>
            </ol>
    </li>
</ol>
</Row>
        </Row>
        </Container>
    )
}
export default (Refund);
