import React, {Component} from 'react';
import { Form, FormGroup,Col,Row,Card,Accordion} from 'react-bootstrap';
import { Input,Label} from 'reactstrap';
// import PaymentIcon from 'react-payment-icons';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import './Accordian.css'

class Accordian extends Component {
  constructor(props){
    // console.log("Props",props);
    super(props);
    this.state = {
      cardDetails: {
        cardNo: null,
        cvv: null,
        expiryMonth: null,
        expiryYear: null,
        name: '',
        city: '',
        state: '',
        country: '',
        pin: '',
        address:'',
        activeId: '0'
      }
    }    
  }

  handleChangeEvent = (event) => {
    event.preventDefault();
    let { cardDetails } = this.state;
    if(event.target.type === 'number'){
      cardDetails[event.target.name] = parseInt(event.target.value);
    }
    else{
      cardDetails[event.target.name] = event.target.value;
    }
    this.setState({cardDetails: cardDetails});
    this.props.childStatedata(cardDetails);  
  }

  chlidCardDetails()
  {
    //debugger
    this.setState({
      cardDetails: {
        cardNo: "",
        cvv: "",
        expiryMonth: "",
        expiryYear: "",
        name: '',
        city: '',
        state: '',
        country: '',
        pin: '',
        address:'',
        activeId: '0'
      }
     });
  }
 
  toggleActive = (id) =>{
    const {activeId}= this.state;
    if (activeId === id) {
      this.setState({activeId: null});
    } else {
      this.setState({activeId: id});
    }
  }
  render(){
  const {cardDetails, activeId} = this.state;   
  return (
    <div className="accordnWp">
   <Accordion defaultActiveKey={activeId} className="mt-2"> 
    <RadioGroup aria-label="gender" name="gender1">
     <div> {!this.props.isWithdraw &&
        <Card className="">
        <Card.Header className={activeId === '0' ? 'cardHead active' : 'cardHead'}>
          <Row>
            <Col md={7} xs={12}>
              <Accordion.Toggle  variant="link" eventKey="0"  onClick={() => this.toggleActive('0')} className="radioOuterBkg">
                <FormControlLabel value="CreditDebit" control={<Radio />} label="" />
               </Accordion.Toggle>
              <Form.Label className="lbltxt mb-0">Credit and Debit Card </Form.Label>
                  <div className="spantxt">Transaction fee may apply</div>
            </Col>
            <Col md={5} className="PaymntCol">
              <div className="paymentIconWp">
                  {/* <PaymentIcon id="visa"  className="payment-icon mr-2 float-right"/>
                  <PaymentIcon id="mastercard"  className="payment-icon mr-2 float-right"/> */}
              </div>
        </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
          <FormGroup>
            <Label for="cardNo" className="frmLbl">Card Number</Label>
              <Input type="text" name="cardNo" required id="cardNo" 
                value={cardDetails.cardNo} onChange={this.handleChangeEvent}/>              
          </FormGroup>
          <FormGroup>
            <Label for="name" className="frmLbl">Name on Card</Label>
              <Input type="text" name="name" required id="name"
                value={cardDetails.name} onChange={this.handleChangeEvent}/>
          </FormGroup>
          <Row>
            <Col  md={4} xs={12}>
              <FormGroup>
                  <Label for="expiryMonth" className="frmLbl">Expiry Month</Label>
                    <Input type="number" name="expiryMonth" required id="expiryMonth"
                      value={cardDetails.expiryMonth} onChange={this.handleChangeEvent}/>
              </FormGroup>  
           </Col>
           <Col  md={4} xs={12}>
              <FormGroup>
                <Label for="expiryYear" className="frmLbl">Expiry Year</Label>
                  <Input type="number" name="expiryYear" required id="expiryYear"
                    value={cardDetails.expiryYear} onChange={this.handleChangeEvent}/>
              </FormGroup>
           </Col>
           <Col  md={4} xs={12}>
              <FormGroup>
                <Label for="cvv" className="frmLbl">CVC</Label>
                  <Input type="text" name="cvv" required id="cvv"
                    value={cardDetails.cvv} onChange={this.handleChangeEvent}/>
              </FormGroup>
           </Col>
          </Row>
          <FormGroup>
            <Label for="address" className="frmLbl">Address</Label>
              <Input type="textarea" name="address" required id="address"
                value={cardDetails.address} onChange={this.handleChangeEvent}/>
          </FormGroup>
          <Row>
            <Col md={6} xs={12}>
              <FormGroup>
                <Label for="city" className="frmLbl">City</Label>
                  <Input type="text" name="city" required id="city"
                    value={cardDetails.city} onChange={this.handleChangeEvent}/>
              </FormGroup>
              </Col>
              <Col  md={6} xs={12}>
                <FormGroup>
                  <Label for="pin" className="frmLbl">Pincode/Zipcode</Label>
                    <Input type="text" name="pin" required id="pin"
                      value={cardDetails.pin} onChange={this.handleChangeEvent}/>
                </FormGroup>
              </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="state" className="frmLbl">State</Label>
                      <Input type="text" name="state" required id="state"
                        value={cardDetails.state} onChange={this.handleChangeEvent}/>
                  </FormGroup>
                </Col>
                <Col  md={6} xs={12}>
                  <FormGroup>
                    <Label for="country" className="frmLbl">Coutry</Label>
                    <Input type="text" name="country" required id="country"
                      value={cardDetails.country} onChange={this.handleChangeEvent}/>
                  </FormGroup>
                </Col>
              </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      
    }
  </div>
      <Card className="mb-1 mt-2" >
      <Card.Header className={activeId === '1' ? 'cardHead active' : 'cardHead'}>
        <Row>
          <Col>
            <Accordion.Toggle variant="link" eventKey="1" onClick={() => this.toggleActive('1')} className="radioOuterBkg" >
              <FormControlLabel value="eCheck" control={<Radio />} label="" />
            </Accordion.Toggle>
            <Form.Label className="lbltxt mb-0">UPI /Net Banking </Form.Label>
            <div className="spantxt">Free of Charge</div>
          </Col>
        </Row>
      </Card.Header>
      <Accordion.Collapse eventKey="1">
        <Card.Body>
          <FormGroup>
            <Label for="entrAmnt" className="frmLbl">Account Number</Label>
              <Input type="text" name="entrAmnt" required id="entrAmntId" />
          </FormGroup>
          <FormGroup>
            <Label for="entrAmnt" className="frmLbl">Routing Number</Label>
              <Input type="text" name="entrAmnt" required id="entrAmntId" />
          </FormGroup>
          </Card.Body>
      </Accordion.Collapse>
      </Card>

      {/* <Card className="mb-3">
      <Card.Header>
          <Row>
            <Col md={7}>
              <Accordion.Toggle variant="link"  eventKey="1" className="radioOuterBkg">
                  <FormControlLabel value="Paypal" control={<Radio />} label="" />
              </Accordion.Toggle>
              < Form.Label className="lbltxt mb-0">Paypal </Form.Label>
                  <div className="spantxt">Transaction fee may apply</div>
            </Col>
            <Col md={5}>
              <PaymentIcon id="paypal" style={{ margin:5,width:60 }}  className="payment-icon float-right"/>
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <FormGroup>
              <Label for="entrAmnt" className="frmLbl">Enter amount to Deposit</Label>
                <Input type="text" name="entrAmnt" required id="entrAmntId" />
            </FormGroup>
            <FormGroup>
              <Label for="entrAmnt" className="frmLbl">Routing Number</Label>
                <Input type="text" name="entrAmnt" required id="entrAmntId" />
            </FormGroup>
            </Card.Body>
        </Accordion.Collapse>
      </Card> */}
      </RadioGroup>
    </Accordion>
    </div>
  );
  }
}
export default Accordian;