import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button, Row, Col } from 'reactstrap';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { Modal } from "react-bootstrap";
import { URL } from "../../../ServerCall/Config";
import { useState, useEffect } from 'react';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Table from 'reactstrap/lib/Table';
import moment from 'moment';
import parse from 'react-html-parser';
import Loader from '../../../Components/Common/Loader/Loader';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from 'reactstrap/lib/FormGroup';
import ViewImg from "../../../Components/Common/ViewImage";

import { duration } from 'moment';
import { Link,useHistory } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { Details } from '@material-ui/icons';
import swal from 'sweetalert';
import flyr from './flyr.jpg';

const FlyerPage = (props) => {
  // console.log(props);
  const history= useHistory();
  const [courseData, setCourseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fileData, setFileData] = useState({
    fileUrl1: "",
    fileType1: "",
    fileUrl2: "",
    fileType2: "",
  });
  const id = props.courseId;
  const [showImg, setShowImg] = useState(false);
  const [toggled, setToggled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [startDate,setStartDate] = useState(moment().format('YYYY-MM-DD')); 
  const [endDate,setEndDate] = useState('');
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const onHide = () => {
    setShowModal(false);
  }

  const showCourseModal = () => {
    setShowModal(true);
  }

  const getCourseDetails = () => {
    setLoading(true);
    const id = props.courseId;
    axiosApiInstance
      .get(`${URL}/course/${id}`)
      .then((response) => {
        // debugger;
        // console.log(response.data.Course);
        let CourseData = response.data.Course;
        setCourseData(CourseData);
        setStartDate(CourseData.startDate);
        setEndDate(CourseData.endDate);
        setFileData({
          fileUrl1: CourseData.flyerUrls[0].url,
          fileType1: CourseData.flyerUrls[0].url.split('.').pop(),
        });
        if (CourseData.flyerUrls[1] != undefined) {
          setLoading(true);
          setFileData({
            fileUrl1: CourseData.flyerUrls[0].url,
            fileType1: CourseData.flyerUrls[0].url.split('.').pop(),
            fileUrl2: CourseData.flyerUrls[1].url,
            fileType2: CourseData.flyerUrls[1].url.split('.').pop(),
          });
        }
        // console.log(CourseData.flyerUrls[1]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    getCourseDetails();
  }, []);

  const goToPayment = () => {
    let UserId = "";
    let FinalPrice = courseData.fees;
    axiosApiInstance.get(`${URL}/getProfile`).then(async (res) => {
      // console.log(res);
      if (res.status !== 500) {
        // debugger
        let User = res.data.User;
        UserId = User._id;
        let courses = [];
        courses.push({
          courseId: courseData._id,
          courseName: courseData.courseName,
          categoryId: courseData.categoryId,
          duration: courseData.duration,
          coursePrice: courseData.fees,
          totalPrice: courseData.fees,
          tax: 0,
        });
        let details = {
          courses: courses,
          userId: UserId,
          finalPrice: FinalPrice,
          clientName: User.fullName,
          phone: User.mobile,
          email: User.email,
        };
        // console.log(details);
        axiosApiInstance.post(`${URL}/initiatePayment`, details).then(res => {
          // console.log(res);
          var information = {
            action: "https://securegw.paytm.in/order/process",
            // action: "https://securegw-stage.paytm.in/order/process",
            params: res.data,
          };
          post(information);

        }
        )
        // ClientName = User.fullName;
        // Phone = User.mobile;
        // Email = User.email;
        //alert(User.profileImage);
        // setProfileImage(User.profileImage);
        // setFullName(User.fullName);
        // props.setProfile(User);
      }
    })
      .catch((err) => {
        console.log("err", err);
      });
    setShowModal(false);
  };

  const enrollmentClick = () => {
    let duration = { startDate, endDate };
    let enrollmentArray = [];
    enrollmentArray.push({ courseId: id, status: true, duration });
    // debugger
    axiosApiInstance.put(`${URL}/addEnrollment/${id}`, { enrollment: enrollmentArray })
      .then(res => {
        // debugger
        // console.log("enrollcourse",res);
        if (res.status === 200) {
          // props.history.push(`/Enrollment`)
          history.push(`/Enrollment`)
          swal("Course Enrolled Successfully", "", "success");
        } else {
          swal(res.data.message, "", "error")
        }
      }).catch(err => {
        console.log(err);
      })
  }



  const onClickInfo = () => {
    // window.open(fileData.fileUrl2, "_blank");
    // setShowImg(true);
    setToggled(!toggled);
  }

  return (
    <div>

      <Row>
        <ViewImg show={showImg}
          animation={true}
          url={fileData.fileUrl2}
          close={() => setShowImg(false)} />
        {/* {courseData.flyerUrls &&
          courseData.flyerUrls.map((data) =>
              <Col className="text-center mt-2 p-2">
                <img src={data.url} height="95%" width="85%" />
              </Col>
          )} */}

        <Col className="text-center mt-2 p-2">
          {loading ?
            <Loader />
            : <div></div>
          }
          {courseData.flyerUrls && courseData.flyerUrls.length > 0 && courseData.flyerUrls[0] != null
            ? (<FormGroup>
              {toggled && <span>
                <img src={fileData.fileUrl1} height="95%" width="85%" />
                {fileData.fileUrl2
                  ? <Tooltip title="More Info" aria-label="info"><IconButton><ChevronRightIcon button onClick={onClickInfo} fontSize="large" /></IconButton></Tooltip>
                  : null}
              </span>}
              {!toggled && <span>
                <img src={fileData.fileUrl2} height="95%" width="85%" />
                <Tooltip title="Less Info" aria-label="info"><IconButton><ChevronLeftIcon button onClick={onClickInfo} fontSize="large" /></IconButton></Tooltip>
              </span>}
              {/* {fileData.fileUrl2 
            ?(<span>
              <hr/>
              {!toggled && <span>
              <img src={fileData.fileUrl2} height="95%" width="85%" />
              </span>}
              {toggled ?
              <Link onClick={onClickInfo}><h5>{toggled && <span>+</span>}{!toggled && <span>-</span>} Info <InfoIcon color="primary"/></h5></Link>
              :null}
              </span>)
            :null} */}
            </FormGroup>)
            : ((<FormGroup className="text-left">
              <Card className="p-4 shadow">
                <Row>
                  <Col>
                    <p className="mt-3 pl-1">
                      <h4 className="hdtxt ">Course Detail {courseData.courseType !== undefined ? <span className="teamName pl-2">({courseData.courseType})</span> : null}</h4>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <Card className="CourseListCardWp pb-4 pt-5" style={{ border: 'none' }}>
                        <Row>
                          <Col md={4} style={{ margin: '15px auto' }}>
                            <img src={courseData.imageOrVideoPath && courseData.imageOrVideoPath.default} style={{ width: "100%" }} alt="Course Image" />
                            {/* <img src="D:\Work\Files\WhatsApp Unknown 2021-06-07 at 17.31.07" style={{width: "100%"}}  alt=""/> */}
                          </Col>
                          <Col md={8} className="CourseHeadDetails"><h3 className="prdctDetlTxt"><a href="#">{courseData.courseName}</a></h3>
                            <p className="Coursedescription"><span>{parse(courseData.description)}</span></p>
                            {/* <span className="teamName">- By {courseData.mainFaculty.facultyFullName}</span> */}
                            {/* <p className="Link"><span>Remove</span><span>Move to Whishlist </span></p>  */}
                            <p className="mt-3 pl-1">
                              <span className="teamName">duration - <span className="boldfont pl-2">{courseData.duration}</span></span> {"  "}
                              <span className="teamName ">Last updated - <span className="boldfont pl-2"> {moment(courseData.updatedAt).format('MM/YYYY')}</span> </span>
                            </p>
                            {courseData.startDate !== undefined ?
                              <p className="mt-3 pl-1">
                                <span className="teamName">Start date - <span className="boldfont pl-2">{moment(courseData.startDate).format('DD/MM/YYYY')}</span></span> {"  "}
                                <span className="teamName">End date   -<span className="boldfont pl-2">{moment(courseData.endDate).format('DD/MM/YYYY')}</span></span>
                              </p>
                              : null
                            }

                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Card>
            </FormGroup>))
          }
        </Col>
        <Col md={4} className="text-center mt-2">
          <Card
            className="priceTotl shadow-sm p-3"
            style={{ marginTop: "10rem" }}
          >
            <CardTitle className="applyCoupn">
              <BeenhereIcon />
              Enrollment
            </CardTitle>
            <Row>
              <Col className="mt-2">
                <Button className="w-100" onClick={showCourseModal}>
                  Enroll Now
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Modal
          show={showModal}
          onHide={onHide}
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modalWp"
        >
          <Modal.Header
            id="contained-modal-title-vcenter"
            closeButton
            onClick={onHide}
          >
            <Modal.Title>Course Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="text-center">
              <Row>
                <Col>
                  <CardText className="Coursetxt fontWeight6 mt-2 mb-2">
                    Course Name :
                  </CardText>
                </Col>
                <Col>
                  <CardText className="Coursetxt  mt-2 mb-2">
                    {courseData.courseName}
                  </CardText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardText className="Coursetxt fontWeight6 mt-2 mb-2">
                    Course Type :
                  </CardText>
                </Col>
                <Col>
                  <CardText className="Coursetxt  mt-2 mb-2">
                    {courseData.courseType}
                  </CardText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardText className="Coursetxt fontWeight6 mt-2 mb-2">
                    Course Duration :
                  </CardText>
                </Col>
                <Col>
                  <CardText className="Coursetxt  mt-2 mb-2">
                    {courseData.duration} days
                  </CardText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardText className="Coursetxt fontWeight6 mt-2 mb-2">
                    Fees :
                  </CardText>
                </Col>
                <Col>
                  <CardText className="Coursetxt mt-2 mb-2">
                    ₹ {courseData.fees}
                  </CardText>
                </Col>
              </Row>
            </Table>
          </Modal.Body>
          <Modal.Footer className="py-1 d-flex justify-content-center mt-2 mb-2">
            <Button variant="primary" onClick={enrollmentClick}>
              Make Payment
            </Button>
            <Button color="danger" onClick={onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </div>
  );
};
export default FlyerPage;
