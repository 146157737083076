import React, { Component } from 'react';
import Quiz from '../../../Components/Student/Test/Quiz';
import _ from 'lodash';
import '../../../Components/Student/Test/Test.css';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {Row,Col} from 'react-bootstrap';
import CountDownTimer from '../../../Components/Student/Test/CountDownTimer';
import Auth from '../../../ServerCall/Auth';

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      test: [],
      questionList: [],
      studentUserId: Auth.getUserId(),
      studentName: Auth.getUserName(),
      questionSet: {},
      timeCounter: 0,
      testHistory: {},
      //selectedAnswers : {},
      studentAnswer: [],
      answerTime: 0,
      totalMarks: 0,
      toatlTimeTook: 0
    };
    // this.setNextQuestion = this.setNextQuestion.bind(this);
    // this.setPreviousQuestion = this.setPreviousQuestion.bind(this);
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
  }

  async componentDidMount (){
    //debugger
    let tag = this.props.location.pathname.split("/").slice(-2)[1];
    let _id = this.props.location.pathname.split("/").slice(-2)[0];
    await this.getChapterTest(_id, tag);
  }

  timeCounterDecrement = async(input) =>{
    const {test} = this.state;
    if(input === 0 && test.timeLimitOption === "Question wise"){
      //await this.setState({timeCounter: input});
      //this.updateTestHistory(true);
      await this.setNextQuestion(true);
    }else if(input === 0 && test.timeLimitOption === "Complete Test"){
      this.updateTestHistory(true);
      this.props.history.push(`/Result/?id=${test._id}`);
    }else{
      await this.setState({timeCounter: input});
    }
  }

  getChapterTest = (chapterId, tag) => {
    var api = tag === "testId" ? "test" : "testByChapterId";
    axiosApiInstance.get(`${URL}/${api}/${chapterId}`)
    .then(res =>{
      if(res.status === 200){
          this.setState({test: res.data.Test})
          const result = _.flatMap(res.data.Test.questions, ({ _id, sectionName, description, questions }) =>
            _.map(questions, question => ({ _id, sectionName, description, ...question })) 
          );
          this.setState({questionList: result});
      }else{
        swal(res.data.message, "", "error")
      }
    }).catch(err => {
      console.log(err)
    })
  }

  async handleAnswerSelected(e){
    const {type, value, checked} = e.target;
    if(type === "checkbox"){
      var answer = [];
      if(!checked){
        answer = this.state.studentAnswer.filter(val => val !== value);
      }else{
        //answer = this.state.studentAnswer;
        answer = this.state.studentAnswer.concat(value);
        //answer.push(value);
      }
    }else{
      var answer = [];
      answer.push(value);
    }
    await this.setState({studentAnswer: answer})
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.questionList !== this.state.questionList){
      this.postTestHistory();
      this.setState({questionSet: this.state.questionList[0]});
    }
    if(prevState.counter !== this.state.counter){
      this.setState({questionSet: this.state.questionList[this.state.counter]})
    }
  }

  postTestHistory = async() => {
    const {studentUserId, test, studentName, questionList} = this.state;
    let totalScore = questionList.reduce(function(prev, current) {
      return prev + +current.score
    }, 0);
    let data = {
      studentUserId: studentUserId,
      studentName: studentName,
      testId: test._id,
      testName: test.testName,
      testTimeLimit: test.duration,
      timeTookToTest: null,
      totalScore: totalScore,
      totalMarksObtained: 0
    }

    await axiosApiInstance.post(`${URL}/testHistory`, data)
    .then(res =>{
      if(res.status === 201){
        this.setState({testHistory: res.data.TestHistory})
      }else{
        swal(res.data.message, "", "error")
      }
    }).catch(err => {
      console.log(err)
    })
  }

  updateTestHistory = async(timeout) => {
    const { questionSet, test, studentAnswer, timeCounter, answerTime, totalMarks, toatlTimeTook} = this.state;
    var correct;
    let answerScore;

    if(studentAnswer.join() === questionSet.answer.join()){
      correct = true;
      answerScore = questionSet.score;
    }else{
      correct = false;
      answerScore = 0;
    }
    
    await this.setState(prevState => {
      return {totalMarks: prevState.totalMarks + answerScore}
    });

    let timeTookToAnswer;
    if(timeout == true){
      timeTookToAnswer = test.timeLimitOption === "Question wise" ? questionSet.timeLimit * 60 : test.duration * 60;
      this.setState({answerTime: timeTookToAnswer});
    }else{
      timeTookToAnswer  = test.timeLimitOption === "Question wise" ? (questionSet.timeLimit * 60) - timeCounter : (test.duration * 60) - timeCounter;
      this.setState({answerTime: timeTookToAnswer});
    }
    let timeTook = test.timeLimitOption === "Question wise" ? timeTookToAnswer : timeTookToAnswer - answerTime;
    this.setState({toatlTimeTook: timeTook});
    let timeTookToTest = test.timeLimitOption === "Question wise" ? toatlTimeTook + timeTook : timeTookToAnswer;
    let answerHistory = {
      questionId: questionSet._id,
      correctAnswer: questionSet.answer,
      studentAnswer: studentAnswer,
      correct: correct,
      timeTookToAnswer: timeTook,
      marksScored: answerScore
    };
    
    let testHis = this.state.testHistory;
    testHis.answers = [...testHis.answers, answerHistory];
    testHis.timeTookToTest = timeTookToTest;
    testHis.totalMarksObtained = this.state.totalMarks;
    this.setState({testHistory: testHis});
    await axiosApiInstance.put(`${URL}/testHistory/${testHis._id}`,testHis)
    .then(res =>{
      if(res.status === 200){
        //  console.log(res,"history updated");
      }else{
        swal(res.data.message, "", "error");
      }
    }).catch(err => {
      console.log(err);
    })
  }

  async setNextQuestion(timeout) {
    const {counter, questionList, test} = this.state;
    // if(this.state.disableOption !== true){
    await this.updateTestHistory(timeout);
    // }

    if(counter + 1 < questionList.length){
      if(timeout){
        swal({
          title: 'Time Out',
          text: 'Redirecting to next question...',
          icon: 'info',
          timer: 1800,
          buttons: false,
          })
      }
      await this.setState({ counter: counter + 1, studentAnswer: []});
    }else{
      if(timeout){
        swal({
          title: 'Time Out',
          text: 'Test submitted...',
          icon: 'info',
          timer: 1800,
          buttons: false,
          })
      }
      this.props.history.push(`/Result/?id=${test._id}`);
    }
      //timeLimit: this.state.chapterTest[0].questions[counter].questions[0].timeLimit * 60
    
    // var prevQuestionId = this.state.testHistory.answers;
    // var b = _.find(prevQuestionId, ['questionId', this.state.question_Id]);
    // console.log("b",b);
    // if(_.isObject(b)){
    //    await this.setState({disableOption:true});
    // }
  }

  // setPreviousQuestion = async()=> {
  //   const counter = this.state.counter - 1;
  //   await this.setState({ counter: counter });
  // }

  renderQuiz() {
    const {questionSet, counter, test, questionList} = this.state;
    return (
      <div style={{width: '100%'}}>
      {Object.keys(questionSet).length > 0 ?
      <Quiz duration={test.duration} counter={counter} questionSet={questionSet} totalQuestions={questionList.length} setNextQuestion={() => this.setNextQuestion(false)}
        //setPreviousQuestion={() => this.setPreviousQuestion()} disableOption={this.state.disableOption}
        onAnswerSelected = {this.handleAnswerSelected} timeCounterDecrement={this.timeCounterDecrement}
      /> : <h4 className="text-center">No Questions Available</h4>}</div>
    );
  }

  render() {
    const {test} = this.state;
    return (
      <div className="testWp text-center">
        {Object.keys(test).length > 0 ?
          <div>
            <h3 className="text-center blueText">{test.testName}</h3>
            {test.duration !== null ? <Row>
            <Col md={12} className="test-timer">
              <CountDownTimer timeLimit={test.duration} timeCounterDecrement={this.timeCounterDecrement}/>
            </Col>
          </Row> : null}
            {this.renderQuiz() }
          </div> : <h4></h4>
        }
      </div>
    );
  }
}

export default Test;