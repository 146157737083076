import React from "react";
import swal from "sweetalert";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "../Validation/captchaModal.js";
import {  Form,  Card,  FormGroup,  Label,  Input,  Col,  Row,  Container,  Button,} from "reactstrap";
import {  AvForm,  AvField,  AvGroup,  AvInput,  AvFeedback} from "availity-reactstrap-validation";
import logo from '../../../Images/con_logo-old.png'

import axios from "axios";
import { URL } from "../../../ServerCall/Config";
import { Modal } from "react-bootstrap";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
);
export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      email: "", 
      error: ""
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }

  handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    let error = this.state.error;
    this.setState({ [name]: value });
    switch (name) {
      case "email":
        error = validEmailRegex.test(value)
          ? ""
          : "Valid email address Required";
        break;
    }
    this.setState({ error: error });
  };

  clearForm=()=> {
    this.form && this.form.reset();

  }
  componentDidMount () {
    loadCaptchaEnginge(6); 
      document.body.style.overflow = "auto";
      document.body.style.backgroundImage = "url(https://consiliumacademy.com/images/login-background.jpg)";
      document.body.style.backgroundSize = "cover";
 };
  handleValidSubmit(event, values) {
    //debugger;
    let { email } = this.state;
    this.setState({show:true, email: values.email });
    let user_captcha = document.getElementById('user_captcha_input').value;
     if (validateCaptcha(user_captcha)==true) {
        //  console.log(this.state.email);
        axios.post(`${URL}/forgotPassword`, { email: email })
          .then((res) => {
            // debugger;
            // console.log(res);
            if (res.data.status == 400) {
              this.setState({ loading: false });
              swal("User not Found", "", "error");
            } else {
              this.setState({ loading: false });
              swal(
                "Reset Link is sent to Email address!",
                "",
                "info"
              );
            }
          })
          .catch((err) => {
            swal("Something went wrong--", "Please try again!", "error");
            console.log("", err);
          });
         loadCaptchaEnginge(6); 
         document.getElementById('user_captcha_input').value = "";
     }

     else {
         swal("Incorrect captcha entered","","error");
         document.getElementById('user_captcha_input').value = "";
     }
    this.clearForm();
  }
  handleInvalidSubmit(event, errors, values) {
    this.setState({ email: values.email, error: "Invalid email" });
    console.log(this.state.error);
  }

  render() {
    const { error,show } = this.state;
    return (
      <div className="h-100">
       <Container fluid className="CouseMasterWp FormWp h-100">
      <Row className="h-100 align-items-center justify-content-center ">
        <Col md={6}>
          <Card className="shadow px-4 py-5 LoginCard">
          <Row>
            <Col md={12}>
              <Row>
                <Col className="mb-4">
                  <div className="logoWp">
                    <img src={logo} alt="logo" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <h3 className="text-center blueText">Forgot Password</h3>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="loginWp">
                  <AvForm
                    ref={c => (this.form = c)}
                    onValidSubmit={this.handleValidSubmit}
                    onInvalidSubmit={this.handleInvalidSubmit}
                  >
                    <Row>
                      <Col md={12} className="colWp">
                        <AvGroup>
                          <Label className="text-left">Email</Label>
                          <AvInput
                            type="email"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            className="userinput"
                            required
                            placeholder="Enter e-mail address"
                          />
                          {error !== "" ? (
                            <Label style={{ fontSize: 12, color: "red" }}>
                              {error}
                            </Label>
                          ) : null}
                          <captcha/>
                          {/* <span style={{ fontSize:"12px",marginTop:'5px'}} >Password reset instructions will be sent to this email address.</span> */}
                        </AvGroup>
                      </Col>
                    </Row>
                    <div>
                      <div className="center-block text-center">
                          <div className="form-group">
                              <div className="col mt-3">
                                  <LoadCanvasTemplate  />
                              </div>
                              <div className="col md-3">
                                  <div><Input className="captcha-input-box" placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text"/></div>
                              </div>
                          </div>
            
                      </div>
                  </div>
                    <Row>
                      <Col className="text-center mt-3">
                        <div className="center-block text-center">
                          <Button
                            className="text-center mr-3 btnblue pl-3 pr-3"
                          >
                            Send Reset Link
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
     </Row>
    </Container>
      </div>
    );
  }
}
