import React from "react";
import { Container ,Row,Col,Card} from "react-bootstrap";
import {Breadcrumb, BreadcrumbItem} from 'reactstrap'
import "bootstrap/dist/css/bootstrap.min.css";
// import ChangePwd from '../../../components/User/UserProfileSetting/ChangePwd'
import ChangePwdForm from '../../../Components/Student/ProfileSetting/ChangePwdForm'
import ProfileSetForm from '../../../Components/Student/ProfileSetting/ProfileSetForm'
//import ProfileSetting from '../../../components/User/UserProfileSetting/ProfileSetting'
//import ProfileNotification from '../../../components/User/UserProfileSetting/ProfileNotification'
import ProfileNotification from '../../../Components/Student/ProfileSetting/ProfileNotification'

export default class UserProfileSetting extends React.Component {
  render() {
    return (
      <Container fluid className="pl-0 pr-0">
        <Card className="shadow pb-4 pl-4 pr-4">
        {/* <div className="BreadcrumbWp mt-3">
            <h4>Profile Setting</h4>
            <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" href="/Dashboard" className="b_link">Dashboard</BreadcrumbItem>
            <BreadcrumbItem tag="a" href="#">Profile Setting</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
        </div> */}
       <Row> 
       <Col md="6" sm={12} xs={12}>
           <Row>
               <Col><ProfileSetForm/></Col>
           </Row>
           <Row>
               <Col></Col>
           </Row>       
       </Col>
       <Col md="6" sm={12} xs={12}>
            {/* <Row>
               <Col><ProfileNotification/></Col>
           </Row> */}
           <Row>
               <Col><ChangePwdForm/></Col>
           </Row> 
       </Col>
       </Row>
       </Card>
      </Container>
    );
  }
}
