import React from 'react'
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import {Label,Button,Row,Col,Card,Container} from 'reactstrap';
import CheckIcon from '@material-ui/icons/Check';
const headerStyle = {
    backgroundColor: '#4c8db5',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default class ResponseTab extends React.Component{
    constructor(props) {
      //debugger
        super(props);
        this.state = {
            columns: [  
                { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
                { title: "Id", field: "studentUserId",hidden:true},
                { title: "Student Name", field: "studentName"},
                { title: "Marks Scored", field: "totalMarksObtained"},
               
              ],
        }
    }

    render()
    {
        const{columns} =this.state;
        return(
        <Card className="p-4">
          <Row>
              <Col> <h3 className="FormHdTxt mb-3">{this.props.testName}</h3> </Col>
            </Row> 
            <div className="analyze-view-summary-table">
              <Row>
                <Col className="simple-data-control-container">
                  <div className="simple-data-control-container">
                    <div className="simple-data-control-content office-form-theme-primary-foreground" aria-hidden="true" role="presentation">{this.props.responseCount}</div>
                    <div className="simple-data-control-description" aria-hidden="true" role="presentation">Responses</div>
                  </div>
                </Col>
                <Col>
                <div className="simple-data-control-container">
                  {this.props.timeAverage.h === "00" || this.props.timeAverage.h === 0 ?
                    <div className="simple-data-control-content office-form-theme-primary-foreground" aria-hidden="true" role="presentation">{this.props.timeAverage.m+":"+this.props.timeAverage.s}</div>:
                    <div className="simple-data-control-content office-form-theme-primary-foreground" aria-hidden="true" role="presentation">{this.props.timeAverage.h+":"+this.props.timeAverage.m+":"+this.props.timeAverage.s}</div>
                  }
                    <div className="simple-data-control-description" aria-hidden="true" role="presentation">Average time to complete</div>
                  </div>
                </Col>
                <Col>
                <div className="simple-data-control-container">
                    <div className="simple-data-control-content office-form-theme-primary-foreground" aria-hidden="true" role="presentation">Active</div>
                    <div className="simple-data-control-description" aria-hidden="true" role="presentation">
                        Status
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <hr/>
            {
                 this.props.testResponse == true ? 
                 <Row className="mb-4 mt-4">
                <Col className="tableWp ResponseTbl">
                        <Row>
                        <Col className="DashboardTbl">
                            <Table  pageSize={10} paging={true} data={this.props.studentList} columns={columns} search={false} headerStyle={headerStyle} rowStyle={rowStyle} 
                            
                            />
                        </Col>
                  </Row>            
              </Col>
          </Row>
          :
          <Row> 
          <Col className="text-center">
            <h3 className="responseTxt pt-3">This test doesn't have any responses yet.
            </h3>
          </Col>
        </Row>
            }
            
        
          </Card>
            
        )
    }
}