import React from 'react'
import AddTest from '../../../Components/Admin/AddTestDetail/AddTest'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import swal from "sweetalert";
import Auth from '../../../ServerCall/Auth';

const userId = Auth.getUserId();
const role  = Auth.getUserRole();
export default class AddTestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList:[],
      courseId:null,
      testName:"",
      description:"",
      duration:null,
      shuffle:false,
      //totalMarks:null,
      testType:"",
      completeTestType:"",
      formErrors:{
        courseId:'',
        testName:'',
        description:'',
        duration:'',
        testType:"",
        completeTestType:"",
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioButton = this.handleRadioButton.bind(this);
  }

    formvalid = formErrors =>{
      //debugger
      let valid = true;
      let {courseId,testName,description,duration,testType,completeTestType} = this.state;
              //courseId !== null?formErrors.courseId ="":formErrors.courseId = "Please select course!";
              testName !== ""?formErrors.testName ="":formErrors.testName = "Please enter test name.!";
              description !== ""?formErrors.description ="":formErrors.description = "Please enter test description!";
              //duration !== null?formErrors.duration ="":formErrors.duration = "Please enter duration of test!";
              testType !== ""?formErrors.testType ="":formErrors.testType = "Please select test type!";
              completeTestType !== ""?formErrors.completeTestType ="":formErrors.completeTestType = "Please choose time limit option !";
              
              this.setState({formErrors:formErrors})
                    Object.values(formErrors).forEach(val =>{
                      //debugger
                      val !== "" && (valid = false);
              }) ;
              return valid;
    } 

    handleChangeShuffle = e => {
      const {checked} = e.target;
      this.setState({shuffle:checked});
    }

    handleChange = e =>{
     // alert(e)
      //debugger
      const { name,value } = e.target;
      this.setState({[name]: value});
      let formErrors = this.state.formErrors;

      switch(name){
        case 'testName':
          formErrors.testName = value === ""?"Please enter test name.!":"";
        break;
        case 'description':
          formErrors.description = value === ""?"Please enter test description!":"";
        break;
        case 'testType':
          formErrors.testType = value === ""?"Please select test type!":"";
        break;
      }
      this.setState({formErrors:formErrors})  
    }
    handleRadioButton = e =>{
     //debugger
      const { name,value} = e.target;
      this.setState({completeTestType:value});
      if(value == "Question wise")
      {
        this.setState({duration:null});
      }
      let formErrors = this.state.formErrors;
      switch(this.state.completeTestType){
        case 'completeTestType':
          formErrors.completeTestType = value === ""?"Please choose time limit option":"";
        break;
    
      }     
    }

    handleSubmit = e => {
      e.preventDefault();
      let {testName,courseId,description,duration,testType,completeTestType,shuffle} = this.state;
      let type =  testType;
      let timeLimitOption = completeTestType;
      let facultyUserId = userId;
      const isValid = this.formvalid(this.state.formErrors);
      if(isValid){
        axiosApiInstance.post(`${URL}/test`,{testName,shuffle,facultyUserId,type,description,courseId,duration,timeLimitOption})
        .then(res=>{
          let Test = res.data.Test;
          swal("Test Created successfully", "Please add section and questions for the test!", 'success')
          this.props.history.push(`/AddSection?id=${Test._id}`)
          //console.log(res);
        }).catch(err=>{
          console.log(err);
        });
      }
    }

    onClickCancel = () =>{
      let {formErrors}=this.state;
      this.setState({
        courseId:null,
        testName:"",
        description:"",
        duration:null,
        shuffle:false,
        testType:"",
        completeTestType:"",
      
      })
      formErrors.testName='';
      formErrors.description='';
      formErrors.testType='';
      formErrors.completeTestType='';
      formErrors.duration ='';
      formErrors.courseId='';
    }

    getCourseData =()=> {
      let user_id = userId;
      var api  = ``;
        if(role === "superAdmin"){
         api = `${URL}/allCourse`;
      }
      else{
        api = `${URL}/courseByFaculty/${user_id}`;
      }
      axiosApiInstance.get(api)
      .then(res =>{
          //debugger
          const response = res.data.Course;
          //console.log(res);
          let  CourseData = [];
         // debugger
          response&&response.map(data =>{
              CourseData.push({text:data._id,value:data.courseName})
            })
          this.setState({courseList: CourseData});
        }).catch(err=>{
          console.log(err)
        })
    }
    
    componentDidMount (){
      //debugger
     this.getCourseData();
    }

    selectedCourse=(value,name)=>{
      //debugger
      this.setState({courseId:value})
    }
  
    render() {
      return (
      <AddTest
          courseList={this.state.courseList}
          selectedCourse={this.selectedCourse}
          courseId={this.state.courseId}
          checkedShuffle={this.state.shuffle}
          handleChange={this.handleChange}
          testName={this.state.testName}
          description={this.state.description}
          duration={this.state.duration}
          handleChangeShuffle={this.handleChangeShuffle}
          testType={this.state.testType}
          completeTestType={this.state.completeTestType}
          handleSubmit={this.handleSubmit}
          onClickCancel={this.onClickCancel}
          handleRadioButton={this.handleRadioButton}
          formErrors = {this.state.formErrors}
         />
      );
    }
  }
  
  
  
  