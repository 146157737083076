import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import TextEditor from '../../../Components/Common/TextEditor';
import { URL } from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';
import moment from 'moment';
// import RichTextEditor from 'react-rte';
import DoneIcon from '@material-ui/icons/Done';
import { replay } from 'video-react/lib/actions/player';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import PaginationLine from '../../../Components/Student/Course/PaginationLine';
import { Fab } from '@material-ui/core';
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
}));

const ForumDetail = (props) => {
    // debugger
    // console.log("Props in Forum Details:",props)
    const classes = useStyles();
    const role = Auth.getUserRole();
    const [question, setQuestion] = useState('');
    const [getReply, setReply] = useState([]);
    const [editorMessage, setEditorMessage] = useState('');
    const [satisfied, setsatisfied] = useState(true);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [noOfPages, setnoOfPages] = useState('');
    let [filteredSearchData, setfilteredSearchData] = useState([]);
    const [filterValue, setfilter] = useState('');
    const [questions, setquestions] = useState([]);
    const [errorValue, setErrorValue] = useState('');
    const [selectedValue, setselectedValue] = useState([]);
    const [scheduleIdForCheck, setScheduleId] = useState(null);
    const [reply, setreply] = useState([]);
    const [sCId, setsCId] = useState(props.match.params.batchId);
    let location = useLocation();
    //console.log("location",location);
    const [value, setValue] = useState('');
    // let qsId = null;
    const [qsId, setQsId] = useState('');
    var Id = props.match.params.id;
    const userId = Auth.getUserId();
    const userName = Auth.getUserName();

    const handleChange = (event, value) => {
        //debugger
        setPage(value);
    };

    var scheduleId = null;

    const getCourseScheduleId = async () => {
        //  debugger
        if (role === "faculty") {
            let id = props.match.params.batchId;
            setScheduleId(id)
            scheduleId = id;

            setQsId(props.match.params.questionId);
        }
        else {
            setQsId(props.location.state.questionId);
            axiosApiInstance.get(`${URL}/courseScheduleByCourseIdUser/${Id}`)
                .then(async (res) => {
                    // debugger
                    // console.log(res)
                    if (res.status === 200) {
                        scheduleId = res.data.Course._id;
                        setScheduleId(res.data.Course._id);
                        await getForum(scheduleId);
                    }
                    else {
                        scheduleId = null;
                        setScheduleId(null);
                        await getForum(scheduleId);
                    }
                })
        }
        await getForum(scheduleId);
    }

    const getForum = (cscheduleId) => {
        let sid = cscheduleId;
        axiosApiInstance.get(`${URL}/forum/${Id}/${sid}`)
            .then(res => {
                //  console.log(res,"res");
                if (res.status === 200) {
                    let forumData = res.data.forum[0];
                    let questions = [];
                    let rep = [];
                    forumData.questions && forumData.questions.map(item => {
                        if (item._id === qsId) {
                            questions.push(item);
                            item.reply.map(i => {
                                rep.push(i);
                            })
                        }
                    });
                    setQuestion(questions);
                    setReply(rep);
                    let pages = Math.ceil(rep.length / itemsPerPage);
                    setnoOfPages(pages);
                    const lowercasedFilter = filterValue.toLowerCase();
                    var filteredData = rep.filter(f =>
                        f.name.toLowerCase().includes(lowercasedFilter));
                    setfilteredSearchData(filteredData);
                }
            }).catch(err => {
                console.log(err);
                return false
            });
    }

    React.useEffect(() => {
        getCourseScheduleId();
    }, [qsId]);

    function isValid() {
        let isValid = true;
        if (editorMessage === "" || editorMessage === "<p><br></p>") {
            setErrorValue("Please Enter a Reply!")
            isValid = false;
        }
        else {
            setErrorValue('')
            isValid = true;
        }

        return isValid;
    }

    function postReplyHandle() {
        let obj = {
            "courseId": Id,
            "questionId": qsId,
            "reply": [{
                "description": editorMessage,
                "name": userName,
                "userId": userId,
                "corretAnswer": false,
                "date": new Date().toISOString()
            }],
            "courseScheduleId": scheduleIdForCheck === "null" ? null : scheduleIdForCheck
        };
        let isValidStatus = isValid();
        if (isValidStatus === true) {
            axiosApiInstance.put(`${URL}/addReplyForum`, obj)
                .then((res) => {
                    //console.log("reply",res);
                    if (res.status === 200) {
                        // debugger
                        let forumData = res.data.forum;
                        let questions = [];
                        let rep = [];
                        // debugger
                        forumData.questions && forumData.questions.map(item => {
                            if (item._id === qsId) {
                                questions.push(item);
                                item.reply.map(i => {
                                    rep.push(i);
                                })
                            }
                        });
                        setQuestion(questions);
                        setReply(rep);
                        let pages = Math.ceil(rep.length / itemsPerPage);
                        setnoOfPages(pages);
                        const lowercasedFilter = filterValue.toLowerCase();
                        var filteredData = rep.filter(f =>
                            f.name.toLowerCase().includes(lowercasedFilter));
                        setfilteredSearchData(filteredData);
                        swal("Reply Added", "", "success")
                        setEditorMessage('');
                    }
                })
                .catch(err => {
                    console.log(err, "err");
                })
        }

    }

    function onChangeEdit(value) {
        // debugger
        setEditorMessage(value);
    }

    function getTime(cretedDate) {
        var datePast = new Date(cretedDate);
        var dateNow = new Date();

        var seconds = Math.floor((dateNow - (datePast)) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
        // console.log("days",days,"hr",hours,"min",minutes);
        return <small class="text-muted ml-2">{days === 0 && hours !== 0 ? hours + "hour" + minutes + "minutes ago" : hours === 0 && days === 0 ? minutes + "minutes ago" : days > 0 ? moment(new Date(cretedDate)).format('MMMM Do YYYY, h:mm:ss a') : days + "day" + hours + "hour" + minutes + "minutes ago"}</small>
    }

    function satifyChangeHandle(replyUserName, replyUserId, answer, replyId) {
        if (question[0].name === userName && question[0].studentId === userId) {
            let obj =
            //  {
            //     "courseId":Id,
            //     "questionId":qsId,
            //     "replyId":replyId,
            //     "corretAnswer":!answer,
            //     "name":userName,
            //     "userId":userId
            // }
            {
                "questionId": qsId,
                "courseScheduleId": scheduleIdForCheck === "null" ? null : scheduleIdForCheck,
                "replyId": replyId,
                "corretAnswer": !answer,
                "userId": userId
            }
            // axiosApiInstance.put(`${URL}/updateReplyForum`,obj).then(res=>{debugger;console.log(res)})
            axiosApiInstance.put(`${URL}/updateReplyAnswer/${Id}`, obj)
                .then(res => {
                    if (res.status === 200) {
                        let forumData = res.data.forum[0];
                        let questions = [];
                        let rep = [];
                        forumData.questions && forumData.questions.map(item => {
                            if (item._id === qsId) {
                                questions.push(item);
                                item.reply.map(i => {
                                    rep.push(i);
                                })
                            }
                        });
                        setQuestion(questions);
                        setReply(rep);
                        let pages = Math.ceil(rep.length / itemsPerPage);
                        setnoOfPages(pages);
                        const lowercasedFilter = filterValue.toLowerCase();
                        var filteredData = rep.filter(f =>
                            f.name.toLowerCase().includes(lowercasedFilter));
                        setfilteredSearchData(filteredData);
                    }
                })
                .catch(err => {
                    console.log(err, "err");
                })
        }

        //setsatisfied(!satisfied);
    }

    console.log(sCId);

    return (
        <div>
            <div class="inner-main-body p-2 p-sm-3  forum-content">
                {role === 'faculty'
                    ? <Link to={{ pathname: `/TabCourseForum/${Id}/${sCId}`, state: { forumTab: "StudentForum", batchId: sCId } }}><Button class="btn btn-light btn-sm mb-3 has-icon"><i class="fa fa-arrow-left mr-2"></i>Back</Button></Link>
                    : <Link to={{ pathname: `/TabCourseForum/${Id}`, state: { forumTab: "StudentForum", batchId: sCId } }}><Button class="btn btn-light btn-sm mb-3 has-icon"><i class="fa fa-arrow-left mr-2"></i>Back</Button></Link>
                }
                <div class="card mt-2 mb-2">
                    <div class="card-body">
                        {question && question.map(item =>
                            <div class="media forum-item">
                                <div className="pr-3">
                                    <Avatar className={classes.orange}>
                                        {item.name.charAt(0) + " "
                                            // +item.name.charAt(11)
                                        }
                                    </Avatar>
                                </div>
                                <div class="media-body ml-3">
                                    <span class="text-secondary ItmNme">{item.name}</span>
                                    <span className="timeTxtclass">{getTime(item.date)}</span>
                                    <h5 class="mt-1 itemHeading">{item.heading}</h5>
                                    <div class="mt-2 font-size-sm">
                                        <p className="ItemMeassge " dangerouslySetInnerHTML={{ __html: item.message }} />
                                    </div>
                                </div>

                                <div class="text-muted small text-center">
                                    {/* <span class="d-none d-sm-inline-block"><i class="far fa-eye"></i> 19</span> */}
                                    <span><i class="far fa-comment ml-2"></i>{item.reply.length}</span>
                                </div>
                            </div>

                        )
                        }
                    </div>
                </div>
                <>
                    {getReply && getReply && filteredSearchData.slice(((page - 1) * (itemsPerPage)), (page) * (itemsPerPage)).map((rep, index) =>
                        <div class="card mb-2">
                            <div class="card-body">
                                <div class="media forum-item">
                                    <div className="pr-3">
                                        {/* <a href="#" class="card-link">
                                            <img src="https://bootdey.com/img/Content/avatar/avatar2.png"
                                                class="rounded-circle" width="50" alt="User" />
                                            <small class="d-block text-center text-muted">Pro</small>
                                            </a> */}
                                        <Avatar className={classes.orange}>
                                            {rep.name.charAt(0) + " "
                                                // +item.name.charAt(11)
                                            }
                                        </Avatar>
                                        <DoneIcon status={rep.corretAnswer} onClick={() => satifyChangeHandle(rep.name, rep.userId, rep.corretAnswer, rep._id)} className={rep.corretAnswer ? "StisfychekBtn btnFont mt-1" : "grychekbtn btnFont mt-1"} />
                                    </div>

                                    <div class="media-body ml-3">
                                        <span class="text-secondary">{rep.name}</span>
                                        {getTime(rep.date)}
                                        <div class="mt-3 font-size-sm">
                                            <p dangerouslySetInnerHTML={{ __html: rep.description }} />
                                        </div>
                                        {/* <button class="btn btn-xs text-muted has-icon"><i class="fa fa-heart" aria-hidden="true"></i>1</button> */}
                                        {/* <Button>Reply</Button> */}

                                    </div>
                                    {/* <Button onClick={() =>setsatisfied(!satisfied)} className={satisfied ? "StisfychekBtn mt-4" : "mt-4"}>
                                                { satisfied ? <Label className="stisfiedchecked mb-0"> <CheckIcon/> Satisfied  </Label>:<span> You Satisfied </span> 
                                       
                                                }
                                             </Button> */}

                                </div>
                            </div>
                        </div>
                    )}
                </>
                <Row>
                    <Col className="mt-3">
                        <PaginationLine
                            noOfPages={noOfPages}
                            page={page}
                            handlePageChange={handleChange}
                        />
                    </Col>
                </Row>
                {getReply.length <= 0 ?
                    <div class="card mb-2">
                        <div class="card-body text-center" >No reply yet! be the first to reply</div>
                    </div> : null
                }
                <div className="mt-3">
                    <h3 className="ReplyText pb-2 pt-2">Reply</h3>
                    <TextEditor onChange={onChangeEdit} value={editorMessage} /><br />
                    {errorValue != "" ? <span style={{ color: 'red' }}>{errorValue}</span> : null}<br />
                    <Button className="mt-3" onClick={postReplyHandle}>Post Your Answer</Button>
                </div>
            </div>
        </div>

        // </div>

    )
}
export default ForumDetail;