import React from 'react';
import  {AvForm, AvField, AvGroup, AvInput,AvFeedback }  from 'availity-reactstrap-validation';
import { Button,Form, FormGroup,Col,Row,Card} from 'react-bootstrap';
import { Input,Label} from 'reactstrap';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import swal from 'sweetalert';
import {URL,mediaUrl} from '../../../ServerCall/Config';
import {Link} from 'react-router-dom';
import ProfileImg from './ProfileImg';
//import axios from 'axios';
import MediaAxiosApiInstance from '../../../ServerCall/MediaAxios.Instance';
import Auth from '../../../ServerCall/Auth';
import { setProfile } from '../../../redux/action/profileAction';
import { connect } from 'react-redux'
let currUser = JSON.parse(localStorage.getItem("userData"));
//const token = Auth.getToken();

// const configImage = {
//   headers: {
//     'content-type': 'multipart/form-data',
//     'Authorization': `Bearer ${token}`,
//   }
// };

const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

class ProfileSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImage:'',
      profileFile:[],
      email:"",
      mobile:"",
      fullName:"",
      CurrPwd:'',
      NewPwd:'',
      Phone:'',
      formErrors:{ 
        email:"",
        mobile:"",
        fullName:"",
      },
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formvalid = formErrors =>{
    //debugger
    let valid = true;
    let {fullName,email,mobile} = this.state;
    let phone = mobile.toString();
        fullName !== ''?formErrors.fullName ="":formErrors.fullName = "Name is required.";
        validEmailRegex.test(email)?formErrors.email ="":formErrors.email = "Valid email address required.";
        // phone.length == 10?formErrors.mobile ="":formErrors.mobile = "Maximum 10 numbers Req.";
        phone !== ''?formErrors.mobile ="":formErrors.mobile = "Mobile number required.";
            this.setState({formErrors:formErrors})
                  Object.values(formErrors).forEach(val =>{
                    //debugger
                    val !== "" && (valid = false);
            }) ;
            return valid;
} 
   
  handleChange(event) {
    const { name,value} = event.target;
    this.setState({[name]: value});
    let formErrors = this.state.formErrors;
    switch(name){
      case 'fullName':
        formErrors.fullName = value !== "" ? "":"Name is required.";
        break;
        case 'mobile':
          // formErrors.mobile = value.length == 10? "":"Mobile numbers required.";
          formErrors.mobile = value !== ''? "":"Mobile numbers required.";
        break;
        case 'email':
        formErrors.email =  validEmailRegex.test(value)?"":"Valid email address required.";
      break;
    }
    this.setState({formErrors:formErrors});
  }

  onClickCancel = () =>{
    axiosApiInstance.get(`${URL}/getProfile`)
    .then(res=>{
      // console.log(res);
      let User = res.data.User;
      this.setState({
        email:User.email,
        fullName:User.fullName,
        mobile:User.mobile,
        formErrors:{
          fullName:"",
          mobile:"",
          email:"",
          LinkDin:""
        }
      });
      this.props.setProfile(User);
    }).catch(err=>{
      console.log(err);
    });
  }

   componentDidMount(){
    axiosApiInstance.get(`${URL}/getProfile`)
    .then(async(res)=>{
      // console.log(res);
      if(res.status !== 500){
       // debugger
        let User = res.data.User;
        await this.setState({
          email:User.email,
          fullName:User.fullName,
          mobile:User.mobile,
          profileImage:User.profileImage
        });
        this.props.setProfile(User);
      }
    }).catch(err=>{
      console.log("err",err);
    })
  }

  uploadImageFile =async(file,proImage)=>{
  //  debugger
    let filesdata= [];
    filesdata.push(file);
    await this.setState({profileFile:filesdata,profileImage:proImage});
    //console.log("profileImage",this.state.profileImage); 
  }

  handleSubmit(event) {
    event.preventDefault();
    let {fullName,email,mobile,profileImage,profileFile} = this.state;
    const isValid = this.formvalid(this.state.formErrors);
    if(isValid){
      //debugger
      if(profileFile.length > 0){
        //debugger
        const formData = new FormData();
        profileFile.forEach((item) => {
            formData.append('photos',item);
        })
        formData.append('name',fullName);
      
          MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`,formData)
          .then(async(res) =>{ 
            //debugger
            if(res.status === 200){
              //debugger
              let profileImage = res.data.data[0].url;
           //   await this.setState({profileImage:profile});
              axiosApiInstance.put(`${URL}/updateProfile`,{fullName,email,mobile,profileImage})
              .then(async(res)=>{
                //debugger
                // console.log(res);
                if(res.data.status == 200){
                  let user = res.data.User;
                  // console.log(user);
                  this.props.setProfile(user);
                  swal("Profile updated successfully", "", 'success');
                }
                else{
                  swal("Something went wrong", "Please try again!", 'error');
                }
              }).catch(err=>{
                swal("Something went wrong", "Please try again!", 'error');
              })
            }
           // console.log("res",res); 
          })
          .catch(err=>{
            console.log("err",err)
          })
      }
      else{
        axiosApiInstance.put(`${URL}/updateProfile`,{fullName,email,mobile,profileImage})
        .then(async(res)=>{
          if(res.data.status == 200){
            let user = res.data.User;
            this.props.setProfile(user);
            this.setState({profileImage:user.profileImage});
            swal("Profile updated successfully", "", 'success');
          }
          else{
            swal("Something went wrong", "Please try again!", 'error');
          }
        }).catch(err=>{
          swal("Something went wrong", "Please try again!", 'error');
        })
      }
    }
  }
  render(){
    let {formErrors} = this.state;
    //alert(this.state.profileImage);
    return (
      <div className="wrp whitebg  userProfWp pl-4 pr-4 pt-4">
        <Row>
          <Col md={9} className="mb-3">
          <h5 className="hdtxt">Profile Setting</h5>
          </Col>
          <Col md={3}>
          {/* <a href="/EditProfileSetForm" className="float-right">Edit</a> */}
          {/* <Link to="/EditProfile" className="float-right">Edit</Link> */}
          </Col>
        </Row>
        <Card className="p-3">
      <Row>
        <Col>
      <AvForm onSubmit={this.handleSubmit}>
      <AvGroup>
        <Row>
          <Col md={3}>
            <div className="profimgWp">
            {/* <img src={user}/> */}
            <ProfileImg imageFile={this.uploadImageFile} profileImage={this.state.profileImage}/>
            </div>
          </Col>
          <Col md={9} className="UsrPrfNmTxt">
            <AvInput type="text"  name="fullName" required  value={this.state.fullName} onChange={this.handleChange}/>
            <AvFeedback>{formErrors.fullName}</AvFeedback>
          </Col>
          </Row>
        </AvGroup>  
        <AvGroup>
          <Label for="Email Address">Email Address</Label>
          <AvInput type="text" name="email"  required value={this.state.email} disabled={true} onChange={this.handleChange}/>
          {
            formErrors.email !== "" ?
            <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.email}</Label>: null
          }
        </AvGroup>
        {/* <AvGroup>
          <Label for="LinkedIn Profile">LinkedIn Profile</Label>
          <AvInput type="text" name="email"  required value={this.state.LinkDin} onChange={this.handleChange}/>
          {
            formErrors.email !== "" ?
            <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.email}</Label>: null
          }
        </AvGroup> */}
        <AvGroup>
          <Label for="Mobile">Mobile Number</Label>
          <AvInput type="text" name="mobile"  required value={this.state.mobile} onChange={this.handleChange}/>
          {
            formErrors.mobile !== "" ?
            <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.mobile}</Label>: null
          }
          {/* <AvFeedback>{formErrors.mobile}</AvFeedback> */}
        </AvGroup>
        <AvGroup>
          <Row className="pt-4">
            <Col md={6}>
                <Button className=" btnblue btnclass w-100 "  onClick={this.handleSubmit}>Save Changes</Button>
            </Col>
            <Col md={6}>
                <Button className="btnblue btnclass w-100" onClick={this.onClickCancel}>Cancel</Button>
            </Col>
          </Row>
        </AvGroup>
      
      </AvForm>
      </Col>
      </Row>
      </Card>
      </div>
    );
  }
}

const mapStoreToProps = (state) => {
  //debugger
  // console.log(state,"state");
  return{
    Profile: state.profile.profile
  }
}

const mapDispatchToProps = dispatch => ({
  setProfile:(profile)=>dispatch(setProfile(profile)),
})
export default connect(mapStoreToProps, mapDispatchToProps)(ProfileSetForm);


