import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faAlignLeft, faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import { Col,Row} from 'react-bootstrap';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Navbar, Button, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import Auth from '../../../ServerCall/Auth';
import {Link} from 'react-router-dom'
import AVATARCIRCLE from '../../../../src/Images/AVATARCIRCLE.png'
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import EventIcon from '@material-ui/icons/Event';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { setProfile } from '../../../redux/action/profileAction';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';


const Topbar = (props) => {
  let history = useHistory();
  let match = useRouteMatch(['/AdminDashobard','/StudentDashboard','/FacultyDashboard']);
  const [topbarIsOpen] = useState(true);
  const [visible, setVisible] = useState(false);
  const [eventvisible, setEventVisible] = useState(false);
  const [profileImage,setProfileImage] = useState('');
  const [fullName,setFullName] = useState('');
  const onDismiss = () => setVisible(false);
  const onEventDismiss = () => setEventVisible(false);
  // const[isRedirect,setIsRedirect] = useState(false);
 
  const handleLogout = () => {
    //debugger
    Auth.logout();
    // localStorage.removeItem('courseId');
    // history.go('/');
   // return <Redirect to='/' />
    // setIsRedirect(true);
    //return <Redirect to='/Login' />
  }
  // const token = Auth.getToken();
  // if (!token) return (<Redirect to='/' />);
  function handleClick() {
    history.push("/UserProfileSetting");
  }
  useEffect(() => {
    axiosApiInstance.get(`${URL}/getProfile`)
    .then(async(res)=>{
      // console.log(res);
      if(res.status !== 500){
       // debugger
       let User = res.data.User;
       //alert(User.profileImage);
       setProfileImage(User.profileImage);
       setFullName(User.fullName);
        props.setProfile(User);
      }
    }).catch(err=>{
      console.log("err",err);
    })
  }, [profileImage,fullName]);
  return (
    <Row>
      <Col className="">
        <Navbar color="light" light
          className="TopbarWp navbar shadow-sm  rounded darkTheme"
          expand="md"
        >
        <Button color="info" className="toggleBtnClass" onClick={props.toggleSidebar}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
          <Collapse isOpen={topbarIsOpen} navbar className="navcollapseClass">
            <NavItem>
                {match ? null
                  :<Row><Col>
                    {/* <Link to={()=>history.goBack(-1)}> */}
                    <Button  className="bluebtn backbtn" onClick={()=>history.goBack(-1)}>
                     Back
                    {/* <FontAwesomeIcon icon={faArrowAltCircleLeft} color='#4C8DB5' size = '3x'/> */}
                    </Button>
                    {/* </Link> */}
                  </Col></Row>
                }
            </NavItem>
          <Nav className="ml-auto topulLISt" navbar >
          {/* <NavItem>
              <NavLink className="my-auto">
                Course
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="my-auto">
               Student
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="my-auto">
               Faculty
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="my-auto">
               News
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="my-auto">
               Event
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
            <Form.Row>
                    <Form.Group as={Col} className="mb-0">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                placeholder="Search here.."
                            />
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
            </NavItem> */}
              
             <NavItem className="UserWp">
             
            <NavLink tag={Link} className="pt-0 pb-0 usernametxt" >
                {/* <Row> <Col md={4} className="my-auto pr-3"><img src={props.Profile.profileImage} className="UsrimgWp" alt="pic"/></Col>
                      <Col md={8} className="Usrname my-auto text-left">{props.Profile.fullName}</Col> */}
                
                <Row><Col md={4} className="my-auto pr-0"><img src={profileImage} className="UsrimgWp" onClick={handleClick}/></Col>
                      <Col md={8} className="Usrname my-auto text-left pl- 2 pr-1">
                       <Row><Col className=""><span className="fullnameSpan">{fullName}</span></Col></Row> 
                       {/* <Row><Col><span style={{fontWeight:'600'}}>Stud Id:1234567</span></Col></Row> */}
                        </Col>
                </Row>
              </NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink className="">  
                <button onClick={() => setVisible(true)} style={{border:'none',backgroundColor:'transparent'}}>
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon className="NotificationIcn"/>
                    </Badge>
                </button>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className="">  
                  <button  onClick={() => setEventVisible(true)} style={{border:'none',backgroundColor:'transparent'}} className="">
                    <Badge badgeContent={4} color="secondary">
                        <EventIcon className="EventIcn"/>
                    </Badge>
                  </button>
                </NavLink>
            </NavItem> */}
            <NavItem>
                <NavLink className="signoutIcon">  
                <Tooltip title="Logout">
                  {/* <Button className=" btnblue LogoutWp btntgle" onClick={() => handleLogout()} > */}
                  {/* {isRedirect ? <Redirect to='/' /> : null } */}
                    <PowerSettingsNewIcon className="powerIcon" onClick={() => handleLogout()} />
                    {/* Logout */}
                  {/* </Button> */}
                  </Tooltip>
                </NavLink>
            </NavItem>
            
          </Nav>
      </Collapse>
    </Navbar>
    </Col>
    </Row>

  );
};

const mapStoreToProps = (state) => {
  //debugger
  // console.log(state,"state");
  return{
    Profile: state.profile.profile
  }
}

const mapDispatchToProps = dispatch => ({
  setProfile:(profile)=>dispatch(setProfile(profile))
})
export default connect(mapStoreToProps, mapDispatchToProps)(Topbar);