import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

 const PaginationLine = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Pagination 
          count={props.noOfPages}
          page={props.page}
          defaultPage={1}
          color="primary"
          showFirstButton
          showLastButton primary variant="outlined"  onChange={props.handlePageChange} />
    </div>
  );
}

export default PaginationLine