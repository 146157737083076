import React from "react";
import Auth from "../../../ServerCall/Auth";
import { PrivateRoute } from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Reports from '../../../Containers/Admin/Reports.js'
import ReleaseContent from '../../../Containers/Faculty/Course/ReleaseContent.js'
// import classNames from "classnames";
import { Container } from "react-bootstrap";
import Topbar from "../../../Components/Common/topbar/Topbar";
import { Switch, Route } from "react-router-dom";
import CourseMaster from "../../../Containers/Admin/Course/CourseMaster";
import CourseList from "../../../Containers/Admin/Course/CourseList";
import FacultyRegistration from "../../../Containers/Admin/FacultyRegistraion/FacultyRegistration";
import SubjectMaster from "../../../Containers/Admin/Course/SubjectMaster";
import Event from "../../../Containers/Admin/Event/Event";
import AssignFaculty from "../../../Containers/Admin/FacultyRegistraion/AssignFaculty";
import CourseCategory from "../../../Containers/Admin/Course/CourseCategory";
import NewsEntry from "../../../Containers/Admin/NewsEntry/NewsEntry";
import AccountInformation from "../../../Containers/Student/Registration/AccountInformation";
import Registration from "../../../Containers/Student/Registration/Registration";
import Login from "../../../Containers/Student/Login/Login";
import Refund from '../../../Components/Common/Footer/Refund'
import Course from "../../../Containers/Student/Course/Course";
import CourseDetail from "../../../Containers/Student/Course/CourseDetail";
import Profile from "../../../Containers/Student/Profile/Profile";
import Enrollment from "../../../Containers/Student/EnrollMent/Enrollment";
import Payment from "../../../Containers/Student/Payment/Payment";
import Checkout from "../../../Containers/Student/Checkout/Checkout";
import Lesson from "../../../Containers/Student/Lesson/Lesson";
import Announcement from "../../../Components/Student/Lesson/Announcement";
import AskQuestion from "../../../Components/Student/Lesson/AskQuestion";
import Progress from "../../../Components/Student/Lesson/Progress";
import Comment from "../../../Containers/Student/Comment/Comment";
import QuestionCount from "../../../Components/Student/Lesson/QuestionCount";
import UserProfileSetting from "../../../Containers/Student/UserProfileSetting/UserProfileSetting";
import EditProfileSetForm from "../../../Components/Student/ProfileSetting/EditProfileSetForm";
import EditProfile from "../../../Components/Student/ProfileSetting/EditProfile";
import StudentRegistration from "../../../Containers/Student/StudentRegistration/StudentRegistration";
import AnnouncementForm from "../../../Containers/Admin/Announcement/AnnouncementForm";
import Test from "../../../Containers/Student/StudentTest/Test";
import AssignedTest from "../../../Containers/Student/StudentTest/AssignedTest";
import AddTestContainer from "../../../Containers/Admin/AddTest/AddTestContainer";
// import ViewShow from '../../../Containers/Admin/ScheduleCal/ScheduleEntry'
import ScheduleEntry from '../../../Containers/Admin/ScheduleCal/ScheduleEntry'
import CourseAllocated from '../../../Containers/Faculty/Course/CourseAllocated'
import FacultyProfile  from '../../../Containers/Faculty/Profile/FacultyProfile'
import StudentQuestion from '../../../Containers/Faculty/Question/StudentQuestion'
import AddSection from '../../../Components/Admin/AddTestDetail/AddSection';
import TestList from '../../../Containers/Admin/AddTest/TestList';
import Result from '../../../Containers/Student/StudentTest/Result';
import Schedule from '../../../Containers/Admin/Course/Schedule';
import ViewSchedule from '../../../Containers/Student/ScheduleCalendar/ViewSchedule';
import StudentList from '../../../Containers/Student/StudentRegistration/StudentList';
import AdminDashobard from '../../../Containers/Admin/AdminDashobard';
import Stud_Forum from '../../../Containers/Student/Course/Stud_Forum';
import ForumDetail from '../../../Containers/Student/Course/ForumDetail';
import TabCourseForum from '../../../Containers/Student/Course/TabCourseForum';
import CourseSchudleList from '../../../Containers/Faculty/Course/CourseSchudleList';
import StudentDashboard from '../../../Containers/Student/Dashboard/StudentDashboard';
import FacultyDashboard from '../../../Containers/Faculty/Dashboard/FacultyDashboard';
import StudentFeedbackForm from '../../../Containers/Student/StudentFeedBack/StudentFeedbackform';
import Home from '../../../Containers/Admin/Home/Home';
import StudentSupport from '../../../Containers/Student/Support/StudentSupport';
import ChangeBatch from '../../../Containers/Student/Course/ChangeBatch';
import TestInstruction from '../../../Containers/Student/StudentTest/TestInstruction';
import StudentSupportForm from '../../../Containers/Student/Support/StudentSupportForm';
import CourseSection from '../../../Components/Common/CourseSection';
import CollapsibleTable from '../../../Components/Common/CollapsibleTable';
import StudentFeedback from '../../../Containers/Student/StudentFeedBack/StudentFeedback';
import ResetPassword from '../ResetPassword/ResetPassword';
import ForgotPassword from '../ResetPassword/ForgotPassword';
import StudentFeedbackList from '../../../Containers/Admin/StudentFeedbackList/StudentFeedbackList';
import ViewFeedback from '../../../Containers/Admin/StudentFeedbackList/ViewFeedback';
import flyerPage from "../../../Containers/Student/Flyer/flyer";
import TransactionUpdate from "../../../Containers/Student/Payment/TransactionUpdate";
//import View_Schedule from '../../../Containers/Faculty/FacultySchedule/ViewSchedule';

// import ForgotPassword from '../../Containers/ForgotPassword/ForgotPassword'
// import ResetPassword from '../../Containers/ResetPassword/ResetPassword'

// const loginType = currentUser.LoginType;
const role = Auth.getUserRole();

const Content = ({
  isLoginPage,
  toggleSidebar,
  // sidebarIsOpen, isLoginPage
}) => (
  <Container
    fluid
    // style={{overflow:'hidden'}}
    className={isLoginPage ? "is-open content" : "content"}
  >
    {!isLoginPage ? <Topbar toggleSidebar={toggleSidebar} /> : null}
    <Switch>
      {/* <Route path={"/Dashboard"} component={RequireAuth(Dashboard)}/> */}
      <PublicRoute
        exact
        restricted={false}
        path="/Registration"
        component={Registration}
      />
      <PublicRoute exact restricted={false} component={Login} path="/" />
      <PrivateRoute exact  path="/CourseSection"  component={(CourseSection)} />
      <PrivateRoute exact  path="/CollapsibleTable"  component={(CollapsibleTable)} />
      <PrivateRoute exact  path="/StudentFeedbackList"  roles={["superAdmin"]}   component={(StudentFeedbackList)} />
      <PrivateRoute exact path="/ViewFeedback" roles={["superAdmin"]}  component={(ViewFeedback)} />
      {role === "student" ?
        <PrivateRoute  path="/CourseMaster" roles={[]} component={(CourseMaster)} />:
        <PublicRoute  restricted={true}  path="/CourseMaster" component={(CourseMaster)} />
      }
      {role === "student" ?
       <PrivateRoute exact roles={[]} path="/CourseList" component={(CourseList)} />:
       <PublicRoute exact restricted={true} path="/CourseList" component={(CourseList)} />
      }
      <PrivateRoute exact  path="/AdminDashobard" roles={["superAdmin"]} component={(AdminDashobard)} />
      <PrivateRoute exact path="/CourseCategory" roles={["superAdmin"]} component={(CourseCategory)} />
      <PrivateRoute exact path="/SubjectMaster" roles={["superAdmin"]} component={(SubjectMaster)} /> 
      <PrivateRoute exact path="/Event" roles={["superAdmin"]} component={(Event)} /> 
      <PrivateRoute exact path="/FacultyRegistration" roles={["superAdmin"]} component={(FacultyRegistration)} />
      <PrivateRoute exact path="/AssignFaculty" roles={["superAdmin"]} component={(AssignFaculty)} />
      <PrivateRoute exact path="/NewsEntry" roles={["superAdmin"]} component={(NewsEntry)} />
      <PrivateRoute exact path="/ViewSchedule" roles={["student"]}  component={(ViewSchedule)} />
      <PrivateRoute exact path="/ChangeBatch" roles={["superAdmin"]}  component={(ChangeBatch)} />
      <PublicRoute exact restricted={true} path="/AccountInformation" component={(AccountInformation)} />
     
      
      
      {role === "faculty" ?
       <PrivateRoute exact path="/Course" roles={[]} component={(Course)} />:
       <PublicRoute exact restricted={true} path="/Course" component={(Course)} />
      }
      {role === "faculty" ?
         <PrivateRoute exact  path="/CourseSection" roles={[]}  component={(CourseSection)} />:
         <PublicRoute exact restricted={true} path="/CourseSection" component={(CourseSection)} />
      }
      <PublicRoute exact restricted={true} path="/CourseDetail" component={CourseDetail} />
      <PublicRoute exact restricted={true} path="/Profile" component={(Profile)} />
      <PrivateRoute exact path="/Enrollment" roles={["student"]} component={(Enrollment)} />
      <PrivateRoute exact path="/Payment" roles={["student"]} component={(Payment)} />
      <PrivateRoute exact path="/Checkout" roles={["student"]} component={(Checkout)} />
      <PublicRoute exact restricted={true}  path="/Lesson" component={(Lesson)} />
      <PublicRoute exact restricted={true}  path="/Announcement" component={(Announcement)} />
      <PrivateRoute exact path="/AskQuestion" roles={["student"]}  component={(AskQuestion)} />
      <PublicRoute exact restricted={true} path="/Progress" component={(Progress)} />
      <PublicRoute exact restricted={true} path="/Comment" component={(Comment)} />
      <PublicRoute exact restricted={true} path="/QuestionCount" component={(QuestionCount)} />
      <PublicRoute exact restricted={true} path="/UserProfileSetting" component={(UserProfileSetting)} />
      <PublicRoute exact restricted={true} path="/Reports" component={(Reports)} />
      <PublicRoute exact restricted={true} path="/ReleaseContent" component={(ReleaseContent)} />
      <PublicRoute exact restricted={true} path="/EditProfileSetForm" component={(EditProfileSetForm)} />
      <PublicRoute exact restricted={true} path="/EditProfile" component={(EditProfile)} />
      <PrivateRoute exact path="/StudentRegistration" roles={["superAdmin"]}  component={(StudentRegistration)} />
      <PublicRoute exact restricted={true}exact path="/AnnouncementForm" component={(AnnouncementForm)} />
      <PrivateRoute path="/Test" roles={["student"]} component={(Test)} />
      <PrivateRoute exact path="/Flyer" roles={["student"]} component={(flyerPage)} />
      <PrivateRoute path="/TransactionUpdate" roles={["student"]} component={(TransactionUpdate)} />
      <PrivateRoute exact path="/AssignedTest" roles={["student"]} component={(AssignedTest)} />
      <PrivateRoute exact path="/StudentDashboard"  roles={["student"]}  component={(StudentDashboard)} />
      {role === "student" ?
       <PrivateRoute exact path="/AddTestContainer" roles={[]} component={(AddTestContainer)} />:
       <PublicRoute exact restricted={true} path="/AddTestContainer" component={(AddTestContainer)} />
      }
      {/* <Route exact path="/Comment" component={(Comment)} /> */}
      {/* <Route exact path="/QuestionCount" component={(QuestionCount)} /> */}
      {/* <Route exact path="/UserProfileSetting" component={(UserProfileSetting)} /> */}
      {/* <Route exact path="/EditProfileSetForm" component={(EditProfileSetForm)} /> */}
      {/* <Route exact path="/AnnouncementForm" component={(AnnouncementForm)} /> */}
      <PrivateRoute
        exact
        path="/FacultyDashboard"
        roles={["faculty"]}
        component={FacultyDashboard}
      />
      <PublicRoute
        exact
        restricted={true}
        roles={["faculty"]}
        path="/TabCourseForum/:id/:batchId"
        component={TabCourseForum}
      />
      <PublicRoute
        exact
        restricted={true}
        roles={["faculty"]}
        path="/ForumDetail/:id/:batchId/:questionId"
        component={ForumDetail}
      />
      <PrivateRoute
        exact
        path="/ScheduleEntry"
        roles={["faculty"]}
        component={ScheduleEntry}
      />
      <PrivateRoute
        exact
        path="/Refund"
        component={Refund}
      />
      {/* <Route exact path= "/ViewShow" component={{ViewShow}} /> */}
      <PrivateRoute
        exact
        path="/CourseAllocated"
        roles={["faculty"]}
        component={CourseAllocated}
      />
      <PublicRoute
        exact
        restricted={true}
        path="/FacultyProfile"
        component={FacultyProfile}
      />
      <PrivateRoute
        exact
        path="/StudentQuestion"
        roles={["faculty"]}
        component={StudentQuestion}
      />
      {/* <Route exact path="/Registration" component={(Registration)} /> */}
      {role === "student" ? (
        <PrivateRoute
          exact
          path="/AddSection"
          roles={[]}
          component={AddSection}
        />
      ) : (
        <PublicRoute
          exact
          restricted={true}
          path="/AddSection"
          component={AddSection}
        />
      )}
      {role === "student" ? (
        <PrivateRoute exact path="/TestList" roles={[]} component={TestList} />
      ) : (
        <PublicRoute
          exact
          restricted={true}
          path="/TestList"
          component={TestList}
        />
      )}
      <Route path="/Result" component={Result} />
      {role === "student" ? (
        <PrivateRoute exact path="/Schedule" roles={[]} component={Schedule} />
      ) : (
        <PublicRoute
          exact
          restricted={true}
          path="/Schedule"
          component={Schedule}
        />
      )}
      <PrivateRoute
        exact
        path="/StudentList"
        roles={["superAdmin"]}
        component={StudentList}
      />
      <PublicRoute
        exact
        restricted={true}
        path="/Stud_Forum/:id"
        component={Stud_Forum}
      />
      <PublicRoute
        exact
        restricted={true}
        path="/ForumDetail/:id"
        component={ForumDetail}
      />
      <PublicRoute
        restricted={true}
        path="/TabCourseForum/:id"
        component={TabCourseForum}
      />
      <PrivateRoute
        exact
        path="/CourseSchudleList"
        role={["faculty"]}
        component={CourseSchudleList}
      />
      {/* <Route  path="/AdminDashobard" component={(AdminDashobard)} /> */}
      {/* <Route  path="/StudentDashboard" component={(StudentDashboard)} /> */}
      {/* <Route  path="/FacultyDashboard" component={(FacultyDashboard)} /> */}
      <PublicRoute
        exact
        restricted={true}
        path="/StudentFeedback"
        component={StudentFeedback}
      />
      <PublicRoute exact restricted={true} path="/Home" component={Home} />
      <PublicRoute
        exact
        restricted={true}
        path="/StudentSupport"
        component={StudentSupport}
      />
      {/* <Route exact path="/ChangeBatch" component={(ChangeBatch)} /> */}
      <PublicRoute exact restricted={true} path="/TestInstruction" component={(TestInstruction)} />
      <PublicRoute exact restricted={true} path="/StudentSupportForm" component={(StudentSupportForm)} />
      <PublicRoute  restricted={true} path="/TestInstruction" component={(TestInstruction)} />
      <PublicRoute exact restricted={false} path="/ForgotPassword" component={(ForgotPassword)} />
      <PublicRoute  restricted={false}  path="/ResetPassword/:token" component={(ResetPassword)} />
    </Switch>
  </Container>
);

export default Content;
