import React from "react";
import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";
// import DropDown from '../../../Components/Admin/Course/DropDown'
import Table from  '../../../Components/Admin/Course/Table'
import '../../../Components/Admin/Course/Table.css'
import MultiSelect from '../../../Components/Admin/FacultyRegistration/MultiSelect';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import  {ValidateEmail, Validatephone} from '../../../Components/Common/Validation/Validation';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';
import Auth from '../../../ServerCall/Auth';
import InfoIcon from '@material-ui/icons/Info';
import { conditionallyUpdateScrollbar } from "reactstrap/lib/utils";
const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}
const role = Auth.getUserRole();
const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

export default class FacultyRegistration extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      addmodl: [],
      addModalShow: false,
      rowData:"",
      showDelFaculty:false,
      FacultyId: null,
      selectedGender: null,
      SelectCourse: null,
      Facultydata: {
        firstName: '',
        middleName: '',
        lastName: "",
        gender: '',
        email: '',
        password: '',
        contactNumber: '',
        address: '',
        state: '',
        city: '',
        pinCode: '',
        country: '',
        qualification: '',
        year: '',
        month: '',
        coreAreasSubject: []
      },
      LabelText: {
        firstName: '',
        middleName: '',
        lastName: "",
        gender: '',
        email: '',
        password: '',
        contactNumber: '',
        address: '',
        state: '',
        city: '',
        pinCode: '',
        country: '',
        qualification: '',
        year: '',
        month: '',
        coreAreasSubject: '',
        selectedGender: null,
        SelectCourse: null,
      },
      istrue: false,
      SelectGender: [
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
        { value: "Other", text: "Other" }
      ],
      SelectRefGender: null,
      SelectYear: [],
      SelectMonth: [],
      SelectCategory: [],
      
      columns: [
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "lastName" },
        { title: "Email Id", field: "email" },
        // { title: "Gender", field: "gender" },
        { title: "Contact Number", field: "contactNumber" },
        // { title: "Address", field: "address" },
        // { title: "Date", field: "createdAt", type: "date" },
        // { title: "State", field: "state", hidden: true },
        // { title: "City", field: "city", hidden: true },
        // { title: "PinCode", field: "pinCode", hidden: true },
        // { title: "Qualification", field: "qualification", hidden: true },
        { title: "Core Skills", 
        // field: "coreAreasSubject.subjectName",
        render: (rowData) =><span>{this.core(rowData)}</span>
        },
        {
          title: "Experience",
         // field: "year",
          render: (rowData) => <span>{rowData.experience.year} y : {rowData.experience.month} m </span>
        },
        // { title: "Experience Month", field: "month", },
       
      ],
      colCore: [],
      Data: [],
      selectedSubject: [],
      subOption: []
    }
    this.handleChange = this.handleChange.bind(this);
  }

  core = (rowData) =>{
    let core = [];
    rowData.coreAreasSubject && rowData.coreAreasSubject.map(data=>{
      core.push(data.subjectName)
    });
    const skills = core.join(" , ");
    return skills;
   // console.log("core",skills);
    }

  selectedGender = (event) =>{
    let {selectedGender} = this.state
    selectedGender = event.target.value;
    this.setState({selectedGender:selectedGender})
  }
  // SelectCategory = (event) =>{
  //   let {SelectCategory} = this.state
  //   SelectCategory = event.target.value;
  //   this.setState({SelectCategory:SelectCategory})
  // }
  formvalid = () =>{
    let valid = true;
    let {Facultydata,LabelText,email} = this.state
    validEmailRegex.test(Facultydata.email)?LabelText.Facultydata.email ="":LabelText.email = " Valid email address Req.";
   this.setState({LabelText:LabelText});
    Object.values(LabelText).forEach(val =>{
        val !== "" && (valid = false);
    }) ;
    return valid;
  }

  handleChange (event) {
    //alert(event.target.value)
    event.preventDefault();
    let {Facultydata,LabelText} = this.state
    Facultydata[event.target.name] = event.target.value;
    this.setState({Facultydata:Facultydata})
    const { name,value} = event.target;
    this.setState({[name]: value});
    // LabelText.email = validEmailRegex.test(value) ? "": " Please Enter Valid email address Req."
    switch(name){
      case 'email':
        LabelText.email=  validEmailRegex.test(value)?"":"Valid email address Req.";
        //formErrors.username = value !=""? "":"Email Req.";
      break;
    }
    this.setState({ LabelText: LabelText});
  }

  getTableData () {
    axiosApiInstance.get(`${URL}/getFacultyList`)
    .then(res =>{
      //debugger
      // console.log(res.data);
      let allFacultyList = res.data.allFacultyList;
      let  Facultydata = [];
      let coreArea = [];
      if(res.status == 200) {
        this.setState({Data:allFacultyList});
      }
    }).catch(err => {
      console.log(err)
    })
  }
  getCategory =()=>{
    axiosApiInstance.get(`${URL}/course/category`)
    .then(res =>{
      //debugger
      //console.log(res,"**response")
      let Categories = res.data.subCategories;
      let Categorydata = [];
      if(res.status == 200){
        Categories && Categories.map(data =>{
          Categorydata.push({id:data._id,categoryName:data.categoryName})
        })
        this.setState({SelectCategory:Categorydata})
        // alert(Categorydata,"Categorydata")
      }
    })
    .catch(err=>{
      console.log(err);
    });
  }
 
  getSkill() {
    axiosApiInstance.get(`${URL}/course/subject`)
      .then(res => {
        let Subject = res.data.subject;
        let subjectData = [];
        // console.log(Subject);
        Subject && Subject.map(data => {
          subjectData.push({
            value: data._id,
            label: data.subjectName
          })
        })
        this.setState({ subOption: subjectData });
      })
  }
 
  async componentDidMount (){
   this.getTableData();
   this.getCategory();
   this.getSkill();
  //  this.checkScheduleFaculty();
  }


  validate=()=>{
    const name = RegExp(/^[a-zA-Z ]*$/);
    const number = RegExp(/^[0-9]+$/);
    //debugger
      let {Facultydata,LabelText} = this.state;
      if (Facultydata.firstName !=="" && Facultydata.firstName !== null) {	
        LabelText.firstName = "";
        if (!Facultydata.firstName.match(/^[a-zA-Z ]*$/)) {
            LabelText.firstName = "*Please Enter only Character.";
           }
        // name.test(Facultydata.firstName)
        // ?LabelText.firstName = ""
        // :LabelText.firstName = "*Please Enter only Character.";
        }
      else{
        LabelText.firstName  = "Please Enter First Name";
      }
       if (Facultydata.middleName !=="" && Facultydata.middleName !== null) {	
        LabelText.middleName = "";
          if (!Facultydata.middleName.match(/^[a-zA-Z ]*$/)) {
            LabelText.middleName = "*Please Enter only Character.";
          }
          else{
            LabelText.middleName = "";
          }
          // name.test(Facultydata.middleName)
          // ?LabelText.middleName = ""
          // :LabelText.middleName = "*Please Enter only Character.";
       }
      //  else{
      //   LabelText.middleName  = "Please Enter Middle Name";
      //  }
       if (Facultydata.lastName !=="" && Facultydata.lastName !== null) {	
        LabelText.lastName = "";
        if (!Facultydata.lastName.match(/^[a-zA-Z ]*$/)) {
          LabelText.lastName = "*Please Enter only Character.";
        }
          // name.test(Facultydata.lastName)
          // ?LabelText.lastName = ""
          // :LabelText.lastName = "*Please Enter only Character.";
        }
       else{
        LabelText.lastName  = "Please Enter Last Name";
       }
       let isEmailValid=ValidateEmail(Facultydata.email);
        if (isEmailValid) {	
            LabelText.email = "";
        }
        else if(Facultydata.email!==""){
            LabelText.email="Invalid Email"; 
        }
        else{
            LabelText.email = "Please Enter Email";
        }
          if(this.state.FacultyId === 0 || this.state.FacultyId === null){
            if (Facultydata.password !==""&& Facultydata.password !== null) {	
                LabelText.password = "";
                // if (!Facultydata.password.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
                //   LabelText.password = "*Please enter secure and strong password.";
                // }
              }
              else{
                LabelText.password = "Please Enter Password";
              }
          }
          if(Facultydata.address !=="" && Facultydata.address !== null){
              LabelText.address ="";
          }
          else{
              LabelText.address ="Please Enter Address"
          }
          let isContactNoValid=Validatephone(Facultydata.contactNumber);
          if (Facultydata.contactNumber!=="" && Facultydata.contactNumber !== null) {	
              LabelText.contactNumber = "";
              // if ((!Facultydata.contactNumber.toString()).match(/^[0-9]+$/)) {
              //   LabelText.contactNumber = "Please Enter only Number.";
              // }
          }
          // else if(Facultydata.contactNumber!==""){
          //     LabelText.contactNumber="please enter only 10 digits"; 
          // }
          else{
              LabelText.contactNumber = "Please Enter Contact Number";
            }
        if (Facultydata.state !=="" && Facultydata.state !== null) {	
          //debugger
           LabelText.state = "";
          if (!Facultydata.state.match(/^[a-zA-Z ]*$/)) {
            LabelText.state = "*Please Enter only Characters";
          }
          // name.test(Facultydata.state)
          // ?LabelText.state = ""
          // :LabelText.state = "*Please Enter only Character.";
          }
        else{
          LabelText.state  = "Please Enter State";
        }
        if (Facultydata.city !=="" && Facultydata.city !== null) {	
          LabelText.city = "";
          if (!Facultydata.city.match(/^[a-zA-Z ]*$/)) {
            LabelText.city = "*Please Enter only Characters";
          }
          // name.test(Facultydata.city)
          // ?LabelText.city = ""
          // :LabelText.city = "*Please Enter only Character.";
          }
        else{
          LabelText.city  = "Please Enter City";
        }
        if (Facultydata.pinCode !=="" && Facultydata.pinCode !== null) {	
          LabelText.pinCode = "";
          // if (!Facultydata.pinCode.match(/^[0-9a-zA-Z(\-)]+$/)) {
          //   LabelText.pinCode = "*Please Enter Valid Pincode";
          // }
          }
        else{
          LabelText.pinCode  = "Please Enter Pincode";
        }
        if (Facultydata.country !=="" && Facultydata.country !== null) {	
          LabelText.country = "";
          if (!Facultydata.country.match(/^[a-zA-Z ]*$/)) {
            LabelText.country = "*Please Enter only Characters";
          }
          // name.test(Facultydata.country)
          // ?LabelText.country = ""
          // :LabelText.country = "*Please Enter only Character.";
        }
        else{
          LabelText.country  = "Please Enter Country";
        }
        if (Facultydata.qualification !==""&& Facultydata.qualification !== null) {
          LabelText.qualification = "";
          // if (!Facultydata.qualification.match(/^[a-zA-Z ]*$/)) {
          //   LabelText.qualification = "*Please Enter only Characters";
          // }

        }
        else{
          LabelText.qualification = "Please Enter Qualification"
        }
        if (Facultydata.year !==""&& Facultydata.year !== null) {
          LabelText.year = "";
          Facultydata.year.toString();
          if(!(Facultydata.year.toString()).match(/^[0-9]+$/)){
            LabelText.year = "Please Enter Valid Year";
          }
          // number.test(Facultydata.year)
          //   ?LabelText.year = ""
          //   :LabelText.year = "Please Enter Valid Year";
        }
        else{
          LabelText.year = "Please Enter Year"
        }
        if (Facultydata.month !== null && Facultydata.month !== '') {
          //debugger
         // alert(Facultydata.month.length);
          LabelText.month = "";
          if(Facultydata.month > 11 || Facultydata.month < 0 
            || Facultydata.month.length > 2
            ){

            LabelText.month = "Please Enter Valid Month";
            // if((!Facultydata.month).match(/^[0-9]+$/)){
            //   LabelText.month = "Please Enter Valid Month";
            // }
          }
          else{
            LabelText.month = "";
          }
        }
        else{
          LabelText.month = "Please Enter Month"
        }
        if ( this.state.selectedGender !== null) {
          if(this.state.selectedGender == "Select Gender"){
              LabelText.selectedGender = "Please select Gender"
          }
          else{
              LabelText.selectedGender = null;
          }
        }
        else{
          LabelText.selectedGender = "Please select Gender"
        }
        if ( Facultydata.coreAreasSubject.length > 0 ) {
          LabelText.coreAreasSubject = "";
        }
        else{
          LabelText.coreAreasSubject = "Please Select Core Skills";
        }
        // if ( this.state.SelectCourse !== null) {
        //   if(this.state.SelectCourse == " Please Select Subject Specialization"){
        //       LabelText.SelectCourse = "Please Select Subject Specialization"
        //   }
        //   else{
        //       LabelText.SelectCourse = null;
        //   }
        // }
        // else{
        //   LabelText.SelectCourse = " Please Select Subject Specialization"
        // }
        this.setState({LabelText: LabelText});
        if(LabelText.firstName===""&& LabelText.middleName === ''&&LabelText.lastName===""&&LabelText.email===""&&LabelText.password===""&&LabelText.contactNumber===""&&LabelText.selectedGender===null
        &&LabelText.city===""&&LabelText.state===""&&LabelText.pinCode===""&&LabelText.country===""&&LabelText.qualification===""&&LabelText.year===""&&LabelText.month===""&&LabelText.coreAreasSubject==="")
            {
              return true;
            }
            else
            {
              return false;
            }
  }

  onClickFacultyData = () => {
    //debugger
    this.coreConverstion();
    const isValid = this.validate();
    if(isValid){
     //debugger
      let gender = this.state.selectedGender;
      const {firstName,middleName,lastName,SelectCourse,contactNumber,email,password,address,city,pinCode,state,country ,qualification,year,month,coreAreasSubject} = this.state.Facultydata;
      let experience = {year,month}
      let categoryId = SelectCourse;
      // console.log(this.state.Facultydata);
      axiosApiInstance.post(`${URL}/addFaculty`,{firstName,middleName,lastName,contactNumber,gender,email,password,address,city,pinCode,state,country,experience,qualification,coreAreasSubject})
      .then(response =>{
        //debugger
        // console.log(response);
        const res = response.data;
        if(response.status == 200) {
          if(response.data.status === 409){
            swal("User with this email Id already exist","","info");
          }
          else{
            swal("Faculty Added", "", "success")
            this.getTableData();
            this.onClickCancel();
            this.state.SelectRefGender.select.onClickCancel();
          }
          this.state.SelectRefGender.select.clearValue();
        } else {   
          //swal("Something went wrong", "", "error");
        }
      }).catch(err => {

        console.log(err)
      })
    }  
  }

  onEdit=(editFacultydata,editFacultyId,rowData) => {
    //debugger
    const {Facultydata}= this.state;
    let selected = [];
    rowData.coreAreasSubject && rowData.coreAreasSubject.map(data=>{
        selected.push({
          value: data.subjectId,
          label: data.subjectName
        })
    })
    this.setState({
      selectedSubject:selected
    });
    var gendervalue = editFacultydata[0].gender;
    var coreSubject = editFacultydata.coreAreasSubject;
      Facultydata.firstName=editFacultydata[0].firstName;
      Facultydata.middleName=editFacultydata[0].middleName;
      Facultydata.lastName=editFacultydata[0].lastName;
      Facultydata.contactNumber=editFacultydata[0].contactNumber;
      this.state.selectedGender=editFacultydata[0].gender
      Facultydata.email=editFacultydata[0].email;
      Facultydata.address=editFacultydata[0].address;
      Facultydata.city=editFacultydata[0].city;
      Facultydata.pinCode=editFacultydata[0].pinCode;
      Facultydata.state=editFacultydata[0].state;
      Facultydata.country=editFacultydata[0].country;
      Facultydata.qualification=editFacultydata[0].qualification;
      Facultydata.year=rowData.experience.year;
      Facultydata.month=rowData.experience.month;
      // Facultydata.coreAreasSubject=coreSubject;
      this.setState({Facultydata:Facultydata})
      this.setState({FacultyId:editFacultyId})
      this.setState({selectedGender:gendervalue})
      this.setState({istrue:true});
      this.validationClear();
      // console.log(coreSubject)
  }

    onUpdateFacultyData = () => {
      //debugger
      this.coreConverstion();
      const isValid = this.validate();
      if(isValid){
     // debugger
      let gender = this.state.selectedGender;
      const {firstName,middleName,lastName,contactNumber,email,address,city,pinCode,state,country,qualification,year,month,password,coreAreasSubject} = this.state.Facultydata
       let FacultyId = this.state.FacultyId;
          let  experience= {year,month}
          // console.log(this.state.Facultydata);
          axiosApiInstance.put(`${URL}/faculty/${FacultyId}`,{firstName,middleName,lastName,gender,contactNumber,email,address,password,city,pinCode,state,country,qualification,experience,coreAreasSubject})
          .then(response =>{
            //debugger
            const res = response.data.news;
          if(response.status == 200) {
            if(response.data.status === 409){
              swal("User with this email Id already exist","","info");
            }
            else{
              swal("Faculty Details Updated", "", "success");
              // this.state.SelectRefGender.select.onClickCancel();
              this.setState({istrue:false, FacultyId:null});
              this.getTableData();
              this.onClickCancel();
            }
          } else {   
            //swal("Something went wrong", "", "error");
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
    validationClear =() => {
      let {LabelText} = this.state
      LabelText.firstName="";
      LabelText.middleName="";
      LabelText.lastName="";
      LabelText.SelectCourse="";
      LabelText.selectedGender="";
      LabelText.contactNumber="";
      LabelText.email="";
      LabelText.password="";
      LabelText.address ="";
      LabelText.city="";
      LabelText.pinCode="";
      LabelText.state ="";
      LabelText.country="";
      LabelText.qualification="";
      LabelText.year ="";
      LabelText.month = "";
      LabelText.coreAreasSubject= "";
    }

    onClickCancel = () => {
      let {Facultydata,LabelText} = this.state
      Facultydata.firstName = "";
      Facultydata.firstName ="";
      Facultydata.middleName ="";
      Facultydata.lastName =""; 
      Facultydata.email ="";
      Facultydata.password ="";
      Facultydata.contactNumber ="";
      Facultydata.address ="";
      Facultydata.state ="";
      Facultydata.city ="";
      Facultydata.pinCode ="";
      Facultydata.country ="";
      Facultydata.qualification ="";
      Facultydata.year ="";
      Facultydata.month = "";
      Facultydata.coreAreasSubject= [];
      this.state.selectedSubject = [];
      this.state.selectedGender = [];
      this.state.SelectCourse=null;
      this.setState({Facultydata: Facultydata,istrue:false, FacultyId:null, selectedGender:null,SelectCourse:null});
      LabelText.firstName="";
      LabelText.middleName="";
      LabelText.lastName="";
      LabelText.SelectCourse="";
      LabelText.selectedGender="";
      LabelText.contactNumber="";
      LabelText.email="";
      LabelText.password="";
      LabelText.address ="";
      LabelText.city="";
      LabelText.pinCode="";
      LabelText.state ="";
      LabelText.country="";
      LabelText.qualification="";
      LabelText.year ="";
      LabelText.month = "";
      LabelText.coreAreasSubject= "";
   }
  
  handelMultiple=(options)=>{
    this.setState({ selectedSubject: options });
    this.coreConverstion();
   }

   coreConverstion(){
    let {Facultydata} = this.state;
  
    var coreSkill = [];
    this.state.selectedSubject && this.state.selectedSubject.map(data => {
      coreSkill.push({
        subjectId: data.value,
        subjectName: data.label
      })
    });
    Facultydata.coreAreasSubject = coreSkill;
    this.setState({Facultydata:Facultydata});
    // console.log("coreSkill",this.state.Facultydata.coreAreasSubject);
   }
   
   onDelete = (facultyDelId) => {
    //debugger;
    axiosApiInstance
      .delete(`${URL}/deleteFacultyByUserId/${facultyDelId}`)
      .then((res) => {
        // debugger;
        // console.log(res);
        this.getTableData();
        swal("Faculty Deleted Successfully!", "", "success");
        this.getTableData();
        this.onClickCancel();
      })
      .catch((err) => {
        console.log(err.message);
      });
    
  };

  checkScheduleFaculty=()=>{
    axiosApiInstance.get(`${URL}/allCourseSchedule`).then(res=>{
      let scheduleData=res.data.CourseSchedule;
      let facultyIds=[];
      let facultyId="";
      // console.log("Schedule Check",scheduleData);
      scheduleData&&scheduleData.map(data=>{
        let facultyData=data.mainFaculty;
        facultyData&&facultyData.map(id=>{
          facultyIds.push(id.facultyUserId);
        })
      })
      // console.log("Faculty Ids: ",facultyId);
      facultyIds&&facultyIds.map(id=>{
        if(id === this.state.rowData.userId){
          facultyId=id;
      }
      })
      if(facultyId===this.state.rowData.userId){
        swal("Can't Delete This Faculty!","Faculty is scheduled to a course","warning",{dangerMode:true})
      }
      else{
        this.setState({showDelFaculty:true});
      }
    })
  }

render(){
 
  const{SelectGender,SelectYear,rowData,SelectMonth,SelectCategory,Data,columns,Facultydata,LabelText,SelectCourse,role,subOption,selectedSubject} =this.state;
    return(

    <Container fluid className="CouseMasterWp FormWp">
      <DeleteConfirm
          show={this.state.showDelFaculty}
          animation={true}
          rowData={rowData}
          id={rowData.userId}
          name={rowData.firstName}
          close={() => this.setState({ showDelFaculty: false })}
          deleteHandler={this.onDelete}
        />
       <Card className="shadow p-4 col-md-12 pl-0 pr-0 facultyCardWpr">
         <Card className="col-lg-9 col-md-12 col-sm-12  col-xs-12 p-4 facultyregistrationWp" style={{margin:'0 auto'}}>
        <Row>
          <Col className="mb-4">
            <h3 className="text-center blueText">Faculty Registration</h3>
          </Col>
        </Row>
       <Form autocomplete="off">
        <Row>
           <Col md={4}>
            <FormGroup>
              {/* <input autocomplete="false" name="hidden" type="text" style="display:none;" /> */}
              <Label>First Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="firstName"  placeholder="" onChange={this.handleChange} value={Facultydata.firstName}/>
                {LabelText.firstName !== "" ?
					        <Label style={{ fontSize: 12, color: "red" }}>
					      {LabelText.firstName }</Label >: null
				        }
            </FormGroup>
           </Col>
           <Col md={4}>
            <FormGroup>
              <Label>Middle Name</Label>
                <Input type="text" name="middleName"  placeholder="" onChange={this.handleChange} value={Facultydata.middleName}/>
                {LabelText.middleName !== "" ?
					        <Label style={{ fontSize: 12, color: "red" }}>
					      {LabelText.middleName }</Label >: null
				        }
            </FormGroup>
           </Col>
           <Col md={4}>
            <FormGroup>
              <Label>Last Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="lastName"  placeholder="" onChange={this.handleChange} value={Facultydata.lastName}/>
                {LabelText.lastName !== "" ?
					        <Label style={{ fontSize: 12, color: "red" }}>
					      {LabelText.lastName }</Label >: null
				      }
            </FormGroup>
           </Col>
        </Row>
        <Row>
          {/* <Col md={4}>
          <Label>Select Subject specialization</Label><span className="requiredFieldSpan">*</span>
          <select id="selectCourse" name="SelectCourse" className="form-control" value={SelectCourse}
                          onChange={this.handleChange}
                         //disabled={role === "faculty" || this.state.scheduleId != ''?true:false}
                        >
                           <option>Select Specialization</option>
                {
                    SelectCategory && SelectCategory.map(select => {
                        return (
                            <option 
                              value={select.id}
                              data-key={select.id}
                            >
                                {select.categoryName}
                            </option> 
                        )
                    })
                  }
          </select>
          {LabelText.SelectCourse !== null ?
            <Label style={{ fontSize: 12, color: "red" }}>
            {LabelText.SelectCourse }</Label >: null
          }
          </Col> */}
          <Col md={6}>
           <FormGroup>
           <Label>Select Gender</Label><span className="requiredFieldSpan">*</span>
              <select id="Dropdown" name="selectedGender" className="form-control" value={this.state.selectedGender}
              onChange={this.selectedGender} ref={ref=>{ this.state.SelectRefGender=ref}}
            >
            <option>Select Gender</option>
            {
              this.state.SelectGender && this.state.SelectGender.map(select => {
                  return (
                      <option 
                      value={select.value}
                      data-key={select.value}
                       >
                           {select.text}
                      </option> 
                  )
              })
            }
          </select>
          {LabelText.selectedGender !== null ?
            <Label style={{ fontSize: 12, color: "red" }}>
            {LabelText.selectedGender }</Label >: null
          }
          </FormGroup>
           </Col>
           {/* <Col md={6}>
            <FormGroup>
              <Label>Gender</Label>
              <DropDown title="Select Gender" options={SelectGender} selectedCategory={this.selectedGender}/>
            </FormGroup>
           </Col> */}
           <Col md={6}>
                <FormGroup>
                <Label>Contact  No</Label><span className="requiredFieldSpan">*</span>
                    <Input type="number" name="contactNumber"  placeholder="" onChange={this.handleChange} value={Facultydata.contactNumber}/>
                    {LabelText.contactNumber !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.contactNumber }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup style={{position:'relative'}}>
                <Label>Email</Label><span className="requiredFieldSpan">*</span>
                    <Input type="Email" name="email" autoComplete="new-password"  placeholder="" disabled={this.state.FacultyId !== 0 && this.state.FacultyId !== null?true:false}  onChange={this.handleChange} value={Facultydata.email}/>
                    <span className="InfoSpan"><InfoIcon/></span>
                    <div className="validationGuide">
                        <span>1.Start with a character (a-z), an underscore (_), or a number (0-9)) <br/></span>
                        <span>2.Email Must having @ Sign. </span><br/>
                        {/* <span>3.Digits from 0 to 9.</span><br/>
                        <span>4.Special characters such as ! # $ %  ' * + - / = ? ^ _ ` </span> */}
                    </div>
                    {LabelText.email !=="" ?
                      <Label style={{fontSize: 12, color:"red"}}>
                      {LabelText.email}</Label>:null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup style={{position:'relative'}}>
                <Label>Password</Label><span className="requiredFieldSpan">*</span>
                    <Input type="Password" name="password"  placeholder="" autoComplete="new-password" onChange={this.handleChange} value={Facultydata.password}/>
                      <span className="InfoSpan"><InfoIcon/></span>
                        <div className="validationGuide">
                            <span>1. At least 8 characters—the more characters <br/></span>
                            <span>2. A mixture of both uppercase and lowercase letters..<br/> </span>
                            {/* <span>3. Inclusion of at least one special character,e.g.,! @ # ?] <br/></span>
                            <span>4. A mixture of letters and numbers.</span> */}
                        </div>
                    {LabelText.password !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.password }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col>
          <FormGroup>
            <Label>Address</Label><span className="requiredFieldSpan">*</span>
            <Input type="textarea" name="address" id="Address" onChange={this.handleChange} value={Facultydata.address}/>
            {LabelText.address !==""?
              <Label style={{ fontSize: 12, color: "red" }}>
              {LabelText.address}</Label>:null
            }
          </FormGroup>
          </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>City</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="city"  placeholder="" onChange={this.handleChange} value={Facultydata.city}/>
                    {LabelText.city !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.city }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Pincode</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="pinCode"  placeholder="" onChange={this.handleChange} value={Facultydata.pinCode}/>
                    {LabelText.pinCode !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.pinCode }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>State</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="state"  placeholder="" onChange={this.handleChange} value={Facultydata.state}/>
                    {LabelText.state !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.state }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Country</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="country"  placeholder="" onChange={this.handleChange} value={Facultydata.country}/>
                    {LabelText.country !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.country }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>Qualification</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="qualification"  placeholder="" onChange={this.handleChange} value={Facultydata.qualification}/>
                    {LabelText.qualification !== "" ?
                      <Label style={{fontSize: 12 ,color: "red"}}>
                      {LabelText.qualification}</Label>:null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Experience</Label><span className="requiredFieldSpan">*</span>
                <Row>
                    {/* <Col>
                        <DropDown title="Year" options={SelectYear} selectedCategory={this.selectedYear}/>
                    </Col>
                    <Col><DropDown title="Month" options={SelectMonth} selectedCategory={this.selectedMonth}/></Col> */}
                      <Col lg={3} md={6} sm={6} xs={6}>
                      <Input type="number" name="year"  placeholder="" onChange={this.handleChange} value={Facultydata.year}/>
                      {LabelText.year !== "" ?
                      <Label style={{fontSize: 12 ,color: "red"}}>
                      {LabelText.year}</Label>:null
                    }
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Years</Label>
                    </Col>
                    <Col  lg={3} md={6} sm ={6} xs={6} className="monthTextboxWp"><Input type="number" name="month" min="1" max="12" placeholder="" onChange={this.handleChange} value={Facultydata.month}/>
                    {LabelText.month !== "" ?
                      <Label style={{fontSize: 12 ,color: "red"}}>
                      {LabelText.month}</Label>:null
                    }
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Months</Label>
                    </Col>
                </Row>
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col md={6}>
              <FormGroup>
                <Label>Core Areas/Subject :</Label><span className="requiredFieldSpan">*</span>
                <MultiSelect subjects={subOption} selected={selectedSubject} handelMultiple={this.handelMultiple} />
                {LabelText.coreAreasSubject !== "" ?
                  <Label style={{fontSize: 12 ,color: "red"}}>
                  {LabelText.coreAreasSubject}</Label>:null
                }
              </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-5">
             <div  className="center-block text-center">
            {this.state.istrue ? 
              <Button className="text-center mr-3 btnblue"  onClick={this.onUpdateFacultyData}>update</Button>             
              :  
              <Button className="text-center mr-3 btnblue"  onClick={this.onClickFacultyData}>Submit</Button>       
            }
              <Button className="text-center clearBtn" color="danger"  onClick={()=>{this.onClickCancel()}}>Cancel</Button>
            </div>
          </Col>
        </Row>
        </Form>
        </Card>
        <Row className="mb-2 mt-5 rowMobmt-0">
          <Col className="tableWp mt-2 zindex0">
                <Row>
                  <Col className="DashboardTbl">
                    <Table data={Data} pageSize={10} paging={true} columns={columns} search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='200px' 
                    actions ={
                      [
                        {
                          icon:'edit',
                          Tooltip:'Edit',
                          onClick: (event, rowData) => {
                            var data = [];
                            //alert("Edit Action Clicked " + rowData.year + rowData.month)
                           var dataFields = {
                              firstName:rowData.firstName,
                              middleName:rowData.middleName,
                              lastName:rowData.lastName, 
                              gender:rowData.gender,
                              email:rowData.email,
                              password:rowData.password,
                              contactNumber:rowData.contactNumber,
                              address:rowData.address,
                              state:rowData.state,
                              city:rowData.city,
                              pinCode:rowData.pinCode,
                              country:rowData.country,
                              qualification:rowData.qualification,
                             // year: rowData.year,
                             // month: rowData.month,                     
                            };
                           // var core = rowData.coreAreasSubject;
                            data.push(dataFields);
                            // console.log(Data);
                            this.onEdit(data,rowData._id,rowData);
                          }

                        },
                        {
                          icon: "delete",
                          tooltip: "Delete",
                          onClick: (event, rowData) => {
                            // alert("Delete Action Clicked ", rowData)
                            this.setState({
                              // showDelFaculty: true,
                              rowData: rowData,
                            });
                            this.checkScheduleFaculty();
                            // console.log(rowData);
                          },
                        },

                      ]
                    }
                    />
                  </Col>
              </Row>            
          </Col>
        </Row>
       
       </Card>
    </Container>
    );
}
}