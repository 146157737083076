import React, {Component} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.state = { text: this.props.value ,readOnly:this.props.readOnly} // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this)
  }
 
  async componentWillReceiveProps(nextProps){
    if (nextProps.value !== this.props.value) {
      await this.setState({ text: nextProps.value });
    }
  }
  handleChange(value) {
    //debugger
    this.setState({ text: value });
    this.props.onChange(value);
  }
 
  render() {
    return (
      <ReactQuill value={this.state.text}
                  onChange={this.handleChange}
                  readOnly={this.state.readOnly} />
    )
  }
}