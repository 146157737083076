import React from "react";
import {Modal,Form, Button,Container,Row,Col,FormGroup} from 'react-bootstrap'
import 'react-dropzone-uploader/dist/styles.css'
import CardText from "reactstrap/lib/CardText";
import {Card} from 'react-bootstrap'

const StudentQuestionModal=({
    answer,
    status,
    show,
    onHide,
    
    }) =>  {
    return(
      <Modal 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modalWp"show={show}
          onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Answer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container className="FormWp">
            <Card className="p-3 answerCard"> 
              <CardText>
                    {
                      status !== false && answer !== ''?answer:"Answer not replied yet!"
                    }
              </CardText>
            </Card>
          </Container>
          </Modal.Body>
        </Modal>  
    )
}

export default StudentQuestionModal




