import React from 'react';
import Question from './Question';
import QuestionCount from './QuestionCount';
import AnswerOption from './AnswerOption';
import AnswerOptionCheck from './AnswerOptionCheck'
import AnswerOptionTextarea from './AnswerOptionTextarea';
import {Button,Row,Col,Card} from 'react-bootstrap';

function Quiz({questionSet, setNextQuestion, setPreviousQuestion, onAnswerSelected, duration, timeCounterDecrement, counter, totalQuestions}) {

  function renderAnswerOptions(option, index) {
    return (
      <AnswerOption
        index ={index}
        key={option}
        answerContent={option}
        //answerType={key.type}
        //answer={answer}
        // disableOption={props.disableOption}
        // questionId={props.questionId}
        //selectedAnswer={selectedAnswer}
        onAnswerSelected={onAnswerSelected}
      />
    );
  }

  function renderMultipleChoiceOptions(option, index) {
    return (
      <AnswerOptionCheck
        index ={index}
        key={option}
        answerContent={option}
        //disableOption={props.disableOption}
        //answerType={key.type}
        //answer={answer}
        //questionId={props.questionId}
        //selectedAnswer={selectedAnswer}
        onAnswerSelected={onAnswerSelected}
      />
    );
  }

  return (
    // questionSet.questions.map((question, index) =>
      <div key={questionSet._id} className="quiz-story">      
      <Row>
        <Col md={12}>
          <QuestionCount duration={duration} counter={counter} total={totalQuestions} timeLimit={questionSet.timeLimit} timeCounterDecrement={timeCounterDecrement}/>
        </Col>
      </Row>
      {questionSet.sectionName ? <Row>
        <Col>
          <h4 className="text-left sectHdrTxt">{questionSet.sectionName+" ("+questionSet.description+" )"} </h4>
        </Col>
      </Row> : null}
      <Row>
        <Col className="mt-3">
          <Card>
            <Question content={questionSet.question} />
              { questionSet.questionType === "single choice" ?
                <ul className="answerOptions">
                  {questionSet.options.map(renderAnswerOptions)}
                </ul> : questionSet.questionType === "multiple choice" ?
                <ul className="answerOptions">
                  {questionSet.options.map(renderMultipleChoiceOptions)}
                </ul> :
                <ul className="answerOptions">
                  <AnswerOptionTextarea 
                  //disableOption={question.disableOption}
                  />
                </ul>
              }
            </Card>
        </Col>
      </Row>
      <div className="bottom-footer col-md-12" >
        {/* {counter > 0 ? (<Button className="Previous-btn" variant="success" onClick={setPreviousQuestion} >Previous</Button>) : (<div></div>)} */}

        {counter + 1 < totalQuestions ? (<Button className="next-btn ml-2 btnblue" onClick={setNextQuestion} >Next</Button>) : (<Button className="next-btn ml-2 btnblue" onClick={setNextQuestion} >Finish</Button>)}
      </div>
    </div>
    )
  // );
}

export default Quiz;