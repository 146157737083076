import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../../../ServerCall/Auth';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        //debugger
        const currentUser = Auth;
        const token = Auth.getToken();
        if (!token) {
           // debugger
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.getUserRole()) === -1) {
            //debugger
            // role not authorised so redirect to home page
            if(currentUser.getUserRole() === "faculty"){
             return <Redirect to={{ pathname: '/FacultyDashboard'}} />
            }
            else if(currentUser.getUserRole() === "superAdmin"){
                return <Redirect to={{ pathname: '/AdminDashobard'}} />
            }
            else if(currentUser.getUserRole() === "student"){
                return <Redirect to={{ pathname: '/StudentDashboard'}} />
            }
           
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)