import React from "react";
import {Modal,Form, Button,Container,Row,Col,FormGroup,Image} from 'react-bootstrap'
import {Label,Input } from 'reactstrap'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';

    const AddChapterModal =({
        ChapterName,
        handleChange,
        onClickAddChapter,
        ModuleHandleChangeStatus,
        onClickCancelButton,
        moduleDescription,
        getUploadParams,
        show,
        onHide,
        title,
        formErrors,
        moduleThumbNail,
        onClickUpdateChapter,
        chapterId
        }) => {
        return(
        <Modal 
       // {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalWp"
        show={show}
        onHide={onHide}
        >
          <Modal.Header 
          // closeButton className="AddChaptermodalWp"
          >
            <Modal.Title id="contained-modal-title-vcenter">
               {chapterId !== ''?"Update Module":"Add Module"}
            </Modal.Title>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClickCancelButton}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
          <Container className="FormWp">
            <Row>
              <Col>
                <Form>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Form.Label>Module Name</Form.Label><span className="requiredFieldSpan">*</span>
                            <Form.Control type="text" name="ChapterName" id="Chaptername"  onChange={handleChange} value={ChapterName} required placeholder=""/>
                            {formErrors.ChapterName !== "" ? (
                              <Form.Label style={{ fontSize: 12, color: "red" }}>
                                {formErrors.ChapterName}
                              </Form.Label>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                  <Col  md={12}>
                    <Label for="exampleText">Descritpion</Label>
                    <Input type="textarea" name="moduleDescription" id="moduleDescription" onChange={handleChange} value={moduleDescription} />
                  </Col>
                </Row>
                <Row>
                  <Col  md={9}>
                    <Label for="exampleText" className="pt-3">Upload Thumbnail Image</Label>
  
                  </Col>
                  <Col md={3} className="WprImg mt-2 mb-2" >
                    <img src={moduleThumbNail}  style={{width:'100%'}}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={ModuleHandleChangeStatus}
                        //  onSubmit={handleSubmit}
                          maxFiles={1}
                          multiple={false}
                          accept="image/*"
                          inputContent="Upload Thumbnail Image"
                        />
                  </Col>
                </Row>
                  <Row>
                      <Col className="text-center mt-3">
                      <div  className="center-block text-center">
                        {chapterId === '' ?
                         <Button className="text-center mr-3" onClick={onClickAddChapter} >Add</Button>:
                         <Button className="text-center mr-3" onClick={onClickUpdateChapter} >Update</Button>
                        }
                        <Button className="text-center " variant="danger"  onClick={onClickCancelButton}>Cancel</Button>
                      </div>
                      </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
          </Modal.Body>
        </Modal>
     
    )
  }

export default AddChapterModal




