import React from 'react';
import {Row,Col,Container }from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {URL} from '../../ServerCall/Config';
import axiosApiInstance from '../../ServerCall/Axios.Instance';
import Auth from '../../ServerCall/Auth';
import { Email } from '@material-ui/icons';
import { setProfile } from '../../redux/action/profileAction';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Label from 'reactstrap/lib/Label';
import Tooltip from '@material-ui/core/Tooltip';

const defaultLabelStyle = {
    fontSize: '5px',
    fontFamily: 'sans-serif',
  };

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
  }));


const userName = Auth.getUserName();
const role = Auth.getUserRole();

const AdminDashobard =(props) =>{
    const classes = useStyles();
    const [selected, setSelected] = React.useState(0);
    const [student,setStudent]=React.useState('');
    const [faculty,setFaculty]=React.useState('');
    const [instruction,setInstruction]=React.useState(0);
    const [prerecorded,setPrerecorded]=React.useState(0);
    const [schedule ,setSchedule] = React.useState(0);
    const [enroll ,setEnroll] = React.useState(0);
    const [trending ,setTrending] = React.useState(0);
    const [email, setEmail] = React.useState('');
    const [name,setName] = React.useState('');
    const [nullCourse,setNullCourse] = React.useState(0);
    

    function courseCount(){
        axiosApiInstance.get(`${URL}/getCourseCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
                //debugger
               let User = res.data.Course;
               User&&User.map(i=>{

                    if(i._id === "Instruction based"){
                        setInstruction(i.count);
                    }
                    else if(i._id === "Prerecorded")
                    {
                        setPrerecorded(i.count);
                    }
                    else if(i._id === null)
                    {
                        setNullCourse(i.count);
                    }
                 }
                )
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function userCount(){
        axiosApiInstance.get(`${URL}/getUsersCount`)
        .then(res=>{
            // console.log(res,"res");
            if(res.status === 200){
                //debugger
               let User = res.data.User;
               User&&User.map(i=>{
                    if(i._id === "student"){
                        setStudent(i.count);
                    }
                    else if(i._id === "faculty")
                    {
                        setFaculty(i.count);
                    }
                 }
                )
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function userDetail(){
        axiosApiInstance.get(`${URL}/getProfile`)
        .then(res=>{
        //   console.log(res);
          if(res.status === 200){
            let User = res.data.User;
            props.setProfile(User);
            setEmail(User.email);
            setName(User.fullName);
          }
        }).catch(err=>{
            console.log(err);
        })
    }

    function scheduleCount(){
        axiosApiInstance.get(`${URL}/getCourseScheduleCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               // debugger
               let scheduleCount = res.data.CourseSchedule;
               setSchedule(scheduleCount);
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    function enrollmentCount(){
        axiosApiInstance.get(`${URL}/getEnrollmentCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
                let Count = res.data.enrollmentCount;
                var value = 0;
                setTrending(Count[1].count);
                // for(let i = 0; i < Count.length; i++) {
                //     if(Count[i]._id !== null){
                //     value += Count[i].count;
                //     }
                // }
                // console.log(value);
             setEnroll(Count.length);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }


    React.useEffect(()=>{
        courseCount();
        userCount();
        scheduleCount();
        enrollmentCount();
        userDetail();
     }, []);




        return( 
            <Container fluid className=" pl-0 pr-0  adminDashWp" style={{position:'relative'}}>
                <Row className="welcomeTextRow">
                    <Col style={{fontSize:'16px',fontWeight:'bold'}} className="WelcomeName" > 
                        Welcome !<span className="blueText pl-2">{name}</span>
                    </Col>
                </Row>
                <Row className="pieContent">
                    <Row className='mb-5'>
                    <Col><PieChart>
                        <PieChart 
                        style={{fontFamily:'"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif', fontSize: '8px', marginRight:'20px'}}
                        data={[{ title: `Instruction led Courses (${instruction})`, value: instruction, color: '#0e1c3a' },
                               { title: `Pre-Recorded Courses (${prerecorded})`, value: prerecorded, color: '#E38627' }]}
                        radius={40}
                        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                        segmentsShift={(index) => (index === selected ? 5: 1)}
                        label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
                        labelPosition={50}
                        labelStyle={{ fill: '#fff', opacity: 0.75, pointerEvents: 'none', fontSize:`8px`}}
                        onClick={(_, index) => {setSelected(index === selected ? undefined : index);}}
                        animate
                        animationDuration={1000}
                        animationEasing='ease-in'
                        reveal={100}
                        /></PieChart></Col>
                        {(instruction!=0&&prerecorded!=0)?(
                            <Col className='piekeys'>
                        <Row>
                        <div className="foo blue"/>
                        <Label className=''>Instruction Led Courses ({instruction})</Label>
                        </Row>
                        <Row>
                        <div className="foo orange"/>
                        <Label className=''>Pre-Recorded Courses ({prerecorded})</Label>
                        </Row>
                        </Col>):null}
                    </Row>

                    {/* <Col md={4} style={{marginLeft:'-97px'}}>
                        <Card className="adminCard">
                            <div className="cardImageTop">
                                <h5 className="text-center hedingTextc"> Top Trending Courses -{trending}</h5>
                                
                            </div>
                            <div className="avtarWp">
                            </div>
                            <CardBody>
                            <CardTitle className="subtiteltxt">Total Trending - ({trending})</CardTitle>
                            
                            </CardBody>
                        </Card>
                    </Col> */}
                    {/* <Col md={4} className="ml-0">  
                        <Card className="adminCard">
                            <div className="cardImageTop">
                                <h5 className="text-center hedingTextc"> {name}</h5>
                            </div>
                            <div className="avtarWp">
                            <Avatar className={classes.orange}>
                                {userName !== undefined? userName.charAt(0)+" "
                                :null} 
                            </Avatar>
                            </div>
                            <CardBody>
                            <CardTitle className="subtiteltxt">Role : Admin</CardTitle>
                            <CardTitle className="subtiteltxt ">{email}</CardTitle>
                            </CardBody>
                        </Card>
                    </Col> */}
                    {/* <Col md={4}>
                    <Link to={'/FacultyRegistration'}>
                    <Card className="adminCard">
                        <div className="cardImageTop"> */}
                            {/* <h5 className="text-center hedingTextc">Instructor - {faculty}</h5> */}
                            {/* <CardText className="cardTextclass">Total Instructor - {faculty}</CardText> */}
                        {/* </div>
                        <div className="avtarWp"> */}
                        {/* <Avatar className={classes.orange}>AB
                        </Avatar> */}
                        {/* </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt">Instructor - ({faculty})</CardTitle> */}
                        {/* <CardTitle className="subtiteltxt ">Schedule of Course - ({schedule})</CardTitle> */}
                        {/* </CardBody>
                    </Card></Link>
                    </Col> */}
                    {/* <Col md={4}>
                    <Link to={'/StudentList'}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc"> Student - {student}</h5> */}
                            {/* <CardText className="cardTextclass">Total Student - {student}</CardText> */}
                        {/* </div>
                        <div className="avtarWp"> */}
                        {/* <Avatar className={classes.orange}>AB
                        </Avatar> */}
                        {/* </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt">Student - ({student})</CardTitle> */}
                        {/* <CardTitle className="subtiteltxt ">schedule of Student (20)</CardTitle> */}
                        {/* </CardBody>
                    </Card></Link>
                    </Col> */}
                </Row>
                <Row className=" DashsecondRow">
                <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                             <h5 className="text-center hedingTextc"> Course Report {/*-{nullCourse+prerecorded+instruction}*/}</h5> 
                            {/* <CardText className="cardTextclass">Total Courses - {nullCourse+prerecorded+instruction}</CardText> */}
                        </div>
                        <div className="avtarWp">
                        </div>
                        <CardBody>
                        <Col>
                        <Tooltip
                            title='Go to Course Reports'
                            disableFocusListener
                            placement='top'
                            >
                        <Link style={{color:'black'}} to={'/Reports'}> 
                        <CardTitle className="subtiteltxt ">Instructor Led Course - ({instruction})</CardTitle>
                        <CardTitle className="subtiteltxt ">Prerecorded Course - ({prerecorded})</CardTitle>
                        </Link></Tooltip>
                        </Col>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="adminCard">
                            <div className="cardImageTop">
                                <h5 className="text-center hedingTextc"> Top Trending Courses -{trending}</h5>
                                {/* <CardText className="cardTextclass">Top Trending - {trending}</CardText> */}
                            </div>
                            <div className="avtarWp">
                            </div>
                            <CardBody>
                            <CardTitle className="subtiteltxt">Total Trending - ({trending})</CardTitle>
                            {/* <CardTitle className="subtiteltxt ">schedule of Student (20)</CardTitle> */}
                            </CardBody>
                        </Card>
                    </Col>
                    
                    <Col md={4}>
                    <Card className="adminCard">
                        <div className="cardImageTop">
                            <h5 className="text-center hedingTextc"> Subscribed Courses - {enroll}</h5>
                            {/* <CardText className="cardTextclass">Total Subscribed Courses - {enroll}</CardText> */}
                        </div>
                        <div className="avtarWp">
                        {/* <Avatar className={classes.orange}>AB
                        </Avatar> */}
                        </div>
                        <CardBody>
                        <CardTitle className="subtiteltxt">Subscribed Course - ({enroll})</CardTitle>
                        {/* <CardTitle className="subtiteltxt ">schedule of Subcribed Courses (20)</CardTitle> */}
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
               
        </Container>
        );
    }
    const mapStoreToProps = (state) => {
        //debugger
        // console.log(state,"datadatadata");
        return{
          Profile: state.profile.profile
        }
      }
      
      const mapDispatchToProps = dispatch => ({
        setProfile:(profile)=>dispatch(setProfile(profile))
      })
      export default connect(mapStoreToProps, mapDispatchToProps)(AdminDashobard);