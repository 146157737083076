import React from "react";
import Dropzone from "react-dropzone-uploader";
import {on} from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { propTypes } from "react-bootstrap/esm/Image";
import { DonutLargeOutlined } from "@material-ui/icons";

const SimpleDropZone = (props) => {
  const getUploadParams = ({ meta }) => {
    // console.log(meta);
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta, file }, status,allFiles) => {
    // if(props.isSubmit === true){allFiles.forEach(f=>f.remove())}
    // console.log(status, meta, file);
  };

  //  const handleSubmit = (files, allFiles) => {
  // //     console.log(files.map(f => f.meta))
  //      allFiles.forEach(f => f.remove())
  //  }

  // const removeAllFiles=(allFiles)=>{
  //   allFiles.forEach(f=>f.remove())
  // }

  const getFilesFromEvent = (e) => {
    //debugger;
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    //debugger;
    // console.log(files,"files");
    props.arrayData(files);
   // props.onSubmit(files);
    // setFiles(files);
    if(props.isSubmit === true){ files.forEach(f=>f.remove())};
    const text =
      files.length > 0
        ? "Add more files"
        : "Drop Files Here or Click to Browse";

    const buttonStyle = {
      //backgroundColor: "#4c8db5",
      backgroundColor: "Transparent",
      color: "#000",
      cursor: "pointer",
      marginTop: 20,
      padding: 5,
      borderRadius: "5px",
    };
    return (
      <label style={buttonStyle}>
        {text}
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }
        }
        />
      </label>
    );
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept="image/*,.pdf,.pptx,.docx,.ppt,.doc"
      // onSubmit={handleSubmit}
      maxFiles={100}
      InputComponent={InputChooseFile}
      getFilesFromEvent={getFilesFromEvent}
      classNames
    />
  );
};

export default SimpleDropZone;
