import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MediaCard from '../../../Components/Student/Course/MediaCard'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={() => onClick()} />;
};

class Slider extends Component {
  _isMounted = false;

  state = {
    awsApiData: [],
    loading: false,
    selectedIndex: 0,
    lightboxIsOpen: false
  };

  



  render() {
   
    return (
 
            <Carousel  responsive={responsive} partialVisible={true}>
                <div className="mr-3">
                  <MediaCard title="Digital Marketing " type="Progressbar"
                      text="Master 20+ Digital Marketing skills &amp; tools from India's #1 Digital Institute!"
                      image={require('../../../Images/DigitalMarketing.jpg')}/>
                  </div>
                  <div  className="mr-3">
                  <MediaCard title="Python " type="Progressbar"
                      text="Python with 200+ examples  of Python.Python is  high-level &amp; programming language. "
                      image={require('../../../Images/python.png')}/>
                  </div>
                  <div  className="mr-3">
                  <MediaCard title="Web Development " type="Progressbar"
                      text="Web development courses equipping you to become a web developer in 2020."
                      image={require('../../../Images/WebDevelopment.jpeg')}/>
                  </div>
                  <div  className="mr-3">
                  <MediaCard title="Java Programming " type="Progressbar"
                      text="Java Programming with 200+ examples  of Java Begginers.Learn Java Object oriented Programming Now!"
                      image={require('../../../Images/java.png')}/>
                  </div>
                  
                </Carousel>
       
      
    );
  }
}

export default Slider;