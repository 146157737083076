import React from 'react';
import{Row,Col,Container,Card,FormGroup,Label,Input,Form,Button} from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }));
const  StudentSupportForm = () =>{
    const classes = useStyles();
    const [state, setState] = React.useState({
      gilad: true,
      jason: false,
      antoine: false,
    });
  
    const handleChange = name => event => {
      setState({ ...state, [name]: event.target.checked });
    };
    const {VideoChat, Phone, Email ,SkillBuilding,Accessibility,CommunicatingwithFaculty,CommunicatingwithOffice,PersonalFinances,Registration,Spirituality,
        StudentAccounts,TechnicalSupport,Other} = state;
    const error = [VideoChat, Phone, Email].filter(v => v).length !== 2;
  
    return(
        <Container>
         <Card className="shadow p-4 col-md-9 marginauto FormWp CouseMasterWp">
             <Form>
                <Row>
                    <Col className="mb-4">
                    <h3 className="text-center blueText">Student Support Form</h3>
                    </Col>
                </Row>
                 <Row>
                     <Col md={6}>
                        <FormGroup>
                            <Label for="Fname">First Name</Label>
                            <Input type="text" name="Fname" id="Fname" placeholder="First Name" />
                        </FormGroup>
                     </Col>
                     <Col md={6}>
                        <FormGroup>
                            <Label for="Fname">Last Name</Label>
                            <Input type="text" name="Lname" id="Lname" placeholder="Last Name" />
                        </FormGroup>
                     </Col>
                 </Row>
                 <Row>
                     <Col md={6}>
                        <FormGroup>
                            <Label for="Email">Email</Label>
                            <Input type="Email" name="Email" id="Email" placeholder="Email" />
                        </FormGroup>
                     </Col>
                     <Col md={6}>
                        <FormGroup>
                            <Label for="Phone Number">Phone Number</Label>
                            <Input type="Number" name="Phone" id="Phone" placeholder="Phone Number" />
                        </FormGroup>
                     </Col>
                 </Row>
                 <Row>
                     <Col md={6}>
                        <FormGroup>
                            <Label for="StudCourse">Student Course</Label>
                            <Input type="text" name="Course" id="Course" placeholder="Student Course" />
                        </FormGroup>
                     </Col>
                     <Col md={6}>
                        <FormGroup>
                            <Label for="StudBatch">Student Batch</Label>
                            <Input type="text" name="Batch" id="Batch" placeholder="Student Batch" />
                        </FormGroup>
                     </Col>
                 </Row>
                 <Row>
                    <Col>
                    <Label>The support I am looking for includes:</Label>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={VideoChat} onChange={handleChange('VideoChat')} value="VideoChat" />}
                                label="Video Chat"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={Phone} onChange={handleChange('Phone')} value="Phone" />}
                                label="Phone"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={Email} onChange={handleChange('Email')} value="Email" />
                                }
                                label="Email"
                            />
                            </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="checkCommunicateWp">
                        {/* <FormControl component="fieldset" className={classes.formControl}> */}
                        <Label>I am able to communicate by: (check all that apply)</Label>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={SkillBuilding} onChange={handleChange('VideSkillBuildingoChat')} value="SkillBuilding" />}
                                label="SkillBuilding"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={Accessibility} onChange={handleChange('Accessibility')} value="Accessibility" />}
                                label="Accessibility"
                            />
                               <FormControlLabel
                                control={
                                <Checkbox checked={Registration} onChange={handleChange('Registration')} value="Registration" />
                                }
                                label="Registration"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={CommunicatingwithFaculty} onChange={handleChange('CommunicatingwithFaculty')} value="CommunicatingwithFaculty" />
                                }
                                label="Communicatin with Faculty"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={CommunicatingwithOffice} onChange={handleChange('CommunicatingwithOffice')} value="CommunicatingwithOffice" />
                                }
                                label="Communicating with Office"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={PersonalFinances} onChange={handleChange('PersonalFinances')} value="PersonalFinances" />
                                }
                                label="Personal Finances"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={StudentAccounts} onChange={handleChange('StudentAccounts')} value="StudentAccounts" />
                                }
                                label="StudentAccounts"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={TechnicalSupport} onChange={handleChange('TechnicalSupport')} value="TechnicalSupport" />
                                }
                                label="TechnicalSupport"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={Other} onChange={handleChange('Other')} value="Other" />
                                }
                                label="Other"
                            />
                            </FormGroup>
                            
                          
                        {/* </FormControl> */}
                    </Col>
                </Row>
                <Row>
                    <Col><Button cla>Submit</Button></Col>
                </Row>
             
             </Form>
        </Card>
        </Container>

    )
}
export default StudentSupportForm;