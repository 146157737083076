import React, { useState, useEffect } from "react";
import { NavItem, NavLink, Nav,Collapse} from "reactstrap";
// import classNames from "classnames";
import { Link } from "react-router-dom";
import  './SideBar.css';
import logo from '../../../Images/con_logo-old.png'
import PieChartIcon from '@material-ui/icons/PieChart';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SubjectIcon from '@material-ui/icons/Subject';
import HelpIcon from '@material-ui/icons/Help';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Auth from '../../../ServerCall/Auth';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PaymentIcon from '@material-ui/icons/Payment';
import ViewListIcon from '@material-ui/icons/ViewList';
import EventIcon from '@material-ui/icons/Event';

const Asubmenus = [
  {id: 101, title: "Course Category",target: "/CourseCategory"},
  {id: 102, title: "Course Master",target: "/CourseMaster"},
  {id: 103, title:"Course List",target:"/CourseList"},
  {id: 104, title:"Schedule",target:"/Schedule"},
  {id: 108, title:"Change Batch",target:"/ChangeBatch"}
];

const Studentmenus = [
  {id: 106, title: "Student Registration",target: "/StudentRegistration"},
  {id: 107, title: "Student List",target: "/StudentList"},
  {id: 108, title: "Student Feedback",target: "/StudentFeedbackList"}
];

const CourseListMenu=[
  {id: 555, title: 'Batch List',target: "/CourseSchudleList", icon:<ScheduleIcon className="mr-3" style={{marginleft:'-2'}}/>},
  {id: 116, title: 'Release Content',target: "/ReleaseContent", icon:<CollectionsBookmarkIcon className="mr-3" style={{marginleft:'-2'}}/>}
];

const SideBar = ({ isOpen ,toggle}) => {
  let tabIndex = 1;
  let tabName = window.location.pathname;
  if(tabName == "/StudentDashboard" || tabName == "/AdminDashobard" || tabName == "/FacultyDashboard") tabIndex = 1;
  else if (tabName == "/CourseList") tabIndex = 5;
  else if (tabName == "/SubjectMaster" || tabName == "/Enrollment") tabIndex = 3;
  else if (tabName == "/FacultyRegistration" || tabName == "/Payment" || tabName == "/StudentQuestion") tabIndex = 4;
  else if (tabName == "/NewsEntry" || tabName == "/AskQuestion") tabIndex = 5;
  else if (tabName == "/Event" || tabName == "/AssignedTest") tabIndex = 6;
  else if (tabName == "/Reports" || tabName == "/ViewSchedule" || tabName == "/ScheduleEntry") tabIndex = 7;
  else if (tabName == "/CourseSchudleList") tabIndex = 8;
  else if (tabName == "/UserProfileSetting" || tabName == "/CourseAllocated")tabIndex = 10;
  //else if (tabName == "/AddTestContainer"  || tabName == "/Course") tabIndex = 9; 
  else if (tabName == "/AddTestContainer"  || tabName == "/CourseSection") tabIndex = 9; 
  else if (tabName == "/CourseCategory" || tabName =="/StudentFeedback") tabIndex = 101; 
  else if (tabName == "/CourseMaster") tabIndex = 102;   
  else if (tabName == "/CourseList") tabIndex = 103;
  else if (tabName == "/Schedule") tabIndex = 104;  
  else if (tabName == "/StudentRegistration") tabIndex = 106;
  else if (tabName == "/StudentList") tabIndex = 107;
  // else if (tabName == "/Course") tabIndex = 11;
  else if (tabName == "/CourseSection") tabIndex = 11;
  else if (tabName == "/TestList") tabIndex = 12;

  const [activeIndex, setActiveIndex] = useState(tabIndex);  
  const [collapsed, setCollapsed] = useState(true);
  const [nestCollapsed, setNestCollapsed] = useState(true);
  const [collaps, setCollaps ] = useState(true);
 
  const role = Auth.getUserRole();
  const token = Auth.getToken();

  useEffect(() => { 
    if(activeIndex === 101 || activeIndex === 102 || activeIndex === 103 || activeIndex === 104){
      return setCollapsed(false);
    }  
    if(activeIndex === 106 || activeIndex === 107){
      return setCollaps(false);
    } 
    if(activeIndex === 10){
      return setNestCollapsed(false);
    }
  }, [activeIndex])

  // useEffect(() => {    
  //   if(activeIndex === 106 || activeIndex === 107 ){
  //     return setCollaps(false);
  //   }  
  // }, [activeIndex])

  const intentFunct = (id) => {
    setActiveIndex(id);
  }

  const closeDropdown = () => {
    setCollapsed(true);
    setCollaps(true);
  }

  const toggleNav = () =>{ setCollapsed(!collapsed); setCollaps(true);}
  const toggleNav1 = () => {setCollaps(!collaps); setCollapsed(true);}
  const toggleNav3 = () => {setNestCollapsed(!nestCollapsed); setNestCollapsed(true);}
  
  
  return(
  <div className={isOpen ? "is-open sidebar shadow " : "sidebar shadow "}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#000" }}>
        &times;
      </span>
      <div className="logowp mt-4 mb-4">
            <img src={logo} alt="logo" />
      </div>
    </div>
    <div className="side-menu ">
  {token && role === "superAdmin"?
      <Nav vertical className="list-unstyled pb-3 sideNv">
        <NavItem>
          <NavLink tag={Link} to={"/AdminDashobard"} onClick={()=> intentFunct(1)}
            className={activeIndex === 1 ? "setActiveTab" : ""}>
            <PieChartIcon className="mr-3" style={{marginleft:'-2'}}/>
            Dashboard
          </NavLink>
          {/* <ASubMenu title="Course" icon={faFileAlt} items={Asubmenus[0]} tag={Link} to={"/Course"}  className="mr-3"
          onClick={()=> intentFunct(2)}
          className={activeIndex === {activeIndex} ? "setActiveTab" : ""}
         /> */}
        </NavItem>
        <NavItem
        onClick={toggleNav}
        className={{ "menu-open": !collapsed }}
      >
        {/* <NavLink className="dropdown-toggle" tag={Link}  to={"/Course"} onClick={()=> intentFunct(100)} */}
         <NavLink className="dropdown-toggle" tag={Link}  to={"/CourseSection"} onClick={()=> intentFunct(100)}
            className={activeIndex === 100 ? "setActiveTab" : ""}>
              <MenuBookIcon className="mr-3" />
              Courses <ArrowDropDownIcon className="float-right"/>
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className="DropNav"
        //className={"items-menu", { "mb-1": !collapsed }}
      >
        {Asubmenus.map(submenu => (
          <NavItem key={submenu.id}     >
            <NavLink tag={Link} to={submenu.target}
              onClick={()=> intentFunct(submenu.id)}
              className={activeIndex === (submenu.id) ? "setActiveTab pl-5" : " pl-5"}>
              {submenu.title}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
        <NavItem onClick={() => closeDropdown()}>
          <NavLink tag={Link} to={"/SubjectMaster"}
          onClick={()=> intentFunct(3)}
          className={activeIndex === 3  ? "setActiveTab" : ""}>
            <SubjectIcon className="mr-3" style={{marginleft:'-2'}}/>
            Core Skills
          </NavLink>
        </NavItem>
        
         <NavItem onClick={() => closeDropdown()}>
          <NavLink tag={Link} to={"/FacultyRegistration"}
          onClick={()=> intentFunct(4)}
          className={activeIndex === 4 ? "setActiveTab" : ""}>
            <GroupAddIcon className="mr-3" style={{marginleft:'-2'}}/>
            Faculty
          </NavLink>
        </NavItem>
          {/* <NavItem >
            <NavLink tag={Link} to={"/AssignFaculty"} onClick={()=> intentFunct(5)}
              className={activeIndex === 5 ? "setActiveTab" : ""}>
              <HowToRegIcon className="mr-3" style={{marginleft:'-2'}}/>
              Assign Faculty
            </NavLink>
          </NavItem> */}
        <NavItem onClick={() => closeDropdown()}>
          <NavLink tag={Link} to={"/NewsEntry"} onClick={()=> intentFunct(5)}
            className={activeIndex === 5 ? "setActiveTab" : ""}>
            <ChromeReaderModeIcon className="mr-3" style={{marginleft:'-2'}}/>
           News
          </NavLink>
        </NavItem>
        <NavItem onClick={() => closeDropdown()}>
          <NavLink tag={Link} to={"/Event"}
          onClick={()=> intentFunct(6)}
          className={activeIndex === 6 ? "setActiveTab" : ""}>            
            <EventNoteIcon className="mr-3" style={{marginleft:'-2'}}/>
             Events
          </NavLink>
        </NavItem>
         
        {/* <NavItem onClick={() => closeDropdown()}>
          <NavLink tag={Link} to={"/Reports"}
           onClick={()=> intentFunct(7)}
            className={activeIndex === 7 ? "setActiveTab" : ""}>
            <DescriptionIcon  className="mr-3" style={{marginleft:'-2'}}/>
            Reports
          </NavLink>
        </NavItem> */}
        {/* <NavItem onClick={() => setCollapsed(true)}>
          <NavLink tag={Link} to={"/StudentRegistration"}
            onClick={()=> intentFunct(8)}
              className={activeIndex === 8 ? "setActiveTab" : ""}>
              <AssignmentIndIcon className="mr-3" style={{marginleft:'-2'}}/>
              Student
            </NavLink>
        </NavItem> */}
        <NavItem
        onClick={toggleNav1}
        className={{ "menu-open": !collaps }}
      >
        <NavLink className="dropdown-toggle" onClick={()=> intentFunct(105)}
            className={activeIndex === 105 ? "setActiveTab" : ""}>
              <AssignmentIndIcon className="mr-3" />
          Student <ArrowDropDownIcon className="float-right"/>
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collaps}
        navbar
        className="DropNav"
        //className={"items-menu", { "mb-1": !collapsed }}
      >
        {Studentmenus.map(studmenu => (
          <NavItem key={studmenu.id}     >
            <NavLink tag={Link} to={studmenu.target}
              onClick={()=> intentFunct(studmenu.id)}
              className={activeIndex === (studmenu.id) ? "setActiveTab pl-5" : " pl-5"}>
              {studmenu.title}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
      {/* <NavItem>
            <NavLink tag={Link} to={"/AddTestContainer"}
            onClick={()=> intentFunct(9)}
              className={activeIndex === 9 ? "setActiveTab" : ""}>
              <PostAddIcon className="mr-3" style={{marginleft:'-2'}}/>
              Create Test
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={Link} to={"/TestList"}
            onClick={()=> intentFunct(12)}
              className={activeIndex === 12 ? "setActiveTab" : ""}>
              <ListAltIcon className="mr-3" style={{marginleft:'-2'}}/>
               Test List
            </NavLink>
        </NavItem> */}
       
        <NavItem>
          <NavLink tag={Link} to={"/UserProfileSetting"}
            onClick={()=> intentFunct(2)}
            className={activeIndex === 2 ? "setActiveTab" : ""}>
            <HowToRegIcon className="mr-3" style={{marginleft:'-2'}}/>
            Profile
          </NavLink>
        </NavItem>
        {/* <NavItem>
            <NavLink tag={Link} to={'/Reports'}
            onClick={()=> intentFunct(9)}
              className={activeIndex === 9 ? "setActiveTab" : ""}>
              <AssessmentIcon className="mr-3" style={{marginleft:'-2'}}/>
              Reports
            </NavLink>
        </NavItem> */}
        </Nav>: token &&  role === "student"?

        <Nav vertical className="list-unstyled pb-3 sideNv">
           <NavItem>
            <NavLink tag={Link} to={"/StudentDashboard"}
              onClick={()=> intentFunct(1)}
              className={activeIndex === 1 ? "setActiveTab" : ""}>
               <PieChartIcon className="mr-3" style={{marginleft:'-2'}}/>
              Dashboard
            </NavLink>
          </NavItem>
          <NavItem>
            {/* <NavLink tag={Link} to={"/Course"} */}
            <NavLink tag={Link} to={"/CourseSection"}
              onClick={()=> intentFunct(9)}
              className={activeIndex === 9 ? "setActiveTab" : ""}>
              <AccountTreeIcon className="mr-3" style={{marginleft:'-2'}}/>
              Avaliable Courses
            </NavLink>
          </NavItem>
        {/* <SubMenu title="Course" icon={faIdCard}  className="mr-3"
          onClick={()=> intentFunct(9)}
          className={activeIndex === 9 ? "setActiveTab" : ""}
         /> */}
        <NavItem>
            <NavLink tag={Link} to={"/Enrollment"}
            onClick={()=> intentFunct(3)}
              className={activeIndex === 3 ? "setActiveTab" : ""}>
              <PostAddIcon className="mr-3" style={{marginleft:'-2'}}/>
              Enrolled Courses
            </NavLink>
        </NavItem>
        {/* <NavItem>
            <NavLink tag={Link} to={"/Payment"}
            onClick={()=> intentFunct(4)}
              className={activeIndex === 4 ? "setActiveTab" : ""}>
              <PaymentIcon className="mr-3" style={{marginleft:'-2'}}/>
             Payment &amp; Invoice
            </NavLink>
        </NavItem> */}
           {/* <SubMenu title="Course Name" icon={faFileAlt}  className="mr-3"
          onClick={()=> intentFunct(10)}
          className={activeIndex === 10 ? "setActiveTab" : ""}
         /> */}
          {/* <NavItem>
            <NavLink tag={Link} to={"/AskQuestion"}
            onClick={()=> intentFunct(5)}
              className={activeIndex === 5 ? "setActiveTab" : ""}>
              <HelpIcon className="mr-3" style={{marginleft:'-2'}}/>
              Ask Question
            </NavLink>
        </NavItem> */}
        <NavItem>
            <NavLink tag={Link} to={"/AssignedTest"}
            onClick={()=> intentFunct(6)}
              className={activeIndex === 6 ? "setActiveTab" : ""}>
              <QueuePlayNextIcon className="mr-3" style={{marginleft:'-2'}}/>
              Assignments
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={Link} to={"/ViewSchedule"}
            onClick={()=> intentFunct(7)}
            className={activeIndex === 7 ? "setActiveTab" : ""}>
            <EventIcon className="mr-3" style={{marginleft:'-2'}}/>
             Calendar
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/StudentFeedback"}
            onClick={()=> intentFunct(109)}
            className={activeIndex === 109 ? "setActiveTab" : ""}>
            <AssignmentIndIcon className="mr-3" style={{marginleft:'-2'}}/>
            Student Feedback
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/UserProfileSetting"}
            onClick={()=> intentFunct(2)}
            className={activeIndex === 2 ? "setActiveTab" : ""}>
            <HowToRegIcon className="mr-3" style={{marginleft:'-2'}}/>
            Profile
          </NavLink>
        </NavItem>
      </Nav>: token && role === "faculty"?
        <Nav vertical className="list-unstyled pb-3 sideNv">
          {/* <NavItem>
            <NavLink tag={Link} to={"/Course"}
            onClick={()=> intentFunct(14)}
              className={activeIndex === 14 ? "setActiveTab" : ""}>
              <HowToRegIcon className="mr-3" style={{marginleft:'-2'}}/>
              Dashboard
            </NavLink>
        </NavItem> */}
        {/* <SubMenu title="Course" icon={faIdCard}  className="mr-3"
          onClick={()=> intentFunct(9)}
          className={activeIndex === 9 ? "setActiveTab" : ""}
         /> */}
         <NavItem>
           <NavLink tag={Link} to={"/FacultyDashboard"}
             onClick={()=> intentFunct(1)}
               className={activeIndex === 1 ? "setActiveTab" : ""}>
               <PieChartIcon className="mr-3" style={{marginleft:'-2'}}/>
               Dashboard
             </NavLink>
           </NavItem>
         <NavItem onClick={toggleNav}
        className={{ "menu-open": !collapsed }}>
        <NavLink className="dropdown-toggle" tag={Link} to={"#"}
          onClick={()=> intentFunct(1111)}
            className={activeIndex === 1111 ? "setActiveTab" : ""}>
            <HowToRegIcon className="mr-3" style={{marginleft:'-2'}}/>
            Courses <ArrowDropDownIcon className="float-right"/>
          </NavLink>
        </NavItem>
        <Collapse
        isOpen={!collapsed}
        navbar
        className="DropNav"
        //className={"items-menu", { "mb-1": !collapsed }}
      > 
      <NavItem>
        <NavLink className="DropNav" tag={Link} to={"/CourseAllocated"}
          onClick={()=> intentFunct(214)}
            className={activeIndex === 214 ? "setActiveTab" : ""}>
            <CollectionsBookmarkIcon className="mr-3 ml-3" />
            Pre-Recorded
          </NavLink>
        </NavItem>
        <NavItem onClick={toggleNav3}
    className={{ "menu-open": !nestCollapsed }}>
        <NavLink className="DropNav" tag={Link} to={"#"}
          onClick={()=> intentFunct(10)}
            className={activeIndex === 10 ? "setActiveTab" : ""}>
            <CollectionsBookmarkIcon className="mr-3 ml-3" />
            Instruction Based<ArrowDropDownIcon className="float-right"/>
          </NavLink>
        <Collapse
        isOpen={!nestCollapsed}
        navbar
        className="DropNav"
        //className={"items-menu", { "mb-1": !collapsed }}
      >
          {CourseListMenu.map(submenu => (
          <NavItem key={submenu.id}     >
            <NavLink tag={Link} to={submenu.target}
              onClick={()=> intentFunct(submenu.id)}
              className={activeIndex === (submenu.id) ? "setActiveTab pl-5" : " pl-5"}>
              {submenu.icon}{submenu.title}
            </NavLink>
          </NavItem>
        ))}</Collapse></NavItem>
      </Collapse>
        <NavItem>
        <NavLink tag={Link} to={"/CourseList"}
          onClick={()=> intentFunct(5)}
            className={activeIndex === 5 ? "setActiveTab" : ""}>
            <ViewListIcon className="mr-3" style={{marginleft:'-2'}}/>
            Course List 
          </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={Link} to={"/AddTestContainer"}
            onClick={()=> intentFunct(9)}
              className={activeIndex === 9 ? "setActiveTab" : ""}>
              <PostAddIcon className="mr-3" style={{marginleft:'-2'}}/>
              Create Test
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={Link} to={"/TestList"}
            onClick={()=> intentFunct(12)}
              className={activeIndex === 12 ? "setActiveTab" : ""}>
              <ListAltIcon className="mr-3" style={{marginleft:'-2'}}/>
               Test List
            </NavLink>
        </NavItem>
        {/* <NavItem>
            <NavLink tag={Link} to={"/StudentQuestion"}
            onClick={()=> intentFunct(4)}
              className={activeIndex === 4 ? "setActiveTab" : ""}>
              <DescriptionIcon className="mr-3" style={{marginleft:'-2'}}/>
             Questions
            </NavLink>
        </NavItem> */}
        {/* <NavItem>
            <NavLink tag={Link} to={"/CourseSchudleList"}
            onClick={()=> intentFunct(8)}
            className={activeIndex === 8 ? "setActiveTab" : ""}>
            <ScheduleIcon className="mr-3" style={{marginleft:'-2'}}/>
             Batches
          </NavLink>
        </NavItem> */}
        <NavItem>
            <NavLink tag={Link} to={"/ScheduleEntry"}
            onClick={()=> intentFunct(7)}
            className={activeIndex === 7 ? "setActiveTab" : ""}>
            <EventIcon className="mr-3" style={{marginleft:'-2'}}/>
             Calendar
            </NavLink>
        </NavItem>
           {/* <SubMenu title="Course Name" icon={faFileAlt}  className="mr-3"
          onClick={()=> intentFunct(10)}
          className={activeIndex === 10 ? "setActiveTab" : ""}
         /> */}
          <NavItem>
            <NavLink tag={Link} to={"/UserProfileSetting"}
            onClick={()=> intentFunct(2)}
              className={activeIndex === 2 ? "setActiveTab" : ""}>
              <HowToRegIcon className="mr-3" style={{marginleft:'-2'}}/>
              Profile
            </NavLink>
        </NavItem>
        
      </Nav>:null}
         
    </div>
  </div>
  )
};

export default SideBar;