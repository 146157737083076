import React from "react";
import "./CourseMaster.css";
import {
  Card,
  FormGroup,
  Label,
  Col,
  Row,
  Container,
  Button,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import TextEditor from '../../../Components/Common/TextEditor';
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import AddModal from "../../../Components/Admin/Course/AddModal";
import AddChapterModal from "../../../Components/Admin/Course/AddChapterModal";
import MultiSelection from "../../../Components/Admin/Course/MultiSelection";
import DropDown from "../../../Components/Admin/Course/DropDown";
import Table from "../../../Components/Admin/Course/Table";
import "../../../Components/Admin/Course/Table.css";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import axios from "axios";
import _, { result } from "lodash";
import swal from "sweetalert";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { URL, mediaUrl } from "../../../ServerCall/Config";
import MediaAxiosApiInstance from "../../../ServerCall/MediaAxios.Instance";
import ToggleSwitch from "../../../Components/Faculty/Course/ToggleSwitch";
import CreateTestModal from "./CreateTestModal";
import moment from "moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Auth from "../../../ServerCall/Auth";
import { Link } from "react-router-dom";
import Loader from "../../../Components/Common/Loader/Loader";
import validator from "validator";
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";
import SimpleDropZone from "../../../Components/Common/SimpleDropZone";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import IconButton from "@material-ui/core/IconButton";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

//import CurrencyInput from 'react-currency-input-field';

const token = Auth.getToken();
const userId = Auth.getUserId();
const userName = Auth.getUserName();
const role = Auth.getUserRole();

// const configImage = {
//   headers: {
//     'content-type': 'multipart/form-data',
//     'Authorization':`Bearer ${token}`,
//   }
// };

const headerStyle = {
  backgroundColor: "#4c8db5",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

var getLocation = function (href) {
  var l = document.createElement("a");
  l.href = href;
  return l;
};
export default class CourseMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batch: "",
      loading: false,
      isSubmit: false,
      lessonId: "",
      urlValue:"",
      scheduleId: "",
      courseType: "",
      shuffle: false,
      scheduleChapterId: "",
      createTestModalState: false,
      CourseName: "",
      SelectCourse: "",
      Fees: "",
      Duration: "",
      startDate: "",
      endDate: "",
      meetingContent: [],
      LessonNo: null,
      Lessonname: null,
      TopicContnet: null,
      CourseDescription: "",
      ChapterName: null,
      moduleDescription: "",
      moduleThumbNail: "",
      moduleImage: [],
      facultyUserId: "",
      facultyFullName: "",
      DeliveryType: null,
      ChapterValue: null,
      c_id: null,
      testId: "",
      chapterId: "",
      testName: "",
      description: "",
      duration: null,
      completeTestType: "",
      testType: "",
      editCourseId: null,
      editCourse: false,
      Lesson_file: [],
      Videourl: [],
      Fileurl: [],
      meetingUrl: "",
      scheduleDate: "",
      startTime: "",
      endTime: "",
      course_file: [],
      coursePreFile: [],
      facultyId: "",
      facultyName: "",
      flyerInfoFile: [],
      flyerPreFile: [],
      flyerImgUrl: [],
      flyerImages: [],
      flyDelUrl: [],
      delFlyUrl: "",
      delFlyInd: "",
      showFlyModal: false,
      delUrlMedia: [],
      formErrors: {
        batch: "",
        meetingUrl: "",
        scheduleDate: "",
        startTime: "",
        endTime: "",
        CourseName: "",
        SelectCourse: "",
        Fees: "",
        Duration: "",
        CourseDescription: "",
        facultyUserId: "",
        facultyFullName: "",
        course_file: "",
        facultyId: "",
        courseType: "",
        facultyName: "",
        ChapterName: "",
        LessonNo: "",
        Lessonname: "",
        DeliveryType: "",
        TopicContnet: "",
        Videourl: "",
        filesUrl: "",
        mainFaculties: "",
        flyerImgUrl: "",
        flyerInfoFile: ""
      },
      testFormErrors: {
        chapterId: "",
        testName: "",
        description: "",
        duration: "",
        testType: "",
        completeTestType: "",
      },
      Chapter: [],
      addModalShow: false,
      addtextbox: false,
      SelectCategory: [],
      SelectChapter: [],
      preDeliveryType: [
        { text: "Manuscript", value: "Manuscript" },
        { text: "Video Lectures", value: "Video Lectures" },
      ],
      instructionDeliveryType: [{ text: "Meeting Url", value: "Meeting Url" }],
      typeOfCourse: [
        { value: "Instruction based", text: "Instruction based" },
        { value: "Prerecorded", text: "Prerecorded" },
      ],
      SelectFaculty: [],
      lessonFaculty: [],
      columns: [
        // { title: "Id", field: "id" , hidden: true },
        // { title: "L.No", field: "lessonNo"},
        {
          title: "Id",
          render: (rowData) => <span>{rowData.tableData.id + 1}</span>,
        },
        { title: "Module Name", field: "chapterNm" },
        { title: "Lesson Name", field: "lessonName" },
        {
          title: "Video",
          field: "videoUrl",
          render: (rowData) =>
            rowData.videoUrl.length > 0 ? (
              <CheckIcon status={rowData.videoUrl} />
            ) : (
              <ClearIcon status={rowData.videoUrl} />
            ),
        },
        {
          title: "File",
          field: "filesUrl",
          render: (rowData) =>
            rowData.filesUrl.length > 0 ? (
              <CheckIcon status={rowData.filesUrl} />
            ) : (
              <ClearIcon status={rowData.filesUrl} />
            ),
        },
        // {
        //   title: "Active/Inactive",
        //   field: "id",
        //   hidden: role === "faculty" ? false : true,
        //   render: (rowData) =>
        //     rowData.facultyName !== userName ? null : (
        //       <ToggleSwitch
        //         status={rowData.active}
        //         id={rowData.id}
        //         chapterId={rowData.scheduleChapterId}
        //         toggleHandler={this.toggleHandler}
        //       />
        //     ),
        // },
      ],
      scheduleColumn: [
        {title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span>},
        { title: "Module Name", field: "chapterNm"},
        { title: "Lesson Name", field: "lessonName"},
        // { title: "Topic Name", field: "topicContent"},
        { title: "Meeting Url", field: "meetingUrl" , render: rowData => rowData.meetingUrl !==undefined? <CheckIcon status={rowData.meetingUrl} />:<ClearIcon status={rowData.meetingUrl} />}, 
        //{ title: "File", render: rowData => rowData.meetingContent.length > 0 ? <CheckIcon status={rowData.meetingContent[0]}/>:<ClearIcon status={rowData.meetingContent[0]} />},  
        // { title: "File", render: rowData => rowData.filesUrl!=="" || rowData.videoUrl !=="" ? <CheckIcon />:<ClearIcon />},
        // {
        //   title: "Video",
        //   field: "videoUrl",
        //   render: (rowData) =>
        //     rowData.videoUrl.length > 0 ? (
        //       <CheckIcon status={rowData.videoUrl} />
        //     ) : (
        //       <ClearIcon status={rowData.videoUrl} />
        //     ),
        // },
        // {
        //   title: "File",
        //   field: "filesUrl",
        //   render: (rowData) =>
        //     rowData.filesUrl.length > 0 ? (
        //       <CheckIcon status={rowData.filesUrl} />
        //     ) : (
        //       <ClearIcon status={rowData.filesUrl} />
        //     ),
        // },  
        {title: "Schedule Date", field: "schedule[0].scheduleDate", render: rowData =>  rowData.schedule.length > 0 ?  moment(new Date(rowData.schedule[0].scheduleDate)).format('YYYY-MM-DD'):null},
        {title: "Time allocated", field: "schedule[0].scheduleTime[0]", render: rowData => rowData.schedule.length > 0 ? rowData.schedule[0].scheduleTime[0].slice(0, -8)+" to "+rowData.schedule[0].scheduleTime[0].slice(8):null},
        // { title: "Schedule Lesson", render: rowData => moment(new Date(rowData.schedule[0].scheduleDate)).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD') || rowData.schedule[0].scheduleTime[0].slice(0, -8) >= new Date().getHours() + ':' + new Date().getMinutes()  ?null:<ScheduleIcon  className="ml-4" onClick={() => this.onEdit(rowData)}/> }
        { title: "Schedule Lesson", render: rowData => <ScheduleIcon  className="ml-4" onClick={() => this.onEdit(rowData)}/> }
      ]
      ,
      Data: [],
      chapterList: [],
      showHide: false,
      showDelModal: false,
      rowData: [],
      closeDelModal: false,
      title: "",
      SelectFaculty: [],
      selectedOption : [ ],
      mainFaculties: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRadioButton = this.handleRadioButton.bind(this);
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.deleteFlyer = this.deleteFlyer.bind(this);
  }

  toggleHandler = (toggle, lessonId, chapterId) => {
    //debugger
    let courseId = this.state.c_id;
    axiosApiInstance
      .put(`${URL}/updateLesson/${courseId}`, {
        chapterId: chapterId,
        lessonId: lessonId,
        active: toggle,
      })
      .then((res) => {
        // console.log("Updated form", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  lessonUpdateformvalid = (formErrors) => {
    let valid = true;
    // debugger
    let {
      DeliveryType,
      meetingUrl,
      scheduleDate,
      startTime,
      endTime,
    } = this.state;

    meetingUrl === "" || meetingUrl === undefined
      ? (formErrors.meetingUrl = "Please enter meeting url!")
      : (formErrors.meetingUrl = "");
    scheduleDate !== ""
      ? (formErrors.scheduleDate = "")
      : (formErrors.scheduleDate = "Please select meeting date !");
    startTime !== ""
      ? (formErrors.startTime = "")
      : (formErrors.startTime = "Please select meeting start time!");
    endTime !== ""
      ? (formErrors.endTime = "")
      : (formErrors.endTime = "Please select meeting end time!");
    endTime !== startTime
      ? (formErrors.endTime = "")
      : (formErrors.endTime = "End time must be greater than start time!");
    endTime > startTime
      ? (formErrors.endTime = "")
      : (formErrors.endTime = " End time must be greater than start time!");
    if (this.state.scheduleId !== "") {
      DeliveryType === null || DeliveryType !== "Meeting Url"
        ? (formErrors.DeliveryType = "Please select lesson delivery type!")
        : (formErrors.DeliveryType = "");
    }
    //course_file.length > 0?formErrors.course_file = "":formErrors.course_file = "Please upload course image!";

    this.setState({ formErrors: formErrors });
    Object.values(formErrors).forEach((val) => {
      //debugger
      val !== "" && (valid = false);
    });
    return valid;
  };

  formvalid = (formErrors) => {
    //debugger
    let valid = true;
    let {
      CourseName,
      SelectCourse,
      batch,
      Fees,
      Duration,
      courseType,
      CourseDescription,
      facultyUserId,
      course_file,
      coursePreFile,
      selectedOption,
      mainFaculties,
      flyerImgUrl,
      flyerImages,
      flyerInfoFile,
      flyerPreFile
    } = this.state;
    //let phone = mobile.toString();
    //debugger
    //alert( phone.length);
    CourseName !== ""
      ? (formErrors.CourseName = "")
      : (formErrors.CourseName = "Please enter course name!");
    SelectCourse !== ""
      ? (formErrors.SelectCourse = "")
      : (formErrors.SelectCourse = "Please select course category!");
    // Fees === ''?formErrors.Fees ="Please enter fees amount!":formErrors.Fees = "";
    Duration !== ""
      ? (formErrors.Duration = "")
      : (formErrors.Duration = "Please enter course duration!");
    CourseDescription !== ""
      ? (formErrors.CourseDescription = "")
      : (formErrors.CourseDescription = "Please enter course description!");
    // facultyUserId !== ""
    //   ? (formErrors.facultyUserId = "")
    //   : (formErrors.facultyUserId = "Please select course main faculty!");
    flyerImgUrl.length || flyerImages.length > 0
      ? (formErrors.flyerImgUrl = "")
      : (formErrors.flyerImgUrl = "Please insert flyer image");
    // flyerInfoFile.length || flyerPreFile.length > 0
    //   ? (formErrors.flyerInfoFile = "")
    //   : (formErrors.flyerInfoFile = "Please insert pdf file");
    mainFaculties.length || selectedOption.length > 0
      ? (formErrors.mainFaculties = "")
      : (formErrors.mainFaculties = "Please select course faculties!");
    // if(this.state.editCourse === false){
    course_file.length || coursePreFile.length > 0
      ? (formErrors.course_file = "")
      : (formErrors.course_file = "Please upload course image!");
    // }
    courseType !== ""
      ? (formErrors.courseType = "")
      : (formErrors.courseType = "Please select type course!");
    if (courseType === "Instruction based") {
      batch !== ""
        ? (formErrors.batch = "")
        : (formErrors.batch = "Please enter batch size for schedule!");
    }

    this.setState({ formErrors: formErrors });
    Object.values(formErrors).forEach((val) => {
      // debugger
      val !== "" && (valid = false);
    });
    return valid;
  };

  chapterValid = (formErrors) =>{
    let valid = true;
    let ChapterName = this.state.ChapterName;
    ChapterName !== null && ChapterName !== ""
      ?(formErrors.ChapterName = "")
      :(formErrors.ChapterName = "Chapter Name is mandatory");
    
    this.setState({ formErrors: formErrors });
    Object.values(formErrors).forEach((val) => {
      //debugger
      val !== "" && (valid = false);
    });
    return valid;
  }

  lessonValid = (formErrors) => {
    let valid = true;
    let {
      LessonNo,
      Lessonname,
      facultyId,
      Fileurl,
      DeliveryType,
      Lesson_file,
      Videourl,
      filesUrl,
    } = this.state;
    // debugger
    LessonNo !== null
      ? (formErrors.LessonNo = "")
      : (formErrors.LessonNo = "Please enter lesson no.!");
    facultyId !== ""
      ? (formErrors.facultyId = "")
      : (formErrors.facultyId = "Please select lesson faculty!");
    Lessonname !== null
      ? (formErrors.Lessonname = "")
      : (formErrors.Lessonname = "Please enter lesson name!");
    DeliveryType !== null
      ? (formErrors.DeliveryType = "")
      : (formErrors.DeliveryType = "Please select lesson delivery type!");
    if (this.state.scheduleId !== "") {
      DeliveryType !== null
        ? (formErrors.DeliveryType = "")
        : (formErrors.DeliveryType = "Please select lesson delivery type!");
    }
    if (DeliveryType === "Manuscript") {
      Lesson_file.length > 0 || Fileurl.length > 0
        ? (formErrors.filesUrl = "")
        : (formErrors.filesUrl = "Please upload file!");
    }
    if (DeliveryType === "Video Lectures") {
      Videourl.length > 0
        ? (formErrors.Videourl = "")
        : (formErrors.Videourl = "Please enter url link!");
    }
    //course_file.length > 0?formErrors.course_file = "":formErrors.course_file = "Please upload course image!";

    this.setState({ formErrors: formErrors });
    Object.values(formErrors).forEach((val) => {
      //debugger
      val !== "" && (valid = false);
    });
    return valid;
  };

  testformvalid = (testFormErrors) => {
    //debugger
    let valid = true;
    let { courseId, testName, description, completeTestType } = this.state;
    //courseId !== null?formErrors.courseId ="":formErrors.courseId = "Please select course!";
    testName !== ""
      ? (testFormErrors.testName = "")
      : (testFormErrors.testName = "Please enter test name.!");
    description !== ""
      ? (testFormErrors.description = "")
      : (testFormErrors.description = "Please enter test description!");
    //duration !== null?formErrors.duration ="":formErrors.duration = "Please enter duration of test!";
    //testType !== ""?testFormErrors.testType ="":testFormErrors.testType = "Please select test type!";
    completeTestType !== ""
      ? (testFormErrors.completeTestType = "")
      : (testFormErrors.completeTestType = "Please choose time limit option !");

    this.setState({ testFormErrors: testFormErrors });
    Object.values(testFormErrors).forEach((val) => {
      //debugger
      val !== "" && (valid = false);
    });
    return valid;
  };

  handleSubmit = (e) => {
   // debugger
    e.preventDefault();
    let {
      CourseName,
      SelectCourse,
      courseType,
      Fees,
      Duration,
      CourseDescription,
      // facultyUserId,
      // facultyFullName,
      flyerImgUrl,
      flyerInfoFile,
      mainFaculties,
      course_file,
      batch,
    } = this.state;

    let courseName = CourseName;
    let categoryId = SelectCourse;
    let fees = Fees;
    let duration = Duration;
    let description = CourseDescription;
    let flyerUrls = [];
    let mainFaculty = mainFaculties;
    let file_Url = course_file;
    let batchSize = batch;
    const isValid = this.formvalid(this.state.formErrors);
    if (isValid) {
      // debugger
      this.setState({ loading: true });
      
      const formDataimage = new FormData();
      file_Url.forEach((item) => {
        formDataimage.append("photos", item);
      });
      formDataimage.append("name", courseName);
      //debugger
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`, formDataimage)
        .then((res) => {
        //  debugger
          const otherImage = [];
          if (res.status == 200) {
            let ulrdatas = res.data.data;
            const defaultimage = res.data.data[0].url;
            ulrdatas.map((ulrdata) => {
              if (defaultimage !== ulrdata.url) {
                otherImage.push(ulrdata.url);
              }
            });
            var imageOrVideoPath = {
              default: defaultimage,
              otherImages: otherImage,
            };
          }
          const formData = new FormData();
      flyerImgUrl.forEach((item) => {
        //single
          // formData.append('photos',item.file);
        //multi
          formData.append('photos',item);

      })
      formData.append('name',courseName);
    
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`,formData)
        .then(async(response) =>{     
          // debugger             
          var imgUrl = [];
          if(response.data.message === "Files are uploaded"){
            response.data.data &&
            response.data.data.map((f) => {
              imgUrl.push({ url: f.url });
            });
          } 
    const formFile = new FormData();
    flyerInfoFile.forEach((item) => {
      //single
        // formFile.append("photos", item.file);
      //multy
        formFile.append("photos", item);

      });
      formFile.append("name", courseName);
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`,formFile)
      .then((res) => {
        // debugger;
        var fileUrl = [];
        if (res.status == 200) {
            res.data.data &&
            res.data.data.map((f) => {
              fileUrl.push({ url: f.url });
            });
            flyerUrls=[...imgUrl,...fileUrl];
        }
         let data = {courseName,courseType,categoryId,fees,duration,description,flyerUrls,mainFaculty,batchSize,imageOrVideoPath};
          // console.log("data",data);
          // debugger
          axiosApiInstance.post(`${URL}/course`,{courseName,courseType,categoryId,fees,duration,description,flyerUrls,mainFaculty,batchSize,imageOrVideoPath})
            .then((response) => {
              //  debugger
                if(response.data.status === 400){
                this.setState({ loading: false });
                swal(response.data.message, "", "info");
                }
                else if(response.data.status === 500){
                  //debugger
                  this.setState({ loading: false });
                  swal("", "Please try again!", "error");
                }
                else{
                 // debugger
                  let course_data = response.data.course;
                  //console.log("course_data",course_data);
                  this.setState({ c_id: course_data._id, editCourse: true });
                  this.setState({ loading: false });
                  swal("Course created successfully!","Please add module and lesson details","info");
                }
                // else{
                //   swal("Something went wrong", "Please try again!", "error");
                // }
            })
            .catch((err) => {
              //debugger
              console.log("err",err);
              swal("Something went wrong", "Please try again!", "error");
              this.setState({ loading: false });
            });
        })
        .catch((err) => {
          swal("Something went wrong", "Please try again!", "error");
          this.setState({ loading: false });
        });
      })
      .catch((err) => {
        swal("Something went wrong", "Please try again!", "error");
        this.setState({ loading: false });
      });
    })
    .catch((err) => {
      swal("Something went wrong", "Please try again!", "error");
      this.setState({ loading: false });
    });
    }
  };

  handleUpdateSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    let {editCourseId,
      c_id,
      batch,
      CourseName,
      SelectCourse,
      courseType,
      Fees,
      Duration,
      CourseDescription,
      flyerImgUrl,
      flyerImages,
      flyerInfoFile,
      flyerPreFile,
      course_file,
      coursePreFile,
      // facultyUserId,
      // facultyFullName,
      delUrlMedia,
      mainFaculties
    } = this.state;
    // alert(courseType);
    var Id = "";
    if (editCourseId === null) {
      Id = c_id;
    } else {
      Id = editCourseId;
    }
    let main = [];
    this.state.selectedOption&&this.state.selectedOption.map(data => {
      main.push({
        facultyUserId: data.value,
        facultyFullName:  data.label
      })
    })
    let courseName = CourseName;
    let categoryId = SelectCourse;
    let fees = Fees;
    let duration = Duration;
    let description = CourseDescription;
    let mainFaculty = main;
    let batchSize = batch;
    let file_Url = course_file;
    let flyerUrls = [];

    // debugger
    const isValid = this.formvalid(this.state.formErrors);
    if (isValid) {

    if(flyerImages[0] != undefined){
      if(flyerImgUrl.length > 0){
        this.mediaDelete(flyerImages);
      }
    }

    if(flyerPreFile[0] != undefined){
      if(flyerInfoFile.length > 0){
        this.mediaDelete(flyerPreFile);
      }
    }

    if(coursePreFile[0] != undefined){
      if(course_file.length > 0){
        this.mediaDelete(coursePreFile);
      }
    }

    if(delUrlMedia.length > 0){
      this.mediaDelete(delUrlMedia);
    }

      const formDataimage = new FormData();
      file_Url.forEach((item) => {
        formDataimage.append("photos", item);
      });
      formDataimage.append("name", courseName);
      // debugger
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`, formDataimage)
        .then((res) => {
        //  debugger
          const otherImage = [];
          if (res.status == 200) {
            let ulrdatas = res.data.data;
            
            if(res.data.status == 409 && coursePreFile.length > 0){
              var imageOrVideoPath = {
                default: coursePreFile[0].url,
                otherImages: [],
              };
            }
            else{
              const defaultimage = res.data.data[0].url;
              ulrdatas.map((ulrdata) => {
                if (defaultimage !== ulrdata.url) {
                  otherImage.push(ulrdata.url);
                }
              });
              var imageOrVideoPath = {
                default: defaultimage,
                otherImages: otherImage,
              };
            }
          }

    const formDataimage = new FormData();
    flyerImgUrl.forEach((item) => {
      //single
        // formDataimage.append("photos", item.file);
      //multy
        formDataimage.append("photos", item);

      });
      formDataimage.append("name", courseName);
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`,formDataimage)
      .then((res) => {
        // debugger;
        var imgUrl = [];
        if (res.status == 200) {
          // var image = [];
          // res.data.data &&
          //   res.data.data.map((img) => {
          //     image.push({ url: img.url });
          //   });
          // var presentImg = [];
          // flyerImages &&
          //   flyerImages.map((data) => {
          //     presentImg.push({ url: data.url });
          //   });
          // var flyerUrls = [...image, ...presentImg];
            res.data.data &&
            res.data.data.map((f) => {
              imgUrl.push({ url: f.url });
            });
          flyerUrls=[...imgUrl];
          if(res.data.status == 409 && flyerImages.length > 0){
            flyerUrls=[...flyerImages];
          }
        }
    const formFile = new FormData();
    flyerInfoFile.forEach((item) => {
      //single
        // formFile.append("photos", item.file);
      //multy
        formFile.append("photos", item);

      });
      formFile.append("name", courseName);
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`,formFile)
      .then((res) => {
        // debugger;
        var fileUrl = [];
        if (res.status == 200) {
            res.data.data &&
            res.data.data.map((f) => {
              fileUrl.push({ url: f.url });
            });
            if(imgUrl.length > 0){
              flyerUrls=[...imgUrl,...fileUrl];
            }
            else if(flyerImages.length > 0){
              flyerUrls=[...flyerImages,...fileUrl];
            }
          if(res.data.status == 409 && flyerPreFile.length > 0){
            if(flyerPreFile[0] != null){
            if(imgUrl.length > 0){
              flyerUrls=[...imgUrl,...flyerPreFile];
            }
            else if(flyerImages.length > 0){
              flyerUrls=[...flyerImages,...flyerPreFile];
            }
            }
          }
        }
        axiosApiInstance
          .put(`${URL}/course/${Id}`, {
            courseName,
            courseType,
            categoryId,
            fees,
            duration,
            description,
            flyerUrls,
            mainFaculty,
            batchSize,
            imageOrVideoPath,
          })
          .then((res) => {
            if (res.data.status == 500) {
              this.setState({ loading: false });
              swal("Something went wrong", "Please try again!", "error");
            } else {
              this.setState({ loading: false });
              swal("Course updated successfully", "", "success");
              this.props.history.push(`/CourseList`);
            }
          }).catch((err) => {
            this.setState({ loading: false });
            swal("Something went wrong", "Please try again!", "error");
          });
        }).catch((err) => {
          this.setState({ loading: false });
          swal("Something went wrong", "Please try again!", "error");
        });
      }).catch((err) => {
        this.setState({ loading: false });
      swal("Something went wrong", "Please try again!", "error");
    });
  }).catch((err) => {
    this.setState({ loading: false });
    swal("Something went wrong", "Please try again!", "error");
  });
  }
  };

  handleChange = (e) => {
    //debugger
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = this.state.formErrors;
    let testFormErrors = this.state.testFormErrors;
    // alert(parseInt(value))
    if (this.state.c_id != null || this.state.editCourse == true) {
      switch (name) {
        case "LessonNo":
          formErrors.LessonNo = value === "" ? "Please enter lesson no.!" : "";
          break;
        case "Lessonname":
          formErrors.Lessonname =
            value === "" ? "Please enter lesson name!" : "";
          break;
        // case 'TopicContnet':
        //   formErrors.TopicContnet = value === ""?"Please enter topic content!":"";
        // break
        case "testName":
          testFormErrors.testName =
            value === "" ? "Please enter test name.!" : "";
          break;
        case "description":
          testFormErrors.description =
            value === "" ? "Please enter test description!" : "";
          break;
        case "testType":
          testFormErrors.testType =
            value === "" ? "Please select test type!" : "";
          break;
      }
      this.setState({ formErrors: formErrors, testFormErrors: testFormErrors });
    } else {
      switch (name) {
        case "CourseName":
          formErrors.CourseName =
            value === "" ? "Please enter course name!" : "";
          break;
        case "Fees":
          //formErrors.NewPassword = value === ""?"Please enter new password.": !value.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)? "Please enter secure and strong password":"";
          formErrors.Fees = !value.match(/^[0-9]+$/)
            ? "Please enter valid fees amount!"
            : "";
          break;
        case "SelectCourse":
          formErrors.SelectCourse =
            value === "" ? "Please select course category!" : "";
          break;
        // case "Duration":
        //   formErrors.Duration =
        //     value === ""
        //       ? "Please enter course duration!"
        //       : !value.match(/^[0-9]+$/)
        //       ? "Please enter valid course duration"
        //       : "";
        //   break;
        case "meetingUrl":
          formErrors.meetingUrl = validator.isURL(value)
            ? ""
            : "Is not valid url!";
          break;
        // case 'endTime' :
        //   formErrors.endTime =
      }
    }
  };

  handleDropdownChange = (e) => {
    //debugger
    const selectedIndex = e.target.options.selectedIndex;
    let textValue = e.target.options[selectedIndex].getAttribute("data-key");
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ facultyFullName: textValue });
    let formErrors = this.state.formErrors;
    // switch (name) {
    //   case "facultyUserId":
    //     formErrors.facultyUserId =
    //       value === "" ? "Please select course main faculty!" : "";
    //     break;
    // }
    this.setState({ formErrors: formErrors });
  };

  clearCourseHandle = () => {
    //debugger
    this.setState({
      CourseName: "",
      SelectCourse: "",
      Fees: "",
      Duration: "",
      CourseDescription: "",
      facultyUserId: "",
      facultyFullName: "",
      courseType: "",
      batch: "",
      selectedOption : [ ],
      mainFaculties: [],
      course_file: [],
      coursePreFile: [],
      SelectChapter: [],
      flyerImages: [],
      flyerImgUrl: [],
      flyerInfoFile: [],
      flyerPreFile: [],
      delUrlMedia: [],
      Data: [],
      scheduleId: "",
      formErrors: {
        batch: "",
        meetingUrl: "",
        scheduleDate: "",
        startTime: "",
        endTime: "",
        CourseName: "",
        SelectCourse: "",
        Fees: "",
        Duration: "",
        CourseDescription: "",
        facultyUserId: "",
        facultyFullName: "",
        course_file: "",
        facultyId: "",
        courseType: "",
        facultyName: "",
        LessonNo: "",
        Lessonname: "",
        DeliveryType: "",
        TopicContnet: "",
        Videourl: "",
        filesUrl: "",
        mainFaculties: "",
        flyerImgUrl: "",
        flyerInfoFile: ""
      },
    });
  };
 
  selectedChapter = (e) => {
    //debugger;
    const selectedIndex = e.target.options.selectedIndex;
    let chapterName = e.target.options[selectedIndex].getAttribute(
      "chapterName"
    );
    let desc = e.target.options[selectedIndex].getAttribute("desc");
    let thum = e.target.options[selectedIndex].getAttribute("thum");
    const { name, value } = e.target;
    this.setState({ ChapterValue: chapterName });
    if (value === "Select Module") {
      this.setState({
        title: "Add",
        chapterId: "",
        ChapterName: null,
        moduleDescription: "",
        moduleThumbNail: "",
      });
    } else {
      this.setState({
        title: "Update",
        chapterId: value,
        ChapterName: chapterName,
        moduleDescription: desc,
        moduleThumbNail: thum,
      });
    }
  };

  selectedTestChapter = (value, name) => {
    //debugger
    this.setState({ chapterId: value });
  };

  selectedForLessonFaculty = (e) => {
    //debugger
    const selectedIndex = e.target.options.selectedIndex;
    let textValue = e.target.options[selectedIndex].getAttribute("data-key");
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ facultyName: textValue });
    // this.setState({facultyId:value,facultyName:name})
  };

  selectedDeliveryType = (value) => {
    this.setState({ DeliveryType: value });
  };

  getCategory = () => {
    //debugger
    axiosApiInstance
      .get(`${URL}/course/category`)
      .then((res) => {
        // debugger
        let Categories = res.data.subCategories;
        let Categorydata = [];
        if (res.status == 200) {
          Categories &&
            Categories.map((data) => {
              Categorydata.push({
                id: data._id,
                categoryName: data.categoryName,
              });
            });
          this.setState({ SelectCategory: Categorydata });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFaculty = () => {
    axiosApiInstance
      .get(`${URL}/getFacultyList`)
      .then((res) => {
        //debugger
        let faculties = res.data.allFacultyList;
        let facultyData = [];
        let lessonFacultyData = [];
        if (res.status == 200) {
          // faculties &&
          //   faculties.map((data) => {
          //     facultyData.push({
          //       facultyUserId: data.userId,
          //       facultyFullName:
          //         data.firstName + " " + data.middleName + " " + data.lastName,
          //     });
          //   });
          facultyData.push({
            value: null,
            label: "To be selected",
          });
          faculties &&
            faculties.map((data) => {
              facultyData.push({
                value: data.userId,
                label: data.firstName + " " + data.middleName + " " + data.lastName,
              });
            });
          faculties &&
            faculties.map((data) => {
              lessonFacultyData.push({
                text: data.userId,
                value:
                  data.firstName + " " + data.middleName + " " + data.lastName,
              });
            });
          this.setState({
            SelectFaculty: facultyData,
            lessonFaculty: lessonFacultyData,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCourse = (Id, schedule) => {
    //debugger
    var url = "";
    if (schedule !== undefined) {
      url = `${URL}/courseSchedule/${Id}`;
    } else {
      url = `${URL}/course/${Id}`;
    }
    axiosApiInstance
      .get(url)
      .then((res) => {
        // debugger
        if (res.data.status === 500) {
          // console.log(res, "-------");
        } else {
          let course = res.data.Course;
          // console.log(course);
          this.setState({
            SelectCourse: course.categoryId,
            CourseName: course.courseName,
            Fees: course.fees,
            Duration: course.duration,
            CourseDescription: course.description,
            // flyerImages: course.flyerUrls,
            courseType: course.courseType,
            batch: course.batchSize,
          });
          // debugger;
          let image = [];
          let file = [];
          let flyer = course.flyerUrls;
          if(flyer!=undefined){image.push(flyer[0]);
          file.push(flyer[1]);}
          this.setState({flyerPreFile: file, flyerImages:image});
          // flyer && flyer.map(data=>{
          //   let url = data.url;
          //   // console.log(url.split('.').pop());
          //   if(url.split('.').pop() === "pdf"){
          //     file.push(data);
          //     this.setState({flyerPreFile: file});
          //   }
          //   else{
          //     image.push(data);
          //     this.setState({flyerImages: image});
          //   }
          // })
          let courseImg = [];
          courseImg.push({url: course.imageOrVideoPath.default});
          this.setState({coursePreFile: courseImg});
          if (schedule !== undefined) {
            this.setState({
              startDate: course.startDate,
              endDate: course.endDate,
            });
          }
          if (course.mainFaculty !== "" || course.mainFaculty !== null) {
            // this.setState({
            //   facultyUserId: course.mainFaculty.facultyUserId,
            //   facultyFullName: course.mainFaculty.facultyFullName,
            // });
            let faculties = [];
            course.mainFaculty && course.mainFaculty.map(data => {
              faculties.push({
                label: data.facultyFullName,
                value: data.facultyUserId
              })
            })
            this.setState({ selectedOption: faculties });
            // console.log(faculties);
          }
          let lesson = [];
          let chapterList = [];
          // console.log("course.chapter", course.chapter);
          // debugger;
          course.chapter &&
            course.chapter.map((chapter) => {
              chapterList.push({
                id: chapter._id,
                chapterName: chapter.chapterName,
              });
              chapter.lessons &&
                chapter.lessons.map((item) => {
                  lesson.push({
                    scheduleChapterId: chapter._id,
                    chapterNm: chapter.chapterName,
                    id: item._id,
                    active: item.active,
                    deliveryType: item.deliveryType,
                    lessonNo: item.lessonNo,
                    lessonName: item.lessonName,
                    topicContent: item.topicContent,
                    filesUrl: item.filesUrl,
                    videoUrl: item.videoUrl,
                    facultyId: item.facultyUserId,
                    facultyName: item.facultyFullName,
                    schedule: item.schedule,
                    meetingUrl: item.meetingUrl,
                    meetingContent: item.meetingContent,
                  });
                });
            });
          // console.log("course", lesson);
          this.setState({
            Data: lesson,
            SelectChapter: course.chapter,
            chapterList: chapterList,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mediaDelete=(urlArray)=>{
    urlArray &&
      urlArray.map((items) => {
        let url = items.url;
        let name = ``;
        var getLocation = function (href) {
          var l = document.createElement("a");
          l.href = href;
          return l;
        };
        var host = getLocation(url);
        if (host.hostname === "lmsmedia.softlabsgroup.in") {
          name = `${url.slice(38)}`;
        } else {
          name = `${url.slice(26)}`;
        }
        let data = { name: name };
        axiosApiInstance
          .post(`${mediaUrl}/delete-file`, data)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
  }

  deleteFlyer = () =>{
    let {delFlyUrl, delFlyIn, delUrlMedia} = this.state;
    // debugger
    delUrlMedia.push({url:delFlyUrl});
    var newArray = [];
    this.state.flyerPreFile &&
      this.state.flyerPreFile.map((data) => {
      newArray.push({ url: data.url });
      });
    newArray &&
      newArray.map((data) => {
        if (data.url === delFlyUrl) {
        var indexOfImage = newArray.findIndex(
          (a) => a.url === data.url
        );
      newArray.splice(indexOfImage, 1);
      }
    });
    this.setState({ flyerPreFile: newArray });
  }

  getFlyersImg=(flyers)=>{
    let { flyerImages ,flyerPreFile } = this.state;
    var flyerImgArr =
      flyers &&
      flyers.map((data, i) => {
        return (
          data != undefined
          ?(<Col xs="2" key={i}>
            {/* {flyers === flyerPreFile
              ?(<IconButton>
              <CancelOutlinedIcon button onClick={() => this.setState({delFlyUrl: data.url, delFlyInd: i, showFlyModal: true})}/>
              </IconButton>)
              :null} */}
                <img className="w-100 h-100"
                  src={data.url}
                  // onClick={() =>
                  //   this.setState({ show: true, rowData: data.url })
                  // }
                  id="image"
                  style={{ width: "100%", height: "100%" }}
                />

          </Col>)
          :null
          );
      });
    return flyerImgArr;
  }

  componentDidUpdate(prevProps, prevState) {
    //debugger
    var Id = this.props.location.search.slice(12);
    if (prevProps.location.search.slice(12) !== Id) {
      this.onClickCancelLesson();
      this.clearCourseHandle();
      this.createTestModalHide();
      this.setState({ editCourse: false, c_id: null, editCourseId: null });
    }
  }

  async componentDidMount() {
    var Id = this.props.location.search.slice(12);
    var withoutChars = this.props.location.search.slice(1, -25);
    if (this.props.location.search === "") {
      this.setState({ editCourse: false, c_id: null, editCourseId: null });
      // this.clearCourseHandle();
    } else {
      if (withoutChars === "scheduleId") {
        this.setState({ scheduleId: Id });
        this.getCourse(Id, withoutChars);
      } else {
        let Id = this.props.location.search.slice(4);
        await this.setState({ editCourseId: Id });
        if (Id !== null && Id !== "") {
          this.setState({ editCourse: true, c_id: Id });
          this.getCourse(Id);
        } else {
          this.clearCourseHandle();
        }
        this.setState({ title: "Add" });
      }
    }
    if(this.state.addModalShow === false || this.state.addtextbox === false){
      this.clearModal();
    }
    this.getCategory();
    this.getFaculty();
  }

  onClickAddChapter = () => {
    let { ChapterName, moduleDescription, c_id } = this.state;
    let imageUrl = this.state.moduleImage;
    const isValid = this.chapterValid(this.state.formErrors);
    if (isValid) {
    const formData = new FormData();
    imageUrl.forEach((item) => {
      formData.append("photos", item);
    });
    formData.append("name", ChapterName);
    MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`, formData)
      .then(async (res) => {
        // debugger
        if(res.status === 200){
        if (res.data.message === "Files are uploaded") {
          var fileUrl = res.data.data[0];
        }
          let urlImg = null;
          if(res.data.status === 409){
            urlImg = null;

          }else{
            urlImg = fileUrl.url;

          }
          await this.setState({ moduleThumbNail: urlImg });
          let data = {
            chapterName: ChapterName,
            chapterDescription: moduleDescription,
            thumbnailImgUrl: urlImg,
          };
          axiosApiInstance.put(`${URL}/addLesson/${c_id}`, { chapter: [data] })
            .then(async (res) => {
              // debugger
              if (res.data.status == 500) {
                swal("Something went wrong", "Please try again!", "error");
              } else {
                //debugger
                // swal("Chapter added successfully", "", 'success');
                this.onClickCancel();
                // await this.setState({addtextbox:false,LessonNo:null,Lessonname:null,TopicContnet:null,facultyId:'',DeliveryType:null,Videourl:'',Fileurl:'',Lesson_file : [],Chapter:[]});
                let chapters = res.data.course.chapter;
                let lesson = [];
                let chapterList = [];
                chapters &&
                  chapters.map((chapter) => {
                    chapterList.push({
                      id: chapter._id,
                      chapterName: chapter.chapterName,
                    });
                    chapter.lessons &&
                      chapter.lessons.map((item) => {
                        lesson.push({
                          scheduleChapterId: chapter._id,
                          chapterNm: chapter.chapterName,
                          id: item._id,
                          active: item.active,
                          deliveryType: item.deliveryType,
                          lessonNo: item.lessonNo,
                          lessonName: item.lessonName,
                          topicContent: item.topicContent,
                          filesUrl: item.filesUrl,
                          videoUrl: item.videoUrl,
                          facultyId: item.facultyUserId,
                          facultyName: item.facultyFullName,
                        });
                      });
                  });
                this.setState({
                  Data: lesson,
                  chapterList: chapterList,
                  SelectChapter: chapters,
                });
              }
            })
            .catch((err) => {
              //this.setState({loading:false});
              // swal("Something went wrong", "Please try again!", 'error');
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ ChapterName: null, addtextbox: false });
    }
  };

  onClickUpdateChapter = () => {
   // debugger;
    let { ChapterName } = this.state;
    let imageUrl = this.state.moduleImage;
    const isValid = this.chapterValid(this.state.formErrors);
    if (isValid) {
      if (imageUrl.length > 0) {
        const formData = new FormData();
        imageUrl.forEach((item) => {
          formData.append("photos", item);
        });
        formData.append("name", ChapterName);
        MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`, formData)
          .then(async (res) => {
            if(res.status === 200){
              if (res.data.message === "Files are uploaded") {
                var fileUrl = res.data.data[0];
              }
              let urlImg = null;
              if(res.data.status === 409){
                urlImg = null;
        
              }else{
                urlImg = fileUrl.url;
        
              }
              await this.setState({ moduleThumbNail: urlImg });
              this.updateModule();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.updateModule();
      }
    }
  };

  updateModule = () => {
    //debugger;
    var {
      ChapterName,
      chapterId,
      moduleDescription,
      moduleThumbNail,
      c_id,
    } = this.state;
    let data = {
      chapterId: chapterId,
      chapterName: ChapterName,
      chapterDescription: moduleDescription,
      thumbnailImgUrl: moduleThumbNail,
    };
    axiosApiInstance.put(`${URL}/updateChapter/${c_id}`, data).then((res) => {
      //debugger;
      if (res.status === 200) {
        this.onClickCancel();
        this.getCourse(c_id);
      }
    });
  };

  onClickCancel = () => {
    this.setState({
      ChapterName: null,
      chapterId: "",
      addtextbox: false,
      moduleDescription: "",
      moduleThumbNail: "",
      moduleImage: [],
      ChapterValue: null,
      formErrors: {
        ChapterName: ""
      },
    });
  };

  onclickLesson = () => {
    //debugger
    this.setState({ lessonId: "" });
    if (this.state.SelectChapter.length <= 0) {
      swal("Please add module first!", "", "info");
      // alert("Please add module first!");
    } else if (
      this.state.ChapterValue !== null &&
      this.state.ChapterValue !== "Select Module"
    ) {
      this.setState({ addModalShow: true });
    } else {
      swal("Please select module!", "", "info");
      // alert("Please select module!");
    }
  };

  imageData = (data) =>{
    if (data.length !== this.state.flyerImgUrl.length) {
      this.setState({ flyerImgUrl: data });
    }
  }

  arrayData = (data) => {
    if (data.length !== this.state.Lesson_file.length) {
      this.setState({ Lesson_file: data });
    }
  };

  onClickAddLesson = ()=>{
    //debugger
    this.setState({loading:true});
    let file_Url = this.state.Lesson_file;
    let lessonName = this.state.Lessonname;
    if(file_Url.length > 0){
      const formData = new FormData();
      file_Url.forEach((item) => {
          formData.append('photos',item.file);
      })
      formData.append('name',lessonName);
    
      MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`,formData)
        .then(async(res) =>{     
          //debugger             
          if(res.data.message === "Files are uploaded"){
           // if(res.data.error === false){
            // let fileUrl = res.data.data[0];
            // this.setState({Fileurl:fileUrl.url});
            //console.log(res)
            var fileUrl = [];
            res.data.data &&
            res.data.data.map((f) => {
              fileUrl.push({ url: f.url });
            });
            this.setState({Fileurl:fileUrl});
            this.addLesson();
           //}
          }
        })
        .catch(err=>{
          console.log(err);
        });
      }
      else {
        this.addLesson();  
      }
  }

  addLesson= async() =>{
  //debugger
  const {facultyId, facultyName, LessonNo, Lessonname, DeliveryType, TopicContnet, Videourl, Fileurl, meetingUrl, scheduleDate, startTime, endTime} = this.state;
  const isValid = this.lessonValid(this.state.formErrors);
  if(isValid){
    //debugger
    let VideoUrls = [];
    this.state.Videourl && this.state.Videourl.map(urlData=>{
      VideoUrls.push({url: urlData.text})
    })
    // this.setState()
  let lessondata = {
    facultyUserId: facultyId,
    facultyFullName: facultyName,
    lessonNo: LessonNo,
    lessonName: Lessonname,
    deliveryType: DeliveryType,
    topicContent: TopicContnet,
    videoUrl: VideoUrls,
    videoTime:"",
    filesUrl: Fileurl,
    //meetingUrl: meetingUrl,
    // meetingContent: "",
    // schedule:[
    //   { scheduleDate: scheduleDate, scheduleTime: startTime + " - " + endTime}
    // ]
  };
  let chapters = this.state.Chapter;
  var b = _.find(chapters, ['chapterName', this.state.ChapterValue]);
  let Chaptervalue = this.state.ChapterValue;
  let thumbnailImgUrl = this.state.moduleThumbNail;
  //alert(thumbnailImgUrl);
  let chapterDescription = this.state.moduleDescription;
  if(_.isObject(b)){
    let chapterindex = _.findIndex(chapters, function(o) { return o.chapterName == Chaptervalue });
    chapters[chapterindex].lessons.push(lessondata);
    this.setState({Chapter:chapters});
  }else{
    let newChapter = {chapterName:Chaptervalue,chapterDescription:chapterDescription,thumbnailImgUrl:thumbnailImgUrl,lessons:[lessondata]}
    chapters.push(newChapter);
    this.setState({Chapter:chapters});
  }
  this.SubmitChapter();
  }
  else{
  this.setState({loading:false});
  }
  }

  SubmitChapter = () => {
    //debugger
    let chapter = [];
    var Id = null;
    chapter = this.state.Chapter;
    let { c_id } = this.state;
    if (c_id == null || c_id == "") {
      Id = this.state.editCourseId;
    } else {
      Id = c_id;
    }
    this.setState({ loading: true });
    axiosApiInstance.put(`${URL}/addLesson/${Id}`, { chapter: chapter })
      .then(async (res) => {
        this.setState({ loading: false });
        if (res.data.status == 500) {
          swal("Something went wrong", "Please try again!", "error");
        } else {
          //debugger
          swal("Lesson added successfully", "", "success");
          await this.setState({
            addModalShow: false,
            LessonNo: null,
            Lessonname: null,
            TopicContnet: null,
            facultyId: "",
            DeliveryType: null,
            Videourl: [],
            Fileurl: [],
            Lesson_file: [],
            Chapter: [],
          });
          let chapters = res.data.course.chapter;
          let lesson = [];
          let chapterList = [];
          chapters &&
            chapters.map((chapter) => {
              chapterList.push({
                id: chapter._id,
                chapterName: chapter.chapterName,
              });
              chapter.lessons &&
                chapter.lessons.map((item) => {
                  lesson.push({
                    scheduleChapterId: chapter._id,
                    chapterNm: chapter.chapterName,
                    id: item._id,
                    active: item.active,
                    deliveryType: item.deliveryType,
                    lessonNo: item.lessonNo,
                    lessonName: item.lessonName,
                    topicContent: item.topicContent,
                    filesUrl: item.filesUrl,
                    videoUrl: item.videoUrl,
                    facultyId: item.facultyUserId,
                    facultyName: item.facultyFullName,
                  });
                });
            });
          this.setState({
            Data: lesson,
            chapterList: chapterList,
            SelectChapter: chapters,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        swal("Something went wrong", "Please try again!", "error");
      });
  };

  getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  handleChangeStatus = async ({ meta, file }, status) => {
    // debugger
    // console.log("file",file);
    let filesdata = [];
    filesdata.push(file);
    await this.setState({ course_file: filesdata });
    this.state.course_file.length > 0
      ? (this.state.formErrors.course_file = "")
      : (this.state.formErrors.course_file = "Please upload course image!");
  };

  handleChangeFlyInfo = async ({ meta, file }, status) => {
    // debugger
    let flyerFile = [];
    flyerFile.push(file);
    await this.setState({ flyerInfoFile: flyerFile });
    this.state.flyerInfoFile.length > 0
      ? (this.state.formErrors.flyerInfoFile = "")
      : (this.state.formErrors.flyerInfoFile = "Please upload file!");
  };

  handleChangeFlyer = async ({ meta, file }, status) => {
    // debugger
    let flyerData = [];
    flyerData.push(file);
    await this.setState({ flyerImgUrl: flyerData });
    this.state.flyerImgUrl.length > 0
      ? (this.state.formErrors.flyerImgUrl = "")
      : (this.state.formErrors.flyerImgUrl = "Please upload flyer image!");
  };

  ModuleHandleChangeStatus = async ({ meta, file }, status) => {
    let filesdata = [];
    filesdata.push(file);
    await this.setState({ moduleImage: filesdata });
    // this.state.course_file.length > 0?this.state.formErrors.course_file = "":this.state.formErrors.course_file = "Please upload course image!";
  };

  // handledataSubmit = async(files,allFiles) => {
  //   //debugger
  //   let filesdata= [];
  //   console.log(files)
  //   files.map(f => {
  //     filesdata.push(f.file)
  //     })
  //   await this.setState({course_file:filesdata})
  //   console.log("course_file",filesdata)
  //  // allFiles.forEach(f => f.remove())
  //  }

  getUploadFileParams = () => ({ url: "https://httpbin.org/post" });

  // handleFileSubmit = (files, allFiles) => {
  //     //debugger
  //     let filesdata= [];
  //     files.map(f => {
  //     filesdata.push(f.file)
  //     })
  //     this.setState({Lesson_file:filesdata})
  //  }

  handleChangeFileStatus = async ({ meta, file, remove }, status) => {
    //debugger
    let filesdata = [];
    filesdata.push(file);
    await this.setState({ Lesson_file: filesdata });
    //console.log("lesson_fileHandle",this.state.Lesson_file);
  };

  NoDropzoneLayout = ({
    previews,
    submitButton,
    input,
    files,
    dropzoneProps,
  }) => {
    const { ref, className, style } = dropzoneProps;
    return (
      <div ref={ref} className={className} style={style}>
        {previews}
        {input}
        {files.length > 0 && submitButton}
      </div>
    );
  };

  onClickCancelLesson = () => {
    this.setState({
      addModalShow: false,
      endTime: "",
      chapterId: "",
      startTime: "",
      LessonNo: null,
      Lessonname: null,
      TopicContnet: null,
      facultyId: "",
      DeliveryType: null,
      ChapterValue: null,
      Videourl: [],
      Fileurl: [],
      Lesson_file: [],
      Chapter: [],
      formErrors: {
        LessonNo: "",
        Lessonname: "",
        Fileurl:"",
        TopicContnet: "",
        facultyId: "",
        DeliveryType: "",
        Videourl: "",
      },
    });
  };

  handleRadioButton = (e) => {
    //debugger
    const { name, value } = e.target;
    this.setState({ completeTestType: value });
    if (value == "Question wise") {
      this.setState({ duration: null });
    }
    let formErrors = this.state.formErrors;
    switch (this.state.completeTestType) {
      case "completeTestType":
        formErrors.completeTestType =
          value === "" ? "Please choose time limit option" : "";
        break;
    }
  };

  handleSubmitTest = (e) => {
    //debugger
    e.preventDefault();
    let {
      testName,
      chapterId,
      c_id,
      shuffle,
      description,
      duration,
      completeTestType,
    } = this.state;
    if (chapterId !== "") {
      const isValid = this.testformvalid(this.state.testFormErrors);
      if (isValid) {
        //debugger
        let type = "Chapter";
        let courseId = c_id;
        let timeLimitOption = completeTestType;
        axiosApiInstance
          .post(`${URL}/test`, {
            testName,
            shuffle,
            userId,
            type,
            description,
            courseId,
            chapterId,
            duration,
            timeLimitOption,
          })
          .then((res) => {
            let Test = res.data.Test;
            swal(
              "Test Created successfully",
              "Please Add test section and question!",
              "success"
            )
              .then((result) => {
                if (result) {
                  this.props.history.push(`/AddSection?id=${Test._id}`);
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.setState({
              testId: Test._id,
              testName: Test.testName,
              description: Test.description,
              testType: Test.type,
              timeLimitOption: Test.timeLimitOption,
              duration: Test.duration,
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    } else {
      swal("Please select module!", "", "info");
      //alert("Please select module!");
    }
  };

  onClickCancelTest = () => {
    this.createTestModalHide();
  };

  createTestModalShow = () => {
    if (this.state.SelectChapter.length <= 0 && this.state.Data.length <= 0) {
      swal("Please add module and lesson first!", "", "info");
      // alert("Please add module and lesson first!");
    } else if (
      this.state.SelectChapter.length > 0 &&
      this.state.Data.length > 0
    ) {
      this.setState({ createTestModalState: true });
    } else {
      swal("Please add lesson first!", "", "info");
      // alert("Please add lesson first!");
    }
    // this.setState({createTestModalState: true});
  };

  handleChangeShuffle = (e) => {
    const { checked } = e.target;
    this.setState({ shuffle: checked });
  };

  createTestModalHide = () => {
    this.setState({
      createTestModalState: false,
      testId: "",
      chapterId: "",
      testName: "",
      description: "",
      duration: null,
      completeTestType: "",
      testFormErrors: {
        chapterId: "",
        testName: "",
        description: "",
        duration: "",
        testType: "",
        completeTestType: "",
      },
      testType: "",
    });
  };

  onDelete = (lessonId) => {
    let courseId = this.state.c_id;
    // debugger;
    axiosApiInstance
      .put(`${URL}/deleteLesson/${courseId}`, { lessonId: lessonId })
      .then((res) => {
        // debugger;
        //console.log(res.data);
        this.getCourse(courseId);
      });
  };

  onClickRemove = (url) => {
    //debugger
    var fileUrls=[];

    // console.log(url)
    // let url = url;
    this.state.Fileurl &&
      this.state.Fileurl.map((data) => {
        fileUrls.push({ url: data.url });
      });
      fileUrls &&
      fileUrls.map((data) => {
        if (data.url === url) {
          var indexOfFile = fileUrls.findIndex((a) => a.url === data.url);
          fileUrls.splice(indexOfFile, 1);
        }
      });
    this.setState({ Fileurl: fileUrls });
  };

  clearModal=()=>{
    //debugger
    this.setState({
      endTime: "",
      chapterId: "",
      startTime: "",
      ChapterName: null,
      moduleDescription: "",
      moduleThumbNail: "",
      ChapterValue: null,
      LessonNo: null,
      Lessonname: null,
      TopicContnet: null,
      DeliveryType: "",
      Videourl: [],
      Fileurl: [],
      Lesson_file: [],
      Chapter: [],
      facultyId: "",
      lessonId: "",
      formErrors: {
        LessonNo: "",
        Lessonname: "",
        TopicContnet: "",
        facultyId: "",
        DeliveryType: "",
        Videourl: "",
        filesUrl: "",
      },
      addModalShow: false,
      addtextbox: false,
    })
  }

  onEdit = async (editLesson) => {
    // debugger
    var fileUrls=[];
    editLesson.filesUrl &&
    editLesson.filesUrl.map((data) => {
        fileUrls.push({ url: data.url });
      });
    await this.setState({
      lessonId: editLesson.id,
      LessonNo: editLesson.lessonNo,
      Fileurl:  fileUrls,
      chapterId: editLesson.scheduleChapterId,
      // Videourl: editLesson.videoUrl,
      //LessonNo: editLesson.lessonNo,
      DeliveryType: editLesson.deliveryType,
      Lessonname: editLesson.lessonName,
      facultyId: editLesson.facultyId,
      facultyName: editLesson.facultyName,
      //facultyUserId: editLesson.facultyId,
      //facultyFullName: editLesson.facultyName,
      TopicContnet: editLesson.topicContent,
      addModalShow: true,
      meetingContent: editLesson.meetingContent,
       meetingUrl: editLesson.meetingUrl,
      scheduleChapterId: editLesson.scheduleChapterId,
      // scheduleTime:editLesson.schedule[0].scheduleTime;
    });

    let videos = [];
    editLesson.videoUrl && editLesson.videoUrl.map(data=>{
      videos.push({id: data.url,
      text: data.url})
    })

    this.setState({Videourl: videos});

    // var fileUrls=[];
    // editLesson.filesUrl &&
    // editLesson.filesUrl.map((data) => {
    //     img.push({ url: data.url });
    //   });

    //console.log(this.state.meetingContent);
    if (editLesson.schedule !== undefined) {
      if (editLesson.schedule.length <= 0) {
        this.setState({ scheduleDate: new Date(this.state.startDate) });
      } else {
        await this.setState({
          scheduleDate: new Date(editLesson.schedule[0].scheduleDate),
          scheduleTime: editLesson.schedule[0].scheduleTime[0],
        });
        let start = this.state.scheduleTime.slice(0, -8);
        let end = this.state.scheduleTime.slice(8);
        this.setState({ startTime: start, endTime: end });
      }
    }
  };

  onUpdateLesson = () => {
    // debugger;
    let {
      LessonNo,
      lessonId,
      DeliveryType,
      chapterId,
      facultyId,
      facultyName,
      Videourl,
      videoTime,
      TopicContnet,
      Fileurl,
    } = this.state;
    let file_Url = this.state.Lesson_file;
    let lessonName = this.state.Lessonname;
    if (file_Url.length > 0) {
      const isValid = this.lessonValid(this.state.formErrors);
      if (isValid) {
        this.setState({ loading: true });
        const formData = new FormData();
        file_Url.forEach((item) => {
          formData.append("photos", item.file);
        });
        formData.append("name", lessonName);

        MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`, formData)
          .then(async (res) => {
            //debugger;
            if (res.data.message === "Files are uploaded") {
              // var fileUrl = res.data.data[0];
              // await this.setState({ Fileurl: fileUrl.url });
              var fileUrl = [];
            res.data.data &&
            res.data.data.map((f) => {
              fileUrl.push({ url: f.url });
            });
            var Files=[];
            this.state.Fileurl && this.state.Fileurl.map((f)=>{
              Files.push({url:f.url});
            })
            var allFiles=[...Files, ...fileUrl]
            // this.setState({Fileurl:fileUrl});
              DeliveryType === "Video Lectures"
                ? (fileUrl = [])
                : (Videourl = []);
              // DeliveryType === "Manuscript"
              //   ? (Videourl = [])
              //   : (fileUrl = []);
              let data = {
                chapterId: chapterId,
                lessonId: lessonId,
                lessonName: lessonName,
                facultyUserId: facultyId,
                facultyFullName: facultyName,
                lessonNo: LessonNo,
                lessonName: lessonName,
                deliveryType: DeliveryType,
                topicContent: TopicContnet,
                videoUrl: Videourl,
                videoTime: videoTime,
                filesUrl: allFiles,
              };
              axiosApiInstance
                .put(`${URL}/updateLesson/${this.state.c_id}`, data)
                .then((res) => {
                  let course = res.data.course;
                  if (res.data.status === 500) {
                    this.setState({ loading: false });
                    swal("Something went wrong", "Please try again!", "error");
                  } else {
                    swal("Lesson Updated Successfully", "", "success");
                    this.getCourse(this.state.c_id);
                    this.onClickCancelLesson();
                    this.setState({ loading: false });
                  }
                  this.setState({ addModalShow: false });
                })
                .catch((err) => {
                  console.log(err);
                  this.onClickCancelLesson();
                  this.setState({ loading: false });
                  swal("Something went wrong", "Please try again!", "error");
                });
            }
          })
          .catch((err) => {
            console.log(err);

            this.setState({ loading: false });
            swal("Something went wrong", "Please try again!", "error");
          });
      }
    } else {
     // debugger;
      const isValid = this.lessonValid(this.state.formErrors);
      if (isValid) {
        let VideoUrls = [];
        this.state.Videourl && this.state.Videourl.map(urlData=>{
            VideoUrls.push({url: urlData.text})
        })
        DeliveryType === "Video Lectures" ? (Fileurl = []) : (Videourl = []);
        let data = {
          chapterId: chapterId,
          lessonId: lessonId,
          lessonName: lessonName,
          facultyUserId: facultyId,
          facultyFullName: facultyName,
          lessonNo: LessonNo,
          lessonName: lessonName,
          deliveryType: DeliveryType,
          topicContent: TopicContnet,
          videoUrl: VideoUrls,
          videoTime: videoTime,
          filesUrl: Fileurl,
        };
        axiosApiInstance
          .put(`${URL}/updateLesson/${this.state.c_id}`, data)
          .then((res) => {
            if (res.data.status === 500) {
              this.setState({ loading: false });
              //console.log("something went worng!");
              swal("Something went wrong", "Please try again!", "error");
            } else {
              swal("Lesson Updated Successfully", "", "success");
              this.onClickCancelLesson();
              this.getCourse(this.state.c_id);
              this.setState({ loading: false });
            }
            this.setState({ addModalShow: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
            swal("Something went wrong", "Please try again!", "error");
            console.log(err);
          });
      }
    }
  };

  onClickUpdateLesson = () => {
    //debugger
    let {
      startTime,
      meetingContent,
      endTime,
      scheduleDate,
      meetingUrl,
      lessonId,
      DeliveryType,
      scheduleId,
      scheduleChapterId,
    } = this.state;
    let file_Url = this.state.Lesson_file;
    let lessonName = this.state.Lessonname;
    if (file_Url.length > 0) {
      const isValid = this.lessonUpdateformvalid(this.state.formErrors);
      if (isValid) {
        this.setState({ loading: true });
        const formData = new FormData();
        file_Url.forEach((item) => {
          formData.append("photos", item);
        });
        formData.append("name", lessonName);

        MediaAxiosApiInstance.post(`${mediaUrl}/multiple-image`, formData)
          .then(async (res) => {
            //debugger
            if (res.data.message === "Files are uploaded") {
              let fileUrl = res.data.data[0];
              await this.setState({ Fileurl: fileUrl.url });
              //debugger
              axiosApiInstance
                .put(`${URL}/courseScheduleUpdateLesson/${scheduleId}`, {
                  chapterId: scheduleChapterId,
                  lessonId: lessonId,
                  schedule: [
                    {
                      scheduleDate: new Date(scheduleDate).toISOString(),
                      scheduleTime: startTime + " - " + endTime,
                    },
                  ],
                  deliveryType: DeliveryType,
                  meetingUrl: meetingUrl,
                  meetingContent: [fileUrl.url],
                })
                .then((res) => {
                  //debugger
                  swal(
                    "Schedule Created successfully",
                    "Please Add Schedule!",
                    "success"
                  );
                  // console.log("resdataaaaa",res);
                  let course = res.data.CourseSchedule;
                  if (res.data.status === 500) {
                    this.setState({ loading: false });
                    //console.log("something went worng!");
                    swal("Something went wrong", "Please try again!", "error");
                  } else {
                    let lesson = [];
                    course.chapter &&
                      course.chapter.map((chapter) => {
                        chapter.lessons &&
                          chapter.lessons.map((item) => {
                            lesson.push({
                              scheduleChapterId: chapter._id,
                              chapterNm: chapter.chapterName,
                              id: item._id,
                              active: item.active,
                              deliveryType: item.deliveryType,
                              lessonNo: item.lessonNo,
                              lessonName: item.lessonName,
                              topicContent: item.topicContent,
                              filesUrl: item.filesUrl,
                              videoUrl: item.videoUrl,
                              facultyId: item.facultyUserId,
                              facultyName: item.facultyFullName,
                              schedule: item.schedule,
                              meetingUrl: item.meetingUrl,
                              meetingContent: item.meetingContent,
                            });
                          });
                      });
                    this.setState({ Data: lesson, startTime: "", endTime: "" });
                    this.setState({ loading: false });
                  }
                  this.setState({ addModalShow: false });
                })
                .catch((err) => {
                  console.log(err);
                  swal("Something went wrong", "Please try again!", "error");
                });
            }
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong", "Please try again!", "error");
          });
      }
      //{{devUrl}}/courseScheduleUpdateLesson/5ffbf4238e03ae25af4553e4
    } else {
      const isValid = this.lessonUpdateformvalid(this.state.testFormErrors);
      if (isValid) {
        axiosApiInstance
          .put(`${URL}/courseScheduleUpdateLesson/${scheduleId}`, {
            chapterId: scheduleChapterId,
            lessonId: lessonId,
            schedule: [
              {
                scheduleDate: new Date(scheduleDate).toISOString(),
                scheduleTime: startTime + " - " + endTime,
              },
            ],
            deliveryType: DeliveryType,
            meetingUrl: meetingUrl,
            meetingContent: meetingContent,
          })
          .then((res) => {
            //debugger
            //console.log("resdataaaaa",res);
            let course = res.data.CourseSchedule;
            if (res.data.status === 500) {
              this.setState({ loading: false });
              //console.log("something went worng!");
              swal("Something went wrong", "Please try again!", "error");
            } else {
              let lesson = [];
              course.chapter &&
                course.chapter.map((chapter) => {
                  chapter.lessons &&
                    chapter.lessons.map((item) => {
                      lesson.push({
                        scheduleChapterId: chapter._id,
                        chapterNm: chapter.chapterName,
                        id: item._id,
                        active: item.active,
                        deliveryType: item.deliveryType,
                        lessonNo: item.lessonNo,
                        lessonName: item.lessonName,
                        topicContent: item.topicContent,
                        filesUrl: item.filesUrl,
                        videoUrl: item.videoUrl,
                        facultyId: item.facultyUserId,
                        facultyName: item.facultyFullName,
                        schedule: item.schedule,
                        meetingUrl: item.meetingUrl,
                        meetingContent: item.meetingContent,
                      });
                    });
                });
              this.setState({ Data: lesson, startTime: "", endTime: "" });
            }

            this.setState({ addModalShow: false });
          })
          .catch((err) => {
            swal("Something went wrong", "Please try again!", "error");
            console.log(err);
          });
      }
    }
  };

  handleSchedule = (date) => {
    //debugger
    this.setState({ scheduleDate: date });
  };

  handleEditorChange=(CourseDescription)=>{
    this.setState({ CourseDescription});
  }

  assignFaculty=()=>{
    let main = [];
    this.state.selectedOption&&this.state.selectedOption.map(data => {
      main.push({
        facultyUserId: data.value,
        facultyFullName:  data.label
      })
    })
        // this.state.formErrors.mainFaculties =
        //   option.length > 0
        //     ? "Please select course faculties!"
        //     : "";
    this.setState({mainFaculties:main});
    // console.log(main);
  }

  handleMultiChange(option) {
    // this.setState(state => {
    //   return {
    //     selectedOption: option
    //   };
    // });
    this.setState({ selectedOption: option });
    this.assignFaculty();
  }

  handleDelete=(i)=>{
    //debugger
    const { Videourl } = this.state;
    this.setState({
     Videourl: Videourl.filter((vUrl, index) => index !== i),
    });
  }

  handleAddition=(vUrl)=>{
    //debugger
    this.setState(state => ({ Videourl: [...state.Videourl, vUrl] }));
}

  render() {
    //alert(this.state.addModalShow);
    const {
      scheduleColumn,
      rowData,
      isSubmit,
      moduleDescription,
      meetingContent,
      batch,
      createTestModalState,
      formErrors,
      lessonFaculty,
      SelectCourse,
      Fileurl,
      Videourl,
      flyerImages,
      flyerImgUrl,
      flyerPreFile,
      LessonNo,
      Lessonname,
      TopicContnet,
      instructionDeliveryType,
      preDeliveryType,
      courseType,
      ChapterName,
      Fees,
      Duration,
      CourseDescription,
      SelectCategory,
      SelectFaculty,
      selectedOption,
      SelectChapter,
      columns,
      Data,
      CourseName,
      meetingUrl,
      startTime,
      endTime,
      scheduleDate,
      coursePreFile,
    } = this.state;
    // alert(Fileurl);
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 ">
        <DeleteConfirm
          show={this.state.showDelModal}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.lessonName}
          close={() => this.setState({ showDelModal: false })}
          deleteHandler={this.onDelete}
        />
        <DeleteConfirm 
          show={this.state.showFlyModal}
          animation={true}
          rowData={this.state.flyDelUrl}
          id={this.state.flyDelInd}
          close={() => this.setState({ showFlyModal: false })}
          deleteHandler={this.deleteFlyer} 
        />
        <Card className="shadow p-4 col-md-12 marginauto">
          {this.state.loading ? <Loader /> : <div></div>}
          <AvForm onSubmit={this.handleSubmit}>
            <Card className="col-md-8 p-4" style={{ margin: "0 auto" }}>
              <Row>
                <Col className="mb-4">
                  <h3 className="text-center blueText">Course Master</h3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AvGroup>
                    <Label for="CourseName">Course Name</Label>
                    <span className="requiredFieldSpan">*</span>
                    <AvInput
                      name="CourseName"
                      id="coursename"
                      onChange={this.handleChange}
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                      value={CourseName}
                    />
                    {formErrors.CourseName !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.CourseName}
                      </Label>
                    ) : null}
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <Label>Select Category</Label>
                    <span className="requiredFieldSpan">*</span>
                    <select
                      id="selectCourse"
                      name="SelectCourse"
                      className="form-control"
                      value={SelectCourse}
                      onChange={this.handleChange}
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                    >
                      <option>Select category</option>
                      {SelectCategory &&
                        SelectCategory.map((select) => {
                          return (
                            <option value={select.id} data-key={select.id}>
                              {select.categoryName}
                            </option>
                          );
                        })}
                    </select>
                    {formErrors.SelectCourse !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.SelectCourse}
                      </Label>
                    ) : null}
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col className="feeInput" md={6}>
                  <AvGroup>
                    <Label>Fees</Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Rs.</InputGroup.Text>
                      </InputGroup.Prepend>
                      <AvInput
                        type="number"
                        name="Fees"
                        placeholder=""
                        onChange={this.handleChange}
                        value={Fees}
                        disabled={
                          role === "faculty" || this.state.scheduleId != ""
                            ? true
                            : false
                        }
                      />
                    </InputGroup>

                    {/* <CurrencyInput
                                name="Fees"
                                value={Fees}
                                disabled={role === "faculty" || this.state.scheduleId != ''?true:false}
                            //  defaultValue={1000}
                            //  decimalsLimit={2}
                                prefix="£"
                                onChange={this.handleChange}
                            />; */}
                    {formErrors.Fees !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.Fees}
                      </Label>
                    ) : null}
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <Label>Duration</Label>
                    <span className="requiredFieldSpan">*</span>
                    <AvInput
                      type="text"
                      name="Duration"
                      onChange={this.handleChange}
                      value={Duration}
                      placeholder=""
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                    />
                    {formErrors.Duration !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.Duration}
                      </Label>
                    ) : null}
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvGroup>
                    <Label>Course Description</Label>
                    <span className="requiredFieldSpan">*</span>
                      {/*  <AvInput 
                    //   type="textarea"
                    //   name="CourseDescription"
                    //   onChange={this.handleChange}
                    //   value={CourseDescription}
                    //   disabled={
                    //     role === "faculty" || this.state.scheduleId != ""
                    //       ? true
                    //       : false
                    //   }
                    //   id="CourseDescription"
                    // />
                    */ }
                    <TextEditor name="CourseDescription"
                    onChange={this.handleEditorChange} value={CourseDescription} id="CourseDescription" 
                    readOnly={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }/>
                    {formErrors.CourseDescription !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.CourseDescription}
                      </Label>
                    ) : null}
                  </AvGroup>
                </Col>
              </Row>
              
              {this.state.scheduleId!=""
              ?null
              :(<Row>
                  <Col>
                    <Label>Flyer Image</Label>
                    <span className="requiredFieldSpan">*</span>
                    <br />
                    <Row>

                    <Col className="text-center">
                    <FormGroup>

                    <Dropzone
                      getUploadParams={this.getUploadParams}
                      onChangeStatus={this.handleChangeFlyer}
                      //onSubmit={this.handledataSubmit}
                      //canRemove={false}
                      maxFiles={1}
                      multiple={false}
                      //getFilesFromEvent={this.getFilesFromEvent}
                      accept="image/*,audio/*,video/*"
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                      //submitButtonContent= 'Uplaod File'
                    />
                   {/* { flyerImgUrl &&
                      flyerImgUrl.map(data => {
                    <Row>
                      <img
                        src={data.url}
                        // onClick={() =>
                        //   this.setState({ show: true, rowData: data.url })
                        // }
                        id="image"
                        style={{ width: "100%" }}
                      />
                    </Row>})} */}
                    {/* <SimpleDropZone arrayData={this.imageData} isSubmit={false} /> */}
                    </FormGroup>
                    </Col>
                    {this.getFlyersImg(flyerImages)}
                    </Row>
                    {formErrors.flyerImgUrl !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.flyerImgUrl}
                      </Label>
                    ) : null}
                  </Col>
              </Row>)}
              { this.state.scheduleId!=""
              ?null
              :(<Row>
                  <Col>
                    <Label>More Info</Label>
                    {/* <span className="requiredFieldSpan">*</span> */}
                    <br />
                    <Row>

                    <Col className="text-center">
                    <FormGroup>
                      
                      
                    <Dropzone
                      getUploadParams={this.getUploadParams}
                      onChangeStatus={this.handleChangeFlyInfo}
                      //onSubmit={this.handledataSubmit}
                      //canRemove={false}
                      maxFiles={1}
                      multiple={false}
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                      //getFilesFromEvent={this.getFilesFromEvent}
                      accept="image/*"
                      //submitButtonContent= 'Uplaod File'
                    />
                   
                    {/* <SimpleDropZone arrayData={this.imageData} isSubmit={false} /> */}
                    {/* <FormGroup>
                      {flyerPreFile && flyerPreFile.map((files,i)=>
                      // <Col>
                      <span>
                      <InsertDriveFileIcon className="pdfIcn mr-1" style={{marginleft:'-1'}}/>
                      <Label key={i}>{getLocation(files.url).hostname === "lmsmedia.softlabsgroup.in"
                        ? files.url.slice(38)
                        : files.url.slice(26)
                        }
                      </Label>
                      </span>
                      // </Col>
                      )}
                    </FormGroup> */}

                    </FormGroup>
                    </Col>
                    {this.getFlyersImg(flyerPreFile)}
                    </Row>
                    {/* {formErrors.flyerInfoFile !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.flyerInfoFile}
                      </Label>
                    ) : null} */}
                  </Col>
                </Row>)}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Faculty/Author Name</Label>
                    <span className="requiredFieldSpan">*</span>
                    {/* <select
                      id="facultyUserId"
                      name="facultyUserId"
                      className="form-control"
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                      value={this.state.facultyUserId}
                      onChange={this.handleDropdownChange}
                    >
                      <option>Select Faculty/Author</option>
                      {SelectFaculty &&
                        SelectFaculty.map((select) => {
                          return (
                            <option
                              value={select.facultyUserId}
                              data-key={select.facultyFullName}
                            >
                              {select.facultyFullName}
                            </option>
                          );
                        })}
                    </select> */}
                    <MultiSelection faculties={SelectFaculty} selected={selectedOption} handleMultiple={this.handleMultiChange}
                    disabled={
                      role === "faculty" || this.state.scheduleId != ""
                        ? true
                        : false
                    }
                    />
                    {formErrors.mainFaculties !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.mainFaculties}
                      </Label>
                    ) : null}
                    {/* <DropDown title="Select Faculty"  value={this.state.facultyFullName}  options={SelectFaculty} selectedCategory={this.selectedFaculty}/> */}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Select Course Type</Label>
                    <span className="requiredFieldSpan">*</span>
                    <select
                      id="Dropdown"
                      name="courseType"
                      className="form-control"
                      value={courseType}
                      onChange={this.handleChange}
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                    >
                      <option>Select Course Type</option>
                      {this.state.typeOfCourse &&
                        this.state.typeOfCourse.map((select) => {
                          return (
                            <option value={select.text} data-key={select.value}>
                              {select.text}
                            </option>
                          );
                        })}
                    </select>
                    {formErrors.courseType !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.courseType}
                      </Label>
                    ) : null}
                    {/* <DropDown title="Select Faculty"  value={this.state.facultyFullName}  options={SelectFaculty} selectedCategory={this.selectedFaculty}/> */}
                  </FormGroup>
                </Col>
              </Row>
              {courseType === "Instruction based" ? (
                <Row>
                  <Col md={6}>
                    <AvGroup>
                      <Label>Batch size</Label>
                      <span className="requiredFieldSpan">*</span>
                      <AvInput
                        type="number"
                        name="batch"
                        onChange={this.handleChange}
                        placeholder=""
                        value={batch}
                        disabled={
                          role === "faculty" || this.state.scheduleId != ""
                            ? true
                            : false
                        }
                      />
                      {formErrors.batch !== "" ? (
                        <Label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.batch}
                        </Label>
                      ) : null}
                    </AvGroup>
                  </Col>
                </Row>
              ) : null}
              {this.state.scheduleId != "" ? (
                <Row>
                  <Col md={6}>
                    <AvGroup>
                      <Label>Course start date</Label>
                      <span className="requiredFieldSpan">*</span>
                      <AvInput
                        type="text"
                        name="startDate"
                        value={moment(this.state.startDate).format(
                          "DD-MM-YYYY"
                        )}
                        placeholder=""
                        disabled={true}
                      />
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <Label>Course end date</Label>
                      <span className="requiredFieldSpan">*</span>
                      <AvInput
                        type="text"
                        name="endDate"
                        value={moment(this.state.endDate).format("DD-MM-YYYY")}
                        placeholder=""
                        disabled={true}
                      />
                    </AvGroup>
                  </Col>
                </Row>
              ) : null}
              {this.state.c_id != null || this.state.editCourse == true ? (
                <>
                  <Row>
                    {this.state.c_id != null ||
                    this.state.editCourse == true ? (
                      <>
                        <Col md={3}>
                          <FormGroup>
                            {/* <Label>Chapter</Label><br/> */}
                            <Button
                              className=" btnblue"
                              id="centered-toggle-button"
                              onClick={() =>
                                this.setState({ addtextbox: true })
                              }
                            >
                              {this.state.chapterId === ""
                                ? " Add Module"
                                : "Update Module"}
                            </Button>
                            <AddChapterModal
                              title={this.state.title}
                              show={this.state.addtextbox}
                              onHide={this.clearModal}
                              ChapterName={ChapterName}
                              formErrors={formErrors}
                              moduleDescription={moduleDescription}
                              ModuleHandleChangeStatus={
                                this.ModuleHandleChangeStatus
                              }
                              handleChange={this.handleChange}
                              getUploadParams={this.getUploadParams}
                              moduleThumbNail={this.state.moduleThumbNail}
                              chapterId={this.state.chapterId}
                              onClickAddChapter={this.onClickAddChapter}
                              onClickUpdateChapter={this.onClickUpdateChapter}
                              onClickCancelButton={this.onClickCancel}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    ) : null}
                    <Col md={4} className="pl-0">
                      <FormGroup>
                        {/* <Label>Select Chapter</Label> */}
                        {/* <Label>Select Module</Label> */}
                        {/* <span className="requiredFieldSpan">*</span> */}
                        <select
                          id="Dropdown"
                          name="module"
                          className="form-control"
                          value={this.state.chapterId}
                          onChange={this.selectedChapter}
                        >
                          <option>Select Module</option>
                          {SelectChapter &&
                            SelectChapter.map((select) => {
                              return (
                                <option
                                  value={select._id}
                                  chapterName={select.chapterName}
                                  desc={select.chapterDescription}
                                  thum={select.thumbnailImgUrl}
                                >
                                  {select.chapterName}
                                </option>
                              );
                            })}
                        </select>
                        {formErrors.courseType !== "" ? (
                          <Label style={{ fontSize: 12, color: "red" }}>
                            {formErrors.courseType}
                          </Label>
                        ) : null}
                      </FormGroup>
                      {/* <DropDown title="Select Module" options={SelectChapter} selectedCategory={this.selectedChapter}/> */}
                    </Col>
                    {/* <Col md={3}>
                    
                    </Col>  */}
                    <Col md={5}>
                      <Row>
                        <Col>
                          <FormGroup>
                            {/* <Label>Lesson</Label><br/> */}
                            <Button
                              className=" btnblue"
                              id="centered-toggle-button"
                              onClick={() => {
                                this.onclickLesson();
                              }}
                            >
                              Add Lesson
                            </Button>
                            {/* {this.state.scheduleId != ''? */}
                            {/* } */}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            {/* <Label>Create Test</Label><br/> */}
                            <Button
                              onClick={() => this.createTestModalShow()}
                              className="btnblue"
                            >
                              Create Test
                            </Button>
                            <CreateTestModal
                              chapterList={this.state.chapterList}
                              selectedTestChapter={this.selectedTestChapter}
                              courseId={this.state.c_id}
                              handleSubmitTest={(e) => this.handleSubmitTest(e)}
                              onClickCancelTest={this.onClickCancelTest}
                              handleRadioButton={this.handleRadioButton}
                              handleChange={this.handleChange}
                              testId={this.state.testId}
                              testName={this.state.testName}
                              testType={this.state.testType}
                              description={this.state.description}
                              duration={this.state.duration}
                              completeTestType={this.state.completeTestType}
                              onHideCreateTestModal={() =>
                                this.createTestModalHide()
                              }
                              testFormErrors={this.state.testFormErrors}
                              chapterId={this.state.chapterId}
                              show={createTestModalState}
                              checkedShuffle={this.state.shuffle}
                              handleChangeShuffle={this.handleChangeShuffle}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : null}
              <AddModal
                startDate={this.state.startDate}
                arrayData={this.arrayData}
                isSubmit={isSubmit}
                onUpdateLesson={this.onUpdateLesson}
                endDate={this.state.endDate}
                onClickUpdateLesson={this.onClickUpdateLesson}
                startTime={startTime}
                scheduleId={this.state.scheduleId}
                endTime={endTime}
                meetingUrl={meetingUrl}
                scheduleDate={scheduleDate}
                Fileurl={Fileurl}
                Videourl={Videourl}
                lessonId={this.state.lessonId}
                LessonNo={LessonNo}
                DeliveryType={this.state.DeliveryType}
                courseType={courseType}
                Lessonname={Lessonname}
                SelectDeliveryType={
                  courseType === "Prerecorded"
                    ? preDeliveryType
                    : this.state.scheduleId != ""
                    ? instructionDeliveryType
                    : preDeliveryType
                }
                lessonFaculty={lessonFaculty}
                handleChange={this.handleChange}
                handleSchedule={this.handleSchedule}
                onClickAddLesson={this.onClickAddLesson}
                onClickCancel={this.onClickCancelLesson}
                facultyId={this.state.facultyId}
                selectedDeliveryType={this.selectedDeliveryType}
                selectedFaculty={this.selectedForLessonFaculty}
                TopicContnet={TopicContnet}
                show={this.state.addModalShow}
                onHide={this.clearModal}
                // handleFileSubmit={this.handleFileSubmit}
                // NoDropzoneLayout={this.NoDropzoneLayout}
                getUploadParams={this.getUploadFileParams}
                // handleChangeStatus={this.handleChangeFileStatus}
                formErrors={formErrors}
                urlValue={this.state.urlValue}
                onClickRemove={this.onClickRemove}
                handleAddition={this.handleAddition}
                handleDelete={this.handleDelete}
              />
            </Card>
            <br/>
             {/* {this.state.c_id == null &&
            this.state.editCourse === false &&
            this.state.scheduleId === "" ? ( */}
              <>
                {role === "superAdmin" && this.state.scheduleId != ""
                ?null
                :(<Row>
                  <Col>
                    <Label>Course Image</Label>
                    <span className="requiredFieldSpan">*</span>
                    <br />
                    <Row>

                    <Col className="text-center">
                    <FormGroup>
                    <Dropzone
                      getUploadParams={this.getUploadParams}
                      onChangeStatus={this.handleChangeStatus}
                      //onSubmit={this.handledataSubmit}
                      //canRemove={false}
                      maxFiles={1}
                      multiple={false}
                      //getFilesFromEvent={this.getFilesFromEvent}
                      disabled={
                        role === "faculty" || this.state.scheduleId != ""
                          ? true
                          : false
                      }
                      accept="image/*,audio/*,video/*"
                      //submitButtonContent= 'Uplaod File'
                    />
                    </FormGroup>
                    </Col>
                    {this.getFlyersImg(coursePreFile)}
                    </Row>
                    {formErrors.course_file !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {formErrors.course_file}
                      </Label>
                    ) : null}
                  </Col>
                </Row>)}
              </>
            {/* ) : null} */}
            {this.state.c_id != null ||
            this.state.editCourse == true ||
            this.state.scheduleId != "" ? (
              <Row className="mb-4 mt-4">
                <Col className="tableWp zindex0">
                  <Row>
                    <Col className="DashboardTbl">
                      <Table
                        data={Data}
                        pageSize={5}
                        paging={true}
                        columns={
                          this.state.scheduleId !== ""
                            ? scheduleColumn
                            : columns
                        }
                        search={false}
                        headerStyle={headerStyle}
                        rowStyle={rowStyle}
                        maxBodyHeight="220px"
                        actions={
                          // this.state.scheduleId != '' ? {
                          //   icon: () => <ScheduleIcon />,
                          //   tooltip: 'Schedule Lesson',
                          //   onClick: (event, rowData) => {
                          //     this.onEdit(rowData);
                          //   }
                          // } :
                          this.state.scheduleId != ''?[null]:[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => {
                             // debugger;
                              this.onEdit(rowData);
                            },
                          },
                          {
                            icon: "delete",
                            tooltip: "Delete",
                            onClick: (event, rowData) => {
                             // debugger;
                              this.setState({
                                showDelModal: true,
                                rowData: rowData,
                              });
                              // console.log(rowData);
                            },
                          }
                        ]
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
           
            {role === "faculty" || this.state.scheduleId != "" ? null : this
                .state.c_id == null || this.state.editCourse === true ? (
              <>
                <Row>
                  <Col className="text-center mt-3">
                    <div className="center-block text-center">
                      {this.state.editCourse === true ||
                      this.state.c_id !== null ? (
                        <Button
                          className="text-center mr-3 btnblue"
                          onClick={this.handleUpdateSubmit}
                        >
                          Save
                        </Button>
                      ) : (
                        <>
                          <Button
                            className="text-center mr-3 btnblue"
                            onClick={this.handleSubmit}
                          >
                            Create Course
                          </Button>
                          <Button
                            className="text-center mr-3 "
                            onClick={this.clearCourseHandle}
                            color="danger"
                          >
                            Clear
                          </Button>
                        </>
                      )}
                      <Link to={"/CourseList"}>
                        <Button className="text-center" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}
          </AvForm>
        </Card>
      </Container>
    );
  }
}
