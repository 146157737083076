

export const URL = "https://apilms.consiliumacademy.com"; // consiliumacademy live url
export const mediaUrl = "https://lmsmedia.consiliumacademy.com"; //  consiliumacademy live url

//export const URL = "https://apilms.softlabsgroup.in"; // demo url
//export const mediaUrl = "https://lmsmedia.softlabsgroup.in"; //  demo url

// export const URL = "http://5.189.157.40:62000"; // development  url
// export const mediaUrl = "http://5.189.157.40:62003"; // development local

//export const URL = "https://apilms.softlabsgroup.com"; //softlabs live url
//export const mediaUrl = "https://lmsmedia.softlabsgroup.com"; // softlabs live url



