import React, {useEffect} from 'react';
import {Modal }from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip'; 
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const FileViewerModal = ( { show, onHide, lessonTitle, fileUrl, fileType, showSwal }) => {
    // debugger;
    // useEffect(() => {
    //   document.addEventListener("contextmenu", (e) => {
    //     e.preventDefault();
    //   });
    // });

    return (
        
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
            centered className="modalWp videomodalWP" show={show} onHide={onHide}>
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    {lessonTitle} &nbsp;
                    {!showSwal
                    ? <Tooltip title="Completed" aria-label="info"><CheckCircleIcon fontSize="large" color="primary" /></Tooltip>
                    : null}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                 {/* <FileViewer fileType="pdf" filePath={fileUrl} /> */}  
                { fileType === 'pptx' || fileType === 'ppt' || fileType === 'docx'|| fileType === 'doc'?
                    <iframe width="100%" height="400px" src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}></iframe> 
                    :<embed type="application/pdf" src={fileUrl + "#toolbar=0&navpanes=0&scrollbar=0"}  width="100%" height="100%"></embed>
                }
            </Modal.Body>
        </Modal>
    );
}

export default FileViewerModal;
