import React from "react";
import { Card ,Row,Col,Container} from 'reactstrap'
import PaginationLine from  '../../../Components/Faculty/Course/PaginationLine'
import '../../Faculty/faculty.css'
import userImg from '../../../Images/man.jpg'
import Circle from '../../../Components/Faculty/Profile/Circle'
import Slider from '../../../Components/Faculty/Profile/Slider'
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default  class FacultyProfile extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
      }
      
      render(){
          
        return( 
            <Container  CardContainer>
                <Col md={10} style={{margin:'0 auto'}}>
                    <Card className="p-4">
                        <Row className="mb-1  mt-5 ">
                            <Col className="ProfImgWp text-center">
                            <div className="ImageWp">
                                <img src={userImg} alt="logo" />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="UserName ">
                                John Deo
                            </Col>
                        </Row>
                        <Row>
                            <Col md={9} className="mt-3 mb-5" style={{margin:'0 auto'}}>
                                <Row>
                                    <Col md={3} className="">
                                        <Circle number="2" Labeltext="Course"/>
                                    </Col>
                                    <Col  md={3} className="">
                                        <Circle number="1" Labeltext="Completed"/>
                                    </Col>
                                    <Col md={3} className="">
                                        <Circle number="1" Labeltext="Certification"/>
                                    </Col>
                                    <Col md={3} className="">
                                        <Circle number="08/10" Labeltext="Grade/Points"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider/>

                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md={6} sm={6} xs={12}>
                            </Col>
                            <Col md={6} sm={6} xs={12} className="float-right pgWp">
                                <PaginationLine/>
                            </Col>
                        </Row>
                </Card>
            </Col>
        </Container>
        );
      }
}