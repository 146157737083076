import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {  Nav} from "reactstrap";
import  './SideBar.css';
import logo from '../../../Images/con_logo.png'
import LSubMenu from './LSubMenu'

const LessonSidebar = ({ isOpen, toggle }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  
  const intentFunct = (id) => {
    setActiveIndex(id);
  }

  return(
  <div className={isOpen ? "is-open sidebar LessonHeader" : "sidebar LessonHeader"}>
    <div className="sidebar-header ">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <div className="logowp mt-4 mb-4">
            <img src={logo} alt="logo" />
      </div>
      <div className="headTitle mb-4 mt-5">
          <h3>Java Programming MasterClass For Software Developer..</h3>
      </div>
      <div className="headTitle mb-2">
          <h3>Course Online</h3>
      </div>
    </div>
    <div className="side-menu ">
      <Nav vertical className="list-unstyled pb-3 sideNv">
        <LSubMenu title="Lesson 1 : Introduction" icon={faPlusCircle} items={submenus[0]}  className="mr-3"
         onClick={()=> intentFunct(1)}
         className={activeIndex === 1 ? "setActiveTab" : ""}
        />
        <LSubMenu title="Lesson 2 : First Step" icon={faPlusCircle} items={submenus[1]} className="mr-3"  />
        <LSubMenu title="Lesson 3 : Java Tutorial" icon={faPlusCircle} items={submenus[2]} className="mr-3"  />
        <LSubMenu title="Lesson 4 : Control Flow Statements" icon={faPlusCircle} items={submenus[3]} className="mr-3"  />
        <LSubMenu title="Quiz and Assignments" icon={faPlusCircle} items={submenus[4]} className="mr-3"  />        
      </Nav>
      
    
    </div>
  </div>
  )
};

const submenus = [
    [{ title: "Introduction To Course",target: "Course",},{title: "Remaster In Progress",target: "",},],
    [{title: "Introduction",target: "Introduction",},{ title: "Hello World Project", target: "HellowWorldProject",},
     {title: "Defining Main Method",target: "MonthlyUsage",},{ title: "Hello World  Challenge and Common Errors", target: "MonthlyUsage",},
     {title: "Variables",target: "MonthlyUsage",}],
     [{title: "Expression",target: "Expression",},{ title: "Statements", target: "Statements",},
     {title: "CodeBlocks",target: "CodeBlocks",}],
     [{title: "FlowChart",target: "FlowChart",}],
     [{title: "Quiz 1",target: "FlowChart"},{title: "Quiz 2",target: "FlowChart"}],
  ];
 

export default LessonSidebar;
