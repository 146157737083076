import axios from 'axios';
import {URL} from './Config';
import Auth from './Auth';
import swal from 'sweetalert';

const MediaAxiosApiInstance = axios.create();
let currUser = JSON.parse(localStorage.getItem("userData"));
// Request interceptor for API calls
MediaAxiosApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${currUser.accessToken}`,
      'content-type': 'multipart/form-data'
    }
    return config;
  },
  error => {
    //debugger
    Promise.reject(error)
});

// Response interceptor for API calls
MediaAxiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  //debugger
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
  // debugger
    originalRequest._retry = true;
    return refreshAccessToken()
    .then(response => {
      //debugger
      currUser.accessToken = response.data.accessToken;
      localStorage.setItem("userData", JSON.stringify(currUser));

      // Set default headers to have authorization the access token as authorization for future requests
      axios.defaults.headers.common["Authorization"] =
      "Bearer " + response.data.accessToken;

      // Get the original that failed due to 401 and resend it
      // with the new access token
      const config = error.config;
      config.headers.Authorization =
        "Bearer " + response.data.accessToken;

      // Resending original request
      return new Promise((resolve, reject) => {
        axios.request(originalRequest)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            //debugger
            reject(error);
          });
      });
    }).catch(error => {
      // if refresh token failed logout
      //debugger
      Promise.reject(error);
      swal("You have been logged out!","",'info');
       Auth.logout();
      // window.location.href = "/";
      // swal("You have been logged out.", {
      //   buttons: ["OK"]
      // })
      // .then((result) => {
      //     if(result) {
      //         window.location.href = "/";  
      //     } else {
      //       // alert(result)
      //     }
      // });
    });
  }
  return Promise.reject(error);
});

export default MediaAxiosApiInstance;

const refreshAccessToken = () => {
 // debugger
  let body = JSON.stringify({
    token: currUser.refreshToken
  });
  let headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return new Promise((resolve, reject) => {
    //debugger
    axios.post(`${URL}/token`, body, headers)
      .then(async response => {
        //debugger
        resolve(response);
      })
      .catch(error => {
        //debugger
        swal("You have been logged out!","",'info');
        Auth.logout();
        // swal("You have been logged out.", {
        //   buttons: ["OK"]
        // })
        // .then((result) => {
        //     if(result) {
        //         window.location.href = "/";  
        //     } else {
        //        alert(result)
        //     }
        // });
        reject(error);
      });
  });
}