import React from 'react';
import { Tab, Tabs, Button, Row, Col, TabContainer } from 'react-bootstrap';
import Stud_Forum from './Stud_Forum';
import CourseDetail from './CourseDetail';
import Auth from '../../../ServerCall/Auth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FlyerPage from '../Flyer/flyer';
import { URL } from "../../../ServerCall/Config";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Label } from '@material-ui/icons';
import InfoIcon from "@material-ui/icons/Info";
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import swal from 'sweetalert';
import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons';


export default class TabCourseForum extends React.Component {
	constructor(props) {
		// debugger
		// console.log("Props in TabCourse :",props)
		super(props);
		// let  schedule  = this.props.location.state;
		// if(schedule !== undefined){
		// 	debugger
		//   if(schedule.scheduleId !== undefined){
		// 	var scheId = schedule.scheduleId;
		//   }
		// }
		this.state = {
			key: 'CourseDetail',
			courseId: this.props.match.params.id,
			userId: Auth.getUserId(),
			showNotification: "",
			role: Auth.getUserRole(),
			checkCourseSchedule: true,
			courseScheduleId: null,
			courseType: "",
			showForum: false
			//scheduleId: scheId
		};
		this.handleSelect = this.handleSelect.bind(this);
	}


	getCourseScheduleId = () => {
		//  debugger
		if (this.state.role === "faculty" && this.props.match.params.batchId != undefined) {
			// debugger
			let id = this.props.match.params.batchId;
			this.setState({ courseScheduleId: id })
			// alert(id)
		}
		else {
			axiosApiInstance.get(`${URL}/courseScheduleByCourseIdUser/${this.state.courseId}`)
			.then(res => {
				// debugger;
				// console.log(res)
				if (res.data != "") {
					this.setState({ courseScheduleId: res.data.Course._id })
				}
			})
		}
		// if(this.state.courseScheduleId!=null||this.state.courseScheduleId!=undefined||this.state.courseScheduleId!="")
		// 	{
		// 		this.setState({showForum:true});
		// 	}
		// 	else{this.setState({showForum:false});}
	}

	getCourseDetails = () => {
		// debugger
		const { checkCourseSchedule, courseType } = this.state;
		if (this.state.role === 'faculty') {
			let courseUrl = ``;
			if (this.state.courseScheduleId !== "null") {
				courseUrl = `${URL}/getCourseByCourseIdScheduleId/${this.state.courseId}/${this.state.courseScheduleId}`;
			}
			else {
				courseUrl = `${URL}/courseDetails/${this.state.courseId}`;
			}
			axiosApiInstance.get(courseUrl)
				.then(res => {
					console.log(res.data)
					if (this.state.courseScheduleId != null && this.state.courseScheduleId != "null" && this.state.courseScheduleId != "" && this.state.courseScheduleId != undefined) {
						this.setState({ checkCourseSchedule: res.data.checkCourseSchedule, courseType: res.data.Course2.courseType });
						if (res.data.checkCourseSchedule === true && res.data.Course2.courseType === "Instruction based") {
							this.setState({ showForum: true });
						}
						else if (res.data.checkCourseSchedule === false && res.data.Course2.courseType === "Prerecorded") {
							this.setState({ showForum: true });
						}
						else if (res.data.Course2.checkCourse === false) {
							this.setState({ showForum: false });
						}
						else {
							this.setState({ showForum: false });
						}
					}
					else {
						// debugger
						this.setState({ checkCourseSchedule: res.data.checkCourseSchedule });
						if (res.data.Course1) {
							if (res.data.checkCourseSchedule === true && res.data.Course1.courseType === "Instruction based") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourseSchedule === false && res.data.Course1.courseType === "Prerecorded") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourse === false) {
								this.setState({ showForum: false });
							}
							else {
								this.setState({ showForum: false });
							}
						}
						else {
							if (res.data.checkCourseSchedule === true && res.data.Course2.courseType === "Instruction based") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourseSchedule === false && res.data.Course2.courseType === "Prerecorded") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourse === false) {
								this.setState({ showForum: false });
							}
							else {
								this.setState({ showForum: false });
							}
						}

					}
				})
				.catch(err => { console.log("Error", err) })
		}
		else {
			axiosApiInstance.get(`${URL}/courseDetails/${this.state.courseId}`)
				.then(res => {
					// debugger
					// console.log(res)
					if (this.state.courseScheduleId != null && this.state.courseScheduleId != "" && this.state.courseScheduleId != undefined) {
						this.setState({ checkCourseSchedule: res.data.checkCourseSchedule, courseType: res.data.Course2.courseType });
						if (res.data.checkCourseSchedule === true && res.data.Course2.courseType === "Instruction based") {
							this.setState({ showForum: true });
						}
						else if (res.data.checkCourseSchedule === false && res.data.Course2.courseType === "Prerecorded") {
							this.setState({ showForum: true });
						}
						else if (res.data.checkCourse === false) {
							this.setState({ showForum: false });
						}
						else {
							this.setState({ showForum: false });
						}
					}
					else {
						// debugger
						this.setState({ checkCourseSchedule: res.data.checkCourseSchedule });
						if (res.data.Course1) {
							if (res.data.checkCourseSchedule === true && res.data.Course1.courseType === "Instruction based") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourseSchedule === false && res.data.Course1.courseType === "Prerecorded") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourse === false) {
								this.setState({ showForum: false });
							}
							else {
								this.setState({ showForum: false });
							}
						}
						else {
							if (res.data.checkCourseSchedule === true && res.data.Course2.courseType === "Instruction based") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourseSchedule === false && res.data.Course2.courseType === "Prerecorded") {
								this.setState({ showForum: true });
							}
							else if (res.data.checkCourse === false) {
								this.setState({ showForum: false });
							}
							else {
								this.setState({ showForum: false });
							}
						}

					}
				})
				.catch(err => { console.log("Error", err) })
		}
	}

	// componentDidUpdate(prevState,prevProps){
	// 	debugger
	// 	//if(courseScheduleId !== )
	// }


	// getEnrollmentData=()=>{
	// 	axiosApiInstance.get(`${URL}/getEnrollment`)
	//     .then(res=>{
	// 		// debugger
	//         if(res.status === 200){
	// 			// debugger
	//             let course = res.data.course;
	//             // console.log("getEnrollment",res.data.course);
	//             let  courseData = [];
	//             //debugger
	//             course&&course.map(data =>{
	//                 courseData.push({Id:data.c_id,courseName:data.courseName,percentage_completed:data.percentage_completed,description:data.description,courseType:data.courseType,duration:data.duration,defaultImage:data.imageOrVideoPath.default})
	//               })
	// 			 courseData&&courseData.map(data=>{
	// 				if (data.Id === this.props.match.params.id){
	// 					if(this.state.courseScheduleId!=null && data.courseType==="Instruction based")
	// 					{
	// 						 this.setState({showForum:true})
	// 						 return
	// 					}
	// 					else if(this.state.courseScheduleId===null && data.courseType==="Prerecorded")
	// 					{
	// 						 this.setState({showForum:true})
	// 						 return
	// 					}
	// 					else
	// 					{
	// 						 this.setState({showForum:false})
	// 						 return
	// 					}
	// 				}
	// 			 })


	//         }else{
	//             swal(res.data.message, "", "error")
	//         }
	//     }).catch(err => {
	//         console.log(err)
	//     })
	// }

	updateNotificationStatus = () => {
		const { courseId, userId, showNotification } = this.state;
		let CourseId = courseId;
		let studentId = userId;
		// console.log("Course Id:",CourseId," Student Id:",studentId)
		axiosApiInstance
			.put(`${URL}/updateForumViewStudentIdsArray`, {
				courseId: CourseId,
				courseScheduleId: this.state.courseScheduleId === "null" ? null : this.state.courseScheduleId,
				studentId: studentId
			}).then((res) => {
				//   debugger
				//   console.log(res);
				this.setState({ showNotification: true });
			});
	}

	getNotificationStatus = () => {
		// debugger
		const { courseId, userId, showForum, courseScheduleId } = this.state;
		let scheduleId = courseScheduleId
		if (showForum === true) {
			axiosApiInstance.get(`${URL}/getForumNotification/${courseId}/${userId}/${scheduleId}`)
				.then(res => {
					// debugger
					// console.log("Response from Notification api:", res);
					let statusNotif = res.data.showNotificationIcon;
					const notifStatus = statusNotif;
					if (res.data.status === 404 || res.data.status === 400) {
						return false;
					}
					if (notifStatus === true) {
						this.setState({ showNotification: false });
					}
					else {
						this.setState({ showNotification: true });
					}
				})
				.catch(err => {
					// debugger
					console.log(err)
					clearInterval(this.interval);
					// if(err.resoponse.data.status===400)
					// {
					// 	console.log("Bad Request")
					// 	return false;
					// }
				})
		}
	}

	handleSelect(key) {
		// alert('selected ' + key);
		this.setState({ key });
		if (key === "StudentForum") {
			this.updateNotificationStatus();
		}
	}


	async componentDidMount() {
		// debugger
		const fromNotifications = this.props.location.state;
		if (fromNotifications !== undefined) {
			if (fromNotifications.forumTab !== undefined) {
				// debugger
				await this.setState({ key: fromNotifications.forumTab });
			}
			// else if(fromNotifications.scheduleId !== undefined){
			// 	await this.setState({scheduleId:fromNotifications.scheduleId});
			// }
		}
		await this.getCourseScheduleId();
		await this.getCourseDetails();
		this.interval = setInterval(() => this.getNotificationStatus(), 1000);

	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}


	assignBatch = () => {
		if (this.state.role === "faculty") {
			if (this.props.match.params.batchId != undefined) {
				return this.props.match.params.batchId
			}
			else {
				return null
			}
		}
		else {
			return this.state.courseScheduleId
		}
	}


	render() {

		// 	const switchForum=()=>{
		// 	switch (this.state.showForum) {
		// 		case true:
		// 			return (

		// 				this.state.role==="student"|| this.state.role==="faculty" ?
		// 				(
		// 				  //   <Tab eventKey="StudentForum" title="Student Forum"> 
		// 				  <Tab eventKey="StudentForum" title={
		// 					  <React.Fragment>
		// 						Student Forum
		// 						{this.state.showNotification===true?
		// 								  (<NotificationsIcon fontSize="medium" className="notification-badge" />)
		// 								  // (<InfoIcon fontSize="small" className="notification-badge" />)
		// 								  : null}
		// 					  </React.Fragment>
		// 					}
		// 					mountOnEnter
		// 					unmountOnExit={false}
		// 				  > 
		// 						<Stud_Forum courseId={this.state.courseId}/>
		// 					</Tab>)
		// 					:null
		// 			)
		// 		default:
		// 			return null;
		// 	}
		// }

		return (
			<div>
				<Row>
					<Col className="mb-2">
						{/* <Button className="bluebtn"  onClick={() => this.props.history.push('/CourseSection')}><ArrowBackIcon/>Back</Button> */}
						<Tabs activeKey={this.state.key} className="mb-4" onSelect={this.handleSelect} id="controlled-tab-example" className="StudForumTabs">
							<Tab eventKey="CourseDetail" title="Course Detail">
								<CourseDetail courseId={this.state.courseId} />
								{/* <FlyerPage courseId={this.state.courseId} /> */}
							</Tab>
							{/* {switchForum()} */}
							{
								(this.state.role === "student" && this.state.showForum === true) || this.state.role === "faculty" ?
									(
										//   <Tab eventKey="StudentForum" title="Student Forum"> 
										<Tab eventKey="StudentForum" title={
											<React.Fragment>
												Student Forum
												{(this.state.role === "student" || this.state.role === "faculty") && this.state.showNotification === false ?
													(<NotificationsIcon fontSize="medium" className="notification-badge" />)
													// (<InfoIcon fontSize="small" className="notification-badge" />)
													: null}
											</React.Fragment>
										}
											mountOnEnter
											unmountOnExit={false}
										>
											<Stud_Forum courseId={this.state.courseId} batchId={this.assignBatch()} />
										</Tab>) : null}
						</Tabs>
					</Col>
				</Row>
			</div>
		);
	}
}
