
import React from 'react';
import { Input,FormGroup,Label } from 'reactstrap';

function AnswerOptionTextarea(props) {
  return (
  
    <li className="answerOption textareaList">
      <FormGroup check disabled>
          <Label check>
            <Input type="textarea" name="radio1" id="horizontal-list"
              id={props.index} disabled = {props.disableOption}
              //value={props.answerContent}
              className={(props.selectedAnswer === props.index) ? 'selected-btn' : '' }
              placeholder="Type your answer here"
              //onClick={props.onAnswerSelected}
            />{' '}
           {/* {props.answerContent} */}
          </Label>
        </FormGroup>
    </li>
     
  );

}

export default AnswerOptionTextarea;