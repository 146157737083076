import React from "react";
import './CourseMaster.css'
import { Form, Card, Col, Row, Container } from 'reactstrap';
import moment from 'moment';
import swal from 'sweetalert';

import Table from '../../../Components/Admin/Course/Table'
import '../../../Components/Admin/Course/Table.css'
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";

import { URL } from '../../../ServerCall/Config';
import Auth from '../../../ServerCall/Auth';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

export default class CourseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addmodl: [],
      role: Auth.getUserRole(),
      addModalShow: false,
      selectedValue: '',
      categoryName: '',
      SelectParentCategory: [],
      categoryId: '',
      istrue: false,
      showDelCourse: false,
      columns: [
        { title: "Id", field: "_id", hidden: true },
        { title: "Course Name", field: "courseName" },
        { title: "Fees", render: rowData => <span>{rowData.fees !== null ? "₹ " + rowData.fees : "₹ " + 0}</span> },
        { title: "Duration", field: "duration" },
        { title: "Created Date", field: "createdAt" }
      ],
      Data: [],
    }
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData = () => {
    let user_id = Auth.getUserId();
    var url = '';
    if (this.state.role === "faculty") {
      url = `${URL}/courseByFaculty/${user_id}`;
    }
    else {
      url = `${URL}/allCourse`;
    }
    axiosApiInstance.get(url)
      .then(res => {
        //debugger
        let Courses = res.data.Course;
        // console.log(Courses)
        let Corsedata = [];
        if (res.status == 200) {
          Courses && Courses.map(data => {
            Corsedata.push({ _id: data._id, courseName: data.courseName, fees: data.fees, duration: data.duration, createdAt: moment(data.createdAt).format('YYYY-MM-DD') })
          })
          this.setState({ Data: Corsedata })
        }

      }).catch(err => {
        console.log(err)
      })
  }


  onDelete = (courseId) => {
    const { Data } = this.state;
    console.log(Data);
    axiosApiInstance
      .delete(`${URL}/course/deleteCourseById/${courseId}`)
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res);
          this.getTableData();
          swal("course Deleted Successfully!", "", "success");
        }
        else if (res.data.status === 400) {
          swal(res.data.message, "", "info");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

  };

  render() {
    const { Data, columns } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 h-100">
        <DeleteConfirm
          show={this.state.showDelCourse}
          animation={true}
          rowData={Data}
          id={Data._id}
          name={Data.courseName}
          close={() => this.setState({ showDelCourse: false })}
          deleteHandler={this.onDelete}
        />
        <Card className="shadow p-4 col-md-12 cardHeight">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Course List</h3>
            </Col>
          </Row>
          <Form>
            <Row className="mb-2 ">
              <Col className="tableWp mt-2">
                <Row>
                  <Col className="DashboardTbl">
                    <Table
                      data={Data}
                      pageSize={10}
                      paging={true}
                      columns={columns}
                      search={false}
                      headerStyle={headerStyle}
                      rowStyle={rowStyle}
                      maxBodyHeight="400px"
                      actions={
                        this.state.role === "faculty" ?[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => {
                              this.props.history.push(
                                `/CourseMaster?id=${rowData._id}`
                              );
                            },
                          },
                        ]:
                        [
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => {
                              this.props.history.push(
                                `/CourseMaster?id=${rowData._id}`
                              );
                            },
                          },

                          {
                            icon: "delete",
                            tooltip: "Delete",
                            onClick: (event, rowData) => {
                              this.setState({
                                Data: rowData,
                                showDelCourse: true
                              });
                            },
                          },
                        ]
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }
}
