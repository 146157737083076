import React from "react";
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import ToggleBtnThree from '../../../Components/Student/Lesson/ToggleBtnThree'
import { Player } from 'video-react';


export default  class Lesson extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            Title:'Chapter 1'

        }
    
      }
      render(){
         const{Title} = this.state;
        return( 
                <Container fluid className="LessonWp" >
                    <Row>
                        <Col style={{margin:'0 auto'}}>
                            <ToggleBtnThree/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            <h3 className="text-center">{ Title}</h3>
                        </Col>
                    </Row>
                    <Row className="mb-4 mt-4">
                        <Col>
                            <Player>
                                <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                            </Player>
                        </Col>
                    </Row>
                    <Form>
                    <Row>
                    <Col md={12} >
                        <FormGroup className="pt-3">
                            <Input type="textarea" name="Event Name"  placeholder="add Comment" required/>
                        </FormGroup>
                    </Col>
                    
                    </Row>
                    <Row>
                        <Col className="text-center mt-3">
                        <div  className="center-block text-center">
                            <Button className="text-center mr-3  btnblue" >Mark as Completed</Button>
                            <Button className="text-center " color="danger">Next</Button>
                        </div>
                        </Col>
                     </Row>
                    </Form>
                   
                </Container>
        );
      }
}