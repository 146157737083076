// import { render } from '@testing-library/react'
import React from 'react';
import { Row,Col,Container,Button} from 'react-bootstrap';
import ToysIcon from '@material-ui/icons/Toys';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsIcon from '@material-ui/icons/Settings';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import MostPopularCourses from '../../Student/Course/MostPopularCourses'
import ClientTestimonial from '../../../Containers/Admin/Home/Components/ClientTestimonial'

export default class Home extends React.Component{
    render()
    {
    return(
        <Container fluid>
            <Row>
                <Col md={12} className="HomeBannerWp">
                    <div className="container">
                        <Row>
                            <Col className="pt-5 my-auto centerContent">
                                 <h1 className="text-white text-center text-shadow mt-4">Learn to Code</h1>
                                 <p className=" mx-auto text-white text-shadow mb-48pt text-center clear">Business, Technology and Creative Skills taught by industry experts. Explore a wide range of skills with our professional tutorials.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="BrowseCorsesCol"><Button className="btn browseCorses text-center"> Browse Courses</Button> </Col>
                        </Row>
                    </div>
                </Col> 
            </Row>
            <Row  className="mt-5">
                <Col md={4}>
                    <div className="d-flex col-md align-items-center  border-md-0 mb-16pt mb-md-0 pb-16pt pb-md-0">
                        <div className="rounded-circle bg-primary w-64 h-64 d-inline-flex align-items-center justify-content-center mr-16pt">
                            <i className="material-icons text-white">subscriptions</i>
                        </div>
                        <div className="flex">
                            <div className="card-title  cTitle mb-4pt">8,000+ Courses</div>
                            <p className="card-subtitle subText text-70">Explore a wide range of skills.</p>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                <div className="d-flex col-md align-items-center  border-md-0 mb-16pt mb-md-0 pb-16pt pb-md-0">
                    <div className="rounded-circle bg-primary w-64 h-64 d-inline-flex align-items-center justify-content-center mr-16pt">
                        <i className="material-icons text-white">verified_user</i>
                    </div>
                    <div className="flex">
                        <div className="card-title cTitle mb-4pt">By Industry Experts</div>
                        <p className="card-subtitle subText text-70">Professional development from the best people.</p>
                    </div>
                </div>
                </Col>
                <Col md={4}>
                <div class="d-flex col-md align-items-center">
                                <div className="rounded-circle bg-primary w-64 h-64 d-inline-flex align-items-center justify-content-center mr-16pt">
                                    <i className="material-icons text-white">update</i>
                                </div>
                                <div className="flex">
                                    <div className="card-title mb-4pt">Unlimited Access</div>
                                    <p className="card-subtitle text-70">Unlock Library and learn any topic with one subscription.</p>
                                </div>
                            </div>
                </Col>
                
            </Row>
            <Row className="mt-5">
                <Col className="text-center">
                    <h4 clasName="text-center categoryText"> Our Course Categories</h4>
                    <p className="Ptext pl-5 pr-5">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a pi classical 
                     Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,</p>
                </Col>
            </Row>
            <Row>
                <Col md={1}></Col>
                <Col md={2}>
                    <div className="single-course-categories">
                        <div className="ItemInner">
                            <div class="item-inner">
                                <div class="cours-icon">
                                    <ToysIcon/>
                                </div>
                                <div class="cours-title">
                                    <h5>Photoshop</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="single-course-categories">
                            <div className="ItemInner">
                                <div class="item-inner">
                                    <div class="cours-icon">
                                        <SettingsEthernetIcon/>
                                    </div>
                                    <div class="cours-title">
                                        <h5>Web Design</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Col>
                <Col md={2}>
                <div className="single-course-categories">
                            <div className="ItemInner">
                                <div class="item-inner">
                                    <div class="cours-icon">
                                        <SettingsIcon/>
                                    </div>
                                    <div class="cours-title">
                                        <h5>Web Development</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Col>
                <Col md={2}>
                <div className="single-course-categories">
                            <div className="ItemInner">
                                <div class="item-inner">
                                    <div class="cours-icon">
                                        <SubscriptionsIcon/>
                                    </div>
                                    <div class="cours-title">
                                        <h5>Media</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Col>
                <Col md={2}>
                    <div className="single-course-categories">
                                <div className="ItemInner">
                                    <div class="item-inner">
                                        <div class="cours-icon">
                                            <SettingsEthernetIcon/>
                                        </div>
                                        <div class="cours-title">
                                            <h5>.NET</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row>
                <Col className="text-center"> <Button className="btn mt-5 pl-4 pr-4"> All Courses</Button></Col>
            </Row>

        <Row>
            <Col className="text-center mt-5 mb-5">
                <h4 clasName="text-center categoryText">Most Popular Courses</h4>
            </Col>
        </Row>
        <Row>
            <Col>
                <MostPopularCourses />
            </Col>
        </Row>
        <Row className="mt-5">
            <Col>
                <ClientTestimonial/>
            </Col>
        </Row>
        </Container>    

    )
}
}