import React from "react";
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import axios from "axios";
import {URL} from '../../../ServerCall/Config';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from '../../../Components/Common/Validation/captchaModal'
import {ValidateEmail, Validatephone} from '../../../Components/Common/Validation/Validation'
import logo from '../../../Images/con_logo-old.png'

import swal from 'sweetalert';
import {Link} from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { Modal } from 'react-bootstrap';
import Loader from '../../../Components/Common/Loader/Loader';


const config = {
    headers: {
        'content-type': 'application/json'
    }
};
const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

export default class Registration extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedGender : null,
            addmodl:[],
            addModalShow:false ,
            StudentId:'',
            loading:false,
            Studentdata:{
                firstName:'',
                middleName:'',
                lastName:"", 
                gender:'',
                email:'',
                password:'',
                contactNumber:'',
                address:'',
                state:'',
                city:'',
                pinCode:'',
                country:''
            },
            formErrors:{ 
                email:"",
              
            },
            SelectGender:[
                {value: "Male", text: "Male"},
                {value:  "Female", text: "Female"},
                {value: "Other", text:"Other"}
            ] ,
            LabelText:{
                firstName:'',
                middleName:'',
                lastName:"", 
                gender:'',
                email:'',
                password:'',
                contactNumber:'',
                state:'',
                city:'',
                pinCode:'',
                country:'',
                selectedGender:null
            },
        }
        this.handleChange = this.handleChange.bind(this);
    }

    selectedGender = (event) =>{
        let {name,value} = event.target;
        this.setState({[name]: value})
    }

    formvalid = () =>{
        let valid = true;
        let {Studentdata,LabelText} = this.state
       validEmailRegex.test(Studentdata.email)? LabelText.Studentdata.email ="":LabelText.Studentdata.email = "Valid email address Req.";
       // username !== ''?formErrors.username ="":formErrors.username = "Please enter email";
       this.setState({LabelText:LabelText});
        Object.values(LabelText).forEach(val =>{
            val !== "" && (valid = false);
        }) ;
        return valid;
    }

    handleChange (event) {
            //alert(event.target.value)
            let {Studentdata,LabelText} = this.state
            Studentdata[event.target.name] = event.target.value;
            const { name,value} = event.target;
            this.setState({Studentdata:Studentdata})
            //LabelText.email = validEmailRegex.test(value) ? "":  "Valid email address Req."
            this.setState({[name]: value});
            switch(name){
                case 'email':
                LabelText.email=  validEmailRegex.test(value)?"":"Valid email address Req.";
                //formErrors.username = value !=""? "":"Email Req.";
                break;
            }
            this.setState({ LabelText: LabelText});
            
    }
    componentDidMount () {
        loadCaptchaEnginge(6);
        console.log(this.props.location.search);
        if (this.props.location.search != "") {
          let param = this.props.location.search;
          const id = param.toString().slice(1);
          this.setState({ courseId: id });
          localStorage.setItem("courseId", id);
          console.log(id);
        }
     };
  
    validate=()=>{
        // debugger
        let {Studentdata,LabelText} = this.state;
        let valid = true;
       
        if (Studentdata.firstName !=="" && Studentdata.firstName !== null) {	
            LabelText.firstName = "";
            if (!Studentdata.firstName.match(/^[a-zA-Z ]*$/)) {
                LabelText.firstName = "*Please Enter only Character.";
              }
            }
        else{
            LabelText.firstName  = "Please Enter First Name";
        }
        // if (Studentdata.middleName !=="" && Studentdata.middleName !== null) {	
        //     LabelText.middleName = "";
        //     if (!Studentdata.middleName.match(/^[a-zA-Z ]*$/)) {
        //         LabelText.middleName = "*Please Enter only Character.";
        //       }
        //     }
        // else{
        //     LabelText.middleName  = "Please Enter Middle Name";
        // }
        if (Studentdata.lastName !=="" && Studentdata.lastName !== null) {	
            LabelText.lastName = "";
            if (!Studentdata.lastName.match(/^[a-zA-Z ]*$/)) {
                LabelText.lastName = "*Please Enter only Character.";
              }
            }
        else{
            LabelText.lastName  = "Please Enter Last Name";
        }
        let isEmailValid=ValidateEmail(Studentdata.email);
            if (isEmailValid) {
                LabelText.email = "";
                //validEmailRegex.test(Studentdata.email)?formErrors.email ="":formErrors.email = "Valid email address Req.";
            }
            else if(Studentdata.email!==""){
                LabelText.email="Invalid Email"; 
            }
            else{
                LabelText.email = "Please Enter Email";
            }
            if (Studentdata.password !==""&& Studentdata.password !== null) {	
                LabelText.password = "";
                // if (!Studentdata.password.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
                //   LabelText.password = "*Please enter secure and strong password.";
                // }
              }
              else{
                LabelText.password = "Please Enter Password";
              }
            let isContactNoValid=Validatephone(Studentdata.contactNumber);
            if (isContactNoValid) {	
                LabelText.contactNumber = "";
                // if (!Studentdata.contactNumber.match(/^[0-9]{10}$/)) {
                //     LabelText.contactNumber = "*Please Enter only Number.";
                //   }
            }
            // else if(Studentdata.contactNumber!==""){
            //     LabelText.contactNumber="please enter only 10 digits"; 
            // }
            else{
                LabelText.contactNumber = "Please Enter Valid Contact Number";
              }
        if (Studentdata.state !=="" && Studentdata.state !== null  ) {	
            LabelText.state = "";
            if (!Studentdata.state.match(/^[a-zA-Z ]*$/)) {
                LabelText.state = "*Please Enter only Characters";
              }
            
            }
        else{
            LabelText.state  = "Please Enter State";
        }
        if (Studentdata.city !=="" && Studentdata.city !== null) {	
            LabelText.city = "";
            if (!Studentdata.city.match(/^[a-zA-Z ]*$/)) {
                LabelText.city = "*Please Enter only Characters";
              }
            
            }
        else{
            LabelText.city  = "Please Enter City";
        }
        if (Studentdata.pinCode !=="" && Studentdata.pinCode !== null) {	
            LabelText.pinCode = "";
            // if (!Studentdata.pinCode.match(/^[0-9a-zA-Z(\-)]+$/)) {
            //     LabelText.pinCode = "*Please Enter Valid Pincode";
            //   }
            
            }
        else{
            LabelText.pinCode  = "Please Enter Pincode";
        }
        if (Studentdata.country !=="" && Studentdata.country !== null) {	
            LabelText.country = "";
            if (!Studentdata.country.match(/^[a-zA-Z ]*$/)) {
                LabelText.country = "*Please Enter only Characters";
              }
            }
        else{
            LabelText.country  = "Please Enter Country";
        }
        if ( this.state.selectedGender !== null) {
            if(this.state.selectedGender == "Select Gender"){
                LabelText.selectedGender = "Please select Gender"
            }
            else{
                LabelText.selectedGender = null;
            }
          }
          else{
            LabelText.selectedGender = "Please select Gender"
          }
        this.setState({LabelText: LabelText});
        if(LabelText.firstName===""&& LabelText.middleName==="" &&LabelText.lastName===""&&LabelText.email===""&&LabelText.selectedGender===null
        &&LabelText.contactNumber===""&&LabelText.city===""&&LabelText.state===""&&LabelText.pinCode===""&&LabelText.country==="")
        {
            return true;
        }
        else
        {
            return false;
        }
        // Object.values(formErrors).forEach(val =>{
        //     val !== "" && (valid = false);
        // }) ;
        // return valid;
    }

    onClickStudentData =()=>{
        this.setState({loading:true})
        const isValid = this.validate( this.state.formErrors);
        //debugger
        let user_captcha = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha)===true) {
            if(isValid){
                const {Studentdata, selectedGender} = this.state;
                Studentdata.gender = selectedGender;
                axios.post(`${URL}/addStudent`,Studentdata,config)
                .then(response =>{
                    //debugger
                    this.setState({loading:false})
                    const res = response.data;
                    if(response.status == 200) {

                        if(response.data.status === 409){
                            this.setState({loading:false})
                            swal("User with this email Id already exist","","info");
                        }
                        else{
                            this.setState({loading:false})
                            // swal("Registration Successful", "", "success")
                            // .then((result) => {
                            //   if(result) {
                            //       this.props.history.push("/")
                            //   } else {
                            //     // not clicked
                            //   }
                            // });
                                let {firstName, lastName} = this.state.Studentdata;
                                swal("Registered Successfully","","success",{buttons: {
                                    cancel: "Close",
                                    Login: "Login",
                                }}).then((value) => {
                                    this.setState({loading:false})
                                    switch (value) {
                                        case "Login":
                                            if (this.props.location.search != "") {
                                                let param = this.props.location.search;
                                                const id = param.toString().slice(1);
                                                console.log(id);
                                                this.props.history.push("/?" + "id");
                                                swal(`${firstName} ${lastName} `,`Welcome to Softlabs Academy`,{button:{
                                                    text: "OK"
                                                }});
                                            }else{
                                                this.props.history.push("/");
                                                swal(`${firstName} ${lastName} `,`Welcome to Softlabs Academy`,{button:{
                                                    text: "OK"
                                                }});
                                            }
                                            

                                        break;
                                
                                        default:
                                        this.clearForm();
                                    }   
                                });
                            }
                    }else{   
                        this.setState({loading:false});
                        swal("Something went wrong","", "error");  
                        
                    }
                })
                .catch(err=>{
                    this.setState({loading:false})
                    console.log(err)
                });

                loadCaptchaEnginge(6); 
                document.getElementById('user_captcha_input').value = "";
            }
        }
        else {
            this.setState({loading:false})
            swal("Incorrect captcha entered","","error");
            document.getElementById('user_captcha_input').value = "";
        }
    
    }
   
    onClickCancel = () => {
        swal("Are you sure you want to clear?", {
            buttons: ["No", "Yes"],
        })
        .then((result) => {
            if(result) {
                this.clearForm();
                this.setState({loading:false})
            } else {
               // alert(result)
            }
        });
   }

   clearForm = () =>{
    let {Studentdata, formErrors} = this.state;
    Studentdata.firstName = "";
    Studentdata.firstName ="";
    Studentdata.middleName ="";
    Studentdata.lastName ="";
    this.state.selectedGender=null;
    Studentdata.email ="";
    Studentdata.password ="";
    Studentdata.contactNumber ="";
    Studentdata.address ="";
    Studentdata.state ="";
    Studentdata.city ="";
    Studentdata.pinCode ="";
    Studentdata.country ="";
    formErrors.email ="";
    this.state.LabelText={
        firstName:'',
        middleName:'',
        lastName:"", 
        gender:'',
        email:'',
        password:'',
        contactNumber:'',
        state:'',
        city:'',
        pinCode:'',
        country:'',
        selectedGender:null
    };
    this.setState({Studentdata: Studentdata, formErrors: formErrors, LabelText: this.state.LabelText, StudentId:0});
   }


render(){
 
  const{SelectGender, selectedGender, Studentdata,
    LabelText} =this.state;
    return(

    <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
       <Card className="shadow p-4 col-md-12">
       {this.state.loading ?
          <Loader/>
          : <div></div>
        }
       <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
        <Row>
            <Col className="mb-4">
                <div className="logoWp">
                    <img src={logo} alt="logo" />
                </div>
            </Col>
        </Row>
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Registration</h3>
         </Col>
       </Row>
       <Form>
        <Row>
           <Col md={4}>
            <FormGroup>
              <Label>First Name </Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="firstName"  placeholder="" onChange={this.handleChange} value={Studentdata.firstName}/>
                {LabelText.firstName !== "" ?
                <Label style={{ fontSize: 12, color: "red" }}>
                {LabelText.firstName }</Label >: null
			    }
            </FormGroup>
            
           </Col>
           <Col md={4}>
            <FormGroup>
              <Label>Middle Name</Label>
                <Input type="text" name="middleName"  placeholder="" onChange={this.handleChange} value={Studentdata.middleName}/>
                {LabelText.middleName !== "" ?
                <Label style={{ fontSize: 12, color: "red" }}>
                {LabelText.middleName }</Label >: null
			    }
            </FormGroup>
           </Col>
           <Col md={4}>
            <FormGroup>
              <Label>Last Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="lastName"  placeholder="" onChange={this.handleChange} value={Studentdata.lastName}/>
                {LabelText.lastName !== "" ?
                <Label style={{ fontSize: 12, color: "red" }}>
                {LabelText.lastName }</Label >: null
			    }
            </FormGroup>
           </Col>
        </Row>
        <Row>
        <Col md={6}>
           <FormGroup>
           <Label>Select Gender</Label><span className="requiredFieldSpan">*</span>
            <select id="Dropdown" name="selectedGender" className="form-control" value={selectedGender}
             onChange={(e) => this.selectedGender(e)}
            >
            <option>Select Gender</option>
            {
              SelectGender && SelectGender.map(select => {
                  return (
                      <option  value={select.value} >
                        {select.text}
                      </option> 
                  )
              })
            }
          </select>
          {LabelText.selectedGender !== null ?
            <Label style={{ fontSize: 12, color: "red" }}>
            {LabelText.selectedGender }</Label >: null
          }
          </FormGroup>
           </Col>
           <Col md={6}>
                <FormGroup>
                <Label>Contact  No</Label><span className="requiredFieldSpan">*</span>
                    <Input type="number" name="contactNumber"  placeholder="" onChange={this.handleChange} value={Studentdata.contactNumber} />
                    {LabelText.contactNumber !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.contactNumber }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
           
            <Col md={6}>
                <FormGroup style={{position:'relative'}}>
                <Label>Email</Label><span className="requiredFieldSpan">*</span>
                    <Input type="Email" name="email"  placeholder="" onChange={this.handleChange} value={Studentdata.email}/>
                    <span className="InfoSpan"><InfoIcon/></span>
                    <div className="validationGuide">
                        <span>1.Start with a character (a-z), an underscore (_), or a number (0-9)) <br/></span>
                        <span>2.Email Must having @ Sign. </span><br/>
                        {/* <span>3.Digits from 0 to 9.</span><br/>
                        <span>4.Special characters such as ! # $ %  ' * + - / = ? ^ _ ` </span> */}
                    </div>
                    {LabelText.email !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.email }</Label >: null
                    }
                </FormGroup>

            </Col>
            <Col md={6}>
                <FormGroup style={{position:'relative'}}>
                <Label>Password</Label><span className="requiredFieldSpan">*</span>
                    <Input type="Password" name="password"  placeholder="" onChange={this.handleChange} value={Studentdata.password}/>
                        <span className="InfoSpan"><InfoIcon/></span>
                        <div className="validationGuide">
                            <span>1. At least 8 characters—the more characters <br/></span>
                            <span>2. A mixture of both uppercase and lowercase letters..<br/> </span>
                            {/* <span>3. Inclusion of at least one special character,e.g.,! @ # ?] <br/></span>
                            <span>4. A mixture of letters and numbers.</span> */}
                        </div>
                    {LabelText.password !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.password }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col>
          <FormGroup>
            <Label>Address</Label><span className="requiredFieldSpan">*</span>
            <Input type="textarea" name="address" id="Address" onChange={this.handleChange} value={Studentdata.address} />
          </FormGroup>
          </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>City</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="city"  placeholder="" onChange={this.handleChange} value={Studentdata.city}/>
                    {LabelText.city !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.city }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Pincode</Label>
                    <Input type="text" name="pinCode"  placeholder="" onChange={this.handleChange} value={Studentdata.pinCode}/>
                    {LabelText.pinCode !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.pinCode }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>State</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="state"  placeholder="" onChange={this.handleChange} value={Studentdata.state}/>
                    {LabelText.state !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.state }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Country</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="country"  placeholder="" onChange={this.handleChange} value={Studentdata.country}/>
                    {LabelText.country !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.country }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
             <div  className="center-block text-center">
             <div className="form-group">
                              <div className="col mt-3">
                                  <LoadCanvasTemplate  />
                              </div>
                              <div className="col md-3">
                                  <div><Input className="captcha-input-box" placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text"/></div>
                              </div>
                              <br/>
                          </div>
              <Button className="text-center mr-3 btnblue"  onClick={this.onClickStudentData}>Submit</Button> 
              <Button className="text-center mr-3" color="danger"  onClick={()=>{this.onClickCancel()}}>Clear</Button>
              <Link to={"/"}><Button className="text-center " color="danger">Cancel</Button></Link> 
            </div>
          </Col>
        </Row>
        <Row>
            <Col className="forgttxt mt-3 text-center">
                Already have an account ?  <a href="/"><b>Login </b></a>now
            </Col>
        </Row>
        </Form>
        </Card>
    
       </Card>
    </Container>
    );
}
}
