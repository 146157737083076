class Auth {
    constructor() {
        this.user_token = JSON.parse(localStorage.getItem('userData'))||{}
    }
    getToken() {
        return this.user_token.accessToken
    }
    getUserId() {
        return this.user_token.user_id
    }
    getUserRole(){
        return this.user_token.role
    }
    getUserName(){
        return this.user_token.fullName
    }
    getUserProfilePic(){
        return this.user_token.profileImage
    }
    setUserToken(new_token) {
        this.user_token = new_token
        localStorage.setItem('auth', JSON.stringify(new_token))
    }
    logout() {
        localStorage.clear('userData');
        window.location.href = "/";
    }
}
export default new Auth()