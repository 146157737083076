import React from "react";
import { Form,Card, FormGroup, Label, Input,Col,Row,Container,Button} from 'reactstrap';
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import StudentQuestionModal from '../../../Components/Faculty/Question/StudentQuestionModal'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
// import moment from 'moment';
import ReplyIcon from '@material-ui/icons/Reply';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}

export default  class StudentQuestion extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        addModalShow:false ,
        istrue:false,
        answer:"",
        lessonId:null,
        chapterId:null,
        chapterName:null,
        question:'',
        answer:'',
        questionId:"",
        columns: [  
          {title :"ID", render:rowData => <span>{rowData.tableData.id+1}</span>},
          { title: "Id", field: "_id", hidden: true},
          { title: "Student Name", field: "studentName"},
          { title: "Course Name", field: "courseTitle"},
          { title: "Chapter Name", field: "chapterName"},
          { title: "Lesson Name", field: "lessonName"},
          { title: "Question", field: "question"},
          { title: "Status", field: "status", render: rowData => <span>{rowData.status ? "Answered" : "Unanswered"}</span>},
          //{ title: "createdDate", field: null,render: (rowData) => moment(rowData.createdAt).format('YYYY-MM-DD')}
        ],
        Data : [],
      }

     }

    onClickSubmit = e =>{
        //debugger
        e.preventDefault();
        const {answer} = this.state
        let questionId = this.state.questionId;
        let status = "true";
        axiosApiInstance.put(`${URL}/askQuestion/${questionId}`,{answer,status})
        .then(response =>{
          //debugger
          //const res = response.data.event;
          if(response.status == 200){
            swal("Answer Updated", "", "success")
            .then((result) => {
              if(result) {
                this.setState({addModalShow:false});
              }
            });
            this.onClickCancel();
          }else{   
            swal(response.data.message, "error"); 
          }
        }).catch(err => {
          console.log(err);
        });  
     }

    onClickCancel = () =>{
        this.setState({addModalShow: false,answer:""});
     }

    handleChange = e =>{
          this.setState({
            [e.target.name]: e.target.value
          });
     }

    onEdit =async(editquestionId,editQuestion,editAnswer)=>{
      //alert("On Edit Called " + editAnswer); 
      await this.setState({addModalShow:true});
      await this.setState({questionId:editquestionId});
      await this.setState({question:editQuestion});
      await this.setState({answer:editAnswer});
    
     }

     getTableData () {
      axiosApiInstance.get(`${URL}/getAskQuestionByUser`)
      .then(res =>{
        //debugger
          let AskQuestion = res.data.AskQuestion;
          if(res.status === 200){
              this.setState({Data: AskQuestion});
          }
      }).catch(err => {
        console.log(err)
      });
  }

   async componentDidMount (){
      await axiosApiInstance.get(`${URL}/allCourse`)
      .then(res =>{
          let Courses = res.data.Course;
          //console.log(Courses)
          let  Corsedata = [];
          if(res.status == 200)
          {
            // Courses&&Courses.map(data =>{
            //     Corsedata.push({_id:data._id,courseName:data.courseName,fees:data.fees,duration:data.duration,createdAt:moment(data.createdAt).format('YYYY-MM-DD')})
            // })
            this.setState({Data:Courses});
          // console.log(Corsedata)
          }
        }).catch(err=>{
          console.log(err)
        });
        await this.getTableData();
     }

    render(){
      const{Data,columns,answer,question} =this.state;
      let addModalClose=() => this.setState({addModalShow:false, answer:""});
        return(
        <Container fluid className="CouseMasterWp FormWp h-100">
          <Card className="shadow p-4 col-md-12 h-100">
        <Row>
          <Col md={6}>
              <FormGroup>
                <StudentQuestionModal 
                    question={question} answer={answer}  handleChange={this.handleChange}
                    onClickSubmit={this.onClickSubmit} onClickCancel={this.onClickCancel}
                    show={this.state.addModalShow} onHide={addModalClose}
                  />
              </FormGroup>
              </Col> 
          </Row>
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Student Questions</h3>
            </Col>
          </Row>
          <Form>
            <Row className="mb-2 ">
              <Col className="tableWp mt-2">
                    <Row>
                      <Col className="DashboardTbl">
                        <Table data={Data} pageSize={10} paging={true} columns={columns}search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='400px' 
                        actions={
                          [
                            {
                              icon: () => <ReplyIcon/>,
                              tooltip: 'Reply',
                              onClick: (event, rowData) => {
                                //alert("Edit Action Clicked " + rowData.answer)
                                this.onEdit(rowData._id,rowData.question,rowData.answer)
                              }
                            }
                          ]
                        }
                        />
                      </Col>
                  </Row>            
              </Col>
            </Row>
          </Form>
          </Card>
        </Container>
      );
     }
} 
