import React from 'react';
import { Container,Row,Col, CardText,Button } from 'reactstrap';
import '../../../Containers/Student/student.css'

const QuestionCount = (props) => {
  return (
    <Container className="Qcount mb-5">
        <Row className="countRow">
            <Col className="pl-0 pb-2">
                <h3 className="qcounttxt">1201 Questions in this Course</h3>
            </Col>
        </Row>
        <Row  className="countRow2">
            <Col md={2} sm={4} xs={6} className=" mt-2">
              <div className="studProfImgWp"></div>
            </Col>
            <Col md={10} sm={4} xs={6} className="qcontTxt">
              <CardText className="hdrtxt">String</CardText>
              <CardText>How To reverse string in java</CardText>
              <CardText className="mb-3 lecDetail"><span className="pr-2">David </span> <span className="pr-2">Lecture 51</span> <span className="pr-2">17hours ago</span></CardText>
            </Col>
        </Row>
        <Row>
          <Col>
            <Button className="w-100 btnblue mt-4">See More</Button>
          </Col>
        </Row>
    </Container>
  );
}

export default QuestionCount;