export const ValidateEmail = (email) => {
    //debugger
    if(email==="")
    {
        return false;
    }else{
        const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);
        return   validEmailRegex.test(email);
    }

}

export const Validatephone = (phone) => {
    //debugger
   {
   const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
   
        return   regex.test(phone);
    }

}

