import React from "react";
import {Modal,Form, Button,Container,Row,Col,FormGroup} from 'react-bootstrap'
import 'react-dropzone-uploader/dist/styles.css'

const StudentQuestionModal=({
    answer,
    show,
    onHide,
    onClickSubmit,
    onClickCancel,
    handleChange,
    question
    }) =>  {
    return(
      <Modal 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modalWp"show={show}
          onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Reply 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container className="FormWp">
            <Row>
              <Col>
                <Form>
                  <Row>
                      <Col>
                          <FormGroup>
                              <Form.Label>Asked Question</Form.Label>
                              <Form.Control type="text" name="question" id="student_Question" onChange={handleChange}  value={question} disabled />
                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                        <Form.Group>
                            <Form.Label>Reply Answer</Form.Label>
                            <Form.Control as="textarea"  name="answer" onChange={handleChange} value={answer} id="answer_Reply" rows="3" />
                        </Form.Group>
                      </Col>
                  </Row>
                    <Row>
                        <Col className="text-center mt-3">
                          <div  className="center-block text-center">
                            <Button className="text-center mr-3" onClick={onClickSubmit}>Submit</Button>
                            <Button className="text-center " variant="danger"  onClick={onClickCancel}>Cancel</Button>
                          </div>
                        </Col>
                    </Row>
                </Form>
              </Col>
            </Row>
          </Container>
          </Modal.Body>
        </Modal>  
    )
}

export default StudentQuestionModal




