import React from 'react';
import { Progress } from 'reactstrap';

const Progressbar = (props) => {
  return (
    <div>
      <div className="text-center" style={{ fontSize:'12px'}}>{props.percentage_completed}% Complete</div>
      <Progress value={props.percentage_completed} />
    </div>
  );
};

export default Progressbar;
