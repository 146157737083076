import React from 'react';
import { Input,FormGroup,Label } from 'reactstrap';

function AnswerOption(props) {
  return (  
    <li className="answerOption">
      <FormGroup check disabled>
        <Label check>
          <Input type="radio" name="radio1" id="horizontal-list"
            // disabled = {props.disableOption}
            id={props.index}
            value={props.answerContent}
            // name={props.answerContent}
            // className={(props.selectedAnswer === props.index) ? 'selected-btn' : '' }
            onClick={props.onAnswerSelected}
          />{' '}
          {props.answerContent}
        </Label>
      </FormGroup>
    </li>
  );
}

export default AnswerOption;