import React from "react";
import "../Course/CourseMaster.css";
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";
import ViewImage from "../../../Components/Common/ViewImage.js";
import {  Form,  Card,  FormGroup,  Label,  Input,  Col,  Row,  Container,  UncontrolledTooltip,  Button,  ListGroup,  ListGroupItem} from "reactstrap";
import Table from "../../../Components/Admin/Course/Table";
import TextEditor from "../../../Components/Common/TextEditor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../Components/Admin/Course/Table.css";
import SimpleDropZone from "../../../Components/Common/SimpleDropZone";
import Loader from "../../../Components/Common/Loader/Loader";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import moment from "moment";
import { URL, mediaUrl } from "../../../ServerCall/Config";
import MediaAxiosApiInstance from "../../../ServerCall/MediaAxios.Instance";
import swal from "sweetalert";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";

const headerStyle = {
  backgroundColor: "#4c8db5",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

export default class NewsEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsId: 0,
      editorText: "",
      loading: false,
      newsImages: [],
      showDelModal: false,
      showDelNews: false,
      fileUrl: [],
      show: false,
      rowData: "",
      newsdata: {
        newsTitle: "",
        newsDate: new Date(),
        istrue: false,
      },
      LabelText: {
        newsTitle: "",
        editorText: "",
        news_file: "",
        newsDate: "",
        istrue: false,
      },
      columns: [
        // { title: "Id", field: "id" , hidden: true },
        {
          title: "Sr No.",
          render: (rowData) => <span>{rowData.tableData.id + 1}</span>,
        },
        { title: "Title", field: "newsTitle" },
        { title: "Date", field: "newsDate", type: "date" },
        {
          title: "Discription",
          render: (rowData) => (
            <p
              className="Coursedescription"
              dangerouslySetInnerHTML={{ __html: rowData.description }}
            />
          ),
        },
      ],
      Data: [],
      isSubmit: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let { newsdata } = this.state;
    newsdata[event.target.name] = event.target.value;
    this.setState({ newsdata: newsdata });
    //alert(this.state.newsdata.title)
  }

  getTableData =()=> {
    axiosApiInstance
      .get(`${URL}/news`)
      .then((res) => {
        //debugger
        let Categories = res.data.news;
        if (res.status === 200 || res.status === 204) {
          this.setState({ Data: Categories });
        }
        // console.log("TData", this.state.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentDidMount() {
    this.getTableData();
  }

  // async componentWillUpdate(){
  //   await this.setState({isSubmit:false});
  // }

  handleEditorChange = (editorText) => {
    // debugger
    this.setState({ editorText: editorText });
  };
  Validation = () => {
    //debugger
    let { newsdata, LabelText, editorText, fileUrl, newsImages } = this.state;
    if (editorText !== "") {
      LabelText.editorText = "";
    } else {
      LabelText.editorText = "Please Enter Description";
    }
    if (newsdata.newsTitle !== "" && newsdata.newsTitle !== null) {
      LabelText.newsTitle = "";
    } else {
      LabelText.newsTitle = "Please Enter News Title";
    }
    if (newsdata.newsDate !== "" && newsdata.newsDate !== null) {
      LabelText.newsDate = "";
    } else {
      LabelText.newsDate = "Please Enter News Date";
    }
    newsImages.length || fileUrl.length > 0
      ? (LabelText.news_file = "")
      : (LabelText.news_file = "Please upload files!");
    this.setState({ LabelText: LabelText });
    if (
      LabelText.newsTitle === "" &&
      LabelText.newsDate === "" &&
      LabelText.news_file === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  onClickNewsData = () => {
    const isValid = this.Validation();
    if (isValid) {
      let newsDate = new Date(this.state.newsdata.newsDate).toISOString();
      const { newsTitle } = this.state.newsdata;
      const { editorText, fileUrl } = this.state;
      //debugger
      this.setState({ loading: true });
      const formDataimage = new FormData();
      fileUrl.forEach((item) => {
        formDataimage.append("photos", item.file);
      });
      formDataimage.append("name", newsTitle);
      //debugger
      MediaAxiosApiInstance.post(
        `${mediaUrl}/multiple-image`,
        formDataimage
      ).then((res) => {
        //debugger
        if (res.status == 200) {
          var image = [];
          res.data.data &&
            res.data.data.map((img) => {
              image.push({ url: img.url });
            });
        }
        axiosApiInstance
          .post(`${URL}/news`, {
            newsTitle,
            newsDate,
            description: editorText,
            fileUrls: image,
          })
          .then((response) => {
            // console.log(response.data);
            const res = response.data.category;
            if (response.status == 201) {
              this.setState({ loading: false });
              swal("News Added", "", "success");
              this.getTableData();
              this.setState({ isSubmit: true });
              this.onClickCancel();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };
  onClickView = () => {
    let url = this.state.rowData;
  };

  deleteMedia = () => {
    let url = this.state.rowData;
    let name = ``;
    if (mediaUrl === "https://lmsmedia.softlabsgroup.in") {
      name = `${url.slice(38)}`;
    } else {
      name = `${url.slice(26)}`;
    }
    let data = { name: name };
    //console.log(name, "is", typeof name);
    axiosApiInstance.post(`${mediaUrl}/delete-file`, data).then((res) => {
      // console.log(res.data);
    });
  };

  onClickRemove = () => {
    // debugger;
    let url = this.state.rowData;
    let name = ``;
    var getLocation = function (href) {
      var l = document.createElement("a");
      l.href = href;
      return l;
    };
    var host = getLocation(url);
    if (host.hostname === "lmsmedia.softlabsgroup.in") {
      name = `${url.slice(38)}`;
    } else {
      name = `${url.slice(26)}`;
    }
    let data = { name: name };
    //console.log(name, "is", typeof name);
    axiosApiInstance
      .post(`${mediaUrl}/delete-file`, data)
      .then((res) => {
        //debugger;
        // console.log(res);
        if (res.data.fileName !== "null") {
          var newsImages = [];
          this.state.newsImages &&
            this.state.newsImages.map((data) => {
              newsImages.push({ url: data.url });
            });
          newsImages &&
            newsImages.map((data) => {
              if (data.url === url) {
                var indexOfImage = newsImages.findIndex(
                  (a) => a.url === data.url
                );
                newsImages.splice(indexOfImage, 1);
              }
            });
          this.setState({ newsImages: newsImages });
        } else {
          swal("Something went Wrong", res.message, "Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  getNewsImages = () => {
    const { newsImages } = this.state;
    var newsImagesArr =
      newsImages &&
      newsImages.map((data, i) => {
        return (
          <Col xs="2" key={i}>
            <Col>
              <Row>
                <IconButton
                  onClick={() =>
                    this.setState({
                      showDelModal: true,
                      rowData: data.url,
                    })
                  }
                >
                  <Cancel />
                </IconButton>
              </Row>
              <Row>
                <img
                  src={data.url}
                  onClick={() =>
                    this.setState({ show: true, rowData: data.url })
                  }
                  id="image"
                  style={{ width: "100%" }}
                />
              </Row>
            </Col>
          </Col>
        );
      });
    return newsImagesArr;
  };

  onClickCancel = () => {
    let { newsdata, LabelText } = this.state;
    newsdata.newsTitle = "";
    newsdata.newsDate = new Date();
    LabelText.newsDate = "";
    LabelText.newsTitle = "";
    LabelText.news_file = "";
    //this.initCallback(SimpleDropZone);
    this.setState({ isSubmit: false });
    this.setState({
      newsdata: newsdata,
      istrue: false,
      newsId: 0,
      editorText: "",
      fileUrl: [],
      newsImages: [],
    });
  };

  //   initCallback (dropzone) {
  //     var myDropzone = dropzone;
  //     //console.log("dropzone"+myDropzone);
  //     // myDropzone.removeAllFiles();
  // }

  onEdit = (
    editCategoryName,
    editDescription,
    editNewsId,
    imageurl,
    editnewsDate
  ) => {
    // debugger;
    //var editnewsDate = moment(editnewsDate).format('YYYY-MM-DD');
    this.onClickCancel();
    const { newsdata } = this.state;
    newsdata.newsTitle = editCategoryName;
    newsdata.description = editDescription;
    newsdata.newsDate = new Date(editnewsDate);
    let img = [];
    imageurl &&
      imageurl.map((data) => {
        img.push({ url: data.url });
      });

    this.setState({
      newsId: editNewsId,
      newsdata: newsdata,
      editorText: editDescription,
      istrue: true,
      newsImages: img,
    });
    // console.log(newsImages);
  };

  onUpdate = () => {
    //debugger
    const isValid = this.Validation();
    let newsDate = new Date(this.state.newsdata.newsDate).toISOString();
    const { newsTitle } = this.state.newsdata;
    const { editorText, fileUrl } = this.state;
    //const start = moment(newsDate).format('YYYY-MM-DD')
    let newsId = this.state.newsId;
    if (isValid) {
      this.setState({ loading: true });
      //debugger;
      const formDataimage = new FormData();
      fileUrl.forEach((item) => {
        formDataimage.append("photos", item.file);
      });
      formDataimage.append("name", newsTitle);
      MediaAxiosApiInstance.post(
        `${mediaUrl}/multiple-image`,
        formDataimage
      ).then((res) => {
        //debugger;
        if (res.status == 200) {
          var image = [];
          res.data.data &&
            res.data.data.map((img) => {
              image.push({ url: img.url });
            });
          var newsImages = [];
          this.state.newsImages &&
            this.state.newsImages.map((data) => {
              newsImages.push({ url: data.url });
            });
          var allImages = [...image, ...newsImages];
        }
        axiosApiInstance
          .put(`${URL}/news/${newsId}`, {
            newsTitle,
            description: editorText,
            fileUrls: allImages,
            newsDate,
          })
          .then((response) => {
            const res = response.data.news;
            if (response.status == 200) {
              this.setState({ loading: false });
              swal("News Updated", "", "success");
              this.getTableData();
              // this.removeData();
              this.setState({ isSubmit: true });
              this.onClickCancel();
              this.setState({ istrue: false });
            } else {
              // swal("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };
  arrayData = (data) => {
    if (data.length !== this.state.fileUrl.length) {
      this.setState({ fileUrl: data });
    }
    // console.log(this.state.fileUrl,"this.state.fileUrl");
  };

  mediaDel=(newsDelFiles)=>{
    newsDelFiles &&
      newsDelFiles.map((items) => {
        let url = items.url;
        let name = ``;
        var getLocation = function (href) {
          var l = document.createElement("a");
          l.href = href;
          return l;
        };
        var host = getLocation(url);
        if (host.hostname === "lmsmedia.softlabsgroup.in") {
          name = `${url.slice(38)}`;
        } else {
          name = `${url.slice(26)}`;
        }
        let data = { name: name };
        axiosApiInstance
          .post(`${mediaUrl}/delete-file`, data)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
  }

  onDelete = (newsDelId, newsDelFiles) => {
    //debugger;
    axiosApiInstance
      .delete(`${URL}/deleteNewsById/${newsDelId}`)
      .then((res) => {
        // debugger;
        // console.log(res);
        this.setState({ isSubmit: true });
        this.mediaDel(newsDelFiles);
        this.onClickCancel();
        this.getTableData();
        swal("News Deleted Successfully!", "", "success");
      })
      .catch((err) => {
        console.log(err.message);
      });
    
  };

  // removeData=(data)=>{
  //   // data.forEach(f=>f.remove());
  //   console.log("reData",data);
  // }

  render() {
    const {
      Data,
      columns,
      newsdata,
      LabelText,
      editorText,
      rowData,
      isSubmit,
    } = this.state;
    const today = new Date();
    //alert(Data)
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <DeleteConfirm
          show={this.state.showDelModal}
          animation={true}
          rowData={rowData}
          close={() => this.setState({ showDelModal: false })}
          deleteHandler={this.onClickRemove}
        />
        <DeleteConfirm
          show={this.state.showDelNews}
          animation={true}
          rowData={rowData}
          id={rowData._id}
          name={rowData.newsTitle}
          files={rowData.fileUrls}
          close={() => this.setState({ showDelNews: false })}
          deleteHandler={this.onDelete}
        />
        <ViewImage
          show={this.state.show}
          animation={true}
          url={rowData}
          viewHandler={this.onClickView}
          close={() => this.setState({ show: false })}
        />
        {this.state.loading ? <Loader /> : <div></div>}
        <Card className="shadow p-4 col-md-12">
          <Card className="col-md-8 p-4" style={{ margin: "0 auto" }}>
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">News</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Title</Label>
                    <span className="requiredFieldSpan">*</span>
                    <Input
                      type="text"
                      name="newsTitle"
                      placeholder=""
                      onChange={this.handleChange}
                      value={newsdata.newsTitle}
                    />
                    {LabelText.newsTitle !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {LabelText.newsTitle}
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Date</Label>
                    <span className="requiredFieldSpan">*</span>
                    <DatePicker
                      dateFormat="MMMM d, yyyy"
                      selected={newsdata.newsDate}
                      onChange={(date) =>
                        this.setState({
                          newsdata: { ...newsdata, newsDate: date },
                        })
                      }
                      disabledDays={{ before: today }}
                      minDate={today}
                    />
                    {LabelText.newsDate !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {LabelText.newsDate}
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label> Description</Label>
                    <span className="requiredFieldSpan">*</span>
                    {LabelText.editorText !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {LabelText.editorText}
                      </Label>
                    ) : null}
                    {/* <Input type="textarea" name="description" id="CourseDescription" onChange={this.handleChange} value={newsdata.description} /> */}
                    <TextEditor
                      name="description"
                      id="CourseDescription"
                      onChange={this.handleEditorChange}
                      value={editorText}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>News Image</Label>
                  <span className="requiredFieldSpan">*</span>
                  <br />
                  <FormGroup>
                    <Row xs="2">{this.getNewsImages()}</Row>
                  </FormGroup>
                  <SimpleDropZone
                    arrayData={this.arrayData}
                    isSubmit={isSubmit}
                    //onSubmit={this.removeData}
                  />
                  {LabelText.news_file !== "" ? (
                    <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.news_file}
                    </Label>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3">
                  <div className="center-block text-center">
                    {this.state.istrue ? (
                      <Button
                        className="text-center mr-3 btnblue"
                        onClick={this.onUpdate}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="text-center mr-3 btnblue"
                        onClick={this.onClickNewsData}
                      >
                        Submit
                      </Button>
                    )}
                    <Button
                      className="text-center clearBtn"
                      color="danger"
                      onClick={() => {
                        this.onClickCancel();
                      }}
                    >
                      {this.state.istrue ? "Cancel" : "Clear"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
          <Row className="mb-4 mt-4">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table
                    data={Data}
                    pageSize={10}
                    paging={true}
                    columns={columns}
                    search={true}
                    headerStyle={headerStyle}
                    rowStyle={rowStyle}
                    maxBodyHeight="900px"
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          //alert("Edit Action Clicked " + moment(rowData.newsDate).format('YYYY-MM-DD') + rowData.id)
                          this.onEdit(
                            rowData.newsTitle,
                            rowData.description,
                            rowData._id,
                            rowData.fileUrls,
                            moment(rowData.newsDate).format("YYYY-MM-DD")
                          );
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          // alert("Delete Action Clicked " + moment(rowData.newsDate).format('YYYY-MM-DD') +"" +rowData)
                          this.setState({
                            showDelNews: true,
                            rowData: rowData,
                          });
                          // console.log(rowData);
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}
