import React from "react";
import { Form, Card, Col, Row, Container } from 'reactstrap';
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";
import Table from  '../../../Components/Admin/Course/Table'
import '../../../Components/Admin/Course/Table.css'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Auth from '../../../ServerCall/Auth';
import swal from "sweetalert";


const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0
}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698'
}

  export default  class TestList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        addmodl: [],
        addModalShow: false,
        selectedValue: "",
        categoryName: "",
        showDelEvent: false,
        rowData: "",
        SelectParentCategory: [],
        categoryId: "",
        istrue: false,
        columns: [
          {
            title: "ID",
            render: (rowData) => <span>{rowData.tableData.id + 1}</span>,
          },
          { title: "Id", field: "_id", hidden: true },
          { title: "Test Name", field: "testName" },
          { title: "Test Duration", field: "duration" },
          { title: "Test Type", field: "type" },
          {
            title: "view",
            field: "_id",
            render: (rowData) => (
              <VisibilityIcon
                className="ml-4"
                onClick={() => this.onClickView(rowData._id)}
              />
            ),
          },
        ],
        Data: [],
      };
    }

    onClickView = (data) => {
      this.props.history.push(`/AddSection/?id=${data}`);
    };


    getTest=()=> {
      let facultyUserId = Auth.getUserId();
      axiosApiInstance
        .get(`${URL}/testByFaculty/${facultyUserId}`)
        .then((res) => {
          let TestList = res.data.Test;
          if (res.status == 200) {
            this.setState({ Data: TestList });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    onDelete = (TestDelId)=>{
      // debugger;
      this.setState({ loading: true });
      axiosApiInstance
        .delete(`${URL}/deleteTestById/${TestDelId}`)
        .then((res) => {
          //debugger;
          if (res.status === 200) {
            this.setState({ loading: false });
            // console.log(res.data.message);
            swal("Test Deleted Successfully!", "", "success");
            this.getTest();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      
    };
    componentDidMount() {
      // let facultyUserId = Auth.getUserId();
      // axiosApiInstance
      //   .get(`${URL}/testByFaculty/${facultyUserId}`)
      //   .then((res) => {
      //     let TestList = res.data.Test;
      //     if (res.status == 200) {
      //       this.setState({ Data: TestList });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      this.getTest();
    }

    render() {
      const { Data, columns,rowData,showDelEvent } = this.state;
      return (
        <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 h-100">
          <DeleteConfirm
            show={this.state.showDelEvent}
            animation={true}
            rowData={rowData}
            id={rowData._id}
            name={rowData.testName}
            close={() => this.setState({ showDelEvent: false })}
            deleteHandler={this.onDelete}
          />
          <Card className="shadow p-4 col-md-12 cardHeight">
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">Test List</h3>
              </Col>
            </Row>
            <Form>
              <Row className="mb-2 ">
                <Col className="tableWp mt-2">
                  <Row>
                    <Col className="DashboardTbl">
                      <Table
                        data={Data}
                        pageSize={10}
                        paging={true}
                        columns={columns}
                        search={false}
                        headerStyle={headerStyle}
                        rowStyle={rowStyle}
                        maxBodyHeight="400px"
                        actions={[
                          //     {
                          //       icon: 'edit',
                          //       tooltip: 'Edit',
                          //       onClick: (event, rowData) => {
                          //         this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                          //       }
                          //     }
                          //   ]

                          {
                            icon: "delete",
                            tooltip: "Delete",
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              this.setState({
                                showDelEvent: true,
                                rowData: rowData,
                              });
                            },
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      );
    }
  } 
