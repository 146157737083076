import React from "react";
import { Form,Card, Col,Row,Container,Button} from 'reactstrap';
import Table from  '../../../Components/Admin/Course/Table'
import '../../../Components/Admin/Course/Table.css'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import EventNoteIcon from '@material-ui/icons/EventNote';
import swal from 'sweetalert';
import TestModel from'../../../Containers/Student/StudentRegistration/TestModel';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";
import { AttachFile } from "@material-ui/icons";

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0
}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698'
}

  export default  class StudentList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          addmodl:[],
          addModalShow:false ,
          StudentId: 0,
          selectedGender : null,
          TestList:null,
          Test:[],
          studentIds:{},
          Studentdata:{
            firstName:'',
            middleName:'',
            lastName:"", 
            gender:'',
            email:'',
            password:'',
            contactNumber:'',
            address:'',
            state:'',
            city:'',
            pinCode:'',
            country:''
          },
          istrue: false,
          SelectGender:[
            {value: 1, text: "Male"},
            {value: 1, text: "Female"}
          ] ,
          LabelText:{
            firstName:'',
            middleName:'',
            lastName:"", 
            gender:'',
            email:'',
            password:'',
            contactNumber:'',
            address:'',
            state:'',
            city:'',
            pinCode:'',
            country:'',
            selectedGender:null,
            TestList:null
          },      
          columns: [  
             //{ title: "SR No", field: "SrNo" },
            { title: "First Name", field: "firstName"},
            // { title: "Middle Name", field: "middleName"},
            { title: "Last Name", field: "lastName"},
            // { title: "Courses/Batch", field: ""},
            { title: "gender", field: "gender",hidden: true},
            { title: "Email Id", field: "email"},
            { title: "Contact Number", field: "contactNumber"},
            // { title: "Enrolled Courses", 
            //   // field:"",
            //   render: rowData =><span>{this.course(rowData)}</span>
            // },
            // cc{ title: "Address", field: "address"},
            // { title: "Date", field: "createdAt", type: "date"},
            { title: "State", field: "state", hidden: true },
            { title: "City", field: "city", hidden: true },
            { title: "PinCode", field: "pinCode", hidden: true },
            { title: "Action", field: "_id", render: rowData =><span><EditIcon className="ml-4" onClick={() =>this.onClickEdit(rowData._id)}/><DeleteIcon className="ml-4" onClick={() => this.isStudentSchedule(rowData)}/></span>}
          ],
          Data : [],
          delModal : false,
          delData: []
        }
      }
      // this.setState({delModal: true,delData: rowData})
          // this.setState({
          //   showDelEvent: true,
          //   delData: rowData,
          // });
      course=(rowData)=>{
        // let array = [{courseName:"cou",batchName:"bat"},
        // {courseName:"cou2",batchName:"bat2"}];
        let course = [];
        // rowData.newCourses && rowData.newCourses.map(data=>{
        //  course.push(data.subjectName)
        // });
        if(rowData.newCourses.length > 0){
        rowData.newCourses && rowData.newCourses.map(data=>{
          if(data.courseName !== "" && data.batchName !== ""){
            course.push(`${course.length+1}. ${data.courseName} / ${data.batchName}`)
          }
          else if(data.batchName === ""){
            course.push(`${course.length+1}. ${data.courseName}`)
          }
          }); 
        }
        else{
          course.push("No courses enrolled yet !! Empty !!")
        }
          // console.log("course",course);
        // const skills = course.join(" , ");
        // const skills = course.join(" || ");
        // const skills = course.join(<br/>).split(<br/>);
        const skills = course.join('\n');
        // return skills;
        let finalSkills = skills.split ('\n').map((item, i) => <p key={i}>{item}</p>);
        return finalSkills;
        // console.log("core",skills);
      }
      
    onClickEdit =(data)=> {
    this.props.history.push(`/StudentRegistration/?id=${data}`)
    }

    validation=()=>{
        let {LabelText} = this.state;
        if ( this.state.TestList !== null) {
          if(this.state.TestList === "Select Test"){
            LabelText.TestList = "Please select Test"
        }
        else{
            LabelText.TestList = null;
        }
    }
    else{
        LabelText.TestList = "Please select Test"
    }
    this.setState({LabelText: LabelText});
    if(LabelText.TestList===null)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    getTableData () {
        axiosApiInstance.get(`${URL}/getStudentList`)
        .then(res =>{
          //debugger
          let allStudentList = res.data.allStudentList;
          if(res.status === 200){
            this.setState({Data: allStudentList});
            // console.log(allStudentList);
          }
        }).catch(err => {
          console.log(err)
        })
    }

      
   fetchTestListApi = () =>{
    axiosApiInstance.get(`${URL}/getAllTest`)
    .then(res =>{
      let TestData =[];
      let TestList = res.data.Test;
      // console.log("TestList",TestList);
      if(res.status === 200)
      {
        TestList && TestList.map(data =>{
          TestData.push({text: data._id,value:data.testName})
        });
        this.setState({Test:TestData});
      }    
    }).catch(err => {
      console.log(err)
    })
  }
      
    componentDidMount (){
        this.getTableData();
        this.fetchTestListApi();
    }

    onSelection =(rowData,event) =>{
        //debugger;
        var data = event;
        var StudId = [];
        for(var i=0;i<data.length;i++)
        { 
            StudId.push(data[i].userId)
        }
        this.setState({studentIds:StudId})
    }

    onClickTestModel =()=> {
        this.setState({addModalShow:true})
    }

    onClickCancelTestModel =()=> {
        this.setState({addModalShow:false})
    }
      
    onClickCancel = () =>{
        this.setState({addModalShow: false});
    }
      
    selectedTest = (value) =>{
      this.setState({TestList:value})
    }

    onUpdateTestData =()=> {
      const isValid = this.validation();
      if(isValid){
      const {studentIds} = this.state;
      const testId = this.state.TestList;
      let  studentId2= {studentIds}
      axiosApiInstance.put(`${URL}/addStudentId/${testId}`,studentId2)
      .then(response =>{
        if(response.status == 200) {
          swal("Test assigned successfully","", "success")
          .then((result) => {
            if(result){
              this.setState({addModalShow:false})
              this.getTableData();
            }
          })
        } else{
          //swal("Something Went Wrong","", "error"); 
        }
      }).catch(err => {
        console.log(err)
      })
    }
    }
    
    onDelete=(id)=>{
      // debugger
      axiosApiInstance
      .delete(`${URL}/deleteStudentByUserId/${id}`)
      .then((res) => {
        // debugger;
        // console.log(res);
        this.getTableData();
      }).catch(err=>{
        console.log("error",err);
      });
    }

    isStudentSchedule=(data)=>{
      console.log(data);
      axiosApiInstance
      .get(`${URL}/allCourseSchedule`)
      .then((res) => {
        let courseSchedule = res.data.CourseSchedule;
        let studId = [];
        let matchId = "";
        // console.log("Schedule Check",courseSchedule);
        courseSchedule && courseSchedule.map(courseBatch =>{
          let studentIds = courseBatch.studentIds;
          studentIds && studentIds.map(studIds=>{
            studId.push(studIds);
          })
        })
        
        studId && studId.map(id=>{
          if(id === data.userId){
            matchId = id;
          }
        })
        if(matchId === data.userId){
          swal("Can't Delete This Student","Student is scheduled to a course","warning");
        }else{
          this.setState({delModal: true,delData: data});
        }
      }).catch(err=>{
        console.log(err);
      });
    }

    render(){
        const{Data,columns,Test,LabelText,delModal,delData} =this.state;
        let addModalClose=() => this.setState({addModalShow:false});
        return(
        <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 h-100">
          <DeleteConfirm
          show={delModal}
          animation={true}
          rowData={delData}
          id={delData.userId}
          name={`${delData.firstName} ${delData.lastName}`}
          close={() => this.setState({ delModal: false })}
          deleteHandler={this.onDelete}
        />
          <Card className="shadow p-4 col-md-12 cardHeight">
          <Row>
					<Col className="mb-2">
						{/* <Button className="bluebtn"  onClick={() => this.props.history.push('/StudentRegistration')}><ArrowBackIcon/>Back</Button> */}
					</Col>
				</Row>
          <Row>
            <Col className="mb-4 studListheaderCol">
              <h3 className="text-center blueText">Student List</h3>
            </Col>
          </Row>
          <Form>
          <Row>
          <Col md={6}>
                  <TestModel 
                      show={this.state.addModalShow} onHide={addModalClose} selectedTest={this.selectedTest} Test={Test}
                      onClickCancelTestModel={this.onClickCancelTestModel} onUpdateTestData={this.onUpdateTestData} LabelText={LabelText.TestList}
                  />
              </Col> 
          </Row>
            <Row className="mb-2 ">
              <Col className="tableWp mt-2">
              <Row>
                  <Col className="DashboardTbl std-tbl">
                    <Table data={Data} pageSize={10} paging={true} columns={columns} selection={true} search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='500px' 
                      detailPanel={[
                        {
                          tooltip: 'See Course',
                          render: rowData => {
                            return (
                              <div
                                style={{
                                  fontSize: 16,
                                  textAlign: 'justify',
                                  color: 'white',
                                  backgroundColor: '#628da4',
                                  padding: 20,
                                  margin: 10
                                }}
                              >
                                <span style={{fontSize: 17,}}><b>{rowData.firstName}'s enrolled courses/batch :</b></span><br/>
                                <br/> <p>{this.course(rowData)}</p>
                              </div>
                            )
                          },
                        },
                      ]}

                      actions ={
                        [
                          {
                            icon: () => <EventNoteIcon className="CalendarIcn"/>,
                            tooltip: 'Edit',
                            onClick: (evt, rowData) => {
                              // alert("Edit Action Clicked " + rowData._id )
                              this.onClickTestModel();
                            }
                          },
                        ]
                      }
                      onSelectionChange={
                        (event, rowData) => this.onSelection(rowData,event)
                      }
                    />
                  </Col>
              </Row>                       
              </Col>
            </Row>
          </Form>
          </Card>
        </Container>
      );
    }
  } 
