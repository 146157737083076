import React from 'react';
import {Container} from 'reactstrap'
import { Col,Row } from 'react-bootstrap';
import { Card, Button} from 'reactstrap';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';

const headerStyle = {
    backgroundColor: '#4c8db5',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0  
}
const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698', 
}

export default class AssignedTest extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: "Id", field: "_id" , hidden: true },
                { title: "Id", render: rowData =><span>{rowData.tableData.id+1}</span>},
                { title: "Test Name", field: "testName"},
                { title: "Test type", field: "type"},
                { title: "Test Duration(minutes)", field: "duration"},
                { title: "Action ", field: "_id", render: rowData => <Button onClick={() =>this.startTestAlert(rowData._id)}>Start Test</Button>}
            ],
            Data : [],
        }
    }

    startTestAlert = (data) => {
        swal("Are you sure you want to start test?", {
            buttons: ["No", "Yes"],
        })
        .then((result) => {
            if(result) {
                this.props.history.push(`/TestInstruction/${data}/testId`)
            } else {
               // alert(result)
            }
        });
    }

    componentDidMount (){
        axiosApiInstance.get(`${URL}/getTestByStudent/`)
        .then(res =>{
            let TestList = res.data.Test;
            // console.log(TestList);
            if(res.status == 200){
              this.setState({Data:TestList});
            }
        }).catch(err => {
            console.log(err);
        })
    }
    
    render(){
        const{ Data,columns} =this.state;
        return (
            <Container fluid  className="pl-0 pr-0  h-100">
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <Card className="p-4 shadow cardHeight">
                            <Row>
                                <Col>
                                    <h3 className="text-center hdtxt" >Assigned Tests</h3>
                                </Col>
                            </Row>
                            <Row className="mb-4 mt-4">
                                <Col className="tableWp">
                                    <Row>
                                        <Col className="DashboardTbl">
                                            <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='400px' 
                                            />
                                        </Col>
                                     </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    };
}