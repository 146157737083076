import React from 'react';
import {  Button,Form, FormGroup,Col,Row,Container} from 'react-bootstrap';
import { Input,Label} from 'reactstrap';
import BrowseFile from './BrowseFile'
//import {FetchProfileData,EditProfileData} from "./ProfileSettingAPI"
//import currentUser from "../../../reducers/userInfo";
//import { Validatephone} from "../../../Validation/validation";
import swal from "sweetalert";

export default class ProfileSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }
  }
 
  handleSubmit(event) { 
    event.preventDefault();
  }
  render(){
    const{FetchProfileDetail} = this.state;
  return (
    <Container fluid  className="wrp">
      <Col md={6} className="whitebg shadow-sm p-4">
      <Row className="">
        <Col md={9}>
        <h5> Edit Profile Setting</h5>
        </Col>
      </Row>
    <Row>
      <Col>
    <Form >
    <FormGroup>
      <Row className="mt-4">
        <Col md={12}>
          <BrowseFile  browsefileHandler={this.profilePic} />
        </Col>
        </Row>
      </FormGroup>  
      <FormGroup>
        <Label for="UserName">First Name</Label>
        <Input type="Text"  name="firstName" required id="firstName" value="" onChange=""/>
      </FormGroup>
      <FormGroup>
        <Label for="UserName">Last Name</Label>
        <Input type="Text"  name="lastName" required id="lastName" value="" onChange=""/>
      </FormGroup>
      <FormGroup>
        <Label for="Email Address">Email Address</Label>
        <Input type="text" name="" id="" required value="" 
        //  disabled={true}
         onChange=""/>
      </FormGroup>
      <FormGroup>
        <Label for="LinkDin Profile">LinkedIn Profile</Label>
        <Input type="text" name="LinkedIn"  required value="" onChange=""/>
      </FormGroup>
      <FormGroup>
        <Label for="Mobile">Mobile Number</Label>
        <Input type="Number" name="Phone" id="Phone"  value="" onChange=""/>
      </FormGroup>
    </Form>
    <FormGroup>
        <Row>
            <Col md={6}>
                <Button className=" btnblue btnclass w-100 ">Save Changes</Button>
            </Col>
            <Col md={6}>
                <Button className="btnblue btnclass w-100">Cancel</Button>
            </Col>
        </Row>
      </FormGroup>
    </Col>
    </Row>
    </Col>
    </Container>
  );
}
}


