
import React from "react";
import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label,Col,Row,Container ,Button} from 'reactstrap';
import DropDown from '../../../Components/Admin/Course/DropDown'
export default  class CourseCategory extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
     
      SelectBranchCategory:[
        { value: 'BE' },
        { value: 'Bsc IT' },
        { value: 'MCA' }
      ],
      SelectCourse:[
        { value: 'Java' },
        { value: 'ReactJs' },
        { value: '.NET' }
      ],
      SelectFaculty:[
        { value: 'John' },
        { value: 'Riya' },
        { value: 'Rajat' }
      ]
    }
  }
  selectedValue = (value) =>{
    this.setState({selectedValue:value})
  }
render(){
  const{SelectFaculty,SelectCourse,SelectBranchCategory} =this.state;
    return(

    <Container fluid className="CouseMasterWp FormWp">
       <Card className="shadow p-4 col-md-8 marginauto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Faculty</h3>
         </Col>
       </Row>
       <Form>
        <Row>
           <Col md={6}>
            <FormGroup>
              <Label>Category Name</Label><span className="requiredFieldSpan">*</span>
              <DropDown title="Select Category" options={SelectBranchCategory} selectedYear={this.selectedValue}/>
            </FormGroup>
           </Col>
           <Col md={6}>
           <FormGroup>
           <Label>Course  Name</Label><span className="requiredFieldSpan">*</span>
            <DropDown title="Select Course" options={SelectCourse} selectedYear={this.selectedValue}/>
          </FormGroup>
           </Col>
        </Row>
        <Row>
           <Col md={6}>
            <FormGroup>
              <Label>Faculty Name</Label><span className="requiredFieldSpan">*</span>
              <DropDown title="Select Faculty" options={SelectFaculty} selectedYear={this.selectedValue}/>
            </FormGroup>
           </Col>
           
        </Row>
        <Row>
          <Col className="text-center mt-3">
          <div  className="center-block text-center">
            <Button className="text-center btnblue mr-3">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>

       </Form>
       </Card>
    </Container>
    );
}
} 
