import react, { Component } from 'react'
import React from 'react';
import {  Button,Form, FormGroup,Col,Row,Container} from 'react-bootstrap';
import { Input,Label} from 'reactstrap';
import BrowseFile from './BrowseFile'

export default class ProfileSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        CurrPwd:'',
        NewPwd:'',
      Phone:''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) { this.setState({value: event.target.value});  }
  handleSubmit(event) { event.preventDefault();}
  render(){
  return (
    <Col md={6}>
    <Container className="wrp whitebg shadow-sm p-4">
     
      <Row>
        <Col md={9}>
        <h5>Profile Setting</h5>
        </Col>
        <Col md={3}>
        <a href="/EditProfile" className="float-right">Edit</a>
        </Col>
      </Row>
    <Row>
      <Col>
    <Form onSubmit={this.handleSubmit}>
    <FormGroup>
      <Row>
        <Col md={3}>
          <BrowseFile />
        </Col>
        <Col md={9} className="UsrPrfNmTxt">
           <Input type="Text"  name="UserName" required id="UserId" value={this.state.UserName} onChange={this.handleChange}/>
        </Col>
        </Row>
      </FormGroup>  
      <FormGroup>
        <Label for="Email Address">Email Address</Label>
        <Input type="text" name="EmailAddrs" id="EmailAddrsId" required value={this.state.EmailAddrs} onChange={this.handleChange}/>
      </FormGroup>
      <FormGroup>
        <Label for="Mobile">Mobile Number</Label>
        <Input type="text" name="MobNo" id="MobId"  value={this.state.MobNo} onChange={this.handleChange}/>
      </FormGroup>
    </Form>
    </Col>
    </Row>
   
    </Container>
    </Col>
  );
}
}


