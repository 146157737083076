import React from 'react'
import {Container,Row,Col,Card,Button} from 'reactstrap';
import swal from 'sweetalert';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import Auth from '../../../ServerCall/Auth';
import _ from 'lodash';
import Loader from '../../../Components/Common/Loader/Loader';

export default class TestInstruction  extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            chapterId:null,
            test:[],
            accessingExam:[],
            questionList:[],
            tags:'',
            loading:false
        }
    }
    async componentDidMount (){
        //debugger
        let tag = this.props.location.pathname.split("/").slice(-2)[1];
        let _id = this.props.location.pathname.split("/").slice(-2)[0];
        this.setState({chapterId:_id,tags:tag});
        await this.getChapterTest(_id, tag);
    }

    getChapterTest = (chapterId, tag) => {
    this.setState({loading:true});
    var api = tag === "testId" ? "test" : "testByChapterId";
    axiosApiInstance.get(`${URL}/${api}/${chapterId}`)
    .then(res =>{
        //console.log(res.data.Test)
        if(res.status === 200){
            this.setState({loading:false});
            this.setState({test: res.data.Test});
            const result = _.flatMap(res.data.Test.questions, ({ _id, sectionName, description, questions }) =>
            _.map(questions, question => ({ _id, sectionName, description, ...question })) 
          );
          this.setState({questionList: result});
        }else{
            this.setState({loading:false});
        swal(res.data.message, "", "error")
        }
    }).catch(err => {
        this.setState({loading:false});
        console.log(err)
    })
    }

    startTestAlert = (chapterId) => {
        this.props.history.push(`/Test/${chapterId}/${this.state.tags}`);
    }
                


    render()
    {
        const {questionList,test} = this.state;
        const instructionList = [
            {description: 'Make Sure You have Good Internet Connection?', key: 0},
            // {description: 'Once you start the exam, do not refresh your browser, use browser buttons, or navigate,away from the exam screen until the exam has been submitted. If you navigate away from the exam, your progress may be lost and cannot be recovered. ', key: 1},
            // {description: 'If You are taking exam late in the day , it is recommended that you reboot your computer before begining to free up memory resources from other programs on your computer', key: 2},
            // {description: 'Shut Down all Instan messaging tools (skype,AIM,MSN Messenger) and Email programs as they cam conflict with exam', key: 3},
            {description: 'Use only one Browser ,do not open other browsers', key: 1},
            // {description: 'Maximize your browser window before starting the test', key: 5},
            {description: 'When you begin the exam click the button launch test?', key: 2},
            ];
            const duringExamInstructionList = [
                // {description: 'Do not resize the browser during test', key: 0},
                // {description: 'Never Click back button on the browser', key: 1},
                {description: 'Click Submit button to submit your test', key: 0},
                
            ];
            let AccessignExam = [
                {description: 'Exam Name: '+test.testName, key: 0},
                {description: 'Exam Type : '+test.timeLimitOption, key: 1},
                {description: test.duration === null?'Exam Duration:'+0+" minutes":'Exam Duration:'+test.duration+" minutes", key: 2},
                {description: 'No of Questions : '+questionList.length, key: 3}, 
            ];
        return(
            <Container fluid>
                 {this.state.loading ?
                        <Loader/>
                        : <div></div>
                 }
                {questionList.length > 0?
                    <Card className="p-3">
                        <Row>
                            <Col><h4 className="text-center bluetext" >Exam Guidelines</h4></Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6>Before Begining the exam :</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ol className="orderListwp">
                                {instructionList.map(instruction => {
                                    return (
                                        <li key={instruction.key}>{instruction.description}</li>
                                    );
                                    })}
                                </ol>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <h6>During  the exam :</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <ol  className="orderListwp">
                                {duringExamInstructionList.map(duringexaminstruction => {
                                    return (
                                        <li key={duringexaminstruction.key}>{duringexaminstruction.description}</li>
                                    );
                                    })}
                                </ol>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <h6>Instructions for accessing exams</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <ol  className="orderListwp">
                                {AccessignExam&&AccessignExam.map(acessexam => {
                                    return (
                                        <li key={acessexam.key}>{acessexam.description}</li>
                                    );
                                    })}
                                </ol>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{margin:'0 auto',display:'inlineBlock'}} className="text-center"><Button onClick={() => this.startTestAlert(this.state.chapterId)} className="bluebtn text-center" style={{display:'inlineBlock'}}>Proceed</Button></Col>
                        </Row>
                    </Card>:<h4>No Test Available</h4>
                }
            </Container>
        )
    }
}
