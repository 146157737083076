import { PinDropSharp } from "@material-ui/icons";
import React from "react";
import { render } from "react-dom";
import Select from "react-select";
//import "./../react-select/dist/react-select.css";

export default class MultiSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multiValue: [],
      selected: []
    };

    this.handleMultiChange = this.handleMultiChange.bind(this);
  }

  async handleMultiChange(option) {
    await this.setState(state => {
      return {
        multiValue: option
      };
    });
    this.props.handleMultiple(this.state.multiValue);
  }

  render() {
    let faculties = this.props.faculties;
    this.state.selected = this.props.selected;
    return (
      <div>
        {/* <label>Multi (now working)</label> */}
        <Select
          name="mainFaculty"
          // placeholder="select faculty .."
          value={this.state.selected}
          options={faculties}
          onChange={this.handleMultiChange}
          defaultValue={this.state.selected}
          isDisabled={this.props.disabled}
          isMulti
        />
      </div>
    );
  }
}
