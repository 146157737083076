import React from "react";
import Table from "../../../Components/Admin/Course/Table";
import "../../../Components/Admin/Course/Table.css";
import { Form, Card, Col, Row, Container, Button } from "reactstrap";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { URL } from "../../../ServerCall/Config";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
const headerStyle = {
  backgroundColor: "#4c8db5",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

export default class StudentFeedbackList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Id", field: "_id", hidden: true },
        { title: "Workshop name", field: "courseName" },
        { title: "Student name", field: "studentName" },
        { title: "date", field: "createdAt", type: "date" },
        // { title: "View", field: "View" ,render: rowData => <VisibilityIcon className="visiblityIcnViewer"/>},
      ],
      Data: [],
    };
  }

  async componentDidMount() {
    this.getAllFeedbacks();
  }

  getAllFeedbacks() {
    // debugger
    axiosApiInstance
      .get(`${URL}/getAllFeedback`)
      .then((res) => {
        // console.log(res.data.Feedback);
        let allFeedbacks = res.data.Feedback;
        let dataFedback = [];
        if (res.status == 200) {
          allFeedbacks &&
            allFeedbacks.map((data) => {
              dataFedback.push({
                _id: data._id,
                courseName: data.courseName,
                studentName: data.stundentInfo.studentName,
                createdAt: data.createdAt,
              });
              // data.map(data1 =>{
              //   dataFedback.push({
              //     studentName:data1.studentName
              //   })
              // })
            });

          this.setState({ Data: dataFedback });
          // console.log(dataFedback);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { columns, Data } = this.state;

    return (
      <Container fluid className=" FormWp formWpr">
        <Card className="shadow p-4 col-md-12 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText"> Student Feedback List</h3>
            </Col>
            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
          </Row>

          <Row className="mt-4 mb-3">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table
                    data={Data}
                    pageSize={8}
                    paging={false}
                    columns={columns}
                    search={false}
                    headerStyle={headerStyle}
                    rowStyle={rowStyle}
                    maxBodyHeight="350px"
                    actions={[
                      {
                        icon: "visibility",
                        tooltip: "View",
                        onClick: (event, rowData) => {
                          this.props.history.push(
                            `/ViewFeedback?id=${rowData._id}`
                          );
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}
