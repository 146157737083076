import React from "react";
class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      name: "",
      options: this.props.options,
      title: this.props.title,
      duration: "",
      // facultyName: "",
      // facultyId: "",
      courseId:"",
    };
  }

  // async componentWillReceiveProps(nextProps) {
  //   if (nextProps.options !== this.props.options) {
  //     await this.setState({ options: nextProps.options });
  //   }
  // }

  // onChange = async (e) => {
  //   const selectedIndex = e.target.options.selectedIndex;
  //   //console.log(e.target.options[selectedIndex].getAttribute('data-key'));
  //   let textValue = e.target.options[selectedIndex].getAttribute("data-key");
  //   await this.setState({
  //     value: this.props.value,
  //     name: "",
  //     options: this.props.options,
  //     title: this.props.title,
  //   });
  //   this.props.selectedCategory(this.state.value, this.state.name);
  // };

  async componentWillReceiveProps(nextProps) {
    // debugger;
    if (nextProps.options !== this.props.options) {
      await this.setState({ options: nextProps.options });
    }
    if (nextProps.value !== this.props.value) {
      // debugger;
      await this.setState({ value: nextProps.value });
    }
  }

  onChange = async (e) => {
    // debugger
    // console.log(e.target.options);
    const selectedIndex = e.target.options.selectedIndex;
    let textValue = e.target.options[selectedIndex].getAttribute("data-key");
    let duration = `${e.target.options[selectedIndex].getAttribute("duration")}`;
    // let facultyId = e.target.options[selectedIndex].getAttribute("facultyId");
    // let facultyName = e.target.options[selectedIndex].getAttribute("facultyName");
    let courseId = e.target.options[selectedIndex].getAttribute("courseId");

    await this.setState({
      value: e.target.value,
      name: textValue,
      duration: duration,
      // facultyId: facultyId,
      // facultyName: facultyName,
      courseId: courseId
    });
    this.props.selectedCategory(
      this.state.value,
      this.state.name,
      // this.state.facultyName,
      // this.state.facultyId,
      this.state.duration,
      this.state.courseId,
      
    );
  };

  render() {
    const {
      options,
      title,
      duration,
      facultyName,
      facultyId,
      courseId,
      value
    } = this.state;
    return (
      <div>
        <select
          className="form-control"
          value={value}
          onChange={this.onChange}
          disabled={this.props.disabled}
        >
          <option>{title}</option>
          {options &&
            options.map((select) => {
              return (
                <option
                  value={select.value}
                  duration={select.duration}
                  // facultyName={select.facultyName}
                  // facultyId={select.facultyId}
                  courseId={select.courseId}
                  data-key={select.text}
                >
                  {select.value}
                </option>
              );
            })}
        </select>
      </div>
    );
  }
}

export default DropDown;
