import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { URL } from '../../../ServerCall/Config';

const TransactionUpdate = (props ) => {
    // console.log(props.location.pathname)
  const [values, setValues] = useState({
    success: false,
    error: false,
  });

  useEffect(() => {
    getStatus();
  }, []);
  const { success, error } = values;
    const getStatus = () => {
        if (props.location.pathname != "") {
            let param = props.location.pathname;
            const orderData = param.toString().slice(19);
            const orderJson = JSON.parse(orderData);
            console.log(orderJson, typeof orderJson);
            const orderId = orderJson.orderId;
            let courseIds = orderJson.courseId;
            // let startDate = orderJson.;
            // let endDate = orderJson.;
            // orderJson.order.courses &&
              // orderJson.order.courses.map((data) => {
              //     courseIds = data.courseId;
              //     startDate = data.startDate;
              //     endDate = data.endDate;
              // });
      
            axiosApiInstance
              .get(`${URL}/getTransactionByOrderId/${orderId}`)
              .then((res) => {
                // debugger;
                // console.log(res);
                  if (res.data.status===200) {
                      // console.log(res.data.data);
                      const paymentStatus = res.data.data.status;
                    //   console.log(paymentStatus)
                      if (paymentStatus === "TXN_FAILURE") {
                         props.history.push(`/TabCourseForum/${courseIds}`);
                          swal("Payment Failed", "", "error");
                      }
                      if (paymentStatus === "TXN_SUCCESS") {
                          let enrollmentArray = [];
                          // let duration = { startDate, endDate };
                        enrollmentArray.push({courseId:courseIds,status:true});
                        axiosApiInstance.put(`${URL}/addEnrollment/${courseIds}`,{enrollment:enrollmentArray})
                        .then(res=>{
                            // debugger
                            // console.log("enrollcourse",res);
                            if(res.status === 200){
                                props.history.push(`/Enrollment`)
                            }else{
                                swal(res.data.message, "", "error")
                            }
                        }).catch(err=>{
                            console.log(err);
                        })
                        swal("Payment Successful", "", "success");
                      }
                  // res.data.paymentHistory.map((data) => {
                  //   if (data.ORDERID === match.params.orderId) {
                  //     if (data.STATUS === "TXN_SUCCESS") {
                  //       setValues({ ...values, success: true, error: false });
                  //     }
                  //   } else {
                  //     setValues({ ...values, success: false, error: "Payment Failed" });
                  //   }
                  // });
                  //   console.log(res.data)
                }
              });
        };
  };

  return (
      <div>
          {/* <h1>Hi</h1>
      {success && <h1>Payment Succesfully</h1>}
      {error && <h1>{error}</h1>} */}
    </div>
  );
};

export default TransactionUpdate
