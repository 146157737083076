import React from "react";
import { Card , Container} from 'reactstrap'
import MediaCard from '../../../Components/Student/Course/MediaCard'
import PaginationLine from  '../../../Components/Student/Course/PaginationLine'
import { Col,Row ,Form,InputGroup,} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from 'react-router-dom';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';
import parse from 'html-react-parser';

export default  class Enrollment extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            enrolledCourse:[],
            isRedirect: false,
            courseId:'',
            itemsPerPage : 8,
            page :1,
            noOfPages : null,
            filter:'',
            filteredData:[],
        }    
    }
    handlePageChange = async(e,value) => {
        await this.setState({page:value})
      };

    getEnrollment=()=>{
        axiosApiInstance.get(`${URL}/getEnrollment`)
        .then(res=>{
            if(res.status === 200){
                let course = res.data.course;
                //console.log("getEnrollment",res.data.course);
                let  courseData = [];
                //debugger
                course&&course.map(data =>{
                    courseData.push({Id:data.c_id,courseName:data.courseName,percentage_completed:data.percentage_completed,description:data.description,courseType:data.courseType,duration:data.duration,defaultImage:data.imageOrVideoPath.default})
                  })
                //  console.log("Enrollment",courseData);
                 let pages = Math.ceil(courseData.length / this.state.itemsPerPage);
                this.setState({enrolledCourse: courseData,noOfPages:pages});
            }else{
                swal(res.data.message, "", "error")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount(){
        this.getEnrollment();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.filter !== prevState.filter || this.state.page !== prevState.page) {
            this.getEnrollment();
        }
    }

    redirect = (Id) => {
        this.setState({ isRedirect: true,courseId:Id });
    }

    handleChange = event => {
        this.setState({ filter: event.target.value });
      };

    render(){
        //debugger
        let {enrolledCourse,filter,filteredData} = this.state;
        const lowercasedFilter = filter.toLowerCase();
         filteredData = enrolledCourse.filter(enroll =>
            enroll.courseName.toLowerCase().includes(lowercasedFilter));
        return( 
            <Container fluid CardContainer  className="enrollmentContainer pl-0 pr-0 h-100">
                <Col md={12} style={{margin:'0 auto'}} className="pl-0 pr-0 cardHeight">
                    <Card className="p-4 shadow  h-100">
                        <Row className="mb-5  mt-4 pl-3">
                            <Col md={6}>
                                <h3 className="hdtxt">Your Courses</h3>
                            </Col>
                            <Col>
                            <Form.Row>
                                <Form.Group as={Col} className="mb-0">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text >
                                                <FontAwesomeIcon icon={faSearch} />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="text"
                                            placeholder="Search Your Course.."
                                            value={filter} onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            </Col>
                        </Row>
                        <Row>
                        {enrolledCourse&&enrolledCourse&&filteredData.slice(((this.state.page - 1)*(this.state.itemsPerPage)), (this.state.page)*(this.state.itemsPerPage)).map(item =>
                            <Col md={3}>
                            <MediaCard courseDetail={() =>this.redirect(item.Id)} title={item.courseName}
                                text={parse(item.description)}
                                image={item.defaultImage} btn="startBtn" percentage_completed={item.percentage_completed} courseType={item.courseType} start={() =>this.redirect(item.Id)}/>
                                  {this.state.isRedirect ? <Redirect
                                  
                                  to={{
                                    pathname: `/TabCourseForum/${this.state.courseId}`,
                                    state: { percent: item.percentage_completed }
                                  }}
                                  //to={`/TabCourseForum/${this.state.courseId}`}
                                   /> : null }
                            </Col>
                        )}
                            {/* <Col md={4}>
                            <MediaCard title="Python " 
                                text="Python with 200+ examples  of Python  Begginers.Python is  high-level &amp; programming language. "
                                image={require('../../../Images/python.png')} btn="startBtn" start={this.startclick}/>
                            </Col>
                            <Col md={4}>
                            <MediaCard title="Graphic Design"
                                text="All Graphic Design courses. Our graphic design courses can prepare you for a wide range of careers."
                                image={require('../../../Images/grp.jpg')} btn="startBtn" start={this.startclick}/>
                            </Col> */}
                        </Row>
                        <Row className="mt-5">
                            <Col md={6} sm={6} xs={12}>
                            
                            </Col>
                            <Col md={6} sm={6} xs={12} className="float-right pgWp">
                                <PaginationLine
                                noOfPages={this.state.noOfPages}
                                page={this.state.page}
                                handlePageChange={this.handlePageChange}
                                />
                            </Col>
                        </Row>
                        
                    </Card> 
                </Col>
            </Container>
        );
    }
}