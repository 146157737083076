import React from "react";
// import "../../../Containers/Admin/Course/CourseMaster.css";
import {  Form,  Card,  FormGroup,  Label,  Input,  Col,  Row,  Container,  Button,} from "reactstrap";
import {  AvForm,  AvField,  AvGroup,  AvInput,  AvFeedback,} from "availity-reactstrap-validation";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import {  faUser,faLock} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../Images/con_logo.png";
import axios from "axios";
import swal from "sweetalert";
import { URL } from "../../../ServerCall/Config";
//import { setProfile } from "../../../redux/action/profileAction";
//import { connect } from "react-redux";

//const validPassword = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
const validPassword = RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      formErrors: {
        newPassword: "",
        confirmPassword: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  formvalid = (formErrors) => {
    let valid = true;
    let { newPassword, confirmPassword } = this.state;
    validPassword.test(newPassword)
      ? (formErrors.newPassword = "")
      : (formErrors.newPassword = "Please Enter Password");
    confirmPassword !== ""
      ? (formErrors.confirmPassword = "")
      : (formErrors.confirmPassword = "Please enter password");
    // (password!= newPassword)?formErrors.password="Not same as New Password":"";
    if (confirmPassword === newPassword) {
      formErrors.confirmPassword = "";
    } else {
      formErrors.confirmPassword = "Passwords dont match";
    }
    this.setState({ formErrors: formErrors });
    Object.values(formErrors).forEach((val) => {
      val !== "" && (valid = false);
    });
    return valid;
  };

  handleChange = (e) => {
    //debugger
    let { newPassword, confirmPassword } = this.state;
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = this.state.formErrors;
    //validEmailRegex.test(email);
    switch (name) {
      case "newPassword":
        formErrors.newPassword = validPassword.test(value)
          ? ""
          : "Atleast 8 characters with one upper case letter and a number";
        //formErrors.username = value !=""? "":"Email Req.";
        break;
      case "confirmPassword":
        formErrors.confirmPassword = value !== "" ? "" : "password Required";
        break;
      case "confirmPassword":
        formErrors.confirmPassword = value !== "" ? "" : "password Required";
        break;
    }
    this.setState({ formErrors: formErrors });
  };
  handleSubmit = (e) => {
    // debugger;
    let formErrors = this.state.formErrors;
    let { confirmPassword, newPassword } = this.state;
    validPassword.test(newPassword)
      ? (formErrors.newPassword = "")
      : (formErrors.newPassword = "Please Enter New Password");
    confirmPassword !== ""
      ? (formErrors.confirmPassword = "")
      : (formErrors.confirmPassword = "Please enter password");
    e.preventDefault();
    const isValid = this.formvalid(this.state.formErrors);
    if (isValid) {
      // debugger;
      const { match = {} } = this.props;
      const { params = {} } = match;
      const resetPasswordToken = params.token;
      axios
        .post(`${URL}/resetPassword `, {
          token: resetPasswordToken,
          newPassword: newPassword,
        })
        .then((res) => {
          // debugger;
          if (res.data.message === "Password reset successfully") {
            swal("Password has been reset Successfully!", "", "success");
            this.props.history.push(`/`);
          }
          else {
            swal("Session Expired", "", "error");
            this.props.history.push(`/`);
          }
        })
        .catch((err) => {
          console.log("Test", err);
          swal("", "Something Went Wrong", "error");
        });
    }
  };
  componentDidMount(){
    document.body.style.overflow = "auto";
    document.body.style.backgroundImage = "url(https://consiliumacademy.com/images/login-background.jpg)";
    document.body.style.backgroundSize = "cover";
  }

  render() {
    let { formErrors } = this.state;
    return (
      <div className="h-100">
      <Container fluid className="CouseMasterWp FormWp h-100">
        <Row className="h-100 align-items-center justify-content-center ">
          <Col md={6}>
            <Card className="shadow px-4 py-5 LoginCard">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col className="mb-4">
                      <div className="logoWp">
                        <img src={logo} alt="logo" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-4">
                      <h3 className="text-center blueText">Reset Password</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="loginWp">
                      <AvForm>
                        <Row>
                          <Col md={6} className="colWp">
                            <AvGroup>
                              <Label className="text-left">New Password</Label>
                              <AvInput
                                type="password"
                                name="newPassword"
                                className="userinput"
                                onChange={this.handleChange}
                                required
                                placeholder=""
                              />

                              {/* <FontAwesomeIcon icon={faUser} /> */}
                              {formErrors.newPassword !== "" ? (
                                <Label style={{ fontSize: 12, color: "red" }}>
                                  {formErrors.newPassword}
                                </Label>
                              ) : null}
                            </AvGroup>
                          </Col>
                          <Col md={6}>
                            <AvGroup>
                              <Label className="text-left"> Confirm Password</Label>
                              <AvInput
                                type="password"
                                name="confirmPassword"
                                onChange={this.handleChange}
                                className="userinput"
                                required
                                placeholder=""
                              />
                              {/* <FontAwesomeIcon icon={faLock} /> */}
                              {formErrors.confirmPassword !== "" ? (
                                <Label style={{ fontSize: 12, color: "red" }}>
                                  {formErrors.confirmPassword}
                                </Label>
                              ) : null}
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center mt-3">
                            <div className="center-block text-center">
                              <Button
                                className="text-center mr-3 btnblue pl-3 pr-3"
                                onClick={this.handleSubmit}
                              >
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col className="forgttxt mt-3 text-center">
                            Already have account ?{" "}
                            <a href="/Login">
                              <b>Login In </b>
                            </a>
                            now
                          </Col>
                          <Col className="forgttxt mt-3 text-center">
                            Don't have account ?{" "}
                            <a href="/Registration">
                              <b> SignUp </b>
                            </a>
                            now
                          </Col> */}
                        {/* </Row> */}
                      </AvForm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}
// const mapStoreToProps = (state) => {
//   //debugger
//   return {
//     Profile: state.profile.profile,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   setProfile: (profile) => dispatch(setProfile(profile)),
// });
//export default connect(mapStoreToProps, mapDispatchToProps)(ResetPassword);
export default ResetPassword;
