import React from 'react';
import {AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Col,Row,Card} from 'react-bootstrap';
import { Label} from 'reactstrap';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';

export default class ChangePwdForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ChangePasswordDetail:{
          CurrentPassword:"",
          NewPassword:"",
          ConfirmNewPassword:""
      },
      formErrors:{
          CurrentPassword:"",
          NewPassword:"",
          ConfirmNewPassword:"",
      },
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event){
    const { name,value} = event.target;
    this.setState({[name]: value});
    let {ChangePasswordDetail}= this.state
    ChangePasswordDetail [event.target.name]= event.target.value;
    this.setState({ChangePasswordDetail: ChangePasswordDetail});
    let formErrors = this.state.formErrors;
    switch(name){
      case 'CurrentPassword':
      formErrors.CurrentPassword = value !== "" ? "":"Please enter current password.";
      break;
      case 'NewPassword':
      formErrors.NewPassword = value === ""?"Please enter new password.": !value.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)? "Please enter secure and strong password":"";
      break;
      case 'ConfirmNewPassword':
      formErrors.ConfirmNewPassword = value === ""?"Please re enter password.": ChangePasswordDetail.NewPassword !== value ?"password doesn't match":"";
      break;         
    }
    this.setState({formErrors:formErrors})  
  }

  handleSubmit(event) {
    event.preventDefault();
    let password = this.state.ChangePasswordDetail.NewPassword;
    let oldPassword = this.state.ChangePasswordDetail.CurrentPassword;
    const isValid = this.formvalid(this.state.formErrors);
    if(isValid){
      axiosApiInstance.put(`${URL}/updateProfile`,{password,oldPassword})
        .then(response =>{
        const res = response.data.User;
        if(response.status == 200){
          alert("Password Updated Successfully")
        }
      }).catch(err=>{
        console.log(err);
      });
    }   
  }
  
  formvalid = formErrors =>{
    //debugger
    let valid = true;
    let {CurrentPassword,NewPassword,ConfirmNewPassword} = this.state.ChangePasswordDetail;
    CurrentPassword !== ''?formErrors.CurrentPassword ="":formErrors.CurrentPassword = "Please enter current password.";
    NewPassword === ''?formErrors.NewPassword="Please enter new password.":!NewPassword.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)?formErrors.NewPassword = "Please enter secure and strong password.":formErrors.NewPassword ="";
    ConfirmNewPassword === ''?formErrors.ConfirmNewPassword ="Please re enter password.":NewPassword !== ConfirmNewPassword ?formErrors.ConfirmNewPassword = "password doesn't match":formErrors.ConfirmNewPassword = "";

    this.setState({formErrors:formErrors})
    Object.values(formErrors).forEach(val =>{
      val !== "" && (valid = false);
    }) ;
    return valid;
  } 
   
  render(){
    const {formErrors} = this.state;
    return (
      <div className="wrp whitebg userProfWp  pt-4 pl-4 pr-4 mt-0">
       <Row>
          <Col className="mb-3">
           <h5 className="hdtxt">Change Password</h5>
          </Col>
      </Row>
      <Card className="p-3">
        <Row>
          <Col>
            <AvForm onSubmit={this.handleSubmit}>
            <AvGroup>
                <Label for="CurrentPassword">Current Password</Label>
                <AvInput type="password" name="CurrentPassword" required id="CurrPwdId" value={this.state.CurrentPassword} onChange={this.handleChange}/>
                  {
                      formErrors.CurrentPassword !== "" ?
                      <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.CurrentPassword}</Label>: null
                  }
              </AvGroup>
              <AvGroup>
                <Label for="NewPassword">New Password</Label>
                <AvInput type="Password" name="NewPassword" id="NewPwdId" required value={this.state.NewPassword} onChange={this.handleChange}/>
                {
                      formErrors.NewPassword !== "" ?
                      <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.NewPassword}</Label>: null
                }
              </AvGroup>
              <AvGroup>
                <Label for="ConfirmNewPassword">Confirmed New Password</Label>
                <AvInput type="Password" name="ConfirmNewPassword" id="ConfmNwPwdId" required  value={this.state.ConfirmNewPassword} onChange={this.handleChange}/>
                {
                      formErrors.ConfirmNewPassword !== "" ?
                      <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.ConfirmNewPassword}</Label>: null
                }
              </AvGroup>
              <AvGroup>
                <Row>
                    <Col md={12}>
                        <Button variant="primary" className=" w-100 " onClick={this.handleSubmit}>Change Password</Button>
                    </Col>
                </Row>
              </AvGroup>
            </AvForm>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
}