import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
import {URL} from '../../../ServerCall/Config';
import moment from 'moment';
//import { scheduleData } from './datasource';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';

export default class ViewSchedule extends React.Component{
    constructor() {
        super(...arguments);
        this.ScheduleData = [];
        axiosApiInstance.get(`${URL}/courseScheduleByUser`)
        .then(res=>{
         // debugger
          //  console.log(res,"res");
            if(res.status === 200)
            {
              //debugger
              //console.log("response",res);
              let schedule = res.data.scheduleData;
              schedule&&schedule.map((item,index)=> {
              if(item.date !== undefined && item.date.length > 0){
              if(item.endTime.length > 0){
                //debugger
               let startDate = moment(item.date[0]).format('YYYY-MM-DD');
               var startTime = item.endTime[0][0].slice(0, -8);
               var endTime = item.endTime[0][0].slice(8);
              //  var qwertyTime = startTime.split(':').join(',');
              //  var endTime = endTime.split(':').join(',');
              //  var newDate = startDate.split('-').join(',');
              //  var newDateStartTime = newDate+","+qwertyTime;
              //  var newDateEndTime = newDate+","+endTime;
              //  console.log(newDateStartTime);
              //  console.log(newDateEndTime);
               this.ScheduleData.push({Id:index+1,Subject:item.course,Course:item.course,Lesson:item.lesson,Description:item.description,
                           Location:item.location,StartTime: moment(startDate + " " +startTime ).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),EndTime:moment(startDate + " " +endTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),CategoryColor: "#1aaa55"
                })
               }
              }
              });
                //console.log("data",this.ScheduleData);
               // this.data = this.ScheduleData;
            }
        })
        .catch(err=>{
          console.log(err);
        });
        this.data = this.ScheduleData;
    }

    onPopupOpen(args) {
      let isCell = args.target.classList.contains('e-work-cells') || args.target.classList.contains('e-header-cells') || args.target.classList.contains('e-all-day-cells');
      if (args.data.Id === undefined && args.type === "QuickInfo" && isCell) { 
        args.cancel = true; 
      } 
      if (args.type === 'Editor') {
          args.cancel = true; 
      }
    }
    content(props) {
      //debugger
      return (<div>
      {props.elementType === 'event' && props.elementType !== "cell" ?
            <div className="e-event-content e-template">
            <div className="e-subject-wrap">
              {(props.Subject !== undefined) ?
                <div className="lesson">{props.Lesson}</div> : ""}
              {(props.Location !== undefined) ?
                <div className="location"><a href={props.Location}>{props.Location}</a></div> : ""}
              {(props.StartTime !== undefined) ?
                <div className="start-time">Start: {props.StartTime.toLocaleString()}</div> : ""}
               {(props.EndTime !== undefined) ?
                <div className="end-time">End: {props.EndTime.toLocaleString()}</div> : ""}
            </div>
          </div>:null}
      </div>);
    }

    render() {
      //debugger
      //console.log(this.data,"dataaaaa");
        return <ScheduleComponent width='100%' height='550px' selectedDate={new Date()} ref={schedule => this.scheduleObj = schedule} eventSettings={{ dataSource: this.data }} 
        quickInfoTemplates={{ content: this.content.bind(this) }}
        popupOpen={this.onPopupOpen.bind(this)}
        >
          <ViewsDirective>
            <ViewDirective option='Day'/>
            <ViewDirective option='Week'/>
            <ViewDirective option='WorkWeek'/>
            <ViewDirective option='Month'/>
            <ViewDirective option='Agenda'/>
          </ViewsDirective>
          <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
        </ScheduleComponent>;
    }
}
// ReactDOM.render(<App />, document.getElementById('schedule'));