import React from "react";
import { Row,Col,Container,Card} from 'reactstrap';
import {Form,InputGroup,} from 'react-bootstrap';
import MediaCard from '../../../Components/Faculty/Course/MediaCard';
import PaginationLine from  '../../../Components/Faculty/Course/PaginationLine';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { Redirect } from 'react-router-dom';
import {URL} from '../../../ServerCall/Config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import Auth from '../../../ServerCall/Auth';
import parse from 'html-react-parser';

export default  class CourseAllocated extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            Coursedata:[],
            isRedirect: false,
            courseId:'',
            itemsPerPage : 8,
            page :1,
            noOfPages : null,
            filter:'',
            filteredCourseData:[],
        }
      }

      getCourseData () {
        let user_id = Auth.getUserId();
        axiosApiInstance.get(`${URL}/courseByFaculty/${user_id}`)
        .then(res =>{
            //debugger
            const response = res.data.Course;
            console.log(res);
            let  CourseData = [];
            //debugger
            response&&response.map(data =>{
                if(data.courseType==="Prerecorded"){CourseData.push({Id:data._id,courseName:data.courseName,description:data.description,defaultimage:data.imageOrVideoPath.default})}
              })
              let pages = Math.ceil(CourseData.length / this.state.itemsPerPage);
              this.setState({Coursedata: CourseData,noOfPages:pages});
          }).catch(err=>{
            console.log(err)
          });
      }
      componentDidMount (){
       this.getCourseData();
      }

      handlePageChange = async(e,value) => {
        //debugger
        await this.setState({page:value});
      };

      redirect = (Id) => {
        //debugger
        this.setState({ isRedirect: true,courseId:Id });
      }

      handleChange = event =>{
        //debugger
        this.setState({filter:event.target.value});
      }

      render(){
          //debugger
          let{ Coursedata,filter,filteredCourseData}=this.state;
          const lowercasedFilter = filter.toLowerCase();
          filteredCourseData = Coursedata.filter(course =>
          course.courseName.toLowerCase().includes(lowercasedFilter));
        return( 
                <Container fluid className="CardContainer CourseAllocated CourseWp">
                  <Card  className="shadow p-4 col-md-12 marginauto">
                  <Row className="mb-5  mt-4 pl-3">
                      <Col md={6}>
                          <h3 className="hdtxt">Pre-Recorded Course</h3>
                      </Col>  
                      <Col>
                      <Form.Row>
                          <Form.Group as={Col} className="mb-0">
                              <InputGroup>
                                  <InputGroup.Prepend>
                                      <InputGroup.Text >
                                          <FontAwesomeIcon icon={faSearch} />
                                      </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                      type="text"
                                      placeholder="Search Your Course.."
                                      value={filter}
                                      onChange={this.handleChange}
                                  />
                              </InputGroup>
                          </Form.Group>
                      </Form.Row>
                      </Col>
                  </Row>
                    <Row>
                    {Coursedata&&Coursedata&&filteredCourseData.slice(((this.state.page - 1)*(this.state.itemsPerPage)), (this.state.page)*(this.state.itemsPerPage)).map(item =>
                        <Col md={3} className="CourseWpCol mb-3">
                           <MediaCard courseDetail={() =>this.redirect(item.Id)} title={item.courseName}
                            text={parse(item.description)}
                            image={item.defaultimage}
                            //image={require('../../../Images/python.png')}
                            />
                          {this.state.isRedirect ? <Redirect to={{pathname:`/TabCourseForum/${this.state.courseId}` ,state:{scheduleId: null}}} /> : null }
                          {/* {this.state.isRedirect ? <Redirect to={`/CourseDetail?id=${this.state.courseId}`} /> : null } */}
                          {/* {this.state.isRedirect ? <Redirect to={`/CourseMaster?id=${this.state.courseId}`} /> : null } */}
                        </Col>
                         )}
                    </Row>
                    <Row className="mt-5">
                      <Col md={7} sm={6} xs={12}>
                          
                      </Col>
                      <Col md={5} sm={6} xs={12} className="float-right pgWp">
                          <PaginationLine
                            noOfPages={this.state.noOfPages}
                            page={this.state.page}
                            handlePageChange={this.handlePageChange}
                          />
                      </Col>
                  </Row>
                    </Card>
                </Container>
        );
      }
}