import React from 'react';
import CountDownTimer from './CountDownTimer'

function QuestionCount(props) {
  return (
    <div className="questionCount">
      Question <span>{props.counter + 1}</span> of <span>{props.total}</span>
       {props.duration === null ? <CountDownTimer timeLimit={props.timeLimit} timeCounterDecrement={props.timeCounterDecrement}
          //timeCounter={question.timeCounter}
        /> : null}
       {/* {props.counter === 5 ? (<a className="result-link" href="" onClick={props.viewreults}>View Results</a>) : (<div></div>)} */}
    </div>
  );

}

export default QuestionCount;