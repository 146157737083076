
  export const setProfile = (profile) => dispatch => {
   // debugger
    dispatch({
      type:SET_PROFILE,
      payload:profile
    })
  }


export const SET_PROFILE='SET_PROFILE'
