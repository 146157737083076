import React from 'react'
import {Grid} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row,Col} from 'reactstrap';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import WebIcon from '@material-ui/icons/Web';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SaveIcon from '@material-ui/icons/Save';
import {URL} from '../../../ServerCall/Config';
import _, { result } from 'lodash'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';

function EditQuestionTab(props) {
  const [questions, setQuestions]= React.useState([]);
  const [state, setState] = React.useState({
    checkedH:false,
    checkedT:false,
    timeLimit:null,
    score:null
  });

  // const handleChange = (event) => {
  //   debugger
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const handleChangeRadio = async(event,index,j) => {
    //debugger
    let value = event.target.value;
    let name = event.target.name;
    var optionsOfQuestion = [...questions];
    optionsOfQuestion.map((ques, i)=>{
      if (index == i) {
        ques.answer = [value];
      }})
      setQuestions(optionsOfQuestion);
      return optionsOfQuestion;
  };

  const handleChangeText = (e,index) => {
    //debugger
    let newArr = questions.map((item, i) => {
      if (index == i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setQuestions(newArr);
    return newArr;
  };

  const handleChangeActive = (e,i) => {
    //debugger
    var updateData = questions[i];
    let newArr = questions.map((item, index) => {
      if (i == index) {
        return { ...item, [e.target.name]: e.target.checked };
      } else {
        return item;
      }
    });
    setQuestions(newArr);
    let qesId = updateData._id;
    let questionBank = {
      "status": e.target.checked
    };
    //console.log(questionBank,"questionBank");
      
    axiosApiInstance.put(`${URL}/question/${qesId}`,questionBank)
    .then(res =>{
      //debugger
      //console.log(res,"res");
      expandCloseAll();
    }).catch(err=>{
      console.log(err,"err")
    })
   
  };

  const handleCheckChieldElement = (e,text,index) => {
    //debugger
    var optionsOfQuestion = [...questions];
    let checked = e.target.checked;
    optionsOfQuestion.map((ques, i)=>{
      if (index == i && checked) {
        ques.answer.push(text);
        }
        else
        {
          var indexData = ques.answer.indexOf(text)
          if (indexData !== -1) {
            ques.answer.splice(indexData, 1);
          }
        }
      })
      setQuestions(optionsOfQuestion);
      return optionsOfQuestion;
  }

  React.useEffect(()=>{
        //debugger
        if(props.getQuestions.length > 0 ){
         const result = _.flatMap(props.getQuestions, ({ sectionName, questions }) =>
            _.map(questions, question => ({ sectionName, ...question})) 
          );
          //console.log("editQuestions",result);
          setQuestions(result);
          }
          props.handleUseEffect(result);
  },[props.getQuestions]);
  
  function updateQuestion(i){
    const userId = Auth.getUserId();
    var updateData = questions[i];
    let qesId = updateData._id;
    let questionBank = {
      "facultyUserId": userId,
      "courseId":props.courseId,
      "question":updateData.question,
      "images": updateData.images,
      "files": updateData.files,
      "questionType":updateData.questionType,
      "minOptions": updateData.options.length,
      "options":updateData.options,
      "answer": updateData.answer,
      "timeLimit": updateData.timeLimit,
      "score": updateData.score,
      "status": updateData.status
    };
    //console.log(questionBank,"questionBank"); 

    axiosApiInstance.put(`${URL}/question/${qesId}`,questionBank)
    .then(res =>{
      //debugger
      //console.log(res,"res");
      expandCloseAll();
    }).catch(err=>{
      console.log(err,"err");
    });
  }

  function handleOptionValue(text,i, j){
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].options[j] = text;
      setQuestions(optionsOfQuestion);
  }

  function handleQuestionValue(text, i){
    //debugger
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].question = text;
      setQuestions(optionsOfQuestion);
  }

  function onDragEnd(result) {
  if (!result.destination) {
    return;
  }
  var itemgg = [...questions];

  const itemF = reorder(
    itemgg,
    result.source.index,
    result.destination.index
  );

  setQuestions(itemF);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  function showAsQuestion(i){
    let qs = [...questions];  
     qs[i].open = false;
     setQuestions(qs);
  }

  function addOption(i){
    //debugger
    var optionsOfQuestion = [...questions];
    if(optionsOfQuestion[i].options.length < 5){
      optionsOfQuestion[i].options.push("Option " + (optionsOfQuestion[i].options.length + 1))
    } else{
      console.log("Max  5 options ");  
    }
    setQuestions(optionsOfQuestion)
  }

  function removeOption(i, j){
    //debugger
    var optionsOfQuestion = [...questions];
    if(optionsOfQuestion[i].options.length > 1){
      optionsOfQuestion[i].options.splice(j, 1);
      setQuestions(optionsOfQuestion);
      console.log(i + "__" + j);
      return optionsOfQuestion;
    }   
  }

  function expandCloseAll(){
    let qs = [...questions]; 
     for (let j = 0; j < qs.length; j++) {  
      qs[j].open = false;
     }
     setQuestions(qs);
  }

  function handleExpand (i){
    //debugger
    let qs = [...questions]; 
    for (let j = 0; j < qs.length; j++) {
      if(i ===j ){
        
        setState({score:qs[i].score,timeLimit:qs[i].timeLimit});
        if(qs[i].questionType == "multiple choice")
        {
          setState({checkedH:true});
        }
        qs[i].open = true;
 
      } else{
        qs[j].open = false;
       }
    }
     setQuestions(qs);
  }

  function editQuestionsUI(){

      return  questions.map((ques, i)=> (
        <>
        <h3 className="HeadingTest"> {ques.sectionName}</h3> 
        <Draggable key={i} draggableId={i + 'id'} index={i}>
            {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className="QtabWp">
                <div style={{marginBottom: "15px"}}>
                  <div style={{width:'100%', marginBottom: '-7px' }}>
                    <DragIndicatorIcon style={{transform: "rotate(-90deg)", color:'#DAE0E2'}} fontSize="small"/>
                  </div>
                  {/* <div>
                  // <h3 className="HeadingTest"> {ques.sectionName}</h3> 
                  </div> */}
                  {/* <Row>
                    <Col className="headingTxt">Question Set Form</Col>
                  </Row> */}
                
                  <Accordion onChange={()=>{handleExpand(i)}} expanded={questions[i].open}>
                    <AccordionSummary            
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      elevation={1} style={{width:'100%'}}
                    >
                    { !questions[i].open ? (
                    <div style={{display: 'flex',flexDirection:'column', alignItems:'flex-start', marginLeft: '3px', paddingTop: '15px', paddingBottom: '15px'}}>
                      
                      <Typography variant="subtitle1" style={{marginLeft: '0px'}}>{i+1}. {ques.question}</Typography>
                      {/* {ques.questionType !== "multiple choice" ?
                       getSelectedOption(ques.answer,i):null} */}
                      {ques.questionType === "single choice" || ques.questionType === "multiple choice"? ques.options.map((op, j)=>(

                      <div key={j}>
                        <div style={{display: 'flex'}}>
                          <FormControl component="fieldset">
                        
                                {ques.questionType !== "multiple choice" ?
                                
                                      <RadioGroup aria-label="" name="option" key={ques._id} value={questions[i].answer[0]}>
                                        <FormControlLabel  key={ques._id}  value={ques.options[j]} disabled={true}  control={<Radio style={{marginRight: '3px', }} />} label={ques.options[j]} />
                                        {/* <Typography variant="subtitle1" style={{marginLeft: '0px'}}>{ques.options[j]}</Typography> */}
                                      </RadioGroup>
                                        :
                                      <div>
                                        <FormControlLabel   key={op}  disabled={true} control={<Checkbox  checked={ques.answer.includes(op)}  name={ques.options[j]} color="primary" /> } label={ques.options[j]}/>
                                    </div>
                                } 
                          </FormControl>
                        </div>
                      </div>
                      )):""}  
                     
                    </div>            
                    ): ""}   
                    </AccordionSummary>
                   
                    <AccordionDetails>
                    <div  className="textareaWp" style={{display: 'flex',flexDirection:'column', alignItems:'flex-start', marginLeft: '15px', marginTop:'-15px',width:'100%'}}>
                      <div style={{display:'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Typography style={{marginTop:'20px',marginRight:'5px'}}>{i+1}.</Typography>
                        <TextField 
                              fullWidth={true} 
                              placeholder="Input your question title here" 
                              style={{marginBottom: '18px'}}  
                              // rows={2}
                              rowsMax={20}
                              multiline={true}
                              value={ques.question}
                              variant="outlined"
                              // variant="filled"
                            onChange={(e)=>{handleQuestionValue(e.target.value, i)}}
                            clasName="QtabTxtfield"
                        />
                        {/* <IconButton aria-label="upload image" onClick={()=>{uploadImage(i, null)}}>
                              <CropOriginalIcon />
                        </IconButton> */}
                      </div>
                      
                      <div style={{width: '100%'}}>
                        {!ques.options.length < 1? ques.options.map((op, j)=>(
                        
                        <div key={j}>
                              <div  style={{display:'flex',width:'50%', flexDirection:'row', marginLeft:'-12.5px', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px'}}>
                                
                                   {ques.questionType !== "multiple choice" ?
                                      <RadioGroup aria-label="option" name="option1" value={ques.answer[0]} onChange={(e)=>{handleChangeRadio(e,i,j)}}>
                                      <FormControlLabel value={ques.options[j]}  control={<Radio />}/>
                                      </RadioGroup>:
                                      <FormControlLabel  key={op} control={<Checkbox  checked={ques.answer.includes(op)} onChange={(e) => handleCheckChieldElement(e,op,i)}  name={ques.options[j]} color="primary" /> }/>
                                  }
                                  <TextField 
                                    fullWidth={true} 
                                    placeholder="Please enter a name for this option." 
                                    style={{marginTop: '5px'}} 
                                    value={ques.options[j]}
                                    onChange={(e)=>{handleOptionValue(e.target.value, i, j)}}
                                    variant="outlined"
                                  />
                                
                                  <IconButton aria-label="delete" onClick={()=>{removeOption(i, j)}}>
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                        </div>
                        )): 
                          
                          <div>
                              {!state.checkedT?
                                <div  className="smallText" style={{display:'flex', width:'100%' ,flexDirection:'row', marginLeft:'-12.5px', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px'}}>
                                
                                    <TextField 
                                      fullWidth={true} 
                                      placeholder="Enter your answer" 
                                      style={{marginTop: '5px',marginLeft:'30px'}} 
                                     // value={}
                                      variant="outlined"
                                      // onChange={(e)=>{handleOptionValue(e.target.value, i, j)}}
                                    />
                                  </div>
                                :
                                <div  style={{display:'flex', width:'100%' ,flexDirection:'row', marginLeft:'-12.5px', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px'}}>
                              
                                  <TextareaAutosize
                                    fullWidth={true} 
                                    placeholder="Enter your answer" 
                                    style={{marginTop: '5px',marginLeft:'30px'}} 
                                   // value={}
                                    variant="outlined"
                                    rowsMin={3} 
                                    className="LongAns"
                                    // onChange={(e)=>{handleOptionValue(e.target.value, i, j)}}
                                  />
                                </div>
                                
                                }
                                
                          </div>
                        
                        }
                      </div>  
                      
                      
                      {!ques.options.length < 1 && ques.options.length < 5 ? (
                        <div>
                        
                          <FormControlLabel disabled control={!state.checkedH?<Radio />:<Checkbox color="primary" /> } label={
                          <Button size="small" onClick={()=>{addOption(i)}} style={{textTransform: 'none', marginLeft:"-5px"}}>
                            Add New Option
                          </Button>
                        } /> 
                        
                        </div>
                      ): ""}

                      <Typography variant="body2" style={{color: 'grey'}}>{!ques.answer?"You can add maximum 5 options. If you want to add more then change in settings. Multiple choice single option is availible":""}</Typography>
                    </div>
                    </AccordionDetails>

                     <Divider />
                    <AccordionActions>   
                        {/* {!ques.options.length < 1?
                          <>
                          <Switch
                            checked={state.checkedH}
                            onChange={handleChange}
                            name="checkedH"
                            disabled
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            color="primary"
                              />
                          <span>Multiselection</span> 
                          </>:null
                          } */}
                            {/* {ques.options.length < 1 ?    
                            <div>
                              <Switch
                                checked={state.checkedT}
                                onChange={handleChange}
                                name="checkedT"
                                disabled
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color="primary"
                              />
                              <span>Long Answer</span> 
                            </div>:null
                          } */}
                           <Switch
                            checked={ques.status}
                            onChange={(e) => { handleChangeActive(e,i) }}
                            name="status"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            color="primary"
                            />
                          <span>Active/Inactive Question</span> 
                        

                          <Row>
                            {props.timeLimitOption == "Question wise"?
                              <Col>
                                <Typography variant="body2" style={{color: 'grey'}}>{"Time Limit(minutes)*"}</Typography>
                                <TextField key={ques._id} value={ques.timeLimit} name="timeLimit" variant="outlined"  placeholder="Time Limit"   onChange={(e) => { handleChangeText(e,i) }} />
                              </Col>
                              :null
                            }   
                              <Col>
                               <Typography variant="body2" style={{color: 'grey'}}>{"Score*"}</Typography>
                               <TextField  key={ques._id} value={ques.score} name="score"  variant="outlined" placeholder="Score"  onChange={(e) => { handleChangeText(e,i) }} />
                              </Col>
                          </Row> 
                        
                          <IconButton aria-label="View" onClick={()=>{showAsQuestion(i)}}>
                            <WebIcon className="webIcn" />
                          </IconButton>

                          {/* <IconButton aria-label="Copy" onClick={()=>{copyQuestion(i)}}>
                            <FilterNoneIcon />
                          </IconButton> */}
                          
                          <Divider orientation="vertical" flexItem/>

                          {/* <IconButton aria-label="delete" onClick={()=>{deleteQuestion(i)}}>
                            <DeleteOutlineIcon />
                          </IconButton> */}
                          <IconButton aria-label="update Question " onClick={()=>{updateQuestion(i)}}>
                            <SaveIcon />
                          </IconButton>
                          {/* <IconButton aria-label="Image">
                            <MoreVertIcon />
                          </IconButton> */}
                    </AccordionActions>
                  </Accordion>
               
                </div>
              </div>
          </div>
        )}
        </Draggable>
       
    </>
       )
      )
  }
  

  return (
       <div style={{marginTop:'15px', marginBottom: '7px', paddingBottom:"30px"}}>
           <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            >
             <Grid item xs={12} sm={12} style={{width: '100%'}}>
                  <Grid style={{paddingTop: '10px'}}>
                    <div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {editQuestionsUI()}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Grid>        
              </Grid>           
           </Grid>
       </div>
  );
}
export default EditQuestionTab;
