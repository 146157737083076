import React from "react";
import './Feed.css'
import {Row,Col,Container,Card,Table,FormGroup,FormLabel,FormControl,Form} from  'react-bootstrap'
import { Input, Button } from 'reactstrap'
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import swal from "sweetalert";
import { URL } from "../../../ServerCall/Config";
import { Link } from "react-router-dom";

export default class StudentFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrolledCourse: [],
      typeOfCourse: "",
      courseName: "",
      locationOfCourse: "",
      duration: "",
      facultyName: [],
      facultyId: "",
      generalEvaluations: [
        {
          question1: "",
          stronglyDisagree1: "",
          disagree1: "",
          neutral1: "",
          agree1: "",
          stronglyAgree1: ""
        },
        {
          question2: "",
          stronglyDisagree2: "",
          disagree2: "",
          neutral2: "",
          agree2: "",
          stronglyAgree2: ""
        },
        {
          question3: "",
          stronglyDisagree3: "",
          disagree3: "",
          neutral3: "",
          agree3: "",
          stronglyAgree3: ""
        },
        {
          question4: "",
          stronglyDisagree4: "",
          disagree4: "",
          neutral4: "",
          agree4: "",
          stronglyAgree4: ""
        },
        {
          question5: "",
          stronglyDisagree5: "",
          disagree5: "",
          neutral5: "",
          agree5: "",
          stronglyAgree5: ""
        },
        {
          question6: "",
          stronglyDisagree6: "",
          disagree6: "",
          neutral6: "",
          agree6: "",
          stronglyAgree6: ""
        },
        {
          question7: "",
          stronglyDisagree7: "",
          disagree7: "",
          neutral7: "",
          agree7: "",
          stronglyAgree7: ""
        },
        {
          question8: "",
          stronglyDisagree8: "",
          disagree8: "",
          neutral8: "",
          agree8: "",
          stronglyAgree8: ""
        }
      ],
      finalThoughts: [],
      con: "",
      stundentInfo: {
        studentName: "",
        userId: "",
        position: "",
        contacted: false,
        phoneOrEmail: "",
      },
    };
  }

  getFeedback(Id) {
    axiosApiInstance
      .get(`${URL}/feedbackById/${Id}`)
      .then((res) => {
        if (res.status === 200) {
          let feedback = res.data.Feedback;
            // console.log("Feedback", feedback);
          this.setState({
            courseName: feedback.courseName,
            duration: feedback.duration,
            locationOfCourse: feedback.locationOfCourse,
            typeOfCourse: feedback.typeOfCourse,
            finalThoughts: feedback.finalThoughts,
            studentName: feedback.stundentInfo.studentName,
            position: feedback.stundentInfo.position,
            contacted: feedback.stundentInfo.contacted,
            phoneOrEmail: feedback.stundentInfo.phoneOrEmail
          });
          let faculties = res.data.Feedback.faculties;
          var facultyName = [];
          faculties &&
            faculties.map(data => {
              facultyName.push(data.facultyName)
            })
          this.setState({facultyName: facultyName});
          
          if (feedback.stundentInfo.contacted === true) this.setState({ con: "Yes" });
          else this.setState({ con: "No" });
          let finalThoughts = res.data.Feedback.finalThoughts;
          var thoughtData = [];
          finalThoughts &&
            finalThoughts.map(data => {
              thoughtData.push({
                question: data.question,
                answer: data.answer
              });
            });
          this.setState({ finalThoughts: thoughtData });
          // console.log(this.state.finalThoughts);
          let genEval = res.data.Feedback.generalEvaluations;
          var genData = [];
          genEval &&
            genEval.map(data => {
              genData.push({
                question: data.question,
                stronglyDisagree: data.stronglyDisagree,
                disagree: data.disagree,
                neutral: data.neutral,
                agree: data.agree,
                stronglyAgree: data.stronglyAgree
              })
            })
          this.setState({generalEvaluations:genData});
        } else {
          swal(res.data.message, "", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentDidMount() {
    let Id = this.props.location.search.slice(4);
    this.getFeedback(Id);
  }
  
  checked(value){
        if (value === true) {
            return true;
      }
        else {
            return false;
      }
  }

  render() {
    const {
      locationOfCourse,
      duration,
      studentName,
      facultyName,
      position,
      con,
      contacted,
      phoneOrEmail,
      courseName,
      finalThoughts,
      generalEvaluations
    } = this.state;
    const getFacultyName = () =>{
      const Names = facultyName.join(', ');
      return Names;
    }
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 feedbackWp">
        <Card className="p-4">
          <Row>
            <Col className="mb-4">
              <h5 className="text-left blueText">Course Evaluation</h5>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 mb-1">
              {" "}
              <h6 className=" font-weight-bold"> General Information</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col className="mb-3">
              <h6 className="text-left">Workshop Information</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                bordered
                style={{ width: "50%" }}
                className="feedbckTbl dropdownTbl"
              >
                <tbody>
                  <tr>
                    <td>
                      Name of Workshop
                    </td>

                    <td>
                      {courseName}
                    </td>
                  </tr>
                  
                  <tr>
                    <td>
                      Location of Course
                    </td>
                    <td>
                      {locationOfCourse}
                    </td>
                  </tr>
                  
                  <tr>
                    <td> Course Length</td>
                    <td>
                      {duration}
                    </td>
                  </tr>
                  <tr>
                    <td>Faculty Name</td>
                    <td>
                      {getFacultyName()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3  mt-3 col-md-6">
              <h6 className="text-left font-weight-bold">
                About Trianee
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table style={{ width: "50%" }} bordered className="feedbckTbl">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Name</td>
                    <td>
                      {/* <Input
                        type="text"
                        onChange={this.handleChange}
                        name="studentName"
                        id="studentName"
                        value={studentName}
                      /> */}
                      {studentName}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Position</td>
                    <td>
                      {/* <Input
                        type="text"
                        name="position"
                        onChange={this.handleChange}
                        id="position"
                        value={position}
                      /> */}
                      {position}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Do you want to be contacted about your training
                      experience?
                    </td>
                    <td>
                        {/* <input
                          type="radio"
                          name="contacted"
                          onChange={this.handleConButton}
                          value="Yes"
                          id="Yes"
                        /> */}
                        {con}
                      {/* <label for="soundsignal" className="mr-5 ml-2">
                        Yes
                      </label>
                      <span>
                        {" "}
                        <input
                          type="radio"
                          name="contacted"
                          onChange={this.handleConButton}
                          value="No"
                          id="No"
                        />
                      </span>
                      <label for="soundsignal" className="ml-2">
                        No
                      </label> */}
                    </td>
                  </tr>
                  {contacted === true ? 
                  <tr>
                    <td>
                      If so, please provide your telephone number and/or e-mail
                      address.
                    </td>
                    <td>
                    {phoneOrEmail}
                        {/* <Input
                          type="text"
                          name="phoneOrEmail"
                          onChange={this.handleChange}
                          id="phoneOrEmail"
                          value={phoneOrEmail}
                        />
                       */}
                    </td>
                    </tr>
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3  mt-3 col-md-6">
              <h6 className="text-left  font-weight-bold">
                General Evaluation 
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table bordered className="feedbckTbl">
                <thead>
                  <tr>
                    <th>Details</th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Neutral</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td id="question1">
                      Overall, the training session was high quality.
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question1"
                        id="sdisagree1"
                        value={generalEvaluations[0].stronglyDisagree}
                        checked={this.checked(generalEvaluations[0].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question1"
                        id="disagree1"
                        value={generalEvaluations[0].disagree}
                        checked={this.checked(generalEvaluations[0].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question1"
                        id="neutral1"
                        value={generalEvaluations[0].neutral}
                        checked={this.checked(generalEvaluations[0].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question1"
                        id="agree1"
                        value={generalEvaluations[0].agree}
                        checked={this.checked(generalEvaluations[0].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question1"
                        id="sagree1"
                        value={generalEvaluations[0].stronglyAgree}
                        checked={this.checked(generalEvaluations[0].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The course covered the material I expected.
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question2"
                        id="sdisagree2"
                        value={generalEvaluations[1].stronglyDisagree}
                        checked={this.checked(generalEvaluations[1].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question2"
                        id="disagree2"
                        value={generalEvaluations[1].disagree}
                        checked={this.checked(generalEvaluations[1].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question2"
                        id="neutral2"
                        value={generalEvaluations[1].neutral}
                        checked={this.checked(generalEvaluations[1].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question2"
                        id="agree2"
                        value={generalEvaluations[1].agree}
                        checked={this.checked(generalEvaluations[1].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question2"
                        id="sagree2"
                        value={generalEvaluations[1].stronglyAgree}
                        checked={this.checked(generalEvaluations[1].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      This training will be beneficial to me in the performance
                      of my job.
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question3"
                        id="sdisagree3"
                        value={generalEvaluations[2].stronglyDisagree}
                        checked={this.checked(generalEvaluations[2].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question3"
                        id="disagree3"
                        value={generalEvaluations[2].disagree}
                        checked={this.checked(generalEvaluations[2].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question3"
                        id="neutral3"
                        value={generalEvaluations[2].neutral}
                        checked={this.checked(generalEvaluations[2].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question3"
                        id="agree3"
                        value={generalEvaluations[2].agree}
                        checked={this.checked(generalEvaluations[2].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question3"
                        id="sagree3"
                        value={generalEvaluations[2].stronglyAgree}
                        checked={this.checked(generalEvaluations[2].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      I had plenty of opportunities to practice what I learned.
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question4"
                        id="sdisagree4"
                        value={generalEvaluations[3].stronglyDisagree}
                        checked={this.checked(generalEvaluations[3].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question4"
                        id="disagree4"
                        value={generalEvaluations[3].disagree}
                        checked={this.checked(generalEvaluations[3].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question4"
                        id="neutral4"
                        value={generalEvaluations[3].neutral}
                        checked={this.checked(generalEvaluations[3].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question4"
                        id="agree4"
                        value={generalEvaluations[3].agree}
                        checked={this.checked(generalEvaluations[3].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question4"
                        id="sagree4"
                        value={generalEvaluations[3].stronglyAgree}
                        checked={this.checked(generalEvaluations[3].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The course gave me specific ideas and tips to implement in
                      my workplace
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question5"
                        id="sdisagree5"
                        value={generalEvaluations[4].stronglyDisagree}
                        checked={this.checked(generalEvaluations[4].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question5"
                        id="disagree5"
                        value={generalEvaluations[4].disagree}
                        checked={this.checked(generalEvaluations[4].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question5"
                        id="neutral5"
                        value={generalEvaluations[4].neutral}
                        checked={this.checked(generalEvaluations[4].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question5"
                        id="agree5"
                        value={generalEvaluations[4].agree}
                        checked={this.checked(generalEvaluations[4].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question5"
                        id="sagree5"
                        value={generalEvaluations[4].stronglyAgree}
                        checked={this.checked(generalEvaluations[4].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The trainer was professional, well-prepared, and
                      knowledgeable about the topics at hand.
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question6"
                        id="sdisagree6"
                        value={generalEvaluations[5].stronglyDisagree}
                        checked={this.checked(generalEvaluations[5].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question6"
                        id="disagree6"
                        value={generalEvaluations[5].disagree}
                        checked={this.checked(generalEvaluations[5].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question6"
                        id="neutral6"
                        value={generalEvaluations[5].neutral}
                        checked={this.checked(generalEvaluations[5].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question6"
                        id="agree6"
                        value={generalEvaluations[5].agree}
                        checked={this.checked(generalEvaluations[5].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question6"
                        id="sagree6"
                        value={generalEvaluations[5].stronglyAgree}
                        checked={this.checked(generalEvaluations[5].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The training facility was well-equipped and comfortable
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question7"
                        id="sdisagree7"
                        value={generalEvaluations[6].stronglyDisagree}
                        checked={this.checked(generalEvaluations[6].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question7"
                        id="disagree7"
                        value={generalEvaluations[6].disagree}
                        checked={this.checked(generalEvaluations[6].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question7"
                        id="neutral7"
                        value={generalEvaluations[6].neutral}
                        checked={this.checked(generalEvaluations[6].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question7"
                        id="agree7"
                        value={generalEvaluations[6].agree}
                        checked={this.checked(generalEvaluations[6].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question7"
                        id="sagree7"
                        value={generalEvaluations[6].stronglyAgree}
                        checked={this.checked(generalEvaluations[6].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Overall, this course was worth my time and money.
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question8"
                        id="sdisagree8"
                        value={generalEvaluations[7].stronglyDisagree}
                        checked={this.checked(generalEvaluations[7].stronglyDisagree)}
                        />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question8"
                        id="disagree8"
                        value={generalEvaluations[7].disagree}
                        checked={this.checked(generalEvaluations[7].disagree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question8"
                        id="neutral8"
                        value={generalEvaluations[7].neutral}
                        checked={this.checked(generalEvaluations[7].neutral)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question8"
                        id="agree8"
                        value={generalEvaluations[7].agree}
                        checked={this.checked(generalEvaluations[7].agree)} 
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name="question8"
                        id="sagree8"
                        value={generalEvaluations[7].stronglyAgree}
                        checked={this.checked(generalEvaluations[7].stronglyAgree)} 
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3  mt-3 col-md-6">
              <h6 className="text-left  font-weight-bold">
                Final Thoughts
              </h6>
            </Col>
          </Row>
          {finalThoughts && finalThoughts.map(data =>
            <Row>
              <Col className="mb-4">
                <h7>Q. {data.question}</h7><br/><br/>
                <h6>Ans. {data.answer}</h6>
            </Col>
          </Row>
          )}
          <Row>
            <Col className="text-center mt-5">
              <div><Link to={"/StudentFeedbackList"}>
                <Button className="text-center " color="danger">
                Cancel
                </Button></Link>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}