import React from "react";
import './CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container,Button} from 'reactstrap';
// import DropDown from '../../../Components/Admin/Course/DropDown'
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import swal from "sweetalert";

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}

export default class CourseCategory extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        addmodl:[],
        addModalShow:false ,
        categoryDropdown: null,
        parentId:null,
        parentName:null,
        categoryName: "",
        selectCategoryName:null,
        SelectParentCategory:[],
        categoryId:0,
        istrue:false,
        columns: [
          { title: "Category Name", field: "categoryName"},
          { title: "Parent Category", field: "parentName"}
        ],
        LabelText:{
          categoryName:'',
          //selectCategoryName:'',
        },
        Data : [],
      }
      this.handleChange = this.handleChange.bind(this);
     }

    handleChange = e =>{
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    handledropChange = e =>{
      this.setState({
        [e.target.name]: e.target.value
      });
      const selectedIndex = e.target.options.selectedIndex;
      let parentId = e.target.options[selectedIndex].getAttribute('parent-key');
      let parentName = e.target.options[selectedIndex].getAttribute(' parent-name');
      let selectCategoryName = e.target.options[selectedIndex].getAttribute('selectCategoryName');
     
      this.setState({parentId:parentId,parentName:parentName,selectCategoryName:selectCategoryName});
    }

    Validation = () => {
      let {LabelText} = this.state;
      if (this.state.categoryName !=="" && this.state.categoryName !== null) {	
        LabelText.categoryName = "";
      } else{
        LabelText.categoryName  = "Please Enter Category Name ";
      }
      // if (this.state.selectCategoryName !=="" && this.state.selectCategoryName !== null) {	
      //   LabelText.selectCategoryName = "";
      // }else{
      //   LabelText.selectCategoryName  = "Please select Category";
      // }
      this.setState({LabelText: LabelText});
      if(LabelText.categoryName===""){
        return true;
      }else{
        return false;
      }
    }

    onClickCategory = () =>{
      const isValid = this.Validation();
      if(isValid){
        //debugger
        const {categoryName,categoryDropdown,selectCategoryName} = this.state;
        let parentId = categoryDropdown;
        let parentName = selectCategoryName;
        axiosApiInstance.post(`${URL}/course/category`,{categoryName,parentId,parentName})
        .then(response =>{
          //debugger
          const res = response.data.category;
          if(response.status === 201){
           // alert("Category Added")
            this.setState({categoryName: "",categoryDropdown: null,selectCategoryName: null});
            this.getTableData();
            swal("Category Added successfully", "", 'success')
          }
          else if(response.data.status === 400){
            swal("Course category already exists", "Please enter unique category name","warning"); 
          }
          else{   
            swal("Something went wrong", "Please try again!", 'warning');
          }
        }).catch(err=>{
          console.log(err);
        });
        
      }
    }

    onUpdateCategory = () => {
      const isValid = this.Validation();
      if(isValid){
        const {categoryId,categoryName,categoryDropdown,selectCategoryName} = this.state
        let parentId = categoryDropdown;
        let parentName = selectCategoryName;
        //debugger
        axiosApiInstance.put(`${URL}/course/category/${categoryId}`,{categoryName,parentId,parentName})
        .then(response =>{
          //debugger
          const res = response.data.category;
          if(res !== [] || res!== undefined || res!== null){
            this.getTableData();
            this.onClickCancel();
            this.setState({istrue:false})
            swal("Category updated successfully", "", 'success')
          //  alert("Category Update"
           }
           else if(response.data.status === 400){
            swal("Course category already exists", "Please enter unique category name","warning"); 
          }
           else{ 
            swal("Something went wrong", "Please try again!", 'warning');  
           // alert("Something went wrong"); 
          }
        }).catch(err=>{
          console.log(err);
        });
      }  
    }

    onEdit =(editCategoryName,editCategoryId,parentId,parentName)=>{
      this.setState({categoryName:editCategoryName, parentId:parentId, selectCategoryName:parentName})
      this.setState({categoryId:editCategoryId, categoryDropdown:parentId})
      this.setState({istrue:true})
     }

    getTableData () {
      axiosApiInstance.get(`${URL}/course/category`)
      .then(res =>{
        let Categories = res.data.subCategories;
        //console.log("Categories",Categories)
        let Categorydata = [];
        if(res.status == 200){
          Categories.map(data =>{
            Categorydata.push({id:data._id,categoryName:data.categoryName,parentId:data.parentId,parentName:data.parentName})
          })
          this.setState({SelectParentCategory:Categorydata})
          this.setState({Data:Categories})
        }
      }).catch(err=>{
        console.log(err);
      });
     }

    componentDidMount (){
      this.getTableData()
    }

    onClickCancel = async() => {
      const {LabelText}=this.state;
      LabelText.categoryName="";
      await this.setState({categoryName: "", istrue: false, categoryId:0,categoryDropdown: "", parentName: "",
      parentId:""});
     
    }


render(){
  const{ Data, columns, LabelText, categoryName} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
       <Card className="shadow p-4 col-md-12">
       <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Course Category</h3>
         </Col>
       </Row>
       <Form>
            <Row>
           <Col md={6}>
            <FormGroup>
              <Label>Category</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="categoryName"  placeholder="" onChange={this.handleChange} value={categoryName}/>
                {LabelText.categoryName !== "" ?
                  <Label style={{ fontSize: 12, color: "red" }}>
                  {LabelText.categoryName }</Label >: null
                }
            </FormGroup>
           </Col>
           <Col md={6}>
            <FormGroup>
              <Label>Select Parent Category</Label>
                  <select id="Dropdown" name="categoryDropdown" className="form-control" value={this.state.categoryDropdown}
                    onChange={this.handledropChange}>
                    <option>Select category</option>
                      {
                        this.state.SelectParentCategory && this.state.SelectParentCategory.map(select => {
                          return (
                            <option
                              value={select.id}
                              parent-key={select.parentId}
                              parent-name={select.parentName}
                              selectCategoryName={select.categoryName}>
                              {select.categoryName}
                            </option>
                          )
                        })
                      }
                  </select>
                  {/* {LabelText.selectCategoryName !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.selectCategoryName }</Label >: null
                  } */}
            </FormGroup>
          </Col>
        </Row>
            <Row>
              <Col className="text-center mt-3">
                <div  className="center-block text-center">
                    {this.state.istrue ?
                      <Button className="text-center mr-3 btnblue"  onClick={this.onUpdateCategory}>Update</Button>           
                      :
                      <Button className="text-center mr-3 btnblue"  onClick={this.onClickCategory}>Submit</Button>          
                    }
                  <Button className="text-center " color="danger"  onClick={()=>{this.onClickCancel()}}>Clear</Button>
                </div>
              </Col>
            </Row>
        </Form>
        </Card>
          <Row className="mb-2 mt-5 mobileTop0">
          <Col className="tableWp mt-2">
            <Row>
              <Col className="DashboardTbl">
                <Table data={Data} pageSize={10} paging={true} columns={columns}search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='200px' 
                actions={
                  [
                    {
                      icon: 'edit',
                      tooltip: 'Edit',
                      onClick: (event, rowData) => {
                        //alert("Edit Action Clicked " + rowData.categoryName + rowData.id)
                        this.onEdit(rowData.categoryName,rowData._id,rowData.parentId,rowData.parentName)
                      }
                    }
                  ]
                }
                />
              </Col>
            </Row>
           </Col>
         </Row>
        </Card>
     </Container>
    );
  }
}