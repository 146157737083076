import React from 'react';
import { Input,FormGroup,Label } from 'reactstrap';

function AnswerOptionCheck(props) {

  return (
  
    <li className="answerOption">
      <FormGroup check disabled>
          <Label check>
            <Input type="checkbox" name="radio1" id="horizontal-list"
              //disabled = {props.disableOption}
              id={props.index}
              value={props.answerContent}
              //className={(props.selectedAnswer === props.index) ? 'selected-btn' : '' }
              onClick={props.onAnswerSelected}
            />{' '}
           {props.answerContent}
          </Label>
        </FormGroup>
    </li>
     
  );

}

export default AnswerOptionCheck;