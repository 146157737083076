import React from "react";

class Timer extends React.Component {
  render() {
    return (
		<div className="float-right">{this.props.value}:{this.props.seconds}</div>     
    );
  }
}

class CountDownTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: '00',
      value: this.props.timeLimit,
      secondsRemaining: 0
    }
    this.tick = this.tick.bind(this);
  }

  tick() {
    var min = Math.floor(this.state.secondsRemaining / 60);
    var sec = this.state.secondsRemaining - (min * 60);

    this.setState({
      value: min,
      seconds: sec
    })

    if (sec < 10) {
      this.setState({ seconds: "0" + this.state.seconds })
    }

    if (min < 10) {
      this.setState({ value: "0" + min })
    }

    if (min === 0 && sec === 0) {
      clearInterval(this.intervalHandle);
      //this.props.timeCounterDecrement(this.state.secondsRemaining);
    }
    
    if(this.state.secondsRemaining > 0){
      this.setState({secondsRemaining: this.state.secondsRemaining - 1});
    }
    //this.secondsRemaining--
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.secondsRemaining !== this.state.secondsRemaining){
      this.props.timeCounterDecrement(this.state.secondsRemaining);
    }
  }

  componentDidMount() {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.state.value;
    //this.secondsRemaining = time * 60;
    this.setState({secondsRemaining: time * 60});
  }

  render() {
    return (
      <div className="TimecounterWp">
        <Timer value={this.state.value} seconds={this.state.seconds} />
      </div>
    )    
  }
}

export default CountDownTimer;
