import React from 'react';
import {Conatiner,Row,Col, FormGroup,Container,Input,Label} from 'reactstrap'
import {Card,CardTitle, Button} from 'reactstrap';

const Comment = (props) => {
  return (
    <Container>
        
        <Row>
            <Col md={8}>
                <Card className="p-4">
                    <Row>
                        <Col>
                            <h3 className="text-center headingText" >Post</h3>
                        </Col>
                    </Row>
                    <CardTitle>Ask a Question</CardTitle>
                    <FormGroup>
                     <Label for="Question" className="Qlabeltext">How do I create a program that takes in a person’s first name and uses the ternary operator to check whether the name starts and ends with a vowel. The aim of the program is to give a special message once the user’s name starts and ends with a vowel?</Label>
                        <Col md={12} className="answerClass">

                        Following pattern matching with regex will Solve your problem:

                        String name="Isha";

                        Pattern pattern = Pattern.compile("^[aeiou].*.[aeiou]$", Pattern.CASE_INSENSITIVE);

                        Matcher matcher = pattern.matcher(name);

                        System.out.println(

                        "Pattern " + pattern.toString() + " matches for String: " + name + " ? " + matcher.matches());
                        </Col>
                    </FormGroup>
                    
                    <FormGroup>
                     <Input type="textarea" name="text" id="exampleText" placeholder="Add Comment"/>
                    </FormGroup>
                    <Button className="btnblue">Submit</Button>
                </Card>
            </Col>
        </Row>
    </Container>
  );
};

export default Comment;