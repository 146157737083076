import React from 'react';
class DropDown extends React.Component {
  constructor(props){
    super(props);
    this.state={
      value: this.props.value,
      name:"",
      options:this.props.options,
      title:this.props.title
    }
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.options !== this.props.options){
      await this.setState({ options: nextProps.options });
    }
    if (nextProps.value !== this.props.value) {
      await this.setState({ value: nextProps.value });
    }
  }

 onChange = async (e) => {
   const selectedIndex = e.target.options.selectedIndex;
  //console.log(e.target.options[selectedIndex].getAttribute('data-key'));
  let textValue = e.target.options[selectedIndex].getAttribute('data-key');
    await this.setState({
      value: this.props.value,
      name: "",
      options: this.props.options,
      title: this.props.title,
    });
    this.props.selectedCategory(this.state.value,this.state.name);
  };


  async componentWillReceiveProps(nextProps){
    //debugger
    if(nextProps.options !== this.props.options){
      await this.setState({ options: nextProps.options });
    }
    if (nextProps.value !== this.props.value) {
      //debugger
      await this.setState({ value: nextProps.value });
    }
  }

 onChange = async (e) => {
   const selectedIndex = e.target.options.selectedIndex;
  let textValue = e.target.options[selectedIndex].getAttribute('data-key');
    await this.setState({
      value: e.target.value,
      name: textValue
    });
    this.props.selectedCategory(this.state.value,this.state.name);
  };

  render() {
    const{options, title}=this.state;
    return (
      <div>
        <select className="form-control"
            value={this.state.value}
            onChange={this.onChange}
            disabled={this.props.disabled}
          >
            <option>{title}</option>
          {
              options && options.map(select => {
                  return (
                      <option
                        value={select.text}
                        data-key={select.value}
                       >
                          {select.value}
                      </option>
                     
                  )
              })
          }
       </select>
     </div>
    )
  }
}

export default DropDown;
