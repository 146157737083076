import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
// import DropDown from '../../../Components/Admin/Course/DropDown'
// import MultiSelect from '../../../Components/Admin/FacultyRegistration/MultiSelect'
import Table from  '../../../Components/Admin/Course/Table';
import   '../../../Components/Admin/Course/Table.css';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
// import { Tooltip } from "@material-ui/core";
import {URL} from '../../../ServerCall/Config';
import {ValidateEmail,Validatephone  } from "../../../Components/Common/Validation/Validation";
import swal from 'sweetalert';
import TestModel from'../../../Containers/Student/StudentRegistration/TestModel';
import EventNoteIcon from '@material-ui/icons/EventNote';
import InfoIcon from '@material-ui/icons/Info';

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}
var ID = 0;
const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

export default  class StudentRegistration extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      urlId:0,
      addmodl:[],
      addModalShow:false ,
      StudentId: 0,
      selectedGender : '',
      TestList:null,
      Test:[],
      studentIds:{},
      Studentdata:{
        firstName:'',
        middleName:'',
        lastName:"", 
        gender:'',
        email:'',
        password:'',
        contactNumber:'',
        address:'',
        state:'',
        city:'',
        pinCode:'',
        country:''
      },
      istrue: false,
      SelectGender:[
        {value: "Male", text: "Male"},
        {value: "Female", text: "Female"},
        {value: "Other", text:"Other"}
      ],
      LabelText:{
        firstName:'',
        middleName:'',
        lastName:"", 
        gender:'',
        email:'',
        password:'',
        contactNumber:'',
        address:'',
        state:'',
        city:'',
        pinCode:'',
        country:'',
        selectedGender:null,
        TestList:null
      },
    }
    this.handleChange = this.handleChange.bind(this);
  }

  selectedGender = (event) =>{
    let {selectedGender} = this.state
    selectedGender = event.target.value;
    this.setState({selectedGender:selectedGender})
  }

  // selectedTest = (value) =>{
  //   this.setState({TestList:value})
  // }
  formvalid = () =>{
    //debugger
    let valid = true;
    let {Studentdata,LabelText} = this.state
   validEmailRegex.test(Studentdata.email)? LabelText.Studentdata.email ="":LabelText.Studentdata.email = "Valid email address Req.";
   // username !== ''?formErrors.username ="":formErrors.username = "Please enter email";
   this.setState({LabelText:LabelText});
    Object.values(LabelText).forEach(val =>{
        val !== "" && (valid = false);
    }) ;
    return valid;
  }


  handleChange (event) {
  
    //alert(event.target.value)
    let {Studentdata,LabelText} = this.state
    Studentdata[event.target.name] = event.target.value;
    this.setState({Studentdata:Studentdata})
    const { name,value} = event.target;
    this.setState({[name]: value});
    switch(name){
      case 'email':
        LabelText.email=  validEmailRegex.test(value)?"":"Valid email address Req.";
        //formErrors.username = value !=""? "":"Email Req.";
      break;
    }
    this.setState({ LabelText: LabelText});
  }
  
// getTableData () {
//   axiosApiInstance.get(`${URL}/getStudentList`)
//   .then(res =>{
//     //debugger
//     let allStudentList = res.data.allStudentList;
//     if(res.status == 200){
//       this.setState({Data: allStudentList})
//     }
//   }).catch(err => {
//     console.log(err)
//   })
// }
onClickEditApi () {  
  
  var query = this.props.location.search;
  const params = new URLSearchParams(query);
  ID = params.get('id');
  this.setState({urlId:ID});
  const {Studentdata, selectedGender} = this.state;
  Studentdata.gender = selectedGender;
  const data = ID
    axiosApiInstance.get(`${URL}/student/${data}`)
    .then(res =>{
     // let selectedGender = this.state.gender ;
      let studentData = res.data.student;
      if(res.status == 200){
        this.setState({
          Studentdata:studentData,
          selectedGender:studentData.gender
        })
      }
    }).catch(err => {
      console.log(err)
    })
}


componentDidMount (){
 //this.getTableData();
 this.fetchTestListApi();
 this.onClickEditApi();
}

// componentDidUpdate (prevProps, prevState) {
//   debugger
//   var query = this.props.location.search;
//   const params = new URLSearchParams(query);
//   var Id = params.get('id');
//   if(prevProps.location.search !== '')
//   {
//     this.onClickCancel();
//   }
// }

validate=()=>{
  let {Studentdata,LabelText} = this.state;
    if (Studentdata.firstName !=="" && Studentdata.firstName !== null) {	
      LabelText.firstName = "";
      if (!Studentdata.firstName.match(/^[a-zA-Z ]*$/)) {
        LabelText.firstName = "*Please Enter only Character.";
      }
       }
     else{
      LabelText.firstName  = "Please Enter First Name";
     }
     if (Studentdata.middleName !=="" && Studentdata.middleName !== null) {	
      LabelText.middleName = "";
        if (!Studentdata.middleName.match(/^[a-zA-Z ]*$/)) {
          LabelText.middleName = "*Please Enter only Character.";
        }
        else{
          LabelText.middleName = "";
        }
       }
     if (Studentdata.lastName !=="" && Studentdata.lastName !== null) {	
      LabelText.lastName = "";
      if (!Studentdata.lastName.match(/^[a-zA-Z ]*$/)) {
        LabelText.lastName = "*Please Enter only Character.";
      }
       }
     else{
      LabelText.lastName  = "Please Enter Last Name";
     }
     let isEmailValid=ValidateEmail(Studentdata.email);
      if (isEmailValid) {	
          LabelText.email = "";
      }
      else if(Studentdata.email!==""){
          LabelText.email="Invalid Email"; 
      }
      else{
          LabelText.email = "Please Enter Email";
      }
      if(this.state.urlId == 0 || this.state.urlId == null){
        if (Studentdata.password !=="" && Studentdata.password !== null ) {	
          LabelText.password = "";
          // if (!Studentdata.password.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
          //   LabelText.password = "*Please enter secure and strong password.";
          //   }
          }
          else{
            LabelText.password = "Please Enter Password";
          }
      }
        if (Studentdata.address !=="" && Studentdata.address !== null) {	
            LabelText.address = "";
           }
         else{
          LabelText.address  = "Please Enter Address";
         }
        // let isContactNoValid=Validatephone(Studentdata.contactNumber);
        // if (isContactNoValid) {	
        //     LabelText.contactNumber = "";
        //     // if (!Studentdata.contactNumber.match(/^[0-9]+$/)) {
        //     //   LabelText.contactNumber = "*Please Enter only Number.";
        //     // }
        // }
         if(Studentdata.contactNumber!=="" && Studentdata.contactNumber !== null ){
              LabelText.contactNumber=""; 
          }
        else{
            LabelText.contactNumber = "please enter valid contact number";
          }
     if (Studentdata.state !=="" && Studentdata.state !== null) {	
      LabelText.state = "";
      if (!Studentdata.state.match(/^[a-zA-Z ]*$/)) {
        LabelText.state = "*Please Enter only Characters";
      }
       }
     else{
      LabelText.state  = "Please Enter State";
     }
     if (Studentdata.city !=="" && Studentdata.city !== null) {	
      LabelText.city = "";
      if (!Studentdata.city.match(/^[a-zA-Z ]*$/)) {
        LabelText.city = "*Please Enter only Characters";
      }
       }
     else{
      LabelText.city  = "Please Enter City";
     }
     if (Studentdata.pinCode !=="" && Studentdata.pinCode !== null) {	
      LabelText.pinCode = "";
      // LabelText.pinCode = "";
      //   if (!Studentdata.state.match(/^[0-9a-zA-Z(\-)]+$/)) {
      //       LabelText.state = "*Please Enter Valid Pincode";
      //     }
       }
     else{
      LabelText.pinCode  = "Please Enter Pincode";
     }
     if (Studentdata.country !=="" && Studentdata.country !== null) {	
      LabelText.country = "";
      if (!Studentdata.country.match(/^[a-zA-Z ]*$/)) {
        LabelText.country = "*Please Enter only Characters";
      }
       }
     else{
      LabelText.country  = "Please Enter Country";
     }
     if ( this.state.selectedGender !== '') {
      if(this.state.selectedGender == "Select Gender"){
          LabelText.selectedGender = "Please select Gender"
      }
      else{
          LabelText.selectedGender = null;
      }
    }
    else{
      LabelText.selectedGender = "Please select Gender"
    }
    
     this.setState({LabelText: LabelText});
     if(LabelText.firstName===""&& LabelText.lastName===""&&LabelText.lastName===""&&LabelText.email===""&&LabelText.password===""&&LabelText.selectedGender===null
     &&LabelText.contactNumber===""&&LabelText.city===""&&LabelText.state===""&&LabelText.pinCode===""&&LabelText.country===""&&LabelText.TestList===null)
         {
           return true;
         }
         else
         {
           return false;
         }
}

onClickStudentData = () => {
  const isValid = this.validate();
  if(isValid){
    const {Studentdata, selectedGender,} = this.state;
    Studentdata.gender = selectedGender;
    axiosApiInstance.post(`${URL}/addStudent`,Studentdata)
    .then(response =>{
      // debugger
      // console.log("response",response);
      if(response.status == 200) {
        if(response.data.status === 409){
          swal("User with this email Id already exist","","info");
        }
        else{
        swal( "Student added successfully", "", "success");
        this.onClickCancel();
        }
       // this.getTableData();
      }
      // else if(response.status === 409){   
      // }
      //  // swal("Something Went Wrong","", "error"); 
      // }
    }).catch(err => {
      console.log(err);
    }) 
  }   
}



  onUpdateStudentData =()=> {
    const isValid = this.validate();
    if(isValid){
    const {Studentdata, selectedGender, urlId} = this.state;
    Studentdata.gender = selectedGender;
    axiosApiInstance.put(`${URL}/student/${urlId}`, Studentdata)
    .then(response =>{
      if(response.status == 200) {
        if(response.data.status === 409){
          swal("User with this email Id already exist","","info");
        }
        else{
          swal("Student Data Updated","", "success")
           this.setState({istrue:false})
           this.onClickCancel();
        }
      } else{
        //swal("Something Went Wrong","", "error"); 
      }
    }).catch(err => {
      console.log(err)
    })
  }
  }
   
    onClickCancel = () => {
      let {Studentdata,LabelText} = this.state;
      Studentdata.firstName = "";
      Studentdata.firstName ="";
      Studentdata.middleName ="";
      Studentdata.lastName =""; 
      Studentdata.email ="";
      Studentdata.password ="";
      Studentdata.contactNumber ="";
      Studentdata.address ="";
      Studentdata.state ="";
      Studentdata.city ="";
      Studentdata.pinCode ="";
      Studentdata.country ="";
      this.setState({Studentdata: Studentdata, istrue: false, selectedGender:'', StudentId: 0,urlId: 0});
      LabelText.firstName = "";
      LabelText.firstName ="";
       LabelText.middleName ="";
      LabelText.lastName =""; 
      LabelText.selectedGender ="";
      LabelText.email ="";
      LabelText.password ="";
      LabelText.contactNumber ="";
      LabelText.address ="";
      LabelText.state ="";
      LabelText.city ="";
      LabelText.pinCode ="";
      LabelText.country ="";
   }

   fetchTestListApi = () =>{
    axiosApiInstance.get(`${URL}/getAllTest/`)
    .then(res =>{
      let TestData =[];
      let TestList = res.data.Test;
      if(res.status == 200)
      {
        TestList && TestList.map(data =>{
          TestData.push({text: data._id,value:data.testName})
        })
        this.setState({Test:TestData})
      }    
    }).catch(err => {
      console.log(err)
    })
  }
   


render(){
  const{Studentdata,LabelText,Test} =this.state;
  let addModalClose=() => this.setState({addModalShow:false});
    return(

    <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
       <Card className="shadow p-4 col-md-12">
       <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Student Registration</h3>
         </Col>
       </Row>
       <Form>
        <Row>
           <Col md={4}>
            <FormGroup>
              <Label>First Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="firstName"  placeholder="" onChange={this.handleChange} value={Studentdata.firstName}/>
                {LabelText.firstName !== "" ?
                <Label style={{ fontSize: 12, color: "red" }}>
                {LabelText.firstName }</Label >: null
			          }
            </FormGroup>
           </Col>
           <Col md={4}>
            <FormGroup>
              <Label>Middle Name</Label>
                <Input type="text" name="middleName" autoComplete="middleName" placeholder="" onChange={this.handleChange} value={Studentdata.middleName}/>
                {LabelText.middleName !== "" ?
                  <Label style={{ fontSize: 12, color: "red" }}>
                  {LabelText.middleName }</Label >: null
			          }
            </FormGroup>
           </Col>
           <Col md={4}>
            <FormGroup>
              <Label>Last Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="lastName"  placeholder="" onChange={this.handleChange} value={Studentdata.lastName}/>
                {LabelText.lastName !== "" ?
                <Label style={{ fontSize: 12, color: "red" }}>
                {LabelText.lastName }</Label >: null
			          }
            </FormGroup>
           </Col>
        </Row>
        <Row>
        <Col md={6}>
           <FormGroup>
           <Label>Select Gender</Label><span className="requiredFieldSpan">*</span>
            <select id="Dropdown" name="selectedGender" className="form-control" value={this.state.selectedGender}
             onChange={this.selectedGender}
            >
            <option>Select Gender</option>
            {
              this.state.SelectGender && this.state.SelectGender.map(select => {
                  return (
                      <option 
                      value={select.value}
                      data-key={select.value}
                       >
                           {select.text}
                      </option> 
                  )
              })
            }
          </select>
          {LabelText.selectedGender !== null ?
            <Label style={{ fontSize: 12, color: "red" }}>
            {LabelText.selectedGender }</Label >: null
          }
          </FormGroup>
           </Col>
           {/* <Col md={6}>
            <FormGroup>
              <Label>Gender</Label>
              <DropDown title="Select Gender" options={SelectGender} selectedCategory={this.selectedGender}/>
            </FormGroup>
           </Col> */}
           <Col md={6}>
                <FormGroup>
                <Label>Contact  No</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="contactNumber"  placeholder="" onChange={this.handleChange} value={Studentdata.contactNumber} />
                    {LabelText.contactNumber !== "" ?
                      <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.contactNumber }</Label >: null
			              }
                </FormGroup>
            </Col>
        </Row>
        <Row>
           
            <Col md={6}>
                <FormGroup style={{position:'relative'}}>
                <Label>Email</Label><span className="requiredFieldSpan">*</span>
                    <Input type="Email" name="email" autoComplete="new-password" disabled={this.state.urlId !== 0 && this.state.urlId !== null?true:false}  placeholder="" onChange={this.handleChange} value={Studentdata.email}/>
                    <span className="InfoSpan"><InfoIcon/></span>
                    <div className="validationGuide">
                        <span>1.Start with a character (a-z), an underscore (_), or a number (0-9)) <br/></span>
                        <span>2.Email Must having @ Sign. </span><br/>
                    </div>
                    {LabelText.email !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.email }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup style={{position:'relative'}}>
                <Label>Password</Label><span className="requiredFieldSpan">*</span>
                    <Input type="Password" name="password" autoComplete="new-password"  placeholder="" onChange={this.handleChange} value={Studentdata.password}/>
                      <span className="InfoSpan"><InfoIcon/></span>
                        <div className="validationGuide">
                            <span>1. At least 8 characters—the more characters <br/></span>
                            <span>2. A mixture of both uppercase and lowercase letters..<br/> </span>
                            {/* <span>3. Inclusion of at least one special character,e.g.,! @ # ?] <br/></span>
                            <span>4. A mixture of letters and numbers.</span> */}
                        </div>
                    {LabelText.password !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.password }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col>
          <FormGroup>
            <Label>Address</Label><span className="requiredFieldSpan">*</span>
            <Input type="textarea" name="address" id="Address" onChange={this.handleChange} value={Studentdata.address} />
            {LabelText.address !== "" ?
              <Label style ={{fontSize: 12, color: "red"}}>
              {LabelText.address}</Label>:null
            }
          </FormGroup>
          </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>City</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="city"  placeholder="" onChange={this.handleChange} value={Studentdata.city}/>
                    {LabelText.city !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.city }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Pincode</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="pinCode"  placeholder="" onChange={this.handleChange} value={Studentdata.pinCode}/>
                    {LabelText.pinCode !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.pinCode }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label>State</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="state"  placeholder="" onChange={this.handleChange} value={Studentdata.state}/>
                    {LabelText.state !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.state }</Label >: null
                    }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label>Country</Label><span className="requiredFieldSpan">*</span>
                    <Input type="text" name="country"  placeholder="" onChange={this.handleChange} value={Studentdata.country}/>
                    {LabelText.country !== "" ?
                    <Label style={{ fontSize: 12, color: "red" }}>
                    {LabelText.country }</Label >: null
                    }
                </FormGroup>
            </Col>
        </Row>
        {/* <Row>
            <Col md={12}>
                <FormGroup>
                <Label>Extra Activities</Label>
                    <Input type="text" name="Activities"  placeholder="" />
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col>
            <MultiSelect/>
          </Col>
        </Row> */}
        <Row>
          <Col className="text-center mt-5">
             <div  className="center-block text-center">
            {this.state.urlId !== 0 && this.state.urlId !== null ?
              <Button className="text-center mr-3 btnblue"  onClick={this.onUpdateStudentData}>update</Button>          
              :
              <Button className="text-center mr-3 btnblue"  onClick={this.onClickStudentData}>Submit</Button>    
            }
              <Button className="text-center clearBtn" color="danger"  onClick={()=>{this.onClickCancel()}}>Clear</Button>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
                  <TestModel 
                      show={this.state.addModalShow} onHide={addModalClose} selectedTest={this.selectedTest} Test={Test}
                      onClickCancelTestModel={this.onClickCancelTestModel} onUpdateTestData={this.onUpdateTestData} LabelText={LabelText.TestList}
                  />
              </Col> 
          </Row> */}
        </Form>
        </Card>
        {/* <Row className="mb-2 mt-5">
          <Col className="tableWp mt-2">
                <Row>
                  <Col className="DashboardTbl">
                    <Table data={Data} pageSize={10} paging={true} columns={columns} selection={true} search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='200px' 
                      actions ={
                        [
                          {
                            icon:'edit',
                            Tooltip:'Edit',
                            position:"row",
                            onClick: (event, rowData) => {
                              var data = [];
                             var dataFields = {
                                firstName:rowData.firstName,
                                middleName:rowData.middleName,
                                lastName:rowData.lastName, 
                                gender:rowData.gender,
                                email:rowData.email,
                                password:rowData.password,
                                contactNumber:rowData.contactNumber,
                                address:rowData.address,
                                state:rowData.state,
                                city:rowData.city,
                                pinCode:rowData.pinCode,
                                country:rowData.country
                              };
                              data.push(dataFields);
                              this.onEdit(data,rowData._id);
                            },

                          },
                          {
                            icon: () => <EventNoteIcon className="CalendarIcn"/>,
                            tooltip: 'Edit',
                            onClick: (evt, rowData) => {
                              // alert("Edit Action Clicked " + rowData._id )
                              this.onClickTestModel();
                            }
                          }
                        ]
                      }
                      onSelectionChange={
                        (event, rowData) => this.onSelection(rowData,event)
                      }
                    />
                  </Col>
              </Row>            
          </Col>
        </Row> */}
    
       </Card>
    </Container>
    );
}
}