import React from "react";
import react, { useRef } from "react";
import './CourseMaster.css'
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import {Card, Label,Col,Row,Container ,Button } from 'reactstrap';
import  {AvForm, AvGroup, AvInput}  from 'availity-reactstrap-validation';
// import moment from 'moment';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import {URL} from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance'; 
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import {Link} from 'react-router-dom';
import StudentModal from '../Course/StudentModal';
import Loader from '../../../Components/Common/Loader/Loader';


const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698'
}

export default class Schedule extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          scheduleId:null,
          courseId:'',
          courseName:'',
          facultyId:'',
          batchName:'',
          studentValue:[],
          studentArray:[],
          facultyName:'',
          defaultImage:'',
          chapter:[],
          courseType:'',
          description:'',
          duration:'',
          fees:'',
          scheduleStudent:[],
          categoryId:'',
          batchSize:'',
          addModalShow:false ,
          loading:false,
          //value: {label: 'Default value', key : '001'},
          formErrors:{ 
            courseId:'',
            courseName:'',
            facultyId:'',
            facultyName:'',
            selected:'', 
            batchName:''
         
            
          },
          selectRefCourse:null,
          selectRefFaculty:null,
          startDate:new Date(),
          endDate:new Date(),
            columns: [  
                { title: "Id", field: "_id",hidden: true},
                { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
                { title: "Course Name", field: "courseName"},
                { title: "Batch", field: "batchName"},
                { title: "Faculty Name", field: "mainFaculty[0].facultyFullName"},
                { title: "Start Date", field: "startDate", type: "date"},
                { title: "End Date", field: "endDate",type: "date"},
                { title: "Student Allocated", field: "studentIds", render: rowData=><span><Link onClick={()=>this.showStudent(rowData)}>{rowData.studentIds.length}</Link></span> }, 
                { title: "Schedule lesson", field: "_id", render: rowData => <Link to={`/CourseMaster?scheduleId=${rowData._id}`}>Click here</Link>}
              ],
              
              StudentColumns: [  
                { title: "Id", field: "userId",hidden: true},
                { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
                { title: "Student Name", render: rowData => <span>{rowData.firstName +" "+ rowData.middleName +" "+ rowData.lastName}</span>},
                { title: "Email", field: "email"}
              ],
              Data : [],
              course: [],
              Faculty: [],
              selectedCourse: [],
              selectedFaculty: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFaculty = this.handleChangeFaculty.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
       
    }

    addModalClose=() =>{this.setState({addModalShow:false})};

    showStudent = (data) =>{
      //debugger
     this.setState({loading:true});
      axiosApiInstance.post(`${URL}/getStudentDetailsByIds`,{"Ids":data.studentIds})
      .then(res=>{
        //debugger
        //console.log("resStudent",res);
        if(res.status === 200){
        
          let students = res.data.item;
          this.setState({scheduleStudent:students});
          this.setState({addModalShow:true,loading:false});
          
        }
        else{
          this.setState({addModalShow:true,loading:false});
        }
      })
      .catch(err=>{
        this.setState({addModalShow:false,loading:false});
        console.log("err",err);
      })

    }
    formvalid = formErrors =>{
      //debugger
      let valid = true;
      let { courseName,facultyName,batchName,} = this.state;

              courseName !== ''?formErrors.courseName ="":formErrors.courseName = "Please select course!";
              facultyName !== ''?formErrors.facultyName ="":formErrors.facultyName = "Please select course faculty!";
              batchName !== ''?formErrors.batchName = "":formErrors.batchName = "Please enter batch name!";
              // axiosApiInstance.get(`${URL}/allCourseSchedule`)
              // .then(res =>{
              //   debugger
              //   let scheduleData = [];
              //   scheduleData.push(res.data.CourseSchedule)
              //   scheduleData&&scheduleData.map(res=>{
              //     if(res.batchName===batchName){
              //       formErrors.batchName = "Same Batch Name Exists!"; 
              //     }
              //   })
              // })
              
              this.setState({formErrors:formErrors})
                    Object.values(formErrors).forEach(val =>{
                      //debugger
                      val !== "" && (valid = false);
              }) ;
              return valid;
    } 

    handleChangeText = e =>{
      //debugger
      const { name,value} = e.target;
      this.setState({[name]: value});
    }

    handleSubmit = e => {
      e.preventDefault();
      //debugger
      let startDate = new Date(this.state.startDate).toISOString();
      let endDate = new Date(this.state.endDate).toISOString();
      const {courseId,batchSize,facultyName,courseName,batchName,studentArray,studentValue,categoryId,facultyId,fees,duration,description,courseType,chapter,defaultImage} = this.state;
      let mainFaculty = {"facultyUserId":facultyId,"facultyFullName":facultyName};
      var imageOrVideoPath = {"default":defaultImage, "otherImages":[]};
      let studentIds = [];
      studentArray.map((value,index)=>{
        if( (index +1) <= batchSize){
        studentIds.push(value.value);
        }
      });
     // console.log("studentIds",studentIds);
      const isValid = this.formvalid(this.state.formErrors);
      if(isValid){
        if(this.state.studentArray.length>0){        
        axiosApiInstance.post(`${URL}/courseSchedule`,{courseId,startDate,endDate,batchName,studentIds,courseName,categoryId,fees,duration,description,mainFaculty,batchSize,courseType,chapter,imageOrVideoPath})
        .then(res =>{
          this.setState({loading:true});
         // debugger
          //console.log("res",res);
          if(res.data.status == 500){
            swal("Something went wrong", "Please try again!", 'error')
            this.setState({loading:false});
          }
          else if(res.data.status == 400){
            swal("Same Batch Name Exists", "Please try again!", 'error')
            this.setState({loading:false});
          } 
          else{
            
            this.getScheduleCourses();
            this.getFaculty();
            this.getCourse();
            swal("Course batch scheduled successfully", "Please schedule lesson for this batch.", 'info'); 
            this.setState({loading:false});
            this.state.selectRefCourse.select.clearValue();
            this.state.selectRefFaculty.select.clearValue();
            this.clearValue();
            this.setState({
              courseId:'',
              courseName:null,
              studentArray:[],
              facultyId:'',
              facultyName:'',
              defaultImage:'',
              chapter:[],
              courseType:'',
              description:'',
              duration:'',
              fees:'',
              categoryId:'',
              batchSize:'',
              startDate:new Date(),
              endDate:new Date(),
          });
          }
        }).catch(err=>{
          console.log(err);
        });
      }
    else
    {
      swal('Batch not Created!','No Students have Enrolled for this Course','error');
    }
  }
    }


    onUpdate = e => {
      e.preventDefault();
      //debugger
      let startDate = new Date(this.state.startDate).toISOString();
      let endDate = new Date(this.state.endDate).toISOString();
      const {scheduleId,courseId,batchSize,facultyName,courseName,batchName,studentArray,categoryId,facultyId,fees,duration,description,courseType,chapter,defaultImage} = this.state;
      let mainFaculty = {"facultyUserId":facultyId,"facultyFullName":facultyName};
      var imageOrVideoPath = {"default":defaultImage, "otherImages":[]};
      let studentIds = studentArray;
      const isValid = this.formvalid(this.state.formErrors);
      if(isValid){
                
        axiosApiInstance.put(`${URL}/courseSchedule/${scheduleId}`,{courseId,startDate,endDate,batchName,studentIds,courseName,categoryId,fees,duration,description,mainFaculty,batchSize,courseType,chapter,imageOrVideoPath})
        .then(res =>{
          this.setState({loading:true});
        //  debugger
          //console.log("res",res);
          if(res.data.status == 500){
            swal("Something went wrong", "Please try again!", 'error')
            this.setState({loading:false});
          } 
          else if(res.data.status == 400){
            swal("Same Batch Name Exists", "Please try again!", 'error')
            this.setState({loading:false});
          }
          else{
            this.getScheduleCourses();
            this.getFaculty();
            this.getCourse();
            swal("Batch schedule updated successfully", "Please schedule lesson accordingly.", 'info'); 
            this.setState({loading:false});
            this.state.selectRefCourse.select.clearValue();
            this.state.selectRefFaculty.select.clearValue();
            this.clearValue();
            this.setState({
              courseId:'',
              courseName:null,
              studentArray:[],
              facultyId:'',
              facultyName:'',
              defaultImage:'',
              chapter:[],
              courseType:'',
              description:'',
              duration:'',
              fees:'',
              categoryId:'',
              batchSize:'',
              startDate:new Date(),
              endDate:new Date(),
          });
          }
        }).catch(err=>{
          console.log(err);
        });
      }
      else
    {
      swal('Batch not Created!','No Students have Enrolled for this Course','error');
    }
    }
  
    handleChange = async(e) =>{
      //debugger
      if(e !== null){
        this.setState({selectedCourse:e})
      await this.setState({courseName: e.courseName,courseId:e._id});

      let id = this.state.courseId;
      axiosApiInstance.get(`${URL}/course/${id}`)
      .then(async(res)=>{
       // debugger
        //console.log(res.data.Course);
        let course = res.data.Course;
        await this.setState({
          defaultImage:course.imageOrVideoPath.default,
          courseType:course.courseType,
          categoryId:course.categoryId,
          batchSize:course.batchSize,
          fees:course.fees,
          duration:course.duration,
          description:course.description,
          chapter:course.chapter
        });
        // await this.createName();
      }).catch(err=>{
        console.log(err);
      });
      await this.fetchStudents(id);
    }
    }

    handleChangeFaculty = async(e) =>{
      if(e !== null){
        await this.setState({facultyName: e.label,facultyId:e.value});
        this.setState({selectedFaculty:e});
      }
    }

    async componentDidMount(){
      await this.getCourse();
      await this.getFaculty();
      await this.getScheduleCourses();
    }

    getCourse(){
      axiosApiInstance.get(`${URL}/allCourse`)
      .then(res => {
          //debugger
          //console.log("res",res);
          let  courseData = [];
          if(res.status == 200){
          //debugger
            res.data.Course &&  res.data.Course.map(data =>{
              if(data.courseType == "Instruction based" )
                courseData.push(data)
            })
            this.setState({course:courseData});
          }
        else{
          console.log("err");
              //swal(res.data.message, "", 'error')
          }
      }).catch(err => {
        console.log(err);
          //swal("Something went wrong", "", 'error')
      })
    }

   getScheduleCourses (){
    axiosApiInstance.get(`${URL}/allCourseSchedule`)
    .then(res =>{
      //debugger
      let scheduleData = res.data.CourseSchedule;
      this.setState({Data:scheduleData});
    }).catch(err=>{
      console.log(err);
    });
   }

  getFaculty =() =>{
    axiosApiInstance.get(`${URL}/getFacultyList`)
    .then(res =>{
      //debugger
      let faculties = res.data.allFacultyList;
      let  facultyData = [];
      if(res.status == 200){
        faculties && faculties.map(data =>{
          facultyData.push({value:data.userId,label:data.firstName+" "+data.middleName+" "+data.lastName})
        })
        this.setState({Faculty:facultyData});
      }
    }).catch(err=>{
      console.log(err);
    });
  }
    
  fetchStudents=(courseId)=>{
      //debugger
      axiosApiInstance.get(`${URL}/getEnrollStudentsByCourseId/${courseId}`)
      .then(res =>{
            //debugger
            let allStudentList = res.data.studentList;
            // console.log("allStudentList",allStudentList);
              var dataarray = [];
              allStudentList&&allStudentList.map(data=>{
                  dataarray.push({
                      value: data.userId,
                      label: data.firstName+" "+data.middleName+" "+data.lastName,
                      isdisabled: true
                  })
              })
              this.setState({studentArray:dataarray});
      }).catch(err=>{
        console.log(err);
      });
  }

  handleMultiChange = async(option) => {
    //debugger
    await this.setState({...this.state.studentValue,studentValue:option});
    // console.log("student",this.state.studentIds);
  }


  createName=()=>
  {
    // const {courseName}=this.state;w
    // let name=courseName;
    // let matches = name.match(/\b(\w)/g);
    // let acronym = matches.join('');
    // let changedName=acronym.toLowerCase()+'Batch';
    axiosApiInstance.get(`${URL}/allCourseSchedule`)
    .then(res =>{
      //debugger
      let scheduleData = res.data.CourseSchedule;
      // let i=0;
      scheduleData&&scheduleData.map(res=>{
        if(res.batchName===this.state.batchName){
          swal('Same Batch Name Exists','','warning')
          // while(res.batchName===changedName)
          // {
          //   i++
          //   changedName=changedName+(''+i)
          // }
        }
        // console.log(res.batchName);
      })
    }).catch(err=>{
      console.log(err);
    });
    // this.setState({batchName:changedName})
  }


   onEdit=(data,scheduleId) => {
    console.log(data,"data");
    this.setState({
    scheduleId:scheduleId,
    courseId:data.courseId,
    courseName:data.courseName,
    facultyId:data.mainFaculty[0].facultyUserId,
    batchName:data.batchName,
    facultyName:data.mainFaculty[0].facultyFullName,
    defaultImage:data.imageOrVideoPath.default,
    chapter:data.chapter,
    courseType:data.courseType,
    description:data.description,
    fees:data.fees,
    startDate:new Date(data.startDate),
    endDate:new Date(data.endDate),
    studentArray:data.studentIds,
    duration:data.duration,
    categoryId:data.categoryId,
    batchSize:data.batchSize,
    selectedCourse:[{courseName:data.courseName,_id:data.courseId}],
    selectedFaculty:[{label:data.mainFaculty[0].facultyFullName,value:data.mainFaculty[0].facultyUserId}]
   });
  }

  clearValue =  () => {
    //debugger
    const{formErrors}=this.state;
    this.state.selectRefCourse.select.clearValue();
    this.state.selectRefFaculty.select.clearValue();
    this.setState({courseId:'',courseName:'',facultyName:'', scheduleId:null ,facultyId:'',batchName:'',selectedFaculty:[], selectedCourse:[],studentValue: [],startDate:new Date(),endDate:new Date()});
    formErrors.courseName='';
    formErrors.facultyName ='';
    formErrors.batchName='';
  };

  render(){
  const{columns,Data,course,Faculty,selectedCourse,selectedFaculty,endDate,startDate,formErrors,StudentColumns,option,select} = this.state;
  const today = new Date();
  
  return(
    <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
        <Card className="shadow p-4 col-md-12 marginauto">
        {this.state.loading ?
          <Loader/>
          : <div></div>
        }
        <AvForm onSubmit={this.handleSubmit} ref={form => this.form = form}>
          <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
          <Row>
              <Col className="mb-4">
                  <h3 className="text-center blueText">Schedule</h3>
              </Col>
          </Row>
          <Row>
              <Col md={6} className="SelectCourse">
                <AvGroup>
                    <Label>Select Course</Label><span className="requiredFieldSpan">*</span>
                    <Select
                      options={course} name="course"  onChange={this.handleChange}
                      value={selectedCourse}
                      getOptionLabel={x => x.courseName}
                      getOptionValue={x => x._id}
                      ref={ref => {
                      this.state.selectRefCourse = ref;
                    }}
                      />
                    {
                      formErrors.courseName !== "" ?
                      <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.courseName}</Label>: null
                    }
                </AvGroup>
              </Col>
              <Col md={6}  className="SelectFaculty">
                <AvGroup>
                    <Label>Select Faculty</Label><span className="requiredFieldSpan">*</span>
                    <Select
                      options={Faculty}
                      name='faculty'
                      onChange={this.handleChangeFaculty} 
                    //  isClearable={true}
                      value={selectedFaculty}
                      ref={ref => {
                      this.state.selectRefFaculty = ref;
                    }}
                    />
                    {
                      formErrors.facultyName !== "" ?
                      <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.facultyName}</Label>: null
                    }
                  </AvGroup>
              </Col>
              </Row>
              <Row>
                  <Col md={6}>
                  <AvGroup className="mb-0">
                      <Label for="CourseName">Batch Name</Label><span className="requiredFieldSpan">*</span>
                          <AvInput name="batchName" id="batchName" placeholder="Batch name" onChange={this.handleChangeText}  value={this.state.batchName} />
                          {/* <Button onClick={this.createName}>GO</Button> */}
                          {
                            formErrors.CourseName !== "" ?
                            <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.batchName}</Label>: null
                          }
                  </AvGroup>
                  </Col>
                  <Col md={6} xs={12}>
                    {/* <AvGroup className="mb-0">
                        <Label for="CourseName">Number of Students</Label>
                            <AvInput name="student" id="student" placeholder="Number of Students" type="text" disabled="true" value={this.state.studentArray.length} />
                            {
                              formErrors.CourseName !== "" ?
                              <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.batchName}</Label>: null
                            }
                    </AvGroup> */}
                          <label htmlFor="validationCustom22">Number of Students</label><span className="requiredFieldSpan">*</span>
                          <div className="SelectStudent" style={{zindex:'4'}}>
                          <Select
                              isDisabled
                              name="filters"
                              placeholder={this.state.studentArray.length +" "+"Students"}
                              value={this.state.studentValue}      
                              options={this.state.studentArray}
                              defaultValue={this.state.studentValue}
                              onChange={this.handleMultiChange}
                              isMulti
                              isOptionDisabled={(option) => option.isdisabled} // disable an option
                          />
                          {/* {
                              getAreapincode !== "" ?
                              <label style={{ fontSize: 12, color: "red",marginTop:'15px' }}>
                                { getAreapincode}</label>: null
                          } */}
                          
                          
                          </div>
                      
                  </Col> 
              </Row>
              <Row className="">
                  <Col md={6}>
                    <AvGroup>
                        <Label>Start Date</Label><span className="requiredFieldSpan">*</span><br/>
                        <DatePicker
                          dateFormat="MMMM d, yyyy"
                          selected={startDate}
                          onChange={date => this.setState({startDate:date})}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          disabledDays={{ before: today }} 
                          minDate={today}
                        />
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                        <Label>End Date</Label><span className="requiredFieldSpan">*</span><br/>
                        <DatePicker
                          selected={endDate}
                          dateFormat="MMMM d, yyyy"
                          onChange={date => this.setState({endDate:date})}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                        {/* <TextField name="endDate"  type="date" onChange={this.handleChange} value={this.state.endDate} inputProps={{ min: moment(new Date()).format('YYYY-MM-DD')}} /> */}
                    </AvGroup>
                  </Col>
              </Row>
              <Row>
              <Col className="text-center mt-3">
              <div  className="center-block text-center">
                {this.state.scheduleId === null ?
                  <Button className="text-center mr-3 btnblue" id='createScheduleButton' onClick={this.handleSubmit}>Create Schedule</Button>:
                  <Button className="text-center mr-3 btnblue"  onClick={this.onUpdate}>update</Button>   
                }
                <Button className="text-center " color="danger"
                //  onClick={this.handleCancel}
                  onClick={this.clearValue}
                
                  >Clear</Button>
                  
                </div>
              </Col>
            </Row>
          </Card>
          <Row>
          <Col md={8}>
                  <StudentModal 
                      show={this.state.addModalShow} onHide={this.addModalClose} columns={StudentColumns} 
                      scheduleStudent={this.state.scheduleStudent}
                  />
              </Col> 
          </Row>
          <Row className="mb-4 mt-4">
                  <Col className="tableWp">
                        <Row>
                            <Col className="DashboardTbl scheduleTbl">
                              <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle} rowStyle={rowStyle}                                   
                                   actions ={
                                    [
                                      {
                                        icon:'edit',
                                        Tooltip:'Edit',
                                        onClick: (event, rowData) => {
                                          this.onEdit(rowData,rowData._id);
                                        }
              
                                      }
                                    ]
                                  }
                                  />
                            </Col>
                        </Row>        
                  </Col>
                </Row>
        </AvForm>
        </Card>
    </Container>
      )
    }
}