import React from "react";
import { Form,Card, FormGroup, Label, Input,Col,Row,Container,Button} from 'reactstrap';
// import DropDown from '../../../Components/Admin/Course/DropDown'
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import Select from 'react-select';
import {URL} from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance'; 
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import IconButton from '@material-ui/core/IconButton';
import swal from "sweetalert";
import Loader from '../../../Components/Common/Loader/Loader';
import axios from "axios";

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}


export default class ChangeBatch extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        selectedOption: null,
        selectedBatch: null,
        selectRefBatch: null,
        loading:false,
        columns: [
          { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
          { title: "Student", field: "studentName"},
          { title: "Course", field: "courseName"},
          { title: "Current batch",render: rowData =><span>{rowData.currentBatchName}({rowData.cStudent+"/"+rowData.cbatchSize})</span>},
          { title: "Requested Batch",render: rowData =>rowData.reqBatchName === ''?null:<span>{rowData.reqBatchName}({rowData.rStudent+"/"+rowData.rBatch})</span>},
          { title: "Status", field: "status", render: rowData=><span><strong>{rowData.status}</strong></span>},
          { title: "Action",render: rowData =><span style={{display:'flex'}}>
            {/* <Button className="text-center mb-2 btnblue">Approve</Button> */}
            <Tooltip title="Approve">
              <IconButton onClick={()=>{this.changeBatch(rowData,"Approved")}} disabled={rowData.status === "Approved" || rowData.status === "Decline"?true:false}>
                 <CheckBoxIcon className="CheckIcon"  disabled={rowData.status === "Approved" || rowData.status === "Decline"?true:false} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Decline">
              <IconButton onClick={()=>{this.changeBatch(rowData,"Decline")}} disabled={rowData.status === "Approved" || rowData.status === "Decline"?true:false}>
                 <CancelPresentationIcon className="closeIcon" disabled={rowData.status === "Approved" || rowData.status === "Decline"?true:false}/>
              </IconButton>
            </Tooltip>
            </span>
    //       { title: "Change Batch", field: "Change",render: rowData =><select id="Dropdown" name="categoryDropdown" className="form-control" >
    //     <option>Select Current Batch</option>
    //     <option>Batch 1</option>
    //     <option>Batch 2</option>
    //   </select>
          },
        ],
        options : [],
        batchOptions:[],
        batch : [],
        Data : [],
      }
   
     }

     handleChange = selectedOption => {
      // debugger
        this.setState({ selectedOption,selectedBatch:null });
        let courseId = selectedOption.value;
        axiosApiInstance.get(`${URL}/getBatchByCourseId/${courseId}`)
        .then(res=>{
        //  console.log("res",res);
          let  batchData = [];
          if(res.status == 200){
          //  debugger
            res.data.data &&  res.data.data.map(data =>
              batchData.push({value:data._id,label:data.batchName})
            )
            this.setState({batchOptions:batchData});
          }else{this.setState({batchOptions:[]});}
        })
      };

      handleChangeBatch = (selectedBatch) => {
       // debugger
        this.setState({ selectedBatch });
        let batchRequest = {
          "courseId":this.state.selectedOption.value,
          "batchId":selectedBatch.value
         };
         axiosApiInstance.post(`${URL}/getBatchByFilter`,batchRequest)
         .then(res=>{
           //debugger
          if(res.status === 200){
            let data = res.data.data;
            let arr = [];
            data&&data.map(i=>
              arr.push({
                _id:i._id,
                userId:i.userId,
                status:i.status,
                courseName:i.courseName,
                reqBatchName:i.reqBatchName,
                studentName:i.studentName,
                currentBatchName:i.currentBatchName,
                cbatchSize:i.currentBatchDetails.batchSize,
                rBatch:i.requestBatchDetails.batchSize,
                cStudent:i.currentBatchDetails.numberOfStudentIds,
                rStudent:i.requestBatchDetails.numberOfStudentIds})
              )
            this.setState({Data:arr});
          }
          else{
            //debugger
            this.setState({Data:[]});
          }
          })
         .catch(err=>{
          console.log(err);
         });
       // {{devUrl}}/getBatchByCourseId/5fbbc9ec2bccd1a180591c85 GET courseId  

      }


      componentDidMount () {
         this.getBatch();
         this.getCourse();
      }

      getBatch = () => {
        //this.setState({loading:true});
        axiosApiInstance.get(`${URL}/getAllBatchRequest`)
        .then(res =>{
            //  this.setState({loading:false});
              //console.log("allRequest",res);
              if(res.status === 200){
                let data = res.data.data;
                let arr = [];
                data&&data.map(i=>
                  arr.push({
                    _id:i._id,
                    userId:i.userId,
                    status:i.status,
                    courseName:i.courseName,
                    reqBatchName:i.reqBatchName,
                    studentName:i.studentName,
                    currentBatchName:i.currentBatchName,
                    cbatchSize:i.currentBatchDetails.batchSize,
                    rBatch:i.requestBatchDetails.batchSize,
                    cStudent:i.currentBatchDetails.numberOfStudentIds,
                    rStudent:i.requestBatchDetails.numberOfStudentIds})
                )
                this.setState({Data:arr});
              }
              else{
                //debugger
                this.setState({Data:[]});
              }
        }).catch(err=>{
          this.setState({loading:false});
          console.log(err);
        });
      }

      getCourse(){
        axiosApiInstance.get(`${URL}/allCourse`)
        .then(res => {
           // debugger
            //console.log("res",res);
            let  courseData = [];
            if(res.status == 200){
            //debugger
              res.data.Course &&  res.data.Course.map(data =>{
                if(data.courseType == "Instruction based" )
                  courseData.push({value:data._id,label:data.courseName})
              })
              this.setState({options:courseData});
            }
          else{
            console.log("err");
            }
        }).catch(err => {
          console.log(err);
        })
      }

      changeBatch = (rowData,status) => {
       // debugger
        let changeBatch = {
          "reqBatchId": rowData._id,
          "userId": rowData.userId,
          "status":status
         }
         axiosApiInstance.put(`${URL}/changeBatch`,changeBatch)
         .then(res=>{
           //debugger
           if(res.status === 200){
            swal(res.data.message, "", 'success');
           //  swal(res.data.message);
            this.getBatch();
           }
           else if(res.status === 204){
            swal("No Student found!", "", 'warning');
           }
          //  console.log(res);

         })
         .catch(err=>{
          this.setState({loading:false});
           console.log("err",err);
         })
      }

      clearValue =  () => {
        //debugger
        this.state.selectRefBatch.select.clearValue();
      };

 render(){
  const{ Data, columns,LabelText,selectedBatch,batchOptions,categoryName,selectedOption,options} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp ChangeSchedule changeBatchWp">
       <Card className="shadow p-4 col-md-12">
       <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Change Batch</h3>
         </Col>
       </Row>
       <Form>
            <Row>
           <Col md={6}>
            <FormGroup>
              <Label>Course</Label>
              {/* <span className="requiredFieldSpan">*</span> */}
                <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        name="course"
                        ref={ref => {
                        this.state.selectRefCourse = ref;
                         }}
                    />
            </FormGroup>
           </Col>
           <Col md={6}>
            <FormGroup>
              <Label> Batch</Label>
              {/* <span className="requiredFieldSpan">*</span> */}
                    <Select
                        value={selectedBatch}
                        name="batch"
                        onChange={this.handleChangeBatch}
                        options={batchOptions}
                        ref={ref => {
                          this.state.selectRefBatch = ref;
                        }}
                    />
            </FormGroup>
          </Col>
        </Row>
        </Form>
        </Card>
          <Row className="mb-2 mt-5 ChangeBatchRow">
          <Col className="tableWp Changebatch mt-2">
            <Row>
              <Col className="DashboardTbl">
                {this.state.loading ?
                  <Loader/>
                  : <div></div>
                }
                <Table data={Data} pageSize={10} paging={true} columns={columns}search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='300px' 
                />
              </Col>
            </Row>
           </Col>
         </Row>
        </Card>
     </Container>
    );
  }
}