import React from 'react';
import {Card ,Row,Col} from 'reactstrap';
import cardImg from '../../../Images/java.png'
import net from '../../../Images/asp.jpg'

const CourseList = (props) => {
  return (
      <div>
            <Card className="CourseListCardWp pb-4 pt-5 mb-3">
                <Row>
                    <Col md={3} className="CourseImageWraper">
                        <img  src={cardImg} alt=""/>
                    </Col>
                    <Col md={7} className="CourseHeadDetails">
                        <p className="prdctDetlTxt"><a href="#">Java Programming Masterclass for Software Developers</a></p>
                        {/* <span className="teamName">- By Team Bhuchlka</span> */}
                       {/* <p className="Link"><span>Remove</span><span>Move to Whishlist </span></p>  */}
                    
                         <p className="mt-3 pl-1"> 
                            <span><a href="#" className="RemoveClass"> Remove</a> </span>
                            <span><a href="#" className="WhishlistTxt  border-left">Move To Wishlist</a></span> 
                        </p>
                    </Col>
                    <Col md={2}>
                        <p className="discPrice">₹ 899</p>
                        <p><span className="Mrptxt"> ₹ 1299 </span> </p>
                        <p><span className="OffPercent"> 40% Off</span></p>
                    </Col>
                </Row>
            </Card>
            <Card className="CourseListCardWp pb-4 pt-5">
                <Row>
                    <Col md={3} className="CourseImageWraper">
                        <img  src={net} alt="" />
                    </Col>
                    <Col md={7} className="CourseHeadDetails">
                        <p className="prdctDetlTxt"><a href="#">.NET Programming Masterclass for Software Developers</a></p>
                        {/* <span className="teamName">- By Team John</span> */}
                       {/* <p className="Link"><span>Remove</span><span>Move to Whishlist </span></p>  */}
                    
                         <p className="mt-3 pl-1"> 
                            <span><a href="#" className="RemoveClass"> Remove</a> </span>
                            <span><a href="#" className="WhishlistTxt  border-left">Move To Wishlist</a></span> 
                        </p>
                    </Col>
                    <Col md={2}>
                        <p className="discPrice">₹ 999</p>
                        <p><span className="Mrptxt"> ₹ 1699 </span> </p>
                        <p><span className="OffPercent"> 40% Off</span></p>
                    </Col>
                </Row>
            </Card>
            </div>

      
     
    
  );
};

export default CourseList;
