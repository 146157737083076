import React from "react";
import react,{useState} from 'react';
import {Modal,Form, Button,Container,Row,Col} from 'react-bootstrap';
import  {AvForm, AvGroup, AvInput }  from 'availity-reactstrap-validation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import SimpleDropZone from "../../../Components/Common/SimpleDropZone";
import { WithContext as ReactTags } from 'react-tag-input';

import DropDown from './DropDown';
import {TextField} from '@material-ui/core';
// import moment from 'moment';
import Auth from '../../../ServerCall/Auth';
import Loader from '../../../Components/Common/Loader/Loader';
import validator from 'validator';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const AddModal = ({
  loading,
  scheduleId,
  courseType,
  startDate,
  endDate,
  urlValue,
  facultyId,
  isSubmit,
  LessonNo,
  Videourl,
  Fileurl,
  Lessonname,
  SelectDeliveryType,
  lessonFaculty,
  handleChange,
  onClickAddLesson,
  onUpdateLesson,
  lessonId,
  onClickCancel,
  selectedDeliveryType,
  selectedFaculty,
  TopicContnet,
  NoDropzoneLayout,
  getUploadParams,
  handleChangeStatus,
  show,
  arrayData,
  onHide,
  handleSchedule,
  formErrors,
  DeliveryType,
  onClickUpdateLesson,
  meetingUrl,
  scheduleDate,
  startTime,
  endTime,
  onClickRemove,
  handleAddition,
  handleDelete
}) => {
  //alert(Fileurl);
  let start = new Date(startDate);
  let end = new Date(endDate);
  const role = Auth.getUserRole();
  const {url, setUrl} = React.useState();
  // const [errorMessage, setErrorMessage] = useState('')
  // const validate = (value) => {
  //   debugger
  //   if (validator.isURL(value)) {
  //     setErrorMessage('Is Valid URL')
  //   } else {
  //     setErrorMessage('Is Not Valid URL')
  //   }

  //   // if (!validator.isURL(value)) {
  //   //    setErrorMessage('Please Enter  valid URL')
  //   // }
  //   // else if (validator.isURL(value) == ""){
  //   //    setErrorMessage('Please Enter URL')
  //   //  }
  // }

  // debugger
  const onRemove=(url)=>{
    onClickRemove(url)
  }
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalWp"
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {lessonId !== ''
            ? // ? "Schedule Lesson"
              "Update Lesson"
            : // : Lessonname !== ""
              // ? "Update Lesson"
              "Add Lesson"}
        </Modal.Title>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClickCancel}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Container className="FormWp">
          {loading ? <Loader /> : <div></div>}
          <Row>
            <Col>
              <AvForm>
                <Row>
                  <Col md={6}>
                    <AvGroup>
                      <Form.Label>Lesson No</Form.Label>
                      <span className="requiredFieldSpan">*</span>
                      <AvInput
                        type="number"
                        name="LessonNo"
                        id="Lesson No"
                        onChange={handleChange}
                        value={LessonNo}
                        disabled={scheduleId != "" ? true : false}
                      />
                      {formErrors.LessonNo !== "" ? (
                        <Form.Label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.LessonNo}
                        </Form.Label>
                      ) : null}
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <Form.Label>Lesson Name</Form.Label>
                      <span className="requiredFieldSpan">*</span>
                      <AvInput
                        type="text"
                        name="Lessonname"
                        id="lessonname"
                        onChange={handleChange}
                        disabled={scheduleId != "" ? true : false}
                        value={Lessonname}
                      />
                      {formErrors.Lessonname !== "" ? (
                        <Form.Label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.Lessonname}
                        </Form.Label>
                      ) : null}
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  {scheduleId != "" ||
                  courseType === "Prerecorded" ||
                  courseType === "Instruction based" ? (
                    <Col md={6}>
                      <AvGroup>
                        <Form.Label>Delivery Type</Form.Label>
                        <span className="requiredFieldSpan">*</span>
                        <DropDown
                          title="Select Delivery Type"
                          options={SelectDeliveryType}
                          selectedCategory={selectedDeliveryType}
                          value={DeliveryType}
                          disabled={
                            scheduleId === "" && lessonId !== ''? true : false
                          }
                        />
                        {formErrors.DeliveryType !== "" ? (
                          <Form.Label style={{ fontSize: 12, color: "red" }}>
                            {formErrors.DeliveryType}
                          </Form.Label>
                        ) : null}
                      </AvGroup>
                    </Col>
                  ) : null}
                  <Col md={6}>
                    <AvGroup>
                      <Form.Label>Faculty Name</Form.Label>
                      <span className="requiredFieldSpan">*</span>
                      <select
                        id="facultyId"
                        name="facultyId"
                        className="form-control"
                        disabled={
                          role === "faculty" || scheduleId != "" ? true : false
                        }
                        value={facultyId}
                        onChange={selectedFaculty}
                      >
                        <option>Select Faculty/Author</option>
                        {lessonFaculty &&
                          lessonFaculty.map((select) => {
                            return (
                              <option
                                value={select.text}
                                data-key={select.value}
                              >
                                {select.value}
                              </option>
                            );
                          })}
                      </select>
                      {formErrors.facultyId !== "" ? (
                        <Form.Label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.facultyId}
                        </Form.Label>
                      ) : null}
                      {/* <DropDown title="Select Faculty" options={lessonFaculty} selectedCategory={selectedFaculty} value={facultyId}/> */}
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvGroup>
                      <Form.Label>Topic Content</Form.Label>
                      <AvInput
                        type="textarea"
                        name="TopicContnet"
                        onChange={handleChange}
                        value={TopicContnet}
                        disabled={scheduleId != "" ? true : false}
                        id="TopicContnet"
                        rows="3"
                      />
                    </AvGroup>
                  </Col>
                </Row>
               { 
                 scheduleId === "" && Fileurl.length>0 && lessonId !== ''?
                <Row>
                  <Col>
                    <AvGroup>
                    {Fileurl && Fileurl.map((files,i)=>
                    <Form.Label key={i}>{files.url.slice(26)}<IconButton onClick={()=>onClickRemove(files.url)}><Cancel /></IconButton></Form.Label>)}
                      
                    </AvGroup>
                  </Col>
                </Row>:null
               }
                {/* {DeliveryType == null ? null : */}
                <div>
                  {scheduleId === "" && DeliveryType === "Manuscript" ? (
                    <Row>
                      <Col>
                        <AvGroup>
                          <Form.Label>
                            Upload file
                            <span className="requiredFieldSpan">*</span>
                          </Form.Label>
                          <br />
                          {/* <Dropzone
                            getUploadParams={getUploadParams}
                            LayoutComponent={NoDropzoneLayout}
                            inputContent="Choose Files"
                            onChangeStatus={handleChangeStatus}
                            //onSubmit={handleFileSubmit}
                            //submitButtonContent= 'Uplaod File'
                            maxFiles={1}
                            multiple={false}
                            value={getUploadParams}
                          /> */}
                          <SimpleDropZone arrayData={arrayData} isSubmit={isSubmit} />
                          {formErrors.filesUrl !== "" ? (
                            <Form.Label style={{ fontSize: 12, color: "red" }}>
                              {formErrors.filesUrl}
                            </Form.Label>
                          ) : null}
                        </AvGroup>
                      </Col>
                    </Row>
                  ) : null}

                  {/* {DeliveryType === "Meeting Url"? */}

                  <div>
                    {scheduleId !== "" ? (
                      <Row className="mb-3 DateTimePikerWp">
                        <Col md={6}>
                          <label
                            className="lbl pr-5"
                            style={{ display: "flex" }}
                          >
                            Date <span className="requiredFieldSpan">*</span>
                          </label>
                          <DatePicker
                            selected={scheduleDate}
                            dateFormat="MMMM d, yyyy"
                            onChange={handleSchedule}
                            //selectsEnd
                            startDate={start}
                            maxDate={end}
                            endDate={end}
                            minDate={start}
                          />
                          {formErrors.scheduleDate !== "" ? (
                            <Form.Label style={{ fontSize: 12, color: "red" }}>
                              {formErrors.scheduleDate}
                            </Form.Label>
                          ) : null}
                          {/* <TextField className="form-control" type="date" minDate={"2020-02-01"} maxDate={"2020-02-06"}  name="scheduleDate" variant="outlined"
                                onChange={handleChange} value={scheduleDate}
                                   inputProps={{ min: moment(new Date()).format('YYYY-MM-DD')}} /> */}
                        </Col>
                        <Col md={3}>
                          <label
                            className="lbl pr-5"
                            style={{ display: "flex" }}
                          >
                            Start Time{" "}
                            <span className="requiredFieldSpan">*</span>
                          </label>
                          <TextField
                            className="form-control"
                            type="time"
                            name="startTime"
                            variant="outlined"
                            onChange={handleChange}
                            value={startTime}
                          />
                          {formErrors.startTime !== "" ? (
                            <Form.Label style={{ fontSize: 12, color: "red" }}>
                              {formErrors.startTime}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label
                            className="lbl pr-5"
                            style={{ display: "flex" }}
                          >
                            End Time{" "}
                            <span className="requiredFieldSpan">*</span>
                          </label>
                          <TextField
                            className="form-control"
                            type="time"
                            name="endTime"
                            variant="outlined"
                            onChange={handleChange}
                            value={endTime}
                          />
                          {formErrors.endTime !== "" ? (
                            <Form.Label
                              style={{
                                fontSize: 12,
                                color: "red",
                                marginTop: "5px",
                              }}
                            >
                              {formErrors.endTime}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    ) : null}
                    {scheduleId !== "" && DeliveryType === "Meeting Url" ? (
                      <>
                        <Row>
                          <Col>
                            <AvGroup>
                              {/* onChange={(e)=>{validate(e.target.value);}} */}
                              <Form.Label> Insert meeting url</Form.Label>
                              <span className="requiredFieldSpan">*</span>
                              <br />
                              <AvInput
                                type="text"
                                name="meetingUrl"
                                id="meetingUrl"
                                onChange={handleChange}
                                value={meetingUrl}
                              />
                              {/* <span style={{  fontSize: 12, color: "red"}}>{errorMessage}</span>  */}
                              {/* <ImageUploader/> */}
                              {formErrors.meetingUrl !== "" ? (
                                <Form.Label
                                  style={{ fontSize: 12, color: "red" }}
                                >
                                  {formErrors.meetingUrl}
                                </Form.Label>
                              ) : null}
                            </AvGroup>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col>
                            <AvGroup>
                              <Form.Label>Upload Content file</Form.Label><span className="requiredFieldSpan">*</span><br/>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  LayoutComponent={NoDropzoneLayout}
                                  inputContent="Only Choose Files (No Dropzone)"
                                  onChangeStatus={handleChangeStatus}
                                  maxFiles={1}
                                  multiple={false}
                                />
                            </AvGroup>
                          </Col>
                        </Row> */}
                      </>
                    ) : null}
                  </div>
                  {scheduleId === "" && DeliveryType === "Video Lectures" ? (
                    <Row>
                      <Col>
                        <AvGroup>
                          <Form.Label> Insert Video Url</Form.Label>
                          <span className="requiredFieldSpan">*</span>
                          <br />
                          <ReactTags tags={Videourl} placeholder="Enter Video URL" inline={false} handleAddition={handleAddition} 
                          handleDelete={handleDelete} delimiters={delimiters}
                          classNames={{
                            tagInputField: 'form-control',
                            selected: 'selectedClass',
                            tag: 'tagClass',
                            remove: 'close_icon',
                            suggestions: 'suggestionsClass',
                            activeSuggestion: 'activeSuggestionClass'
                          }}
                          />
                          {/* <AvInput
                            type="text"
                            name="Videourl"
                            id="Videourl"
                            onChange={handleChange}
                            value={Videourl}
                          /> */}
                          {/* <ImageUploader/> */}
                          {formErrors.Videourl !== "" ? (
                            <Form.Label style={{ fontSize: 12, color: "red" }}>
                              {formErrors.Videourl}
                            </Form.Label>
                          ) : null}
                        </AvGroup>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                {/* } */}
                <Row>
                  <Col className="text-center mt-3">
                    <div className="center-block text-center">
                      {scheduleId !== "" ? (
                        <Button
                          className="text-center mr-3"
                          onClick={onClickUpdateLesson}
                        >
                          Save Changes
                        </Button>
                      ) : lessonId !== '' ? (
                        <Button
                          className="text-center mr-3"
                          onClick={onUpdateLesson}
                        >
                          Update Lesson
                        </Button>
                      ) : (
                        <Button
                          className="text-center mr-3"
                          onClick={onClickAddLesson}
                        >
                          Add Lesson
                        </Button>
                      )}
                      <Button
                        className="text-center "
                        variant="danger"
                        onClick={onClickCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;