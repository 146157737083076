import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Card } from 'reactstrap';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';

class Result extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            testHistory: {},
            studentUserId: Auth.getUserId(),
            timeTook: {}
        }
    }

    componentDidMount(){
        let _id = this.props.location.search.slice(4);
        this.getTestHistory(_id);
    }
    
    getTestHistory(testId){
        const {studentUserId} = this.state;
        axiosApiInstance.get(`${URL}/testHistoryByStudentUserIdTestId/${testId}/${studentUserId}`)
        .then(res =>{
            if(res.status === 200){
                this.setState({testHistory: res.data.TestHistory});
                this.calculateMinutesFromSeconds();
            }else{
                swal(res.data.message, "", "error")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    calculateMinutesFromSeconds(){
        const {testHistory} = this.state;
        
        var hours = Math.floor(testHistory.timeTookToTest / (60 * 60));
        if(hours !== hours){
            hours = "00";
        }
        var divisor_for_minutes = testHistory.timeTookToTest % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        if(minutes !== minutes){
            minutes = "00";
        }
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        if(seconds !== seconds){
            seconds = "00";
        }
        var obj = {
            "h": ('0' + hours).slice(-2),
            "m": ('0' + minutes).slice(-2),
            "s":  ('0' + seconds).slice(-2)
        };
        //alert(obj)
        this.setState({timeTook: obj});
    }

    render() {
        const {testHistory, timeTook} = this.state;
        return(
            <Container>
                {Object.keys(testHistory).length > 0 ?
                <Row>
                    <Col>
                        <Card className="p-4 mt-4">
                            <Row>
                                <Col>
                                    <h5 className="text-center testreslttext">{testHistory.testName}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div id="quizcontainer">
                                        <h6 className="TestHead">Student Name : {testHistory.studentName}</h6>
                                        <h6 className="TestHead">Score : {testHistory.totalMarksObtained} of {testHistory.totalScore}</h6>  
                                        {/* <h6 className="TestHead">40% Correct:</h6>   */}
                                    </div>
                                </Col>
                                <Col>
                                    <div id="quizcontainer">
                                    <h6 className="TestHead">Result : {parseFloat((testHistory.totalMarksObtained/testHistory.totalScore)*100).toFixed(2) + "%" }</h6>
                                    <h6 className="TestHead">Time Spent : {timeTook.h !== "00" && timeTook.h + " : "}{timeTook.m} : {timeTook.s}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col>
                                    <div id="QuizMain">
                                        <div id="quizcontainer">
                                            {testHistory.answers.map((answer, index) =>
                                            <div>
                                                <Row>
                                                    <Col md={6}>
                                                        <p className="Qtext">Question {index + 1}:</p>
                                                    </Col>
                                                    <Col><p className="Qtext">Marks Scored: {answer.marksScored}</p></Col>
                                                    <Col><p className="Qtext">Time Spent for Answer: {answer.timeTookToAnswer}</p></Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                    <p>{answer.questionId.question}</p>
                                                        {answer.studentAnswer.map(option => <div className={ answer.correct ? "radiocontainer correct selected" : "radiocontainer wrong"} id="label3" style={{paddingRight:"135px"}}>
                                                            <div className="markcontainer">
                                                                <i className={answer.correct ? "fa fa-check" : "fa fa-times"}></i>
                                                            </div>
                                                            {option}&nbsp;&nbsp;
                                                            <span className="answercomment pl-2 pr-2">Your answer</span>
                                                        </div>)}
                                                        {!answer.correct ? answer.correctAnswer.map(option =>
                                                        <div class="radiocontainer correct mt-2" id="label1" style={{paddingRight:"160px"}}>{option}
                                                        <span class="answercomment pl-2 pr-2">Correct answer</span></div>) : null}
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> : null}
            </Container>
        );
    }
}

export default Result;