import React from "react";
// import './CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container,Button} from 'reactstrap';
import Table from  '../../../Components/Admin/Course/Table'
import '../../../Components/Admin/Course/Table.css';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0
}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698'
}

export default  class CourseCategory extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      addmodl:[],
      addModalShow:false ,
      AnnouncementId:0,
      AnnouncementTitle:'',
      description:'',
      istrue:false,
      LabelText:{
        AnnouncementTitle:'',
        description:'',
      },
      columns: [  
        //{ title: "Id", field: "_id" },
        { title: "Announcement Title", field: "AnnouncementTitle"},
        { title: "Description", field: "description"}
      ],
      Data : [],
    }
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event){
    //debugger
    //alert(event.target.value)
    let {name, value} = event.target;
    this.setState({[name]: value}); 
 }
 
 onClickCancel = () => {
  //debugger
 let {AnnouncementTitle,description} = this.state
 AnnouncementTitle = "";
 description = "";
 this.setState({AnnouncementTitle: AnnouncementTitle});
 this.setState({description: description, istrue:false, AnnouncementId:0});
}

Validation = () => {
  //debugger
    let {LabelText} = this.state;
    if (this.state.AnnouncementTitle !=="" && this.state.AnnouncementTitle !== null) {	
        LabelText.AnnouncementTitle = "";
        }
    else{
        LabelText.AnnouncementTitle  = "Please Enter Announcemen Title";
    }
    if (this.state.description !=="" && this.state.description !== null) {	
      LabelText.description = "";
      }
  else{
      LabelText.description  = "Please Enter Description";
  }
  this.setState({LabelText: LabelText});
      if(LabelText.AnnouncementTitle===""&&LabelText.description==="")
      {
          return true;
      }
      else
      {
          return false;
      }
}
  onClickAnnouncement =()=> {
      //debugger
      const isValid = this.Validation();
      if(isValid){
      const {AnnouncementTitle,description} = this.state
      axiosApiInstance.post(`${URL}/announcement`,{AnnouncementTitle,description})
          .then(response =>{
            //debugger
          const res = response.data.announcement;
          if(response.status == 200)
          {
            swal("Announcement Added", "", "success")
              this.getTableData()
              this.onClickCancel()
          }
          else
          {   
           // swal("Something went wrong", "", "error");
          }
          }).catch(err=>{
            console.log(err);
          });
        }   
    }

    onUpdateAnnouncement =()=>
    {
      //debugger
      let AnnouncementId = this.state.AnnouncementId;
      const {AnnouncementTitle,description} = this.state
      axiosApiInstance.put(`${URL}/announcement/${AnnouncementId}`,{AnnouncementTitle,description})
          .then(response =>{
            //debugger
          const res = response.data.category;
          if(response.status == 200)
          {
            //debugger
            swal("Announcement Updated", "", "success")
              this.getTableData();
              this.onClickCancel();
              this.setState({istrue:false})
          }
          else
          {   
            //swal("Something went wrong", "", "error"); 
          }
          }).catch(err=>{
            console.log(err);
          });   
    }

    onEdit =(editAnnouncementTitle,editDescription,editAnnouncementId)=>
    {
      //alert("On Edit Called " + editCategoryName + editCategoryId);
      this.setState({AnnouncementTitle:editAnnouncementTitle})
      this.setState({description:editDescription})
      this.setState({AnnouncementId:editAnnouncementId})
      this.setState({istrue:true})
    }

    getTableData () {
      axiosApiInstance.get(`${URL}/announcement`)
      .then(res =>{
          //debugger
          let announcement = res.data.announcement;
          if(res.status == 201){
            this.setState({Data:announcement})
          }
        }).catch(err=>{
          console.log(err);
        });
    }
    componentDidMount (){
     this.getTableData()
    }



render(){
  const{ SelectParentCategory ,Data,columns,LabelText} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
       <Card className="shadow p-4 col-md-8">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Announcement</h3>
         </Col>
       </Row>
       <Form>
        <Row>
           <Col md={12}>
            <FormGroup>
              <Label>Announcement Title</Label>
                <Input type="text" name="AnnouncementTitle"  placeholder="" onChange={this.handleChange} value={this.state.AnnouncementTitle}/>
                {LabelText.AnnouncementTitle !== "" ?
                <Label style={{ fontSize: 12, color: "red" }}>
                {LabelText.AnnouncementTitle }</Label >: null
			          }
            </FormGroup>
           </Col>
           </Row>
           <Row>
           <Col md={12}>
           <FormGroup>
           <Label>Description</Label>
           <Input type="textarea" name="description"  placeholder="" onChange={this.handleChange} value={this.state.description}/>
           {LabelText.description !== "" ?
            <Label style={{ fontSize: 12, color: "red" }}>
            {LabelText.description }</Label >: null
            }
          </FormGroup>
           </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
           <div  className="center-block text-center">
          {this.state.istrue ?  
            <Button className="text-center mr-3 btnblue"  onClick={this.onUpdateAnnouncement}>update</Button>             
            :
            <Button className="text-center mr-3 btnblue"  onClick={this.onClickAnnouncement}>Submit</Button>        
          }
          <Button className="text-center " color="danger"  onClick={()=>{this.onClickCancel()}}>Cancel</Button>
          </div>
          </Col>
        </Row>
        <Row className="mb-2 mt-5">
          <Col className="tableWp mt-2">
                <Row>
                  <Col className="DashboardTbl">
                    <Table data={Data} pageSize={10} paging={true} columns={columns}search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='200px' 
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            //alert("Edit Action Clicked " + rowData.categoryName + rowData.id)
                            this.onEdit(rowData.AnnouncementTitle,rowData.description ,rowData._id)
                          }
                        }
                      ]
                    }
                    />
                  </Col>
              </Row>            
          </Col>
        </Row>
       </Form>
       </Card>
    </Container>
    );
}
}