import React from 'react';
import {Label,Button,Row,Col,Card,Container} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import {Modal } from 'react-bootstrap'
import {useState} from 'react'
import { useLocation } from 'react-router-dom';
import {URL} from '../../../ServerCall/Config';
import QuestionsTab from './QuestionsTab'
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import EditQuestionTab from './EditQuestionTab'
import ResponseTab from './ResponseTab'
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
const AddSection = (props) => {
    let location = useLocation();
    const [show, setShow] = useState(false);
    const [useEffectId,setUseEffect] = useState('');
    const handleShow = (showModal) => setShow(showModal);
    const handleUseEffect = (testData) => setUseEffect(testData);
    const [handle, setHandle] =  React.useState(false);
    const [sectionGetName,setSectionName] = React.useState("");
    const [section,setSection] = React.useState([]);
    const [sectionNameId,setSectionNameId] = React.useState('');
    const [getQuestions,setQuestions] = React.useState([]);
    const [testId,setTestId] = React.useState('');
    const [responseCount,setResponseCount] = React.useState('');
    const [timeAverage,setTimeAverage] = React.useState('');
    const [studentList,setStudentList] = React.useState([]);
    const [testResponse, setTestResponse] = useState(true);
    const classes = useStyles();
    const handleClose = () =>{
     setShow(false);
     setState({
      sectionName:'',
      sectionDescription:''
     })
   
    }
    const [state, setState] = React.useState({
      testName:'',
      timeLimitOption:'',
      type:'',
      averageTime:'',
      description:'',
      duration:null,
      courseId:'',
      sectionName:'',
      sectionDescription:'',
    });

    function testDetail(_id) {
      //debugger
      axiosApiInstance.get(`${URL}/test/${_id}`)
      .then(res=>{
        if(res.data !== "Invalid Token"){
            let Test = res.data.Test;
            let questions = Test.questions;
            questions.map((qs,i)=>{
                qs.questions&&qs.questions.map(function(e){
                  e.open = false;
                });
            });
           // console.log("allquestion",questions);
            setQuestions(questions);
            setState({
              testName:Test.testName,
              timeLimitOption:Test.timeLimitOption,
              type:Test.type,
              description:Test.description,
              duration:Test.duration,
              courseId:Test.courseId
            });
        }
      }).catch(err=>{
        console.log("err",err);
      })
    }  

    React.useEffect(() => {
     // debugger
      var _id = location.search.slice(4);
         setTestId(_id)
        testDetail(_id);
        getTestHistory(_id);
        //console.log(useEffectId,"useEffectId");

    },[useEffectId,section]);

    const handleChange = (e) => {
      //debugger
      //console.log("e",e.target.value)
      //setSectionDescription( e.target.value);
      setState({ ...state, [e.target.id]: e.target.value});
    }

    const handleChangeDropdown = async(e) => {
      //debugger
      const selectedIndex = e.target.options.selectedIndex;
      let textValue = e.target.options[selectedIndex].getAttribute('data-key');
      const { name,value} = e.target;
     
      if(textValue === "Select Section" && value === "Select Section")
      {
        await setSectionName('');
        await setSectionNameId('');
      }
      else{
        await setSectionNameId(value);
        await setSectionName(textValue);
      }
     
      //console.log("state",state);
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      //debugger
      var id = location.search.slice(4);
      let section = {"sectionName":state.sectionName,"description":state.sectionDescription}
      let question =[section];
      axiosApiInstance.put(`${URL}/addSectionTest/${id}`,{"questions":question})
      .then(async(res)=>{
        //console.log("res",res)
        let section ;
        section = res.data.TestHistory;
        //console.log("section",section);
        setSection(section.questions);
        await setHandle(true);
        await handleClose();
      }).catch(err=>{
        console.log(err);
      });
    }
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
  
    function renderEditQuestion() {
      return (
      <EditQuestionTab
       formData="data1"
        handleShow={handleShow}
        getQuestions={getQuestions}
        courseId={state.courseId}
        timeLimitOption={state.timeLimitOption}
        sectionNameId={sectionNameId}
        handleUseEffect={handleUseEffect}
        sectionGetName={sectionGetName}
        testId={testId}
        />
      )
    }

    function getTestHistory(id) {
      axiosApiInstance.get(`${URL}/testHistoryByTestId/${id}`)
      .then(res=>{
        if(res.data !== "Invalid Token"){
          //debugger
        //console.log("testHistory",res);
        setResponseCount(res.data.TestHistory.length);

        //StudentList
         var data = [];

        res.data.TestHistory.map(student=>{
          let score = student.answers.reduce( function(cnt,o){ return cnt + o.marksScored; }, 0);
          student.totalMarksObtained = score;
          data.push({studentUserId:student.studentUserId,studentName:student.studentName,totalMarksObtained:student.totalMarksObtained});
          
        });
        if(data.length <= 0){
          setTestResponse(false);
        }
        setStudentList(data);

        // count timeAverage of over all Test attamped
        let count = res.data.TestHistory.reduce( function(cnt,o){ return cnt + o.timeTookToTest; }, 0);
        
        const countAverage = count /  res.data.TestHistory.length;

        var hours = Math.floor(countAverage / (60 * 60));
        if(hours !== hours){
           hours = "00";
        }
        var divisor_for_minutes = countAverage % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        if(minutes !== minutes){
          minutes = "00";
       }
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        if(seconds !== seconds){
          seconds = "00";
       }
        var obj = {
            "h": ('0' + hours).slice(-2),
            "m": ('0' + minutes).slice(-2) ,
            "s":  ('0' + seconds).slice(-2)
        };
       // console.log("obj",obj);
        setTimeAverage(obj);

        }
        
      }).catch(err=>{
        console.log(err);
      });
    }

  return (
    <Container>
      <Row>
					<Col className="mb-2">
						<Button className="bluebtn"  onClick={() => props.history.push('/AddTestContainer')}><ArrowBackIcon/>Back</Button>
					</Col>
				</Row>
      <Row>
    
        <Col md={8}>
         <Row>
         
      </Row>
      <Modal show={show} onHide={handleClose} animation={false} 
       {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Header closeButton>
          <Modal.Title>Add Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <AvForm className="FormWp" onSubmit={(e) => { handleSubmit(e) }}>
                    <Row>
                      <Col>
                      <AvGroup>
                        <Label for="example">Section Name</Label>
                          <AvInput name="sectionName" id="sectionName"  onChange={(e) => { handleChange(e) }} value={state.sectionName}required />
                        {/* <AvFeedback>Please Enter Section Name!</AvFeedback> */}
                      </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col>
                      <AvGroup>
                        <Label for="example">Description</Label>
                          <AvInput name="sectionDescription" id="sectionDescription"  onChange={(e) => { handleChange(e) }} value={state.sectionDescription} required />
                        {/* <AvFeedback>please Enter Description!</AvFeedback> */}
                      </AvGroup>
                    </Col>
                </Row>
            </AvForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btnblue" onClick={(e) => { handleSubmit(e) }}>
            Submit
          </Button>
          <Button variant="danger" color="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* </Card> */}
      </Col>
      </Row>
      <Row>
        <Col>
      <Nav tabs className="QTabCol">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Questions
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            <Badge badgeContent={responseCount} color="primary" >Responses</Badge>
           
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
          <Col md={12}>
        <Card className="p-4 addsectionWp" style={{borderTop:"0"}}>
            {/* <Row>
              <Col> <h3 className="blueText mb-3">Test Detail </h3> </Col>
            </Row> */}
           
            <Row className="mb-2">
              <Col md={6}>
                {/* <Label> Test name : </Label>  */}
                <h3 className="HeadingTest"> {state.testName}</h3> 
                <h4 className="testDetailspan pl-0 pt-2">{state.description}</h4>
              </Col>
              <Col>
                 <Label>Test Type:</Label> <span className="testDetailspan">{state.type}</span>
              </Col>
              { state.duration !== null ?
              <Col>
                <Label>Duration: </Label><span className="testDetailspan"> {state.duration}</span>
              </Col>
              :null
              } 
            </Row>
           
         
            {/* <Row className="mb-2"> 
              <Col>
              <FiberManualRecordIcon/>  <Label>TestLimit option: </Label><span className="testDetailspan">{state.timeLimitOption}</span>
              </Col>
            </Row> */}
            </Card>
            <Card className="p-4 mt-2">
              <Row>
                  <Col md={12}>
                    {
                      !handle ? null
                    :<Row>
                        <Col md={8}>
                          <h3 className="HeadingTest"> {sectionGetName}</h3> 
                          {/* <h4 className="testDetailspan pl-0 pt-2">{state.description}</h4> */}
                        </Col>
                        <Col md={4}>
                            <div  className="">
                              <select id="sectionName" name="sectionNameId" className="form-control"
                              onChange={(e) => { handleChangeDropdown(e) }}
                              >
                                <option>Select Section</option> 
                                {
                                    section&&section.map(select => {
                                      if(select.sectionName){
                                        return (
                                          <option 
                                            value={select._id}
                                            data-key={select.sectionName}
                                          >
                                              {select.sectionName}
                                          </option> 
                                       )  
                                      }
                                       
                                    })
                                }
                              </select>
                            </div>
                      </Col>
                    </Row>
                    }
                  {renderEditQuestion()}
                  <QuestionsTab formData="data1"
                  courseId={state.courseId}
                  handleShow={handleShow}
                  timeLimitOption={state.timeLimitOption}
                  sectionNameId={sectionNameId}
                  handleUseEffect={handleUseEffect}
                  testId={testId}
                  sectionGetName={sectionGetName} />
                  </Col>
                </Row>
            </Card>
      </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <ResponseTab responseCount={responseCount} timeAverage={timeAverage} studentList={studentList} testResponse = {testResponse} testName={state.testName}  />
        </TabPane>
      </TabContent>
        </Col>
      </Row>
    </Container>
  );
};

export default AddSection;