import React from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Button,Card,Container ,Row,Col} from 'reactstrap';
import DropDown from "../../../Components/Admin/Course/DropDown";
  
export default class FormExample extends React.Component {

  render() {
    return (
        <Container>
            <Card className="shadow p-4 col-md-9 marginauto FormWp CouseMasterWp">
            <Row>
                <Col className="mb-4">
                <h3 className="text-center blueText">Student Feedback Form</h3>
                </Col>
            </Row>
      <AvForm>
            <Row>
                <Col>
                    <AvField name="name" label="Student first name" required />
                </Col>
                <Col>
                    <AvField name="Lname" label="Student Last name" required />
                </Col>
            </Row>
            <Row>
                <Col>  <AvField name="Lname" label="Course" required /></Col>
                <Col>  <AvField name="Lname" label="Batch" required /></Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="name" label="Faculty  first name" required />
                </Col>
                <Col>
                    <AvField name="Lname" label="Faculty  Last name" required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="Subject" label="Subject" required />
                </Col>
                <Col>
                    <AvField name="CourseCode" label="CourseCode" required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="chooseCourse" label="Why did you chose this course?" required />
                </Col>
                <Col>
                <AvField name="Review" label="Your Review" required />
                </Col>
            </Row>
            <Row>
                    <Col> 
                        <AvRadioGroup name="radioCustomInputExample" label="Level of knowledge on start of course" inline className="LblText" required>
                            <AvRadio customInput label="Very Good" value="Bulbasaur" />
                            <AvRadio customInput label="Good" value="Squirtle" />
                            <AvRadio customInput label="Fair" value="Charmander" />
                            <AvRadio customInput label="Poor" value="Pikachu"  />
                        </AvRadioGroup>
                    </Col>
                    <Col>
                        <AvRadioGroup inline name="LevelKnowledge" label="Level of effort invested in course" className="LblText" required>
                            <AvRadio customInput label="Very Good" value="Bulbasaur" />
                            <AvRadio customInput label="Good" value="Squirtle" />
                            <AvRadio customInput label="Fair" value="Charmander" />
                            <AvRadio customInput label="Poor" value="Pikachu"/>
                        </AvRadioGroup>
                     </Col>
                </Row>
                <Row>
                    <Col>
                        <AvRadioGroup inline name="radioCustomInputExample2" label="Level of knowledge at the end of the course" className="LblText" required>
                            <AvRadio customInput label="Very Good" value="Bulbasaur" />
                            <AvRadio customInput label="Good" value="Squirtle" />
                            <AvRadio customInput label="Fair" value="Charmander" />
                            <AvRadio customInput label="Poor" value="Pikachu"/>
                        </AvRadioGroup>
                    </Col>
                     <Col>
                        <AvRadioGroup inline name="radioExample4" label="Level of communication" className="LblText" required>
                            <AvRadio   customInput label="Very Good" value="VeryGood" />
                                <AvRadio  customInput  label="Good " value="Good" />
                                <AvRadio  customInput label="Fair" value="Fair" />
                                <AvRadio  customInput label="Poor" value="Poor" />
                        </AvRadioGroup>
                    </Col>
                
                    </Row>
                    <Row>
                        <Col>
                            <AvRadioGroup inline name="LevelOfKnowlege" label="Level of knowledge at the end of the course" 
                            className="LblText" required>
                                <AvRadio  customInput  label="Very Good" value="VeryGood" />
                                <AvRadio  customInput  label="Good " value="Good" />
                                <AvRadio  customInput label="Fair" value="Fair" />
                                <AvRadio   customInput label="Poor" value="Poor" />
                            </AvRadioGroup>
                        </Col>
                        <Col>
                            <AvRadioGroup inline name="Recommendtoother" label="Would you recommend this course to other students?" className="LblText" required>
                                <AvRadio  customInput  label="Yes" value="Yes" />
                                <AvRadio  customInput label="No" value="No" />
                                
                            </AvRadioGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} style={{margin:'0 auto'}}>
                            <Button className="text-center mt-2">Submit</Button>
                        </Col>
                    </Row>
                    
      </AvForm>
      </Card>
        </Container>
    );
  }
}