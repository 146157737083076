import React from 'react';
import {Modal} from 'react-bootstrap'
import { Button, Input ,Row,Col,FormGroup,Label,Form} from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    group: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      }
  });
  function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }
const CreateTestModal = ({
        show,
        //courseId,
        testId,
        //testType,
        handleChange,
        testName,
        description,
        checkedShuffle,
        handleChangeShuffle,
        onHideCreateTestModal,
        duration,
        completeTestType,
        chapterList,
        selectedTestChapter,
        chapterId,
        handleSubmitTest,
        onClickCancelTest,
        handleRadioButton,
        testFormErrors
  }) => {

    return (
      <>
        <Modal show={show} onHide={onHideCreateTestModal} animation={false} size ="lg">
          <Modal.Header closeButton>
            <Modal.Title>Create Test</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* { testId === "" ? */}
              <Form className="FormWp">
                  <Row>
                  <Col md={5}>
                      <FormGroup>
                      <Label>Test Name</Label><span className="requiredFieldSpan">*</span>
                          <Input type="text" name="testName"  placeholder="Test Name" value={testName} onChange={handleChange} />
                          {
                              testFormErrors.testName !== "" ?
                              <Label  style={{ fontSize: 12, color: "red" }}>{testFormErrors.testName}</Label>: null
                          } 
                      </FormGroup>
                  </Col>
                  <Col md={7}>
                      <FormGroup>
                      <Label>Description</Label><span className="requiredFieldSpan">*</span>
                          <Input type="text" name="description"  placeholder="Description" value={description} onChange={handleChange} />
                          {
                              testFormErrors.description !== "" ?
                              <Label  style={{ fontSize: 12, color: "red" }}>{testFormErrors.description}</Label>: null
                          }
                      </FormGroup>
                  </Col>
                  </Row>
                  <Row>
                      <Col md={5}>
                          <div  className="">
                          <Label>Select Module</Label><span className="requiredFieldSpan">*</span>
                          <select id="chapterId" name="chapter" className="form-control" value={chapterId}
                          onChange={(e)=>{selectedTestChapter(e.target.value)}}
                          >
                           <option>Select Module</option> 
                            {
                                chapterList && chapterList.map(select => {
                                    return (
                                        <option 
                                            value={select.id}
                                            data-key={select.chapterName}
                                        >
                                            {select.chapterName}
                                        </option> 
                                    )
                                })
                            }
                          </select>
                      </div>
                      </Col>
                      <Col md={7} className="TimelimitRadio">
                      <FormControl component="fieldset">
                      <Label>Time Limit option <span className="requiredFieldSpan">*</span></Label>
                      {/* <FormLabel component="legend">Time Limit option</FormLabel> */}
                          <RadioGroup name={completeTestType} value={completeTestType} onChange={handleRadioButton} aria-label="timeLimitOption" >
                          <FormControlLabel value="Complete Test" control={<StyledRadio />} label="Complete Test" />
                          <FormControlLabel value="Question wise" control={<StyledRadio />} label="Question wise" className="ml-2"/>
                          <FormControlLabel value="None" control={<StyledRadio />} label="None" className="ml-2"/>
                          </RadioGroup>
                            {
                                testFormErrors.completeTestType !== "" ?
                                <Label  style={{ fontSize: 12, color: "red" }}>{testFormErrors.completeTestType}</Label>: null
                            }
                      </FormControl>
                      </Col>
                  </Row>
                  <Row>
                      { completeTestType !== "Question wise"?
                      <Col md={5}>
                      <FormGroup>
                          <Label>Duration(minutes)</Label><span className="requiredFieldSpan">*</span>
                          <Input type="number" name="duration"  placeholder="Duration" value={duration} onChange={handleChange}  />
                      </FormGroup>
                      </Col>:null
                      }
                      {/* <Col md={7}>
                      <FormGroup>
                        <Label>Shuffle</Label><span className="requiredFieldSpan">*</span><br/>
                          <Switch
                            checked={checkedShuffle}
                            onChange={handleChangeShuffle}
                            name="shuffle"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            color="primary"
                              />
                      </FormGroup>
                      </Col> */}
                  </Row>
                  <Row>
                      <Col className="text-center mt-2">
                      <div  className="center-block text-center">
                          <Button className="text-center mr-3 pl-3 pr-3 btnblue" onClick={handleSubmitTest}>Submit</Button>            
                          <Button className="text-center pl-3 pr-3 " color="danger" onClick={onClickCancelTest}>Cancel</Button>
                      </div>
                      </Col>
                  </Row>
              </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  export default CreateTestModal;
  