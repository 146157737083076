import React, { Component } from 'react';
import { render } from 'react-dom';
import '../../../Containers/Student/student.css'

export default class ToggleBtnThree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentSelection: 'Announcement',
      active: false
    }

  }

  toggleContent = (event) => {
    const currentState = this.state.active;
    event.preventDefault();
    this.setState({
      paymentSelection: event.target.value,
      active: !currentState
    })
  }



  render() {
    const { paymentSelection } = this.state;
    const { active } = this.state;

    return (
      <div className="container-fluid payment-btn-group text-center mt-3">
        <button outline className={paymentSelection ==='Announcement' ? 'active' : null} color="secondary" value="Announcement" onClick={this.toggleContent} ><a href="/Announcement">Announcement</a></button>
        <button outline className={paymentSelection ==='Progress' ? 'active' : null} color="secondary" value="Progress" onClick={this.toggleContent} >Progress</button>
        <button outline className={paymentSelection ==='AskQuestion' ? 'active' : null} color="secondary" value="AskQuestion" onClick={this.toggleContent} >Ask a Question</button>

      </div>
    );
  }
}