import React, { useState,useEffect } from "react";
import { Col,ListGroup,ListGroupItem,Row} from 'react-bootstrap';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Navbar, Button, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import Auth from '../../../ServerCall/Auth';
import {Link} from 'react-router-dom';
import {URL} from '../../../ServerCall/Config';
import { List } from "@material-ui/core";

const Footer = (props)=>{
    return(
        <div className="main-footer">
      <div className="container">
        {/* <Row >
          <Col className="footer-col" >
          <h4>Softlabs ACADEMY (INDIA)</h4>
            <ul className="list-unstyled">
            <li className="icon-location"> Bund Garden Road, Level 2, Connaught Place, Pune, Maharashtra 411001.
            </li>
            <li className="icon-call"> 93 721 09557 </li>
            <li className="icon-call"> 70 304 97497</li>
            <li className="icon-mail"> <a href="#" className="__cf_email__" data-cfemail="472e29212807242829342e2b2e322a26242623222a3e6924282a">[email&#160;protected]</a>
            </li>
            </ul>
            <a href="#" className="icon_bar  icon_bar_linkedin icon_bar_small">
              <span className="t"><i className="icon-linkedin"></i></span>
              <span className="b"><i className="icon-linkedin"></i></span></a>
            <a href="#" className="icon_bar  icon_bar_facebook icon_bar_small" target="_blank" rel="noopener noreferrer">
              <span className="t"><i className="icon-facebook"></i></span>
              <span className="b"><i className="icon-facebook"></i></span></a>
            <a href="#" className="icon_bar  icon_bar_twitter icon_bar_small" target="_blank" rel="noopener noreferrer">
              <span className="t"><i className="icon-twitter"></i></span>
              <span className="b"><i className="icon-twitter"></i></span></a>
            <a href="#" className="icon_bar  icon_bar_vimeo icon_bar_small">
              <span className="t"><i className="icon-vimeo"></i></span>
              <span className="b"><i className="icon-vimeo"></i></span></a>
            <a href="#" className="icon_bar  icon_bar_youtube icon_bar_small">
              <span className="t"><i className="icon-play"></i></span>
              <span className="b"><i className="icon-play"></i></span></a>
          </Col>
      
          <Col>
            <h4>Column 2</h4>
            <ui className="list-unstyled">
              <li>lorem Ipsum</li>
              <li>lorem Ipsum</li>
            </ui>
          </Col>
          
          <Col>
            <h4>Column 3</h4>
            <ui className="list-unstyled">
              <li><Link className="footer-links" to='/Refund'>Refund Policy</Link></li>
            </ui>
          </Col>
        </Row> */}
        <Row>
          <Col>
          <p className="d-inline-block">&copy;{new Date().getFullYear()} Consilium Academy</p>
          <Nav className="float-right">
          <NavItem className="">
            <Link className="footer-links" to="#" >Term &#038; Conditions</Link>
            <Link className="footer-links" to="#" >Privacy Policy</Link>
            <Link className="footer-links" to='/Refund'>Refund &#038; Cancellation Policy</Link>
            </NavItem>
            </Nav>    
          </Col>
        </Row>
      </div>
    </div>
    )

}
export default (Footer);
