import react from 'react'
import {Row,Col,Container} from 'react-bootstrap'
import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button} from 'reactstrap';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button onClick={() => onClick()} />;
  };
 const ClientTestimonial = () =>{
     return(
        <Container fluid className="ClientTestimonialWp">
            <Row>
                <Col>
                <h4 className="mainHeadingText clientHeadText text-center">What Our Clinet Say</h4>
                </Col>
            </Row>
            <Carousel  responsive={responsive} partialVisible={true}>
                <div>
                    <Card className="clientCard">
                            <CardBody>
                                <CardTitle className="clintTestiTitl text-center">Alex Ritchell</CardTitle>
                                <CardSubtitle  className="mb-3 desigText text-center">CEO ,Founder</CardSubtitle>
                                <Row><Col md={4} className="profileWp text-center mx-auto"> <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"  className="rounded-circle img-fluid"/></Col></Row>
                                <CardText className="testiText">Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                            </CardBody>
                        </Card>
                        <div className="greenBox">
                            <FormatQuoteIcon/>
                        </div>
                
                    </div>
                    <div>
                
                        <Card className="clientCard">
                            <CardBody>
                                <CardTitle className="clintTestiTitl text-center">Alex Ritchell</CardTitle>
                                <CardSubtitle  className="mb-3 desigText text-center">CEO ,Founder</CardSubtitle>
                                <Row><Col md={4} className="profileWp text-center mx-auto"> <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"  className="rounded-circle img-fluid"/></Col></Row>
                                <CardText className="testiText">Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                            </CardBody>
                        </Card>
                        <div className="greenBox">
                            <FormatQuoteIcon/>
                        </div>
                
                    </div>
                    <div>
                    <Card className="clientCard">
                            <CardBody>
                                <CardTitle className="clintTestiTitl text-center">Alex Ritchell</CardTitle>
                                <CardSubtitle  className="mb-3 desigText text-center">CEO ,Founder</CardSubtitle>
                                <Row><Col md={4} className="profileWp text-center mx-auto"> <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"  className="rounded-circle img-fluid"/></Col></Row>
                                <CardText className="testiText">Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                            </CardBody>
                        </Card>
                        <div className="greenBox">
                            <FormatQuoteIcon/>
                        </div>
                    </div>
                    <div>
                    <Card className="clientCard">
                            <CardBody>
                                <CardTitle className="clintTestiTitl text-center">Alex Ritchell</CardTitle>
                                <CardSubtitle  className="mb-3 desigText">CEO ,Founder</CardSubtitle>
                                <Row><Col md={4} className="profileWp text-center mx-auto"> <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"  className="rounded-circle img-fluid"/></Col></Row>
                                <CardText className="testiText">Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                            </CardBody>
                        </Card>
                        <div className="greenBox">
                            <FormatQuoteIcon/>
                        </div>
                    </div>
                    <div>
                    <Card className="clientCard">
                            <CardBody>
                                <CardTitle className="clintTestiTitl text-center">Alex Ritchell</CardTitle>
                                <CardSubtitle  className="mb-3 desigText text-center">CEO ,Founder</CardSubtitle>
                                <Row><Col md={4} className="profileWp text-center mx-auto"> <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"  className="rounded-circle img-fluid"/></Col></Row>
                                <CardText className="testiText">Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                            </CardBody>
                        </Card>
                        <div className="greenBox">
                            <FormatQuoteIcon/>
                        </div>
                    </div>
            </Carousel>
        </Container>

     );
 }
 export default ClientTestimonial;