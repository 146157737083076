import React from "react";
import "../Course/CourseMaster.css";
import DeleteConfirm from "../../../Components/Common/deleteConf/deleteComf.js";
import ViewImage from "../../../Components/Common/ViewImage.js";
import {  Form,  Card,  FormGroup,  Label,  Input,  Col,  Row,  Container,  UncontrolledTooltip,  Button,  ListGroup,  ListGroupItem} from "reactstrap";
import Table from "../../../Components/Admin/Course/Table";
import TextEditor from "../../../Components/Common/TextEditor";
import Loader from "../../../Components/Common/Loader/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../Components/Admin/Course/Table.css";
import SimpleDropZone from "../../../Components/Common/SimpleDropZone";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import moment from "moment";
import { URL, mediaUrl } from "../../../ServerCall/Config";
import MediaAxiosApiInstance from "../../../ServerCall/MediaAxios.Instance";
import swal from "sweetalert";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";

const headerStyle = {
  backgroundColor: "#4c8db5",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

export default class EventEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      SelectParentCategory: [],
      EventId: 0,
      loading: false,
      fileUrl: [],
      eventImages: [],
      showDelModal: false,
      showDelEvent: false,
      rowData: "",
      show: false,
      eventData: {
        eventName: "",
        description: "",
        startDate: new Date(),
        endDate: new Date(),
      },
      istrue: false,
      LabelText: {
        eventName: "",
        description: "",
        event_file: "",
        startDate: "",
        endDate: "",
      },
      columns: [
        // { title: "Id", field: "id" , hidden: true },
        {
          title: "Sr No.",
          render: (rowData) => <span>{rowData.tableData.id + 1}</span>,
        },
        { title: "Title", field: "eventName" },
        { title: "Start Date", field: "startDate", type: "date" },
        { title: "End Date", field: "endDate", type: "date" },
        {
          title: "Discription",
          render: (rowData) => (
            <p
              className="Coursedescription"
              dangerouslySetInnerHTML={{ __html: rowData.description }}
            />
          ),
        },
      ],
      Data: [],
      isSubmit: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    //debugger;
    //alert(event.target.value)
    let { eventData } = this.state;
    eventData[event.target.name] = event.target.value;
    this.setState({ eventData: eventData });
  }

  getTableData =()=> {
    axiosApiInstance
      .get(`${URL}/event`)
      .then((res) => {
        let Events = res.data.event;
        // console.log(res)
        if (res.status === 200 || res.status === 204 ) {
          this.setState({ Data: Events });
        }
        // console.log("TData", this.state.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getTableData();
  }

  Validation = () => {
    let { eventData, LabelText, fileUrl, eventImages } = this.state;
    if (eventData.eventName !== "" && eventData.eventName !== null) {
      LabelText.eventName = "";
    } else {
      LabelText.eventName = "Please enter event Title";
    }
    if (eventData.startDate !== "" && eventData.startDate !== null) {
      LabelText.startDate = "";
    } else {
      LabelText.startDate = "Please select start date";
    }
    if (eventData.endDate !== "" && eventData.endDate !== null) {
      LabelText.endDate = "";
    } else {
      LabelText.endDate = "Please select end date";
    }
    eventImages.length || fileUrl.length > 0
      ? (LabelText.event_file = "")
      : (LabelText.event_file = "Please upload files!");
    this.setState({ LabelText: LabelText });
    if (
      LabelText.eventName === "" &&
      LabelText.startDate === "" &&
      LabelText.endDate === "" &&
      LabelText.event_file === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  onClickEventData = () => {
    const isValid = this.Validation();
    if (isValid) {
      let startDate = new Date(this.state.eventData.startDate).toISOString();
      let endDate = new Date(this.state.eventData.endDate).toISOString();
      const { eventName, description } = this.state.eventData;
      const { fileUrl } = this.state;
      this.setState({ loading: true });
      const formDataimage = new FormData();
      fileUrl.forEach((item) => {
        formDataimage.append("photos", item.file);
      });
      formDataimage.append("name", eventName);
      //debugger
      MediaAxiosApiInstance.post(
        `${mediaUrl}/multiple-image`,
        formDataimage
      ).then((res) => {
        //debugger
        if (res.status == 200) {
          var image = [];
          res.data.data &&
            res.data.data.map((img) => {
              image.push({ url: img.url });
            });
        }
        axiosApiInstance
          .post(`${URL}/event`, {
            eventName,
            description,
            startDate,
            endDate,
            fileUrls: image,
          })
          .then((response) => {
            const res = response.data.category;
            if (response.status == 201) {
              this.setState({ loading: false });
              swal("Event Added", "", "success");
              this.getTableData();
              this.setState({ isSubmit: true });
              this.onClickCancel();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  onClickRemove = () => {
    //debugger;
    let url = this.state.rowData;
    let name = ``;
    var getLocation = function (href) {
      var l = document.createElement("a");
      l.href = href;
      return l;
    };
    var host = getLocation(url);
    if (host.hostname === "lmsmedia.softlabsgroup.in") {
      name = `${url.slice(38)}`;
    } else {
      name = `${url.slice(26)}`;
    }
    axiosApiInstance
      .post(`${mediaUrl}/delete-file`, { name: name })
      .then((res) => {
        if (res.data.fileName != "null") {
          // console.log("deleted", name);
          var eventImages = [];
          this.state.eventImages &&
            this.state.eventImages.map((data) => {
              eventImages.push({ url: data.url });
            });
          eventImages &&
            eventImages.map((data) => {
              if (data.url === url) {
                var indexOfImage = eventImages.findIndex(
                  (a) => a.url === data.url
                );
                eventImages.splice(indexOfImage, 1);
              }
            });
          this.setState({ eventImages: eventImages });
        } else {
          swal("Something went Wrong", res.message, "Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    //console.log(name, "is", typeof name);
  };

  getEventImages = () => {
    const { eventImages } = this.state;
    var eventImagesArr =
      eventImages &&
      eventImages.map((data, i) => {
        return (
          <Col xs="2" key={i}>
            <Col>
              <IconButton
                onClick={() =>
                  this.setState({
                    showDelModal: true,
                    rowData: data.url,
                  })
                }
              >
                <Cancel />
              </IconButton>
              <img
                src={data.url}
                onClick={() => this.setState({ show: true, rowData: data.url })}
                id="image"
                style={{ width: "100%" }}
              />
            </Col>
          </Col>
        );
      });
    return eventImagesArr;
  };

  onClickCancel = () => {
    let { eventData, LabelText } = this.state;
    eventData.eventName = "";
    eventData.description = "";
    eventData.startDate = new Date();
    eventData.endDate = new Date();
    LabelText.eventName = "";
    LabelText.startDate = "";
    LabelText.endDate = "";
    this.setState({ isSubmit: false });
    this.setState({
      eventData: eventData,
      istrue: false,
      EventId: 0,
      fileUrl: [],
      eventImages: [],
    });
  };

  onEdit = (
    editEventName,
    editDescription,
    editEventId,
    imageurl,
    editstartDate,
    editendDate
  ) => {
    //alert("On Edit Called " + editstartDate + editendDate);
    this.onClickCancel();
    const { eventData } = this.state;
    eventData.eventName = editEventName;
    eventData.description = editDescription;
    eventData.startDate = new Date(editstartDate);
    eventData.endDate = new Date(editendDate);
    let img = [];
    imageurl &&
      imageurl.map((data) => {
        img.push({ url: data.url });
      });
    this.setState({ EventId: editEventId });
    this.setState({ eventData: eventData, eventImages: img });
    this.setState({ istrue: true });
  };

  onUpdateEvent = () => {
    const isValid = this.Validation();
    if (isValid) {
      let startDate = new Date(this.state.eventData.startDate).toISOString();
      let endDate = new Date(this.state.eventData.endDate).toISOString();
      const { eventName, description } = this.state.eventData;
      const { fileUrl } = this.state;
      let EventId = this.state.EventId;
      this.setState({ loading: true });
      //debugger;
      const formDataimage = new FormData();
      fileUrl.forEach((item) => {
        formDataimage.append("photos", item.file);
      });
      formDataimage.append("name", eventName);
      MediaAxiosApiInstance.post(
        `${mediaUrl}/multiple-image`,
        formDataimage
      ).then((res) => {
        //debugger;
        if (res.status == 200) {
          var image = [];
          res.data.data &&
            res.data.data.map((img) => {
              image.push({ url: img.url });
            });
          var eventImages = [];
          this.state.eventImages &&
            this.state.eventImages.map((data) => {
              eventImages.push({ url: data.url });
            });
          var allImages = [...image, ...eventImages];
        }
        axiosApiInstance
          .put(`${URL}/event/${EventId}`, {
            eventName,
            description,
            fileUrls: allImages,
            startDate,
            endDate,
          })
          .then((response) => {
            const res = response.data.event;
            if (response.status == 200) {
              this.setState({ loading: false });
              swal("Event Updated", "", "success");
              this.getTableData();
              this.setState({ isSubmit: true });
              this.onClickCancel();
              this.setState({ istrue: false });
            } else {
              // swal("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  handleEditorChange = (editorText) => {
    //debugger;
    let { eventData } = this.state;
    eventData.description = editorText;
    this.setState({ eventData });
  };

  arrayData = (data) => {
    if (data.length !== this.state.fileUrl.length) {
      this.setState({ fileUrl: data });
    }
    // console.log(this.state.fileUrl, "this.state.fileUrl");
  };

  mediaDelete=(eventDelFiles)=>{
    eventDelFiles &&
      eventDelFiles.map((items) => {
        let url = items.url;
        let name = ``;
        var getLocation = function (href) {
          var l = document.createElement("a");
          l.href = href;
          return l;
        };
        var host = getLocation(url);
        if (host.hostname === "lmsmedia.softlabsgroup.in") {
          name = `${url.slice(38)}`;
        } else {
          name = `${url.slice(26)}`;
        }
        let data = { name: name };
        axiosApiInstance
          .post(`${mediaUrl}/delete-file`, data)
          .then((res) => {
            if(res.status===200){
              // console.log("Response: ",res);
              // console.log("Media files Deleted successfully");
            }
            else{
              // console.log("Files not Deleted");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
  }

   onDelete = (eventDelId, eventDelFiles) => {
    // debugger;
    this.setState({ loading: true });
    axiosApiInstance
      .delete(`${URL}/deleteEventById/${eventDelId}`)
      .then((res) => {
        //debugger;
        if(res.status===200){
          this.mediaDelete(eventDelFiles);
          this.setState({ loading: false });
          // console.log(res.data.message)
          swal("Event Deleted Successfully!", "", "success");
          this.onClickCancel();
          this.getTableData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  render() {
    const { Data, columns, rowData, LabelText, isSubmit } = this.state;
    let { eventData } = this.state;
    const today = new Date();
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <DeleteConfirm
          show={this.state.showDelModal}
          animation={true}
          rowData={rowData}
          // id={}
          // name={}
          close={() => this.setState({ showDelModal: false })}
          deleteHandler={this.onClickRemove}
        />
        <DeleteConfirm
          show={this.state.showDelEvent}
          animation={true}
          rowData={rowData}
          id={rowData._id}
          name={rowData.eventName}
          files={rowData.fileUrls}
          close={() => this.setState({ showDelEvent: false })}
          deleteHandler={this.onDelete}
        />
        <ViewImage
          show={this.state.show}
          animation={true}
          url={rowData}
          viewHandler={this.onClickView}
          close={() => this.setState({ show: false })}
        />
        {this.state.loading ? <Loader /> : <div></div>}
        <Card className="shadow p-4 col-md-12">
          <Card className="col-md-8 p-4" style={{ margin: "0 auto" }}>
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">Events</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Title</Label>
                    <Input
                      type="text"
                      name="eventName"
                      onChange={this.handleChange}
                      value={eventData.eventName}
                    />
                    {LabelText.eventName !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {LabelText.eventName}
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Start Date</Label>
                    <DatePicker
                      dateFormat="MMMM d, yyyy"
                      selected={eventData.startDate}
                      onChange={(date) =>
                        this.setState({
                          eventData: { ...eventData, startDate: date },
                        })
                      }
                      selectsStart
                      startDate={eventData.startDate}
                      endDate={eventData.endDate}
                      disabledDays={{ before: today }}
                      minDate={today}
                    />
                    {LabelText.startDate !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {LabelText.startDate}
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>End Date</Label>
                    <DatePicker
                      selected={eventData.endDate}
                      dateFormat="MMMM d, yyyy"
                      onChange={(date) =>
                        this.setState({
                          eventData: { ...eventData, endDate: date },
                        })
                      }
                      selectsEnd
                      startDate={eventData.startDate}
                      endDate={eventData.endDate}
                      minDate={eventData.startDate}
                    />
                    {LabelText.endDate !== "" ? (
                      <Label style={{ fontSize: 12, color: "red" }}>
                        {LabelText.endDate}
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label> Description</Label>
                    <span className="requiredFieldSpan">*</span>
                    {/*  <Input type="textarea" name="description" id="CourseDescription" onChange={this.handleChange} value={eventData.description} />*/}
                    <TextEditor
                      name="description"
                      id="CourseDescription"
                      onChange={this.handleEditorChange}
                      value={eventData.description}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label>Event Image</Label>
                  <span className="requiredFieldSpan">*</span>
                  <br />
                  <FormGroup>
                    <Row xs="2">{this.getEventImages()}</Row>
                  </FormGroup>
                  <SimpleDropZone
                    arrayData={this.arrayData}
                    isSubmit={isSubmit}
                  />
                  {LabelText.event_file !== "" ? (
                    <Label style={{ fontSize: 12, color: "red" }}>
                      {LabelText.event_file}
                    </Label>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3">
                  <div className="center-block text-center">
                    {this.state.istrue ? (
                      <Button
                        className="text-center mr-3 btnblue"
                        onClick={this.onUpdateEvent}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="text-center mr-3 btnblue"
                        onClick={this.onClickEventData}
                      >
                        Submit
                      </Button>
                    )}
                    <Button
                      className="text-center clearBtn"
                      color="danger"
                      onClick={() => {
                        this.onClickCancel();
                      }}
                    >
                      {this.state.istrue ? "Cancel" : "Clear"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
          <Row className="mb-4 mt-4">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table
                    data={Data}
                    pageSize={5}
                    paging={true}
                    columns={columns}
                    search={true}
                    headerStyle={headerStyle}
                    rowStyle={rowStyle}
                    maxBodyHeight="200px"
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          //alert("Edit Action Clicked " + rowData.startDate + rowData.id)
                          this.onEdit(
                            rowData.eventName,
                            rowData.description,
                            rowData._id,
                            rowData.fileUrls,
                            moment(rowData.startDate).format("YYYY-MM-DD"),
                            moment(rowData.endDate).format("YYYY-MM-DD")
                          );
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          this.setState({
                            showDelEvent: true,
                            rowData: rowData,
                          });
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}
