import React from 'react';
import {Card ,Row,Col,Container} from 'reactstrap';
import '../../../Containers/Student/student.css'
export default  class Payment extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            EnrollDate:"04/05/2019",
            CourseName:'2290',
            AssignmentScore:'Quiz 1-10,Quiz 2-20',
           
        }
      }
      render(){
          const{EnrollDate,CourseName,AssignmentScore}=this.state;
        return( 
            <Container  className="PaymentWp Progress">
                <Col md={6} style={{margin:'0 auto'}}>
                    <Card className="p-4 priceTotl shadow-sm p-3">
    
                    <Row>
                            <Col className="mb-5 mt-2">
                                <h4 className="pricetxt text-center ">Progress</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}  sm={6} xs ={6}>
                                <h5 className="totalpriceTxt "> Enroll Date </h5>
                            </Col>
                            <Col md={8}  sm={6} xs ={6}>
                                <h5 className="PriceValueText">  {EnrollDate} </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}  sm={6} xs ={6}>
                                <h5 className="totalpriceTxt"> Course Name </h5>
                            </Col>
                            <Col md={8}  sm={6} xs ={6}>
                                <h5 className="PriceValueText"> {CourseName} </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}  sm={6} xs ={6}>
                                <h5 className="totalpriceTxt"> Assignment score </h5>
                            </Col>
                            <Col md={8}  sm={6} xs ={6}>
                                <h5 className="PriceValueText"> {AssignmentScore} </h5>
                            </Col>
                        </Row>
                      
                </Card>
            </Col>
        </Container>

    )
        }}
