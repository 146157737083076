import React from "react";
import '../../../Containers/Admin/Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container,Button } from 'reactstrap';
import  {AvForm, AvField, AvGroup, AvInput,AvFeedback }  from 'availity-reactstrap-validation';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import {  faUser,faLock} from "@fortawesome/free-solid-svg-icons";
import logo from '../../../Images/con_logo-old.png'

//import FacebookLogin from 'react-facebook-login';
//import GoogleLogin from 'react-google-login';
import '../student.css'
import axios from 'axios';
import swal from "sweetalert";
import {URL} from '../../../ServerCall/Config';
import { setProfile } from '../../../redux/action/profileAction';
import { connect } from 'react-redux';

const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

class Login extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        username:"",
        password:"",
        formErrors:{ 
            username:"",
            password:"",
        },
    }
    this.handleChange = this.handleChange.bind(this);
  }

  formvalid = formErrors =>{
    let valid = true;
    let {username,password} = this.state;
    validEmailRegex.test(username)?formErrors.username ="":formErrors.username = "Invalid Email entered";
   // username !== ''?formErrors.username ="":formErrors.username = "Please enter email";
    password !== ''?formErrors.password ="":formErrors.password = "Please Enter Password!";
    this.setState({formErrors:formErrors});
    Object.values(formErrors).forEach(val =>{
        val !== "" && (valid = false);
    }) ;
    return valid;
  }

  handleChange = e =>{
    //debugger
    e.preventDefault();
    const { name,value} = e.target;
    this.setState({[name]: value});  
  }

  componentDidMount(){
    document.body.style.overflow = "auto";
    document.body.style.backgroundImage = "url(https://consiliumacademy.com/images/login-background.jpg)";
    document.body.style.backgroundSize = "cover";
  }
  
  handleSubmit = e =>{
    let {username,password} = this.state;
    e.preventDefault();
    const { name, value } = e.target;
    const isValid = this.formvalid(this.state.formErrors);
    let formErrors = this.state.formErrors;
    //validEmailRegex.test(email);
    if (username === "") {formErrors.username="Please Enter Email!"}
    switch (name) {
      case "username":
        formErrors.username = validEmailRegex.test(value)
          ? ""
          : "Valid Email address Required";
        //formErrors.username = value !=""? "":"Email Req.";
        break;
      case "password":
        formErrors.password = value !== "" ? "" : "password Required";
        break;
    }
    this.setState({ formErrors: formErrors });
    if(isValid){
        //debugger
        axios.post(`${URL}/users/login`,{"credential":{username,password}})
        .then((res)=>{
            //debugger
            //console.log("res----",res);
            if(res.status === 200 ){
               // debugger
              //  console.log(res.data.user_token);
              //  debugger
            //    this.props.profile.fullName = res.data.user_token.user_name;
               
                localStorage.setItem('userData', JSON.stringify(res.data.user_token));
                let courseId = localStorage.getItem('courseId');
                this.props.setProfile(res.data.user_token);
                if(res.data.user_token.role === "faculty"){
                    window.location.href = "/FacultyDashboard";
                   // this.props.history.push("/CourseAllocated");
                }
                else if (res.data.user_token.role === "student"){
                  courseId != null
                    ? window.location.href = "/CourseSection"
                    : window.location.href = "/StudentDashboard";
                 }
                else {
                    window.location.href = "/AdminDashobard";
                    //this.props.history.push("/Course");
                }
                
            }else{
                //debugger
                swal("", res.message || "Incorrect Email or Password!", 'error');
            }
        }).catch(err=>{
            console.log("Test",err);
           // swal(, "Something Went Wrong", 'error');
        })
    }
}

render(){
     let {formErrors} = this.state;
    return (
      <div className="h-100">
      <Container fluid className="CouseMasterWp FormWp h-100">
        <Row className="h-100 align-items-center justify-content-center ">
          <Col md={6}>
            <Card className="shadow px-4 py-5 LoginCard">
              <Col md={12}>
                <Row>
                  <Col className="mb-4">
                    <div className="logoWp">
                      <img src={logo} alt="logo" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4">
                    <h3 className="text-center blueText">Login</h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="loginWp">
                    <AvForm>
                      <Row>
                        <Col md={6} className="colWp">
                          <AvGroup>
                            <Label className="text-left">Email</Label>
                            <AvInput
                              type="Email"
                              name="username"
                              className="userinput"
                              onChange={this.handleChange}
                              required
                              placeholder=""
                            />
                            {/* <FontAwesomeIcon icon={faUser} /> */}
                            {formErrors.username !== "" ? (
                              <Label style={{ fontSize: 12, color: "red"}}>
                                {formErrors.username}
                              </Label>
                            ) : null}
                          </AvGroup>
                        </Col>
                        <Col md={6}>
                          <AvGroup>
                            <Label className="text-left">Password</Label>
                            <AvInput
                              type="password"
                              name="password"
                              onChange={this.handleChange}
                              className="userinput"
                              required
                              placeholder=""
                            />
                            {/* <FontAwesomeIcon icon={faLock} /> */}
                            {formErrors.password !== "" ? (
                              <Label style={{ fontSize: 12, color: "red" }}>
                                {formErrors.password}
                              </Label>
                            ) : null}
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center mt-3">
                          <div className="center-block text-center">
                            <Button
                              className="text-center mr-3 btnblue pl-3 pr-3"
                              onClick={this.handleSubmit}
                            >
                              Sign In
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                                  <Col className="forgttxt text-center">
                                      Forgot Password
                                  </Col>
                              </Row> */}
                      {/* <Row className="mt-3"> 
                              <Col md={1}></Col>
                                  <Col md={5} sm={12} xs={12} className="text-center">
                                      <FacebookLogin
                                          //appId="448032606079938"
                                          fields="name,email,picture"
                                          //callback={responseFacebook}
                                          cssClass="my-facebook-button-class"
                                          icon={<i className="fa fa-facebook" style={{}}></i>}
                                          />
                                  </Col>
                                  <Col md={5} sm={12} xs={12}  className="text-center">
                                      <GoogleLogin className="btnGoogle"
                                        // clientId="1072858851365-n2gp5smrtho98v0vjkabp0i9471rmrvk.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                                          buttonText="Login With Google"              
                                          //onSuccess={responseGoogle}
                                          //onFailure={responseGoogle} 
                                      />
                                  </Col>
                                  <Col md={1}></Col>
                              </Row> */}
                      <Row>
                      <Col className="forgttxt mt-3 text-center">
                        <center>
                          <b>Don't have an account ? </b>
                          <a href="/Registration">
                            <b> Click Here </b>
                          </a>
                          <b>to register </b>
                        </center>
                        <center>
                          <a href="/ForgotPassword">
                            <b>  Forgot Password ? </b>
                          </a>
                        </center>
                      </Col>
                    </Row>
                    </AvForm>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
    );
}
} 
const mapStoreToProps = (state) => {
    //debugger
    // console.log(state,"datadatadata");
    return{
      Profile: state.profile.profile
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    setProfile:(profile)=>dispatch(setProfile(profile))
  })
  export default connect(mapStoreToProps, mapDispatchToProps)(Login);
