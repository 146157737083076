
import React from "react";
import { Form,Card,Col,Row,Container} from 'reactstrap';
import Table from  '../../../Components/Admin/Course/Table';
import   '../../../Components/Admin/Course/Table.css'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {Link} from 'react-router-dom';
import {URL} from '../../../ServerCall/Config';
import Auth from '../../../ServerCall/Auth';
import StudentModal from '../../Admin/Course/StudentModal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ContactMailSharp } from "@material-ui/icons";

  const headerStyle = {
    backgroundColor: '#4c8db5',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,

  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',

  }

  export default  class CourseSchudleList extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        addmodl:[],
        role: Auth.getUserRole(),
        addModalShow:false ,
        selectedValue: '',
        categoryName:'',
        SelectParentCategory:[],
        categoryId:'',
        istrue:false,
        loading:false,
        addModalShow:false,
        columns: [  
          { title: "Id", field: "_id", hidden: true},
          { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
          { title: "Course Name", field: "courseName"},
          { title: "Batch",field:"batchName"}, 
          { title: "Duration", field: "duration"},
          { title: "Start Date", field: "startDate", type: "date"},
          { title: "End Date", field: "endDate",type: "date"},
          { title: "Student Allocated", field: "studentIds", render: rowData=><span><Link onClick={()=>this.showStudent(rowData)}>{rowData.studentIds.length}</Link></span>},
          { title: "view", field: "_id", render: rowData => <Link to={{ pathname: `/TabCourseForum/${rowData.courseId}/${rowData._id}`,state: {batchId: rowData._id}}}><VisibilityIcon className="ml-4"/></Link>}
        ],
        StudentColumns: [  
          { title: "Id", field: "userId",hidden: true},
          { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
          { title: "Student Name", render: rowData => <span>{rowData.firstName +" "+ rowData.middleName +" "+ rowData.lastName}</span>},
          { title: "Email", field: "email"}
        ],
        Data : [],
      }
    }

    // getBatchName = (rowData) =>{
    //   // debugger
    //   let scheId=rowData._id
    //   let batchName = "";
    //   axiosApiInstance.get(`${URL}/courseSchedule/${scheId}`)
    //   .then(res => {
    //     // console.log(res.data.Course.batchName);
    //     batchName=res.data.Course.batchName;
    //     console.log(batchName)
    //   })
    //   .catch(err=>{console.log(err);});      
    //   return batchName;
    // }
    addModalClose=() =>{this.setState({addModalShow:false})};
    showStudent = (data) =>{
      //debugger
     this.setState({loading:true});
      axiosApiInstance.post(`${URL}/getStudentDetailsByIds`,{"Ids":data.studentIds})
      .then(res=>{
        //debugger
        //console.log("resStudent",res);
        if(res.status === 200){
        
          let students = res.data.item;
          this.setState({scheduleStudent:students});
          this.setState({addModalShow:true,loading:false});
          
        }
        else{
          this.setState({addModalShow:true,loading:false});
        }
      })
      .catch(err=>{
        this.setState({addModalShow:false,loading:false});
        console.log("err",err);
      })

    }

    componentDidMount (){
      let user_id = Auth.getUserId();

      axiosApiInstance.get(`${URL}/courseScheduleByFaculty/${user_id}`)
      .then(res =>{
          //debugger
          let Courses = res.data.CourseSchedule;
          // console.log(res)
          if(res.status == 200)
          {
            this.setState({Data:Courses});
          }
    
        }).catch(err => {
          console.log(err);
        })
    }

    render(){
      const{Data,columns} =this.state;
        return(
        <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 h-100">
          <Card className="shadow p-4 col-md-12 cardHeight">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Batch List</h3>
            </Col>
          </Row>
          <Form>
          <Row>
          <Col md={8}>
                  <StudentModal 
                      show={this.state.addModalShow} onHide={this.addModalClose} columns={this.state.StudentColumns} 
                      scheduleStudent={this.state.scheduleStudent}
                  />
              </Col> 
          </Row>
            <Row className="mb-2 ">
              <Col className="tableWp mt-2">
                    <Row>
                      <Col className="DashboardTbl">
                        <Table data={Data} pageSize={10} paging={true} columns={columns}search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='400px' 
                            // actions={
                            //   [
                            //     {
                            //       icon: 'edit',
                            //       tooltip: 'Edit',
                            //       onClick: (event, rowData) => {
                            //         this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                            //       }
                            //     }
                            //   ]
                            // }
                        />
                      </Col>
                  </Row>            
              </Col>
            </Row>
          </Form>
          </Card>
        </Container>
      );
    }
  } 
