import React from "react";
import { Row,Col,Container,Card} from 'reactstrap';
import {Form,InputGroup,} from 'react-bootstrap';
import MediaCard from '../../../Components/Student/Course/MediaCard';
import PaginationLine from  '../../../Components/Student/Course/PaginationLine';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { Redirect } from 'react-router-dom';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export default class Course extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      Coursedata:[],
      isRedirect: false,
      courseId:'',
      itemsPerPage : 8,
      page :1,
      noOfPages : null,
      filter:'',
      filteredCourseData:[],
    }
  }

  getCourseData () {
    axiosApiInstance.get(`${URL}/allCourse`)
    .then(res =>{
      //debugger
      let CourseData = [];
      if(res.status === 200){
        res.data.Course && res.data.Course.map(data =>{
          CourseData.push({Id:data._id,courseName:data.courseName,description:data.description,defaultimage:data.imageOrVideoPath.default})
        })
        let pages = Math.ceil(CourseData.length / this.state.itemsPerPage);
        this.setState({Coursedata: CourseData,noOfPages:pages});
      }else{
        swal(res.data.message, "", "error")
      }
    }).catch(err => {
      console.log(err)
    })
  }

  componentDidMount (){
    this.getCourseData()
  }

  handlePageChange = async(e,value) => {
    await this.setState({page:value})
  };
      // courseLink =(id) =>{
      //   debugger
      //   return <Redirect to={`/CourseDetail?id=${id}`} />
      //   //this.props.history.push(`/CourseDetail?id=${id}`)
      //   //this.props.history.push(`/Checkout`)
      // }
  redirect = (Id) => {
    this.setState({ isRedirect: true,courseId:Id });
  }
  
  handleChange = event =>{
    //debugger
    this.setState({filter:event.target.value})
  }

  render(){
    //debugger
    let{ Coursedata,filter,filteredCourseData}=this.state;
    const lowercasedFilter = filter.toLowerCase();
     filteredCourseData = Coursedata.filter(course =>
      course.courseName.toLowerCase().includes(lowercasedFilter));
    return( 
      <Container fluid  className=" pl-0 pr-0 CardContainer CourseWp h-100">
        {/* <Row className="mb-5  mt-5 pl-3">
            <Col>
                10,000 Results of Java Programming
            </Col>
        </Row> */}
        <Card className="p-4 shadow h-100">
        <Row className="mb-5  mt-4 pl-3">
            <Col md={6}>
                <h3 className="hdtxt">Avaliable Courses</h3>
            </Col>
            <Col>
            <Form.Row>
                <Form.Group as={Col} className="mb-0">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text >
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            placeholder="Search Your Course.."
                            value={ this.filter}
                            onChange={this.handleChange}
                        />
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            </Col>
        </Row>
          <Row>
            {Coursedata&&Coursedata&&filteredCourseData.slice(((this.state.page - 1)*(this.state.itemsPerPage)), (this.state.page)*(this.state.itemsPerPage)).map(item =>
              <Col md={3} className="CourseWpCol mb-3">
                <MediaCard courseDetail={() =>this.redirect(item.Id)} title={item.courseName}
                  text={item.description}
                  image={item.defaultimage}
                  //image={require('../../../Images/python.png')}
                />
                {this.state.isRedirect ? <Redirect to={`/TabCourseForum/${this.state.courseId}`} /> : null }
              </Col>
            )}
          </Row>                   
          <Row className="mt-5">
              <Col md={7} sm={6} xs={12}>
                  
              </Col>
              <Col md={5} sm={6} xs={12} className="float-right pgWp">
                  <PaginationLine
                    noOfPages={this.state.noOfPages}
                    page={this.state.page}
                    handlePageChange={this.handlePageChange}
                  />
              </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}