import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons";

const LSubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items } = props;

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle">
          <FontAwesomeIcon icon={icon} className="mr-3" />
          {title}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => (
          <NavItem key={index} >
            <NavLink tag={Link} to={item.target} className="pl-4" >
              {item.title}
               <a href="#" className="videoTime  float-right">2.27</a>
               <FontAwesomeIcon icon={faPlayCircle} className=" float-right mr-4 mt-1" />
            </NavLink>
          
         
          </NavItem>
        ))}
      </Collapse>
    </div>
  );
};


export default LSubMenu;