//import * as ReactDOM from 'react-dom';
import * as React from 'react';
//import { Query } from '@syncfusion/ej2-data';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
// import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
// import { DropDownListComponent,MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import 'react-dropzone-uploader/dist/styles.css'
import _, { result } from 'lodash';
import moment from 'moment';


export default  class ScheduleEntry extends React.Component{
  constructor() {
    //debugger
    super(...arguments);
    this.ScheduleData = [];
    axiosApiInstance.get(`${URL}/courseScheduleByUser`)
    .then(res=>{
        if(res.status === 200)
        {
          //debugger
         // console.log("response",res);
          let schedule = res.data.scheduleData;
            schedule&&schedule.map((item,index)=> {
              //debugger
                if(item.date !== undefined && item.date.length > 0){
                    if(item.endTime.length > 0){
                      //debugger
                      let startDate = moment(item.date[0]).format('YYYY-MM-DD');
                      var startTime = item.endTime[0][0].slice(0, -8);
                      var endTime = item.endTime[0][0].slice(8);
                        this.ScheduleData.push({Id:index+1,Subject:item.course,Course:item.course,Lesson:item.lesson,Description:item.description,
                                    Location:item.location,StartTime: moment(startDate + " " +startTime ).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),EndTime:moment(startDate + " " +endTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),CategoryColor: "#1aaa55"
                        })
                    }
                }
            });
             // console.log("data",this.ScheduleData);
        }
    })
    .catch(err=>{
      console.log(err);
     });
     this.data = this.ScheduleData;
 }

// handleChange(FileList)
//     {
//       //debugger
//         console.log(FileList[0],"FileList");
//     }

// onChange(args){
//   //debugger
//   if(args.itemData !== null){
//   let data = args.itemData;
//   if(data._id === args.value){
//     var chapter = data.chapter;
//   }
//   console.log("chapterList",chapter);

//   this.chapterList = chapter;
//  }
// }

// onChangeChapter(args){
//   let data = args.itemData;
// }



// onMoreEventsClick(args) {
//   debugger
//   args.cancel = true;
// }

onPopupOpen(args) {
  //debugger
  //args.cancel = true; 
  let isCell = args.target.classList.contains('e-work-cells') || args.target.classList.contains('e-header-cells') || args.target.classList.contains('e-all-day-cells');
  if (args.type === "QuickInfo" && isCell) { 
    args.cancel = true; 
  } 
  // if(args.data.Id === undefined){
  //   args.cancel = true;
  // }
    if (args.type === 'Editor') {
        args.cancel = true; 
       //debugger
        // let subjectElement = args.element.querySelector('#Subject');
        // if (subjectElement) {
        //     subjectElement.value = (args.data).Subject || "";
        // }
        // // let statusElement = args.element.querySelector('#EventType');
        // // statusElement.setAttribute('name', 'EventType');

        // let courseElement = args.element.querySelector('#course');
        // courseElement.setAttribute('name', 'course');

        // // let lessonElement = args.element.querySelector('#lesson');
        // // lessonElement.setAttribute('name', 'lesson');

        // // let chapterElement = args.element.querySelector('#chapter');
        // // chapterElement.setAttribute('name', 'chapter');
        // let descriptionElement = args.element.querySelector('#Description');
        // if (descriptionElement) {
        //     descriptionElement.value = (args.data).Description || "";
        // }
        // let locationElement = args.element.querySelector('#Location');
        // if (locationElement) {
        //   locationElement.value = (args.data).Location || "";
        // }


    }
    // else if(args.type === 'QuickInfo' && !isCell) {
    //   debugger
    //   var buttonElement =".e-event-popup .e-edit";
    //   var deleteButtonElement = ".e-event-popup .e-delete";
    //   var editButton = document.querySelector(buttonElement);
    //   var deleteButton = document.querySelector(deleteButtonElement);
    //   if (editButton && deleteButton ) {
    //     // css("visibility", "hidden")
    //     editButton.disabled = true;
    //     deleteButton.disabled =  true;
    //   }
    // }
}

// onActionBegin(args) {
//   debugger
//   if (args.requestType == 'eventChange') {
//     console.log("data",args.data);
//     console.log("changedRecords",args.changedRecords[0]);

//   }
//   else if(args.requestType == 'eventRemove'){
//     console.log("data",args.data);
//     console.log("deletedRecords",args.deletedRecords[0]);
//   }
//   else if(args.requestType === 'eventCreate'){
//     console.log("data",args.data);
//     console.log("addedRecords",args.addedRecords[0]);
//   }
// }
content(props) {
  //debugger  
  return (<div>
  {props.elementType === 'event' && props.elementType !== "cell" ?
      <div className="e-event-content e-template">
      <div className="e-subject-wrap">
        {(props.Subject !== undefined) ?
          <div className="lesson">{props.Lesson}</div> : ""}
        {(props.Location !== undefined) ?
          <div className="location"><a href={props.Location}>{props.Location}</a></div> : ""}
        {(props.StartTime !== undefined) ?
                <div className="start-time">Start: {props.StartTime.toLocaleString()}</div> : ""}
        {(props.EndTime !== undefined) ?
        <div className="end-time">End: {props.EndTime.toLocaleString()}</div> : ""}
      </div>
    </div>:null}
</div>);
}

// onPopupClose(args) {
//   debugger
//   // if (args.type === 'Editor' && !isNullOrUndefined(args.data)) {
//   //     let subjectElement = args.element.querySelector('#Subject');
//   //     if (subjectElement) {
//   //         (args.data).Subject = subjectElement.value;
//   //     }
//   //     // let statusElement = args.element.querySelector('#EventType');
//   //     // if (statusElement) {
//   //     //     (args.data).EventType = statusElement.value;
//   //     // }
//   //     let courseElement = args.element.querySelector('#course');
//   //     if (courseElement) {
//   //         (args.data).course = courseElement.value;
//   //     }

//   //     let chapterElement = args.element.querySelector('#chapter');
//   //     if (chapterElement) {
//   //         (args.data).chapter = chapterElement.value;
//   //     }

//   //     let lessonElement = args.element.querySelector('#lesson');
//   //     if (lessonElement) {
//   //         (args.data).lesson = lessonElement.value;
//   //     }
//   //     (args.data).StartTime = this.startObj.value;
//   //     (args.data).EndTime = this.endObj.value;
//   //     let descriptionElement = args.element.querySelector('#Description');
//   //     if (descriptionElement) {
//   //         (args.data).Description = descriptionElement.value;
//   //     }
//   //     let locationElement = args.element.querySelector('#Location');
//   //     if (locationElement) {
//   //       (args.data).Location = locationElement.value;
//   //     }
//   // }
// }


// editorTemplate(props) {
//   //debugger
//     return (props !== undefined ? <table className="custom-event-editor" style={{ width: '100%', cellpadding: '5' }}><tbody>
//     <tr><td className="e-textlabel">Summary</td><td colSpan={4}>
//       <input id="Subject" className="e-input" type="text" name="Subject" style={{ width: '100%' }}/>
//     </td></tr>
  
//   {/* <tr><td className="e-textlabel">Status</td><td colSpan={4}>

//     <DropDownListComponent id="EventType" placeholder='Choose status' data-name="EventType" style={{ width: '100%' }} dataSource={['New', 'Requested', 'Confirmed']} value={props.EventType || null}></DropDownListComponent>
//   </td></tr> */}
//     <tr><td className="e-textlabel">Course</td><td colSpan={4}>
//     {/* <select className="e-field" name="course" value={this.state.course} id="course" onChange={(e) => this.handleDropdownChange(e)}>
//         <option>All Courses</option>
//         {this.state.Course && this.state.Course.map(course =>
//             <option id="courseName" courseTitle={course.courseName} value={course._id}>{course.courseName}</option>
//         )}
//     </select> */}

  
//     <DropDownListComponent className="e-field" id="course" placeholder='Choose Course' data-name="course" dataSource={this.state.Course} fields={this.courseFields} change={this.onChange} value={props.course || null}/>
//       {/* <DropDownListComponent id="Course" placeholder='Choose Course' data-name="Course" style={{ width: '100%' }} dataSource={['New', 'Requested', 'Confirmed']} value={props.Course || null}></DropDownListComponent> */}
//     </td></tr>
//     <tr><td className="e-textlabel">Chapter</td><td colSpan={4}>

//       <DropDownListComponent className="e-field" id="chapter"  placeholder='Choose chapter' data-name="chapter" style={{ width: '100%' }} dataSource={this.chapterList} fields={this.chapterFields} change={this.onChangeChapter} value={props.Lesson || null}></DropDownListComponent>
//     </td></tr>
//     <tr><td className="e-textlabel">Lesson</td><td colSpan={4}>
//     {/* <select className="e-field" name="lesson" id="lesson" value={this.state.lesson} onChange={(e) => this.handleDropdownChange(e)}>
//       <option>All Lessons</option>
//       {this.state.lessonList && this.state.lessonList.map(lesson =>
//           <option facultyUserId={lesson.facultyUserId}  facultyName={lesson.facultyFullName} lessonName={lesson.lessonName} value={lesson._id}>{lesson.lessonName}</option>
//       )}
//     </select> */}
//       <DropDownListComponent id="lesson" placeholder='Choose lesson' data-name="lesson" style={{ width: '100%' }} dataSource={this.lessons} value={props.lesson || null}></DropDownListComponent>
//     </td></tr>
//     <tr><td className="e-textlabel">From</td><td colSpan={4}>
//       <DateTimePickerComponent format='dd/MM/yy hh:mm a' id="StartTime" data-name="StartTime" ref={(date) => { this.startObj = date; }} value={new Date(props.startTime || props.StartTime)}></DateTimePickerComponent>
//     </td></tr>
//     <tr><td className="e-textlabel">To</td><td colSpan={4}>
//       <DateTimePickerComponent format='dd/MM/yy hh:mm a' id="EndTime" data-name="EndTime" ref={(date) => { this.endObj = date; }} value={new Date(props.endTime || props.EndTime)}></DateTimePickerComponent>
//     </td></tr>
//     <tr><td className="e-textlabel">Metting Url</td><td colSpan={4}>
//     <input type="text" id="Location" className="e-input" name="Location" style={{ width: '100%', height: '60px !important', resize: 'vertical' }} />  </td></tr>
//     <tr><td className="e-textlabel">Meeting Content</td><td colSpan={4}>
//       <input type="text"  id ="Description" className="e-input" data-name="Description" style={{ width: '100%', height: '60px !important', resize: 'vertical' }}/>
//       <input type="file" id="avatar" name="avatar" onChange={ (e) => this.handleChange(e.target.files) } />
//     {/* <Dropzone
//       getUploadParams={this.getUploadParams}
//       LayoutComponent={this.NoDropzoneLayout}
//       inputContent="Only Choose Files (No Dropzone)"
//       onChangeStatus={this.handleChangeStatus}
//       //onSubmit={handleFileSubmit}
//       //submitButtonContent= 'Uplaod File'
//       maxFiles={1}
//       multiple={false}
//     /> */}
//       </td></tr></tbody></table> : <div></div>);
// }


render() {
    return <ScheduleComponent width='100%' height='550px' selectedDate={new Date()} ref={schedule => this.scheduleObj = schedule} eventSettings={{ dataSource: this.data }} 
    // editorTemplate={this.editorTemplate.bind(this)} createEventOnDblClick={true} showQuickInfo={true}
   //  popupClose={this.onPopupClose.bind(this)}
     popupOpen={this.onPopupOpen.bind(this)}
    // moreEventsClick={this.onMoreEventsClick.bind(this)}
     //actionBegin={this.onActionBegin.bind(this)}
    quickInfoTemplates={{ content: this.content.bind(this),
        //header: this.header.bind(this)
       }}
    >
      
      <ViewsDirective>
        <ViewDirective option='Day'/>
        <ViewDirective option='Week'/>
        <ViewDirective option='WorkWeek'/>
        <ViewDirective option='Month'/>
        <ViewDirective option='Agenda'/>
      </ViewsDirective>
      <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
    </ScheduleComponent>;
}
}