import react, { Component } from 'react'
import React from 'react';
import {  Button,Form, FormGroup,Col,Row,Container} from 'react-bootstrap';
import { Input,Label} from 'reactstrap';
import ToggleSwitch from '../../Common/ToggleSwitch'


export default class ProfileNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) { this.setState({value: event.target.value});  }
  handleSubmit(event) { event.preventDefault();}
  render(){
  return (
    <div className="wrp whitebg shadow-sm p-4">
      <Row>
        <Col>
        <h5 className="hdtxt">Notification</h5>
        </Col>
      </Row>
      <br/>
    <Row>
      <Col>
    <Form onSubmit={this.handleSubmit}>
     <FormGroup>
       <Row>
         <Col md={10}>
          <Label for="CurrPwd" className="profnotflbl">Profile Notification</Label>
        </Col>
        <Col md={ 2} className="float-right"><ToggleSwitch isChecked/></Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={10} sm={12} xs={12}>
            <Label for="CurrPwd" className="profnotflbl">TwoFactor Authentication</Label>
          </Col>
          <Col md={2} sm={12} xs={12} className="float-right">
            <ToggleSwitch /></Col>            
        </Row>
                {/* <AddModal show={this.state.addModalShow} onHide={ this.addModalClose} /> */}
      </FormGroup>
    </Form>
    </Col>
    </Row>
    </div>
  );
}
}


