import React from "react";
import './ToggleSwitch.css';

export default class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props)
  this.state = {
    toggle: this.props.status
  }
  }
  
  async componentWillReceiveProps(nextProps){
    if(nextProps.status !== this.props.status){
      //debugger
      await this.setState({ toggle: nextProps.status });
    }
  }
  async toggle () {
    //debugger
    await this.setState({ toggle: !this.state.toggle })
    //console.log(this.state.toggle)
    this.props.toggleHandler(this.state.toggle,this.props.id,this.props.chapterId);
  }

  render () {
    const className = `toggle-component ${ this.state.toggle ? 'inactive' : ''}`
    return (
      <div
        className={className}
        onClick={() => this.toggle()}>
        <div className='toggle-button' />
      </div>
    )
  }
}
  
