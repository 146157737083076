import React from "react";
import './CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
// import DropDown from '../../../Components/Admin/Course/DropDown'
import Table from  '../../../Components/Admin/Course/Table'
import   '../../../Components/Admin/Course/Table.css'
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';

const headerStyle = {
  backgroundColor: '#4c8db5',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0 ,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}

export default  class SubjectMaster extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      SelectParentCategory:[],
      Subjectdata:{
        subjectName:'',
        subjectDescription:'',
        istrue:false,
      },
      LabelText:{
        subjectName:'',
        subjectDescription:'',
      },
      subjectId: 0,
      istrue:false,
      columns: [  
        // { title: "Id", field: "id" , hidden: true },
        { title: "Core skills", field: "subjectName"},
        { title: "Description", field: "subjectDescription"}
        
      ],
      Data : [],
    }
    this.handleChange = this.handleChange.bind(this);
  //   this.onClickCancel = this.onClickCancel.bind(this);
   }
  selectedValue = (value) =>{
    this.setState({selectedValue:value});
  }

  handleChange(event){
    //alert(event.target.value)
    let {Subjectdata}= this.state
    Subjectdata[event.target.name] = event.target.value;
    this.setState({Subjectdata: Subjectdata});   
    //alert(this.state.newsdata.title) 
 }

  Validation = () => {
   // debugger
      let {Subjectdata,LabelText} = this.state;
      if (Subjectdata.subjectName !=="" &&  Subjectdata.subjectName !== null) {	
          LabelText.subjectName = "";
          }
      else{
          LabelText.subjectName  = "Please Enter Subject Name";
      }
    this.setState({LabelText: LabelText});
        if(LabelText.subjectName==="")
        {
            return true;
        }
        else
        {
            return false;
        }
  }

  onClickSubject =()=>{
    //debugger
    //let subjectDescription = this.state.selectedValue;
    const isValid = this.Validation();
    if(isValid){
    const {subjectName,subjectDescription} = this.state.Subjectdata
    axiosApiInstance.post(`${URL}/course/subject`,{subjectName,subjectDescription})
        .then(res =>{
       // debugger
        const resdata = res.data;
        if(res.status === 201)
        {
         // debugger
          swal("Skill Added", "", "success")
          this.getTableData()
          this.onClickCancel()
        }
        else if(res.status == 500){
          swal("Please enter unique skill name", "","warning"); 
        }
        else if(res.data.status === 400){
          swal(res.data.message, "Please enter unique skill name","warning"); 
        }
        }).catch(err=>{
          console.log(err);
        });  
      } 
    }

    onUpdateCategory =()=>
    {
      const isValid = this.Validation();
      if(isValid){
      let subjectId = this.state.subjectId;
      //let subjectDescriptionu = this.state.selectedValue;
      const {subjectName,subjectDescription} = this.state.Subjectdata
      axiosApiInstance.put(`${URL}/course/subject/${subjectId}`,{subjectName,subjectDescription})
          .then(response =>{
           // debugger
          const res = response.data.category;
          if(response.status == 200)
          {
            //debugger
            swal("Skill update sucessfully", "", "success")
              this.getTableData();
              this.onClickCancel();
              this.setState({istrue:false})
          }
          else
          {   
            //swal("Something went wrong","", "error"); 
          }
          }).catch(err=>{
            console.log(err);
          }); 
        }    
    }

    onEdit =(editSubjectName,editSubjectDescription,editSubjectId)=>
    {
      //debugger
      //alert("On Edit Called " + editSubjectName + editSubjectId);
      const {Subjectdata}= this.state
      Subjectdata.subjectName=editSubjectName;
      Subjectdata.subjectDescription=editSubjectDescription;
      this.setState({subjectId:editSubjectId})
      this.setState({Subjectdata:Subjectdata})
      this.setState({istrue:true})
    }

    getTableData () {
      axiosApiInstance.get(`${URL}/course/subject`)
      .then(res =>{
          let Subject = res.data.subject;
          let  Subjectdata = [];
          if(res.status == 201)
          {
            this.setState({Data:Subject})
          }   
          else{
           // swal("Something went wrong","", "error");
          }
        }).catch(err=>{
          console.log(err);
        }); 
    }

    componentDidMount (){
      this.getTableData();
    }

 onClickCancel = () => {
  let {Subjectdata,LabelText} = this.state
  Subjectdata.subjectName = "";
  Subjectdata.subjectDescription = "";
  LabelText.subjectName="";
  this.setState({Subjectdata: Subjectdata, istrue:false, subjectId:0});
}

render(){
  const{ Data,columns,Subjectdata,LabelText} =this.state;
  //alert(Data)
    return( 
    <Container fluid className="CouseMasterWp FormWp">
       <Card className="shadow p-4 col-md-12">
       <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
       <Row>
       <Col className="mb-4">
          <h3 className="text-center blueText">Core Skills Master</h3>
         </Col>
       </Row>
       <Form>
        <Row>
           <Col md={6}>
            <FormGroup>
              <Label>Core Skill Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="subjectName"  placeholder="" onChange={this.handleChange} value={Subjectdata.subjectName}/>
                {LabelText.subjectName !== "" ?
                  <Label style={{ fontSize: 12, color: "red" }}>
                  {LabelText.subjectName }</Label >: null
			          }
            </FormGroup>
           </Col>
           <Col md={6}>
           <FormGroup>
              <Label>Description</Label>
                <Input type="text" name="subjectDescription"  placeholder="" onChange={this.handleChange} value={Subjectdata.subjectDescription}/>
            </FormGroup>
           {/* <FormGroup>
           <Label>Description</Label>
            <DropDown title="Select Category" options={SelectParentCategory} selectedCategory={this.selectedValue}/>
          </FormGroup> */}
           </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
          <div  className="center-block text-center">
          {this.state.istrue ?   
            <Button className="text-center mr-3 btnblue"  onClick={this.onUpdateCategory}>update</Button>          
            : 
            <Button className="text-center mr-3 btnblue"  onClick={this.onClickSubject}>Submit</Button>         
          }
          <Button className="text-center clearBtn" color="danger"  onClick={()=>{this.onClickCancel()}}>Clear</Button>
          </div>
          </Col>
        </Row>
        </Form>
        </Card>
        <Row className="mb-2 mt-5 TableRowWp">
          <Col className="tableWp mt-2">
                <Row>
                  <Col className="DashboardTbl">
                    <Table data={Data} pageSize={10} paging={true} columns={columns}search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='200px' 
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            //alert("Edit Action Clicked " + rowData.subjectDescription + rowData.subjectName)
                            this.onEdit(rowData.subjectName ,rowData.subjectDescription,rowData._id)
                          }
                        }
                      ]
                    }
                    />
                  </Col>
              </Row>            
          </Col>
        </Row>
    
       </Card>
    </Container>
    );
}
} 