import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import moment from 'moment';
import { PDFViewer } from "@react-pdf/renderer";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { URL } from "../../ServerCall/Config";
import axiosApiInstance from "../../ServerCall/Axios.Instance";
import Auth from "../../ServerCall/Auth";
import { setProfile } from "../../redux/action/profileAction";
import { connect } from "react-redux";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import Label from "reactstrap/lib/Label";
import Table from "../../Components/Admin/Course/Table";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";

const headerStyle = {
  backgroundColor: "#4c8db5",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

const userName = Auth.getUserName();
const role = Auth.getUserRole();

export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      selectRefCourse: null,
      studentArray: [],
      courseId: "",
      selectedCourse: "",
      courseType: "",
      categoryId: "",
      columns: [
        // { title: "Course Id", field: "_id", hidden: true, export: false },
        // {
        //   title: "Id",
        //   field: "",
        //   render: (rowData) => (
        //     <span>{JSON.stringify(rowData.tableData.id + 1)}</span>
        //   ),
        // },
      //   { title: "Course Name", field: "courseName" },
      //   { title: "Batch", field: "batchName" },
      //   { title: "Batch Size", field: "batchSize" },
      //   // { title: "Start Date", field: "startDate" },
      //   // { title: "End Date", field: "endDate" },
      //   {title:'Duration', field:'duration'},
      //   {title:'Fees (₹)', field:'fees',
      //   render: rowData => <span>{rowData.fees !== null?"₹ "+rowData.fees:"₹ "+0}</span>
      // },

      
        // { title: "Id", field: "_id", hidden: true},
        { title: "Course Name", field: "courseName"},
        {title: "Course Type", field: "courseType"},
        { title: "Fees",field:"fees", render: rowData => <span>{rowData.fees !== null?"₹ "+rowData.fees:"₹ "+0}</span>},
        { title: "Duration", field: "duration"},
        { title: "Created Date", field: "createdAt"}
        // { title: "Progress", field: ""},
      ],
      Data: [],
    };
  }
  handleChange = async (e) => {
    this.setState({ selectedCourse: e });
    await this.setState({ courseId: e._id });
    let id = this.state.courseId;
    axiosApiInstance
      .get(`${URL}/course/${id}`)
      .then(async (res) => {
        // //debugger
        //console.log(res.data.Course);
        let course = res.data.Course;
        await this.setState({
          courseType: course.courseType,
          categoryId: course.categoryId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await this.fetchStudents(id);
  };

  downloadPdf = (e) => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("Course Report", 135, 10);
    // console.log(doc.getFontList());
    doc.setFont("Helvetica")
    doc.autoTable({
      // columnStyles: { Id: { halign: "center" } },
      theme: "striped",
      // tableWidth: "auto",
      body: this.state.Data,
      columns: this.state.columns.map((col) => ({
        ...col,
        dataKey: col.field,
      })),
    });
    doc.save("table.pdf");
  };

  getScheduleCourses() {
    // axiosApiInstance
    //   .get(`${URL}/allCourseSchedule`)
    //   .then((res) => {
    //     //debugger;
    //     let scheduleData = res.data.CourseSchedule;
    //     // console.log(scheduleData);
    //     this.setState({ Data: scheduleData });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axiosApiInstance.get(`${URL}/allCourse`)
      .then(res =>{
          //debugger
          let Courses = res.data.Course;
          // console.log(Courses)
          let  Corsedata = [];
          if(res.status == 200)
          {
            Courses&&Courses.map(data =>{
                Corsedata.push({_id:data._id,courseName:data.courseName,courseType:data.courseType,fees:data.fees,duration:data.duration,createdAt:moment(data.createdAt).format('YYYY-MM-DD')})
            })
            // this.setState({SelectParentCategory:Categorydata})
            this.setState({Data:Corsedata})
          console.log(Corsedata)
          }
    
        }).catch(err => {
          console.log(err)
        })
  }

  fetchStudents = (courseId) => {
    //debugger;
    axiosApiInstance
      .get(`${URL}/getEnrollStudentsByCourseId/${courseId}`)
      .then((res) => {
        //debugger;
        let allStudentList = res.data.studentList;
        console.log("allStudentList", allStudentList);
        var dataarray = [];
        allStudentList &&
          allStudentList.map((data) => {
            dataarray.push({
              value: data.userId,
              label:
                data.firstName + " " + data.middleName + " " + data.lastName,
              isdisabled: true,
            });
          });
        this.setState({ studentArray: dataarray });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // getCourse = () => {
  //   axiosApiInstance
  //     .get(`${URL}/allCourse`)
  //     .then((res) => {
  //       //debugger;
  //       //console.log("res",res);
  //       let courseData = [];
  //       if (res.status == 200) {
  //         ////debugger
  //         res.data.Course &&
  //           res.data.Course.map((data) => {
  //             courseData.push(data);
  //           });
  //         this.setState({ course: courseData });
  //       } else {
  //         console.log("err");
  //         //swal(res.data.message, "", 'error')
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       //swal("Something went wrong", "", 'error')
  //     });
  // };

  componentDidMount = () => {
    // this.getCourse();
    this.getScheduleCourses();
  };
  render() {
    const { Data, columns } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
        <Card className="shadow p-4 col-md-12 marginauto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Course Reports</h3>
            </Col>
          </Row>
          <Row>
            {/* <Card className="col-md-8 p-4" style={{ margin: "0 auto" }}>
              <Col md={6} className="SelectCourse">
                <Label>Select Course</Label>
                <span className="requiredFieldSpan">*</span>
                <Select
                  options={this.state.course}
                  name="course"
                  onChange={this.handleChange}
                  value={this.state.selectedCourse}
                  getOptionLabel={(x) => x.courseName}
                  getOptionValue={(x) => x._id}
                  ref={(ref) => {
                    this.state.selectRefCourse = ref;
                  }}
                />
              </Col>
              <Col md={6} className="SelectCourse">
                {/* <Button className="text-center mr-3 btnblue" id='createScheduleButton' onClick={this.handleSubmit}>Print Report</Button> */}
              {/* </Col>
            </Card> */}
          </Row>

          <Row id="divcontents" className="mb-4 mt-4">
            <Col className="tableWp" id="ifmcontentstoprint">
              <Col className="DashboardTbl scheduleTbl">
                <Table
                  data={Data}
                  pageSize={5}
                  paging={true}
                  columns={columns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  // exportPdf={(Data, columns) => {
                  //   const doc = new jsPDF();

                  //   const columnTitles = this.state.columns.map(
                  //     (columnDef) => columnDef.title
                  //   );

                  //   const pdfData = this.state.Data.map((rowData) =>
                  //     columns.map((columnDef) => rowData[columnDef.field])
                  //   );

                  //   doc.autoTable({
                  //     head: [columnTitles],
                  //     body: pdfData,
                  //   });

                  //   doc.save(`data.pdf`);
                  // }}
                  actions={[
                    {
                      icon: () => <PrintIcon />, // you can pass icon too
                      tooltip: "Download in Pdf Format",
                      onClick: () => this.downloadPdf(),
                      isFreeAction: true,
                    },
                  ]}
                />
              </Col>
            </Col>
          </Row>
        </Card>
        {/* <Link onClick={()=>window.print()} className="btn btn-primary mr-2">Print</Link> */}
      </Container>
    );
  }
}
