import {
  SET_PROFILE
} from '../action/profileAction'

const initialState = {
  profile:{}
}

export default (state = initialState, action) => {
 // debugger
  switch (action.type) {
    case SET_PROFILE:
        return{
          ...state,
          profile: action.payload
        }
   
    default:
      return state
  }
}
