import React from "react";
import {Modal,Form, Button,Container,Row,Col,FormGroup} from 'react-bootstrap'
import 'react-dropzone-uploader/dist/styles.css'
import CardText from "reactstrap/lib/CardText";
import {Card} from 'react-bootstrap'
import {TextField} from '@material-ui/core';
//import DropDown from '../../../Components/Faculty/Course/DropDown'
import DropDown from '../../../Components/Admin/Course/DropDown';

const TestModel=({
    show,
    onHide,
    Test,
    selectedTest,
    onUpdateTestData,
    onClickCancelTestModel,
    LabelText
    }) =>  {
    return(
      <Modal 
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          className="modalWp"show={show}
          onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Assign Test
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container className="FormWp">
          {/* <Row className="mb-3 DateTimePikerWp">
            <Col md={6}>
            <label className="lbl pr-5" style={{display:'flex'}}>Date</label>
                <TextField className="form-control" type="date" name="scheduleDate" variant="outlined"/>
            </Col>
            <Col md={3}>
            <label className="lbl pr-5" style={{display:'flex'}}>Start Time</label>
                <TextField className="form-control" type="time" name="startTime" variant="outlined"/>
            </Col>
            <Col md={3}>
            <label className="lbl pr-5" style={{display:'flex'}}>End Time</label>
                <TextField className="form-control" type="time" name="endTime" variant="outlined"/>
            </Col>
        </Row> */}
        <Row>
            <Col md={12}>
                <FormGroup>
                    <Form.Label>Test List</Form.Label>
                    <DropDown title="Select Test" options={Test} selectedCategory={selectedTest}/>
                    {LabelText !== "" ?
                      <label style={{ fontSize: 12, color: "red" }}>
                      {LabelText }</label >: null
			              }
                </FormGroup>
            </Col>
            </Row>
            <Row>
                  <Col className="text-center mt-3">
                  <div  className="center-block text-center">
                    <Button className="text-center mr-3" onClick={onUpdateTestData}>Submit</Button>
                    <Button className="text-center " variant="danger" onClick={onClickCancelTestModel}>Cancel</Button>
                  </div>
                  </Col>
              </Row>
            {/* <Row>
            <Col md={12}>
                <Button onClick={onUpdateTestData}>Submit</Button> 
            </Col>
            </Row> */}
          </Container>
          </Modal.Body>
        </Modal>  
    )
}

export default TestModel




