
import React from "react";
import '../../../Containers/Admin/Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button } from 'reactstrap';
import DropDown from '../../../Components/Admin/Course/DropDown'
import userImg from '../../../Images/man.jpg'
import  logo from '../../../Images/con_logo.png'
import '../student.css'
import ImageUploader from '../../../Components/Admin/Course/ImageUploader'
export default  class AccountInformation extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      SelectCountry:[
        { value: 'India' },
        { value: 'Malesia' }
      ] ,
     
    }

  }
  
  selectedValue = (value) =>{
    this.setState({selectedValue:value})
  }

render(){
  const{SelectCountry,} =this.state;
    return(

      <Container fluid className="CouseMasterWp FormWp">
      <Card className="shadow p-4 col-md-12">
          <Row>
          <Col className="mb-4">
                    <div className="logoWp">
                        <img src={logo} alt="logo" />
                    </div>
                </Col>
          </Row>
          <Row>
            <Col className="mb-5">
                <h3 className="text-center blueText">Account Information</h3>
            </Col>
          </Row>
        <Row>
            <Col md={8}>
                <Row>
                    <Col md={12}>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                      <Label>Name</Label>
                                        <Input type="text" name="Name"  placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                <FormGroup>
                                    <Label>Mobile</Label>
                                    <Input type="text" name="Mobile"  placeholder="" />
                                </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input type="Email" name="Email"  placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Age</Label>
                                        <Input type="text" name="Age"  placeholder="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                     <Label>Country</Label>
                                        <DropDown title="Select Country" options={SelectCountry} selectedYear={this.selectedValue}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                <FormGroup>
                                    <Label>Profession</Label>
                                    <Input type="text" name="student"  placeholder="" />
                                </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Label>Highest Education</Label>
                                    <FormGroup>
                                        <Input type="text" name="Degree"  placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="pl-4">
                                     <Label check>
                                        <Input type="checkbox" />{' '}
                                            I agree Terms &amp; Conditions
                                        </Label>                
                                    </FormGroup>
                                </Col>
                            </Row>
                        <Row>
                            <Col className="text-center mt-3">
                            <div  className="center-block text-center">
                            <Button className="text-center mr-3 btnblue">Renew and join</Button>
                            <Button className="text-center " color="danger">Cancel</Button>
                        </div>
                        </Col>
                        
                        </Row>
                        
                    </Form>
                    </Col>
                </Row>
               </Col>
               <Col md={4} className="editWpcol">
                    <Row>
                        <Col md={12} className="text-center">
                            <Button className="text-center mr-3 btnblue">Edit and join</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-center">
                            <div className="ImageWp text-center">
                                <img src={userImg} alt="logo" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-center proftxt">
                            <h5>Add Profile Picture</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="text-center proftxt uploadWp" style={{margin:'0 auto'}}>
                        {/* <Button className="text-center mr-3 btnblue">Browse</Button> */}
                           <ImageUploader />
                        </Col> 
                    </Row>
                </Col>
            </Row>
      </Card>
   </Container>
    );
}
} 
