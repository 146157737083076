import React from "react";
import './Feedback.css'
import {Row,Col,Container,Card,Table,FormGroup,FormLabel,FormControl,Form} from  'react-bootstrap'
import { Input, Button } from 'reactstrap'
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import swal from "sweetalert";
import { URL } from "../../../ServerCall/Config";
// import Auth from "../../../ServerCall/Auth"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import validator from "validator";
// import { Dropdown } from "bootstrap";
import DropDown from "../../../Components/Student/DropDown";
import { duration } from "moment";
import { text } from "@fortawesome/fontawesome-svg-core";
import { stubTrue } from "lodash";
import Auth from "../../../ServerCall/Auth";
export default class StudentFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrolledCourse: [],
      typeOfCourse: "",
      courseName: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      courseId: "",
      locationOfCourse: "",
      duration: "",
      facultyName: "",
      agree: "",
      facultyId: "",
      faculties: [],
      generalEvaluations: [],
      finalThoughts: [],
      stundentInfo: {
        studentName: Auth.getUserName(),
        userId: "",
        position: "",
        contacted: false,
        phoneOrEmail: "",
      },
      formErrors: {
        locationOfCourse: "",
        courseName: "",
        phoneOrEmail: "",
        position: "",
        locationOfCourse: "",
        generalEvaluations: "",
      //   question1: "",
      //   question2: "",
      //   question3: "",
      //   question4: "",
      //   question5: "",
      //   question6: "",
      //   question7: "",
      //   question8: "",
      },
    };
  }




  clearForm = () => {
    this.setState({
      enrolledCourse: [],
      faculties: [],
      courseName: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      locationOfCourse: "",
      duration: "",
      // facultyName: "",
      agree: "",
      // facultyId: "",
      studentName: Auth.getUserName(),
      position: "",
      contacted: false,
      phoneOrEmail: "",
      formErrors: {
        locationOfCourse: "",
        courseName: "",
        generalEvaluations: "",
        finalThoughts: ""
      },
    });
    const radioBtns = document.querySelectorAll("input[type='radio']"
    );
    radioBtns.forEach((radioBtn) => {
      if (radioBtn.checked === true) radioBtn.checked = false;
    });
    this.SelectedWorkshop("Select Course")
    this.getCourses();
  }

  formValid = (formErrors) => {
   // debugger
    let valid = true;
    const {
      courseName,
      locationOfCourse,
      answer1,
      answer2,
      answer3,
      answer4,
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
    } = this.state;
    courseName == ""
      ? (formErrors.courseName = "Select Workshop from here")
      : (formErrors.courseName = "");
    
    locationOfCourse == ""
      ? (formErrors.locationOfCourse = "Enter location here")
      : (formErrors.locationOfCourse = "");
    
    !answer1
      ? (formErrors.finalThoughts = "Please fill these details")
      : (formErrors.finalThoughts = "");
    !answer2
      ? (formErrors.finalThoughts = "Please fill these details")
      : (formErrors.finalThoughts = "");
    !answer3
      ? (formErrors.finalThoughts = "Please fill these details")
      : (formErrors.finalThoughts = "");
    !answer4
      ? (formErrors.finalThoughts = "Please fill these details")
      : (formErrors.finalThoughts = "");

    !question1
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = "");
    !question2
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
    !question3
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
    !question4
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
    !question5
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
    !question6
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
    !question7
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
    !question8
      ? (formErrors.generalEvaluations = "Please fill these details")
      : (formErrors.generalEvaluations = ""); 
      
    this.setState({ formErrors: formErrors });
    Object.values(formErrors).forEach((val) => {
      //debugger
      val !== "" && (valid = false);
    });
    return valid;
  };

  getCourses() {
    axiosApiInstance
      .get(`${URL}/getEnrollment`)
      .then((res) => {
        if (res.status === 200) {
          let course = res.data.course;
          // console.log("getEnrollment", res.data.course);
          let courseData = [];
          // let faculties = [];
          // debugger;
          course &&
            course.map((data) => {
              // data.mainFaculty && data.mainFaculty.map(faculty=>{
              //   faculties.push({facultyName: faculty.facultyFullName, facultyId: faculty.facultyUserId});
              // });
              courseData.push({
                courseId: data.c_id,
                value: data.courseName,
                duration: data.duration,
                // facultyName: data.mainFaculty.facultyFullName,
                // facultyId: data.mainFaculty.facultyUserId,
              });
            });
          this.setState({ enrolledCourse: courseData });
        } else {
          swal(res.data.message, "", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentDidMount() {
    this.getCourses();
  }
  handleGEvaluation = (e) => {
    // debugger;
    const { name, value } = e.target;
    
    switch (name) {
      case "question1":
        var question1 = {};
        if (value === "sdisagree") {
          question1 = {
            question: name,
            stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false,
          };
          this.setState({ question1: question1 });
        } else if (value === "disagree") {
          question1 = {
            question: name,
            stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false,
          };
          this.setState({ question1: question1 });
        } else if (value === "neutral") {
          question1 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question1: question1 });
        } else if (value === "agree") {
          question1 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false,
          };
          this.setState({ question1: question1 });
        } else if (value === "sagree") {
          question1 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question1: question1 });
        }
      case "question2":
        var question2 = {};
        if (value === "sdisagree") {
          question2 = {
            question: name,
            stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question2: question2 });
        } else if (value === "disagree") {
          question2 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question2: question2 });
        } else if (value === "neutral") {
          question2 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false,
          };
          this.setState({ question2: question2 });
        } else if (value === "agree") {
          question2 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false,
          };
          this.setState({ question2: question2 });
        } else if (value === "sagree") {
          question2 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question2: question2 });
        }
      case "question3":
        var question3 = {};
        if (value === "sdisagree") {
          question3 = {
            question: name,
            stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false,
          };
          this.setState({ question3: question3 });
        } else if (value === "disagree") {
          question3 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question3: question3 });
        } else if (value === "neutral") {
          question3 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question3: question3 });
        } else if (value === "agree") {
          question3 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false, };
          this.setState({ question3: question3 });
        } else if (value === "sagree") {
          question3 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question3: question3 });
        }
      case "question4":
        var question4 = {};
        if (value === "sdisagree") {
          question4 = { question: name, stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question4: question4 });
        } else if (value === "disagree") {
          question4 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question4: question4 });
        } else if (value === "neutral") {
          question4 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question4: question4 });
        } else if (value === "agree") {
          question4 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false, };
          this.setState({ question4: question4 });
        } else if (value === "sagree") {
          question4 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question4: question4 });
        }
      case "question5":
        var question5 = {};
        if (value === "sdisagree") {
          question5 = { question: name, stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question5: question5 });
        } else if (value === "disagree") {
          question5 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question5: question5 });
        } else if (value === "neutral") {
          question5 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question5: question5 });
        } else if (value === "agree") {
          question5 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false, };
          this.setState({ question5: question5 });
        } else if (value === "sagree") {
          question5 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question5: question5 });
        }
      case "question6":
        var question6 = {};
        if (value === "sdisagree") {
          question6 = { question: name, stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question6: question6 });
        } else if (value === "disagree") {
          question6 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question6: question6 });
        } else if (value === "neutral") {
          question6 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question6: question6 });
        } else if (value === "agree") {
          question6 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false, };
          this.setState({ question6: question6 });
        } else if (value === "sagree") {
          question6 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question6: question6 });
        }
      case "question7":
        var question7 = {};
        if (value === "sdisagree") {
          question7 = {
            question: name,
            stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question7: question7 });
        } else if (value === "disagree") {
          question7 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question7: question7 });
        } else if (value === "neutral") {
          question7 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question7: question7 });
        } else if (value === "agree") {
          question7 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false, };
          this.setState({ question7: question7 });
        } else if (value === "sagree") {
          question7 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question7: question7 });
        }
      case "question8":
        var question8 = {};
        if (value === "sdisagree") {
          question8 = { question: name, stronglyDisagree: true,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question8: question8 });
        } else if (value === "disagree") {
          question8 = { question: name, stronglyDisagree: false,
            disagree: true,
            neutral: false,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question8: question8 });
        } else if (value === "neutral") {
          question8 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: true,
            agree: false,
            stronglyAgree: false, };
          this.setState({ question8: question8 });
        } else if (value === "agree") {
          question8 = { question: name, stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: true,
            stronglyAgree: false, };
          this.setState({ question8: question8 });
        } else if (value === "sagree") {
          question8 = {
            question: name,
            stronglyDisagree: false,
            disagree: false,
            neutral: false,
            agree: false,
            stronglyAgree: true,
          };
          this.setState({ question8: question8 });
        }
    }
  };

  handleConButton = (e) => {
    // debugger;
    const { name, value } = e.target;
    if (value === "No") this.setState({ [name]: false });
    else this.setState({ [name]: true });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let  formErrors  = this.state.formErrors;
    const validAlpha = RegExp(/^[a-zA-Z]+$/);
    const validPhone = RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);
    const validEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    switch (name) {
      case "phoneOrEmail":
        formErrors.phoneOrEmail = validEmail.test(value)
          ? ""
          : validPhone.test(value)
          ? ""
          : "Invalid Email or Phone";
        break;
      case "locationOfCourse" :
        formErrors.locationOfCourse = validAlpha.test(value)
          ? ""
          : "Alphabets only";
        break;
      case "position":
        formErrors.position = validAlpha.test(value)
          ? ""
          : "Alphabets only";
        break;
    }
  };


  SelectedWorkshop = (
    value,
    title,
    // facultyName,
    // facultyId,
    duration,
    courseId
  ) => {
  //  debugger;
    if(value != "Select Course"){
      this.setState({
        courseName: value,
        duration: duration,
        // facultyName: facultyName,
        // facultyId: facultyId,
        courseId: courseId,
        formErrors: {courseName:""}
      });
      this.checkCourse(courseId);
    }else{
      this.setState({
        courseName: value,
        duration: '',
        // facultyName: facultyName,
        // facultyId: facultyId,
        courseId: '',
        faculties: [],
        formErrors: {courseName:""}
      });
    }
  };

  checkCourse = (courseId) => {
    axiosApiInstance.get(`${URL}/courseDetails/${courseId}`)
        .then(async(res)=>{
            if(res.status === 200){
                // debugger
               // console.log(res,"courseDetails");

                // if course is pre-recorded based
                if(res.data.Course1 != undefined){
                    let mainFaculty = res.data.Course1.mainFaculty;

                    let faculties = [];

                    mainFaculty && mainFaculty.map(faculty => {
                      faculties.push({facultyName: faculty.facultyFullName, facultyId: faculty.facultyUserId});
                    });

                    this.setState({faculties: faculties});
                }

                // if course is instruction based
                else if(res.data.Course2 != undefined){
                  let isSchedule = res.data.checkCourseSchedule;
                  if(isSchedule){
                    let mainFaculty = res.data.Course2.mainFaculty;
                    
                    let faculties = [];

                    mainFaculty && mainFaculty.map(faculty => {
                      faculties.push({facultyName: faculty.facultyFullName, facultyId: faculty.facultyUserId});
                    });

                    this.setState({faculties: faculties});
                   // console.log(this.state.faculties);
                  }else{
                    swal("Batch Not Schedule !", "There is no batch sechedule for respective course", "");
                  }
                }

                // else{
                //     let course = res.data.Course;
                //     let isSchedule = res.data.checkCourseSchedule;
                //     // console.log("course3",res.data);
                //     await this.setState({courseData: course, isCourseScheduleForStudent: isSchedule});
                // }
              }
        }).catch(err => {
            console.log(err)
        })
  }

  onSubmitClick = () => {
    //debugger;
    let {
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      contacted,
      question8,
      locationOfCourse,
      courseName,
      courseId,
      // facultyName,
      // facultyId,
      faculties,
      duration,
      position,
      phoneOrEmail,
      answer1,
      answer2,
      answer3,
      answer4,
    } = this.state;
    let finalThoughts = [
      {
        question:
          "If a colleague was going to take this course, would you recommend them?",
        answer: answer1,
      },
      {
        question:
          "If you could change one thing about this course, what would it be?",
        answer: answer2,
      },
      {
        question: "What was the most important thing that you learned today?",
        answer: answer3,
      },
      {
        question: "Do you have anything else to share?",
        answer: answer4,
      },
    ];
    const isValid = this.formValid(this.state.formErrors);
    if (isValid) {
      // console.log("valid form");
      axiosApiInstance
        .post(`${URL}/createFeedback`, {
          courseName: courseName,
          courseId: courseId,
          locationOfCourse: locationOfCourse,
          duration: duration,
          // faculties: {
          //   facultyId: facultyId,
          //   facultyName: facultyName,
          // },
          faculties: faculties,
          stundentInfo: {
            studentName: Auth.getUserName(),
            userId: Auth.getUserId(),
            position: position,
            phoneOrEmail: phoneOrEmail,
            contacted: contacted,
          },
          
          finalThoughts: finalThoughts,
          generalEvaluations: [
            question1,
            question2,
            question3,
            question4,
            question5,
            question6,
            question7,
            question8,
          ],
        })
        .then((res) => {
          if (res.data.status == 500 && res.data == "") {
            // debugger
            //alert(500);
            this.setState({ loading: false });
            swal("Something went wrong", "Please try again!", "error");
          } else {
            // console.log(res.data);
            this.setState({ loading: false });

            swal("Thank You for your feedback!", "", "info");
            this.clearForm();
            // window.location.href = "/StudentDashboard";
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      this.setState({ loading: false });
      swal("", "Please fill the mandatory fields!", "error");
    }
  };

  render() {
    const {
      locationOfCourse,
      enrolledCourse,
      courseName,
      duration,
      answer1,studentName,
      answer2,
      answer3,
      answer4,
      facultyName,
      formErrors,
      position,
      contacted,
      faculties,
      phoneOrEmail,
      stundentInfo
    } = this.state;
    const getFacultyName = () =>{
      let facultyLable = [];
      faculties && faculties.map(faculty => {
        facultyLable.push(faculty.facultyName);
      })
      const Names = facultyLable.join(', ');
      return Names;
    }
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0 feedbackWp">
        <Card className="p-4">
          <Row>
            <Col className="mb-4">
              <h5 className="text-left blueText">Course Evaluation</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Dear Trainee,</p>
              <p>
                We are at the end of our course and we have a special assignment
                for you. We would greatly appreciate it if you could answer a
                few questions about your training experience. This will help us
                make your next experience in the future even better! Please
                suggest improvements if any.
              </p>
              <p>Thank you!</p>
              <p>Your Training Team</p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 mb-1">
              {" "}
              <h6 className=" font-weight-bold"> General Information</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col className="mb-3">
              <h6 className="text-left">Workshop Information</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                bordered
                style={{ width: "50%" }}
                className="feedbckTbl dropdownTbl"
              >
                <tbody>
                  <tr>
                    <td>
                      Name of Workshop
                      <span className="requiredFieldSpan">*</span>
                    </td>

                    <td>
                      {/* <Form.Control as="select">
                        <option>Introduction to SQL</option>
                        <option>Introduction to java</option>
                        <option>Introduction to React</option>
                      </Form.Control> */}
                      <DropDown
                        title="Select Course"
                        name="dropDown"
                        options={enrolledCourse}
                        value={courseName}
                        selectedCategory={this.SelectedWorkshop}
                      />
                    </td>
                  </tr>
                  {formErrors.courseName !== "" ? (
                    <label style={{ fontSize: 12, color: "red" }}>
                      {formErrors.courseName}
                    </label>
                  ) : null}
                  <tr>
                    <td>
                      Location of Course
                      <span className="requiredFieldSpan">*</span>
                    </td>
                    <td>
                      <Input
                        type="text"
                        onChange={this.handleChange}
                        name="locationOfCourse"
                        id="locationOfCourse"
                        placeholder="Enter text"
                        value={locationOfCourse}
                        maxlength="50"
                        minlength="3"
                      />
                    </td>
                  </tr>
                  {formErrors.locationOfCourse !== "" ? (
                    <label style={{ fontSize: 12, color: "red" }}>
                      {formErrors.locationOfCourse}
                    </label>
                  ) : null}
                  <tr>
                    <td> Course Length</td>
                    <td>
                      {/* <label>{durtion}</label> */}
                      <label name="duration" id="duration" value={duration}>
                        {duration}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Faculty Name</td>
                    <td>
                      <label
                        name="facultyName"
                        id="facultyName"
                        value={facultyName}
                      >
                        {getFacultyName()}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3  mt-3 col-md-6">
              <h6 className="text-left font-weight-bold">
                A Bit About You (optional)
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table style={{ width: "50%" }} bordered className="feedbckTbl">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Name</td>
                    <td>
                      <Input
                        type="text"
                        disable
                        onChange={this.handleChange}
                        name="studentName"
                        id="studentName"
                        value={stundentInfo.studentName}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Position</td>
                    <td>
                      <Input
                        type="text"
                        name="position"
                        onChange={this.handleChange}
                        id="position"
                        value={position}
                      />
                    </td>
                  </tr>
                  <span style={{ fontSize: 12, color: "red" }}>
                    {formErrors.position}
                  </span>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Do you want to be contacted about your training
                      experience?
                    </td>
                    <td>
                      <span className="ml-4">
                        <input
                          type="radio"
                          name="contacted"
                          onChange={this.handleConButton}
                          value="Yes"
                          id="Yes"
                        />
                      </span>
                      <label for="soundsignal" className="mr-5 ml-2">
                        Yes
                      </label>
                      <span>
                        {" "}
                        <input
                          type="radio"
                          name="contacted"
                          onChange={this.handleConButton}
                          value="No"
                          id="No"
                        />
                      </span>
                      <label for="soundsignal" className="ml-2">
                        No
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "50%" }}>
                      If so, please provide your telephone number and/or e-mail
                      address.
                    </td>
                    <td>
                      {contacted === true ? (
                        <Input
                          type="text"
                          name="phoneOrEmail"
                          onChange={this.handleChange}
                          id="phoneOrEmail"
                          value={phoneOrEmail}
                        />
                      ) : null}
                    </td>
                  </tr>
                  {
                    <span style={{ fontSize: 12, color: "red" }}>
                      {formErrors.phoneOrEmail}
                    </span>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3  mt-3 col-md-6">
              <h6 className="text-left  font-weight-bold">
                General Evaluation <span className="requiredFieldSpan">*</span>
              </h6>
            </Col>
          </Row>
          {formErrors.generalEvaluations !== "" ? (
            <label style={{ fontSize: 12, color: "red" }}>
              {formErrors.generalEvaluations}
            </label>
          ) : null}
          <Row>
            <Col>
              <Table bordered className="feedbckTbl">
                <thead>
                  <tr>
                    <th>Details</th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Neutral</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Overall, the training session was high quality.
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question1 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question1}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question1"
                        id="sdisagree1"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question1"
                        id="disagree1"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question1"
                        id="neutral1"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question1"
                        id="agree1"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question1"
                        id="sagree1"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The course covered the material I expected.
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question2 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question2}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question2"
                        id="sdisagree2"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question2"
                        id="disagree2"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question2"
                        id="neutral2"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question2"
                        id="agree2"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question2"
                        id="sagree2"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      This training will be beneficial to me in the performance
                      of my job.
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question3 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question3}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question3"
                        onChange={this.handleGEvaluation}
                        id="stronglydesagree4"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question3"
                        id="disagree4"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question3"
                        id="neutral4"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question3"
                        id="agree4"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        onChange={this.handleGEvaluation}
                        type="radio"
                        name="question3"
                        id="sagree4"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      I had plenty of opportunities to practice what I learned.
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question4 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question4}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question4"
                        id="stronglydesagree5"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question4"
                        id="disagree5"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question4"
                        id="neutral5"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question4"
                        id="agree5"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        onChange={this.handleGEvaluation}
                        type="radio"
                        name="question4"
                        id="sagree5"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The course gave me specific ideas and tips to implement in
                      my workplace
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question5 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question5}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question5"
                        id="stronglydesagree6"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question5"
                        id="disagree6"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question5"
                        id="neutral6"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question5"
                        id="agree6"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question5"
                        id="sagree6"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The trainer was professional, well-prepared, and
                      knowledgeable about the topics at hand.
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question6 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question6}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question6"
                        id="stronglydesagree7"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question6"
                        id="disagree7"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question6"
                        onChange={this.handleGEvaluation}
                        id="neutral7"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question6"
                        onChange={this.handleGEvaluation}
                        id="agree7"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question6"
                        onChange={this.handleGEvaluation}
                        id="sagree7"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The training facility was well-equipped and comfortable
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question7 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question7}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question7"
                        onChange={this.handleGEvaluation}
                        id="stronglydesagree8"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question7"
                        onChange={this.handleGEvaluation}
                        id="disagree8"
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question7"
                        onChange={this.handleGEvaluation}
                        id="neutral8"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question7"
                        id="agree8"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question7"
                        id="sagree8"
                        value="sagree"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Overall, this course was worth my time and money.
                      {/* <span className="requiredFieldSpan">*</span> */}
                    </td>
                    {/* {formErrors.question8 !== "" ? (
                        <label style={{ fontSize: 12, color: "red" }}>
                          {formErrors.question8}
                        </label>
                      ) : null} */}
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        onChange={this.handleGEvaluation}
                        name="question8"
                        id="stronglydesagree9"
                        value="sdisagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question8"
                        id="disagree9"
                        onChange={this.handleGEvaluation}
                        value="disagree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question8"
                        onChange={this.handleGEvaluation}
                        id="neutral9"
                        value="neutral"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question8"
                        onChange={this.handleGEvaluation}
                        id="agree9"
                        value="agree"
                      />
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        type="radio"
                        name="question8"
                        onChange={this.handleGEvaluation}
                        id="sagree9"
                        value="sagree"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3  mt-3 col-md-6">
              <h6 className="text-left  font-weight-bold">
                Final Thoughts<span className="requiredFieldSpan">*</span>
              </h6>
              {formErrors.finalThoughts !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.finalThoughts}
                </label>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <label id="Question1">
                1. If a colleague was going to take this course, would you
                {/* recommend them?<span className="requiredFieldSpan">*</span> */}
              </label>
              <Input
                type="textarea"
                name="answer1"
                id="answer1"
                onChange={this.handleChange}
                value={answer1}
                className="mt-3"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <label id="Question2">
                2.If you could change one thing about this course, what would it
                {/* be?<span className="requiredFieldSpan">*</span> */}
              </label>
              <Input
                type="textarea"
                name="answer2"
                id="answer2"
                onChange={this.handleChange}
                value={answer2}
                className="mt-3"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <label
                id="Question3"
                value="What was the most important thing that you learned today?"
              >
                3.What was the most important thing that you learned today?
                {/* <span className="requiredFieldSpan">*</span> */}
              </label>
              <Input
                type="textarea"
                name="answer3"
                id="answer3"
                onChange={this.handleChange}
                value={answer3}
                className="mt-3"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <label id="Question4">
                4.Do you have anything else to share?
                {/* <span className="requiredFieldSpan">*</span> */}
              </label>
              <Input
                type="textarea"
                name="answer4"
                id="answer4"
                onChange={this.handleChange}
                value={answer4}
                className="mt-3"
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-5">
              <div className="center-block text-center">
                <Button
                  id="submit"
                  className="text-center mr-3 btnblue"
                  onClick={this.onSubmitClick}
                >
                  Submit
                </Button>
                <Button
                  className="text-center mr-3"
                  color="danger"
                  onClick={this.clearForm}
                >
                  Clear
                </Button>
                <Button className="text-center " color="danger">
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}