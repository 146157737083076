
import React from 'react';

export default class BrowseFile extends React.Component {
   constructor(props){
     super(props);
     this.state = {
       id : "someUniqueId", // I would use this.props.id for a real world implementation
       imageURI : null
     }
   }
   
   buildImgTag(){
     
     let imgTag = null;
     if (this.state.imageURI !== null)
       imgTag = (<div className="row">
                   <div className="small-9 small-centered columns">
                     <img className="thumbnail" alt="img" src={this.state.imageURI}></img>
                   </div>
                 </div>);
     return imgTag;
   }
   
   readURI(e){
     
     if(e.target.files && e.target.files[0]){
       let reader = new FileReader();
       reader.onload = function(ev){
         this.setState({imageURI:ev.target.result});

       }.bind(this);
       reader.readAsDataURL(e.target.files[0]);
       //this.props.browsefileHandler(e.target.files[0]);

     }
   }
   
   handleChange(e){
     //debugger
     this.readURI(e); // maybe call this with webworker or async library?
     if (this.props.onChange !== undefined)
       this.props.onChange(e.target.result); // propagate to parent component
   }
 
   render() {
    // debugger
     const imgTag = this.buildImgTag();
 
     return <div className="browseFileWp">
             <label
               htmlFor={this.state.id}
               className="button" style={{fontSize:'12px'}}>
               Upload image
             </label>
             <input
               id={this.state.id}
               type="file"
               onChange={this.handleChange.bind(this)}
               className="show-for-sr inputFile" />
             {imgTag}
             </div>
   }
 }