import React from 'react';
import {Container, FormGroup,Input,Label} from 'reactstrap';
import { Col,Row ,Form} from 'react-bootstrap';
import { Card, CardTitle, Button} from 'reactstrap';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import swal from "sweetalert";
import Table from  '../../../Components/Admin/Course/Table';
import '../../../Components/Admin/Course/Table.css'
import {URL} from '../../../ServerCall/Config';
// import StudentQuestionModal from '../../../Components/Faculty/Question/StudentQuestionModal'
import AskQuestionModal from'../../../Components/Student/Lesson/AskQuetionModel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Auth from '../../../ServerCall/Auth';

const headerStyle = {
    backgroundColor: '#4c8db5',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0
}
const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698'
}

export default  class AskQuestion extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            studentUserId: Auth.getUserId(),
            facultyUserId:'',
            courseTitle:'',
            facultyName:'',
            chapterName:'',
            lessonName:'',
            studentName: Auth.getUserName(),
            Type:'string',
            question:'',
            status:'',
            answer:'',
            Course: [],
            chapterList: [],
            lessons: [],
            course: 0,
            chapter: 0,
            lesson: 0,
            description:'',
            addModalShow:false,
            columns: [
                { title: "ID", render: rowData => <span>{rowData.tableData.id+1}</span> },
                { title: "Id", field: "_id",hidden: true},
                { title: "Question", field: "question"},
                { title: "Date", field: "createdAt", type: "date"},
                { title: "Description", field: "description"},
                { title: "Status", field: "status", render: rowData => <span>{rowData.status ? "Answered" : "Unanswered"}</span>},
                { title: "View Answer", field: "ViewAnswer", render: rowData => <VisibilityIcon  className="ml-4" onClick={() => this.onClickViewAnswer(rowData.status,rowData.answer)}/>} 
              ],
              LabelText:{
                course: 0,
                chapter: 0,
                lesson: 0, 
                question:''
              },
            Data : [],
        }
    }

    validate=()=>{
        //debugger
        let {LabelText} = this.state;
        if (this.state.question !=="" && this.state.question !== null) {	
            LabelText.question = "";
             }
           else{
            LabelText.question  = "Please Enter Question";
           }
           if ( this.state.course !== 0) {
            if(this.state.course == "All Courses"){
                LabelText.course = "Please select course"
            }
            else{
                LabelText.course = 0;
            }
          }
          else{
            LabelText.course = "Please select course"
          }
          if(this.state.chapter !== 0){
              if(this.state.chapter == "All Chapters"){
                  LabelText.chapter = "Please select chapter"
              }
              else{
                  LabelText.chapter = 0;
              }
          }
          else{
            LabelText.chapter = "Please select chapter"
          }
          if(this.state.lesson !== 0){
            if(this.state.lesson == "All Lessons"){
                LabelText.lesson = "Please select lesson"
            }
            else{
                LabelText.lesson = 0;
            }
          }
          else{
              LabelText.lesson ="Please select lesson"
          }
           this.setState({LabelText: LabelText});
           if(LabelText.question==="" &&LabelText.course===0 &&LabelText.chapter=== 0 &&LabelText.lesson===0)
               {
                 return true;
               }
               else
               {
                 return false;
               }
      }

    onClickViewAnswer =(status,answer)=> {
        //debugger
        this.setState({addModalShow:true,status:status,answer:answer})
    }

    onClickCancel = () =>{
        this.setState({addModalShow: false,status:'',answer:''});
     }


    getTableData () {
        axiosApiInstance.get(`${URL}/getAskQuestionByUser`)
        .then(res =>{
            if(res.status === 200){
                this.setState({Data: res.data.AskQuestion});
            }else{
            }
        }).catch(err => {
            console.log(err);
        })
    }
      
   async componentDidMount (){
        await axiosApiInstance.get(`${URL}/getEnrollment`)
        .then(res => {
            if(res.status === 200){
                this.setState({Course: res.data.course});
            }else{
                swal(res.data.message, "", 'error');
            }
        }).catch(err => {
            console.log(err);
        })

        await this.getTableData();
    }

    handleChange = (e) => {
        const {name, value, id} = e.target;
        this.setState({[name]: value})
    }

    handleDropdownChange = e =>{
        const selectedIndex = e.target.options.selectedIndex;
        let courseTitle = e.target.options[selectedIndex].getAttribute('courseTitle');
        let facultyUserId = e.target.options[selectedIndex].getAttribute('facultyUserId');
        let facultyName = e.target.options[selectedIndex].getAttribute('facultyName');
        let chapterName = e.target.options[selectedIndex].getAttribute('chapterName');
        let lessonName = e.target.options[selectedIndex].getAttribute('lessonName');
        const { name,value} = e.target;
        this.setState({[name]: value});
        if(name === "course" && value != "All Courses"){
            this.setState({courseTitle:courseTitle})
            this.setState({chapterList : this.state.Course.find(course => course._id === value).chapter});
            this.setState({chapter:0,lesson:0});
        }else if(name === "chapter" && value != "All Chapters"){
            this.setState({chapterName:chapterName})
            // const chapt = this.state.Course.find(course => course._id === this.state.course).chapter;
            // this.setState({lessonList : chapt.find(chapter => chapter._id === value).lessons});
            this.setState({lessonList : this.state.chapterList.find(chapter => chapter._id === value).lessons});
        }
        else if(name === "lesson" && value != "All Lessons"){ 
            this.setState({facultyUserId:facultyUserId,facultyName:facultyName,lessonName:lessonName});
        }
        //this.setState({facultyFullName:textValue})
    }

    postQuestions = async(e) => {
        e.preventDefault();
        const isValid = this.validate();
        if(isValid){
            const {studentUserId,question,studentName,course,facultyUserId,facultyName, chapter, lesson,description,courseTitle,chapterName,lessonName} = this.state;
            let data = {
                studentUserId: studentUserId,
                studentName: studentName,
                facultyUserId: facultyUserId,
                facultyName: facultyName,
                courseId: course,
                courseTitle: courseTitle,
                chapterId: chapter,
                chapterName: chapterName,
                lessonId : lesson,
                lessonName: lessonName,
                question: question,
                description: description
            }
            await axiosApiInstance.post(`${URL}/askQuestion`,data)
            .then(async(res) =>{
                if(res.status == 201) {
                    swal("", "Question Posted Successfully", "success")
                    await this.getTableData();
                }else{   
                   // swal("","Something Went Wrong", "error"); 
                }
            }).catch(err=>{
                console.log("err",err)
            })
        }
    }

    render(){
        const{ Course, chapterList, lessonList, course, chapter, lesson,Data,columns,studentName,LabelText} =this.state;
        let addModalClose=() => this.setState({addModalShow:false});
        return (
            <Container fluid  className="pl-0 pr-0">                
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <Card className="p-4 shadow">
                            <Card className="col-md-8 p-4" style={{margin:'0 auto'}}>
                            <Row>
                                <Col>
                                    <h3 className="text-center hdtxt" >Ask Question</h3>
                                </Col>
                            </Row>  
                            <Form className="FormWp">                           
                            <Row className="mt-3">
                            <Col md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label>Courses</Label><span className="requiredFieldSpan">*</span>
                                    <select className="form-control" name="course" value={course} id={studentName} onChange={(e) => this.handleDropdownChange(e)}>
                                        <option>All Courses</option>
                                        {Course && Course.map(course =>
                                            <option id="courseName" courseTitle={course.courseName} value={course._id}>{course.courseName}</option>
                                        )}
                                    </select>
                                    {LabelText.course !== 0 ?
                                    <Label style={{ fontSize: 12, color: "red" }}>
                                    {LabelText.course }</Label >: null
                                    }
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={4} xs={12}>
                                <FormGroup>
                                    <Label>Chapters</Label><span className="requiredFieldSpan">*</span>
                                    <select className="form-control" name="chapter" value={chapter} disabled={course !== 0 ? false : true} onChange={(e) => this.handleDropdownChange(e)}>
                                        <option>All Chapters</option>
                                        {chapterList && chapterList.map(chapter =>
                                            <option chapterName={chapter.chapterName} value={chapter._id}>{chapter.chapterName}</option>
                                        )}
                                    </select>
                                    {LabelText.chapter !== 0 ?
                                    <Label style={{ fontSize: 12, color: "red" }}>
                                    {LabelText.chapter }</Label >: null
                                    }
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={4} xs={12}>
                            <FormGroup>
                                <Label>Lessons</Label><span className="requiredFieldSpan">*</span>
                                <select className="form-control" name="lesson" value={lesson} disabled={chapter !== 0 ? false : true} onChange={(e) => this.handleDropdownChange(e)}>
                                    <option>All Lessons</option>
                                    {lessonList && lessonList.map(lesson =>
                                        <option facultyUserId={lesson.facultyUserId}  facultyName={lesson.facultyFullName} lessonName={lesson.lessonName} value={lesson._id}>{lesson.lessonName}</option>
                                    )}
                                </select>
                                {LabelText.lesson !== 0 ?
                                <Label style={{ fontSize: 12, color: "red" }}>
                                {LabelText.lesson }</Label >: null
                                }
                            </FormGroup>
                            </Col>
                            </Row>
                            {/* <hr/> */}
                            {/* <Row>
                                <Col>
                                    <QuestionCount/>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    {/* <CardTitle className="font-weight-bold" style={{Color:'#5c6070'}}>Ask a Question</CardTitle> */}
                                    <FormGroup>
                                        <Label for="name" className="Qlabeltext">Name</Label><span className="requiredFieldSpan">*</span>
                                        <Input type="text" name="question" value={this.state.question} placeholder="Write Question" onChange={(e) => this.handleChange(e)}/>
                                        {LabelText.question !== "" ?
                                        <Label style={{ fontSize: 12, color: "red" }}>
                                        {LabelText.question }</Label >: null
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="description" className="Qlabeltext">Description</Label>
                                        <Input type="textarea" name="description" placeholder="Description Here..." onChange={(e) => this.handleChange(e)}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button className="btnblue w-100" onClick={(e) => this.postQuestions(e)}>Post</Button>
                            </Form>
                            </Card>
                            <Row className="mb-4 mt-4">
                                <Col className="tableWp">
                                    <Row>
                                        <Col className="DashboardTbl">
                                            <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle} rowStyle={rowStyle} maxBodyHeight='220px' 
                                            />
                                        </Col>
                                     </Row>       
                                </Col>
                            </Row>
                            <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <AskQuestionModal 
                                        show={this.state.addModalShow} onHide={addModalClose}
                                        answer={this.state.answer}
                                        status={this.state.status}
                                        onClickCancel={this.onClickCancel}
                                    />
                                </FormGroup>
                                </Col> 
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    };
}