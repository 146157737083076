import React from 'react'
import {Row,Col,Container,InputGroup,FormControl,Button }from 'react-bootstrap'
import {Card ,CardTitle,CardText} from 'reactstrap'
import '../../../Containers/Student/student.css'
import Accordian from '../../../Components/Student/Checkout/Accordian'
import {  Form, FormGroup} from 'react-bootstrap';
import { Input,Label} from 'reactstrap';

export default  class Checkout extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            CourseTitle:"Java Programming",
            CourseName:'Java Programming MasterClass of Software Development',
            TotalFees:'4090',
            PaidFess:"1000",
           BalanceFees:'3090',
           TotalPrice:'4000',
           TotalDiscount:'1000',
           TotalAmount:'5000'

        }
      }
      render(){

        const{disabled,CourseTitle,CourseName,TotalFees,PaidFess,BalanceFees,TotalPrice,TotalDiscount,TotalAmount}=this.state;
        return( 
            <Container  CheckoutWp>
                <Col md={12} style={{margin:'0 auto'}}>
                    <Card className="p-4">
                        <Row>
                            <Col>
                                <h4 className="hdtxt">Home/Checkout</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Card className="p-3">
                                    <Row>
                                        <Col md={3} sm={5} className="pr-0 ">
                                            <CardText className="Coursetxt fontWeight6 mt-3 mb-2">Course Title :</CardText>
                                        </Col>
                                        <Col md={9}  sm={7} className=" pl-0">
                                            <CardText className="Coursetxt  mt-3 mb-2">{CourseTitle}</CardText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3} sm={5} className="pr-0 ">
                                            <CardText className="Coursetxt fontWeight6 mt-3 mb-2">Course Name :</CardText>
                                        </Col>
                                        <Col md={9}  sm={7} className="pl-0 ">
                                            <CardText className="Coursetxt  mt-3 mb-2">{CourseName}</CardText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col   md={3} className=" pr-0">
                                            <CardText className="Coursetxt fontWeight6 mt-3 mb-2">Total Fees :</CardText>
                                        </Col>
                                        <Col   md={9} className="pl-0 ">
                                            <CardText className="Coursetxt mt-3 mb-2">{TotalFees}</CardText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col  md={3} className="pr-0 ">
                                            <CardText className="Coursetxt fontWeight6 mt-3 mb-2">Paid Fees:</CardText>
                                        </Col>
                                        <Col   md={9} className="pl-0 ">
                                            <CardText className="Coursetxt mt-3 mb-2">{PaidFess}</CardText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3} className=" pr-0">
                                            <CardText className="Coursetxt fontWeight6 mt-3 mb-2">Balance Fees"</CardText>
                                        </Col>
                                        <Col md={9} className=" pl-0">
                                            <CardText className="Coursetxt mt-3 mb-2">{BalanceFees}</CardText>
                                        </Col>
                                    </Row>
                                </Card>
                                
                            </Col>
                            <Col md={4}>
                                <Card className="priceTotl shadow-sm p-3" >
                                    <CardTitle className="applyCoupn fontWeight6">Summary</CardTitle>
                                        <Row>
                                            <Col md={6}  sm={6} xs ={6}>
                                                <h5 className="totalpriceTxt"> Total Price </h5>
                                            </Col>
                                            <Col md={6}  sm={6} xs ={6}>
                                                <h5 className="PriceValueText">  {TotalPrice} </h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}  sm={6} xs ={6}>
                                                <h5 className="totalpriceTxt"> Total Discount </h5>
                                            </Col>
                                            <Col md={6}  sm={6} xs ={6}>
                                                <h5 className="PriceValueText"> {TotalDiscount} </h5>
                                            </Col>
                                        </Row>
                                    <hr/>
                                    <Row>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="totalpriceTxt"> Total Amount </h5>
                                            </Col>
                                            <Col md={6}  sm={6} xs ={6} >
                                                <h5 className="PriceValueText"> {TotalAmount}</h5>
                                         </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-2">
                                            <Button className="w-100">
                                                Checkout
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={12} className="whitebg shadow-sm p-4">
                            <Card className="p-3">
                                <Form onSubmit={this.handleSubmit}>
                                {/* <FormGroup>
                                    <Label for="transactionAmount" className="grylbl">Enter amount to Deposits</Label>
                                    <Input type="number" name="transactionAmount" required id="transactionAmount" 
                                    value={this.state.transactionAmount} onChange={this.handleChange}/>
                                    {/* {
                                        errors.amount != "" ? <Label style={{ fontSize: 12, color: "red" }}>{errors.amount}</Label>: null
                                    } */}
                                {/* </FormGroup> */} 
                                <FormGroup className="mb-3 mt-2 ">
                                <Label for="entrAmnt" className="pl-2">Select Payment Method</Label>
                                <Accordian childStatedata={this.childStatedata} ref={this.DepositeAmount} />
                                </FormGroup>
                                <FormGroup check > 
                                    <Label check  className="ml-4 tc grylbl" >
                                    <Input type="checkbox"
                                        onClick={()=>{this.setState({disabled: !disabled})}}/>{' '}
                                        <span className="pt-3">Remember This card</span>
                                        {/* <TermsAndCon show={this.state.showModal} onHide={this.addModalClose} /> */}
                                    </Label>
                                </FormGroup>
                                
                                </Form>
                            </Card>
                            </Col>
                            </Row>
            
                </Card>
            </Col>
        </Container>
        );
      }
}
