import React from 'react';
import { Button,Card, Input ,Row,Col,FormGroup,Label,Container,Form} from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  group: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    }
});
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}



const AddTest = (
  {
  courseList,
  selectedCourse,
  courseId,
  checkedShuffle,
  handleChange,
  testName,
  description,
  duration,
  handleChangeShuffle,
  testType,
  completeTestType,
  handleSubmit,
  onClickCancel,
  handleRadioButton,
  formErrors

  }) => {
  //debugger
  
  return (
    <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
      <Card className="col-md-10 p-3 addtest mx-auto">
      <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Add Test</h3>
         </Col>
       </Row>
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Test Name</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="testName"  placeholder="Test Name" value={testName} onChange={handleChange} />
                {
                    formErrors.testName !== "" ?
                    <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.testName}</Label>: null
                 } 
            </FormGroup>
           </Col>
       
        <Col md={6}>
            <FormGroup>
              <Label>Description</Label><span className="requiredFieldSpan">*</span>
                <Input type="text" name="description"  placeholder="Description" value={description} onChange={handleChange} />
                {
                    formErrors.description !== "" ?
                    <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.description}</Label>: null
                }
            </FormGroup>
           </Col>
           </Row>
           <Row>
             <Col md={6}>
              <FormGroup>
                <Label>Test Type</Label><span className="requiredFieldSpan">*</span>
                <select id="testType" name="testType" className="form-control" value={testType}
                onChange={handleChange}>
                  <option>Test Type</option> 
                  <option value= "Course">Course</option>
                  <option value= "Other">Other</option>
                </select>
                {
                    formErrors.testType !== "" ?
                    <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.testType}</Label>: null
                }
              </FormGroup>
             </Col>
             <Col md={6} className="TimelimitRadio">
              <FormControl component="fieldset">
              <Label>Time Limit option <span className="requiredFieldSpan">*</span></Label>
              {/* <FormLabel component="legend">Time Limit option</FormLabel> */}
                <RadioGroup name={completeTestType} value={completeTestType} onChange={handleRadioButton} aria-label="timeLimitOption" >
                  <FormControlLabel value="Complete Test" control={<StyledRadio />} label="Complete Test" />
                  <FormControlLabel value="Question wise" control={<StyledRadio />} label="Question wise" className="ml-2"/>
                  <FormControlLabel value="None" control={<StyledRadio />} label="None" className="ml-2"/>
                </RadioGroup>
                {
                    formErrors.completeTestType !== "" ?
                    <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.completeTestType}</Label>: null
                }
              </FormControl>
            </Col>
           </Row>
           <Row>
             { completeTestType !== "Question wise"?
             <Col md={6}>
              <FormGroup>
                <Label>Duration(minutes)</Label><span className="requiredFieldSpan">*</span>
                  <Input type="number" name="duration"  placeholder="Duration" value={duration} onChange={handleChange}  />
                  {
                    formErrors.duration !== "" ?
                    <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.duration}</Label>: null
                  }
              </FormGroup>
             </Col>:null
            }
            { testType === "Course"?  
              <Col md={6}>
                <div  className="">
                  <Label>Select Course</Label><span className="requiredFieldSpan">*</span>
                  <select id="facultyUserId" name="Course" className="form-control" value={courseId}
                  onChange={(e)=>{selectedCourse(e.target.value)}}
                    //onChange={selectedCourse}
                  >
                    <option>Select Course</option> 
                  {
                      courseList && courseList.map(select => {
                          return (
                              <option 
                                value={select.text}
                                data-key={select.value}
                              >
                                  {select.value}
                              </option> 
                          )
                      })
                  }
                </select>
                {
                    formErrors.courseId !== "" ?
                    <Label  style={{ fontSize: 12, color: "red" }}>{formErrors.courseId}</Label>: null
                }
               </div>
              </Col>
          //  :testType === "Independent"? 
          //  <Col md={6}>
          //    <FormGroup>
          //    <Label>Shuffle</Label><br/>
          //    <Switch
          //     checked={checkedShuffle}
          //     onChange={handleChangeShuffle}
          //     name="shuffle"
          //     inputProps={{ 'aria-label': 'secondary checkbox' }}
          //     color="primary"
          //       />
          //    </FormGroup>
          
          //   </Col>
            :null
           }
             {/* <Col>
              <FormGroup>
                <Label>Total Marks</Label>
                  <Input type="text" name="totalMarks"  placeholder="Total Marks" value={totalMarks} onChange={handleChange}  />
              </FormGroup>
             </Col> */}
           </Row>

           <Row>
            <Col className="text-center mt-5">
              <div  className="center-block text-center">
                <Button className="text-center mr-3 pl-3 pr-3 btnblue" onClick={handleSubmit}>Submit</Button>            
                <Button className="text-center pl-3 pr-3 clearBtn" color="danger" onClick={onClickCancel}>Clear</Button>
              </div>
            </Col>
        </Row>
        <Row>
          <Col>
          {/* <AddSection/> */}
          </Col>
        </Row>
      </Form>
          {/* <Row className="mt-4">
              <Col>
              <QuestionsTab formData="data1" courseId={courseId} />
              </Col>
          </Row> */}
          <Row>
            
          </Row>
      </Card>
      </Container>
  );
}

export default AddTest;