import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "./Components/Common/sidebar/SideBar";
import Footer from "./Components/Common/Footer/Footer"
import Content from './Components/Common/content/Content';
import "./App.css";
import Auth  from '../src/ServerCall/Auth';
import LessonSidebar from './Components/Common/sidebar/LessonSidebar';

const token = Auth.getToken();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      sidebarIsOpen:true,
      // setSidebarOpen:true,
      // isSignupPage:true,
      isLoginPage: false
    }
  }
  toggleSidebar = () => {
    this.setState({ sidebarIsOpen:!this.state.sidebarIsOpen });
  };

  // toggleSidebar = () => {
  //   this.props.toggleSidebar(!this.props.sidebarIsOpen);
  // };
  componentDidMount(){
      if (token === undefined ||  window.location.pathname === '/Login' ||window.location.pathname === '/' ||
      window.location.pathname === '/Registration' ||  window.location.pathname === '/ResetPassword'  ||  window.location.pathname === '/ForgotPassword' ){
      this.setState({isLoginPage: true})
    }else{
      this.setState({isLoginPage: false}) 
    }
  }
 
  render(){
    return (
      <div>
        <Router>
          <div className="App">
          {/* {
              !this.state.isLoginPag&&this.state.SignupPage?
              <SideBar toggle={this.toggleSidebar} isOpen={this.state.sidebarIsOpen} /> : null    
          } */}
          {/* <LessonSidebar toggle={this.toggleSidebar} isOpen={this.props.sidebarIsOpen} /> */}
          {/* { window.location.pathname=='/Lesson'|| window.location.pathname=='/Announcement' || window.location.pathname == '/Comment' || window.location.pathname == '/Progress' 
            // || window.location.pathname == '/AskQuestion' 
            ? <LessonSidebar toggle={this.toggleSidebar} isOpen={this.state.sidebarIsOpen} /> :
              window.location.pathname === '/Login' ||window.location.pathname === '/' || window.location.pathname === '/login'
              || window.location.pathname === '/Registration' ||  window.location.pathname === '/ResetPassword'  ||  window.location.pathname === '/ForgotPassword'? null
            : <SideBar toggle={this.toggleSidebar} isOpen={this.state.sidebarIsOpen} /> 
          } */}
           { !token ||  window.location.pathname === '/Login' ||window.location.pathname === '/' ||
               window.location.pathname === '/Registration' ||  window.location.pathname === '/ResetPassword'  ||  window.location.pathname === '/ForgotPassword'? null
            : <SideBar toggle={this.toggleSidebar} isOpen={this.state.sidebarIsOpen} /> 
          }
            <Content
              isLoginPage ={ this.state.isLoginPage}
              // isSignupPage ={ this.state.isSignupPage}
              toggleSidebar={this.toggleSidebar}
              sidebarIsOpen={this.state.sidebarIsOpen} />
          </div>
          <br/>
          {!this.state.isLoginPage ? <Footer/> : null}
        </Router>
      </div>
    );
  }
};

export default App;