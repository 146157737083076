import React from 'react'
import CourseList from '../../../Components/Student/Payment/CourseList'
import {Row,Col,Container,InputGroup,FormControl ,Button }from 'react-bootstrap'
import {Card ,CardTitle} from 'reactstrap'
import '../../../Containers/Student/student.css'
import BeenhereIcon from '@material-ui/icons/Beenhere';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PaymentSlider from '../../../Components/Student/Payment/PaymentSlider'
//import { Redirect } from 'react-router-dom'

export default  class Payment extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            TotalPrice:"4098",
            TotalDiscount:'2290',
            TotalAmount:'4090',
            price:this.props.price,
        }
      }
      checkoutClick = () =>{
        this.props.history.push(`/Checkout`)
      }
      render(){
          const{TotalPrice,TotalDiscount,TotalAmount,Price}=this.state;
        return( 
            <Container fluid PaymentWp className="pl-0 pr-0"> 
                <Col md={12} style={{margin:'0 auto'}} className="pl-0 pr-0">
                    <Card className="p-4 shadow">
                        <Row>
                            <Col>
                                <h4 className="hdtxt">Home/Payment</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} md={12} sm={12} xs={12}>
                                <Row>
                                    <Col className=" ">
                                        <CardTitle className="coursesText mt-3 mb-2">2 Courses in Cart</CardTitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CourseList/>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <CourseList/>
                                    </Col>
                                </Row> */}
                            </Col>
                            <Col  lg={4} md={12} sm={12} xs={12}>
                                <Card className="priceTotl shadow-sm p-3" >
                                    {/* <CardTitle className="applyCoupn"><BeenhereIcon/> Apply Coupon</CardTitle> */}
                                    <Row>
                                        <Col className="mt-2">
                                            {/* <InputGroup className="mb-3">
                                                <FormControl
                                                placeholder="Enter Coupon Code"
                                                aria-label="Recipient's username"
                                                aria-describedby="Apply" className="CoupnInput"
                                                />
                                                 <InputGroup.Append>
                                                    <Button variant="primary">Button</Button>
                                                    </InputGroup.Append>
                                                </InputGroup> */}
                                                
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3 mt-2">
                                            <h4 className="pricetxt">Price Details</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="totalpriceTxt "> Total Price </h5>
                                        </Col>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="PriceValueText">  {TotalPrice} </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="totalpriceTxt"> Total Price </h5>
                                        </Col>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="PriceValueText"> {TotalDiscount} </h5>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="totalpriceTxt"> Coupon Discount </h5>
                                        </Col>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="ApplyText" > <a href="#">Apply</a> </h5>
                                        </Col>
                                    </Row> */}
                                    <hr/>
                                    <Row>
                                        <Col md={6}  sm={6} xs ={6}>
                                            <h5 className="totalpriceTxt"> Total Amount </h5>
                                            </Col>
                                            <Col md={6}  sm={6} xs ={6} >
                                                <h5 className="PriceValueText"> {TotalAmount}</h5>
                                         </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-2">
                                            <Button className="w-100" onClick={this.checkoutClick}>
                                                Checkout
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    <Row>
                        <Col className="AddMoreIcons mt-4">
                            <h3><LocalMallIcon/><span>Add More From Wishlist</span></h3>
                        </Col>    
                    </Row> 
                    <hr/>   
                    <Row>
                        <Col className=" ItemLikeText mt-4">
                            <h3>You May Also  Like</h3>
                        </Col>    
                    </Row>  
                    <Row className="mt-4 PaymentsliderWp"> 
                            <Col>
                                <PaymentSlider price={Price}/>

                            </Col>
                        </Row>           
                </Card>
            </Col>
        </Container>
        );
      }
}
