import React from 'react';

function Question(props) {
  return (
    <div className="QuestionWp">
     <h2 className="question">{props.content}</h2>
  </div>
  );
}

export default Question;