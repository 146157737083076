import React,{useState } from 'react'
import {Row,Col,Container,  Card, CardText, CardBody, CardLink,Button,
    CardTitle, CardSubtitle} from 'reactstrap'
    import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import SettingsIcon from '@material-ui/icons/Settings';
import ListIcon from '@material-ui/icons/List';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import {URL} from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import EventIcon from '@material-ui/icons/Event';
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import {Alert  } from "reactstrap";
import  { setProfile } from '../../../redux/action/profileAction';
import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    }
 
  }));

const userName = Auth.getUserName();
const role = Auth.getUserRole();
const StudentDashboard = (props) =>{
   
    const classes = useStyles();
    const [chapter,setChapter]=React.useState(0);
    const [course, setCourse] = React.useState(0);
    const [independent, setIn] = React.useState(0);
    const [post ,setPost] = React.useState(0);
    const [enroll ,setEnroll] = React.useState(0);
    const [qs ,setQs] = React.useState(0);
    const [qsReply ,setQsReply] = React.useState(0);
    const [notReply ,setNotReply] = React.useState(0);
    const [test ,setTest] = React.useState(0);
    const [reply ,setReply] = React.useState(0);
    const [name,setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [eventvisible, setEventVisible] = useState(false);
    const onDismiss = () => setVisible(false);
    const onEventDismiss = () => setEventVisible(false);
    function enrollmentCount(){
        axiosApiInstance.get(`${URL}/getEnrollmentCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
               let Count = res.data.enrollmentCount;
               Count&&Count.map(i=>{
                    if(i._id !== null){
                    setEnroll(i.count)
                    }
                }
               )
              // var value = 0;
               // setTrading(Count[1].count);
                // for(let i = 0; i < Count.length; i++) {
                //     if(Count[i]._id !== null){
                //     value += Count[i].count;
                //     }
                // }
                // console.log(value);
            // setEnroll(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function userDetail(){
        axiosApiInstance.get(`${URL}/getProfile`)
        .then(res=>{
        //   console.log(res);
          if(res.status === 200){
            let User = res.data.User;
            props.setProfile(User);
            setEmail(User.email);
            setName(User.fullName);
          }
        }).catch(err=>{
            console.log(err);
        })
    }

    function forumCount(){
        axiosApiInstance.get(`${URL}/getForumCountByUser`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
              // debugger
               let Count = res.data.forumPostCount;
               Count&&Count.map(i=>
                setPost(i.count)
               )
           //  setPost(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }
    function forumReply(){
        axiosApiInstance.get(`${URL}/getForumReplyCountByUser`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
              // debugger
               let Count = res.data.forumReplyCount;
               var value = 0;
               for(let i = 0; i < Count.length; i++) {
                   if(Count[i]._id !== null){
                   value += Count[i].count;
                   }
               }
               console.log(value);
                setReply(value)
  
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    
    function askQsCount(){
        axiosApiInstance.get(`${URL}/getAskQuestionCountByUser`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
               let Count = res.data.AskQuestion;
               setQs(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function replyQs(){
        axiosApiInstance.get(`${URL}/getAskQuestionAnswerCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
               let Count = res.data.AskQuestion;
               Count&&Count.map(i=>{
                if(i._id === true){
                    setQsReply(i.count);
                }
                else if(i._id === false)
                {
                    setNotReply(i.count);
                }
             }
            )
               ///setQsReply(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function getTest(){
        axiosApiInstance.get(`${URL}/getTestCount`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
               let Count = res.data.testCount;
               Count&&Count.map(i=>{
                if(i._id === "Chapter"){
                    setChapter(i.count);
                }
                else if(i._id === "Independent")
                {
                    setIn(i.count);
                }
                else if(i._id === "Course")
                {
                    setCourse(i.count);
                }
             }
            )
               ///setQsReply(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    function TestAttampted(){
        axiosApiInstance.get(`${URL}/getTestHistoryCountByUser`)
        .then(res=>{
            //console.log(res,"res");
            if(res.status === 200){
               //debugger
               let Count = res.data.TestHistoryCount;
               setTest(Count);     
            }
           
        }).catch(err=>{
            console.log(err);
        })
    }

    React.useEffect(()=>{
        forumCount();
        askQsCount();
        enrollmentCount();
        forumReply();
        replyQs();
        getTest();
        TestAttampted();
        userDetail();
     }, []);

    return(
        <Container fluid className="StudDashboard">
            {/* <Row>
                <Col className="PanelWp p-3" style={{position:'relative'}}>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <Col md={1}>
                                    <Avatar className={classes.orange}>
                                                {userName !== undefined? userName.charAt(0)+" " 
                                               // +item.name.charAt(11)
                                                :null}
                                    </Avatar>
                                </Col>
                                <Col md={11}>
                                    <h4 className="StudHeadText">{name}</h4>
                                    <h6 className="studEmail">{email}</h6>
                                    <h6 className="StudHeadText"><Link to={"/UserProfileSetting"}><SettingsIcon className="pr-2"/>View Profile</Link></h6>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2}>
                            <div className=" studentNotifctionWp float-right mr-4">
                            <button onClick={() => setVisible(true)} style={{border:'none',backgroundColor:'transparent'}}>
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon/>
                            </Badge>
                            </button>
                            <button  onClick={() => setEventVisible(true)} style={{border:'none',backgroundColor:'transparent'}} className="ml-4">
                            <Badge badgeContent={4} color="secondary">
                                <EventIcon/>
                            </Badge>
                            </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row> */}
      
            {/* <Row>
                <Col md={5}></Col>
                <Col md={4} className="alertPopClass">
                     <Alert variant="primary" color="primary" isOpen={visible} toggle={onDismiss}>
                       Notification alert
                     </Alert></Col>
                    <Col md={1}></Col>
            </Row>
            <Row>
                <Col md={5}></Col>
                <Col md={4} className="alertPopClass">
                     <Alert variant="primary" color="primary" isOpen={eventvisible} toggle={onEventDismiss}>
                      Event Alert
                     </Alert></Col>
                    <Col md={1}></Col>
            </Row> */}
           <Row className="welcomeTextRow">
               <Col style={{fontSize:'16px',fontWeight:'bold'}} className="mb-3  WelcomeName" > 
                 Welcome !<span className="blueText pl-2">{name}</span>
               </Col>
           </Row>
            <Row className="pt-2">
            <Col  lg={3}  md={5}>
                    <Card className="shadow studProfile">
                        <Row>
                            <Col md={12} style={{margin:'0 auto'}}> 
                                <div className="text-center profileWp pt-5 pb-2">
                                    <Avatar className={classes.orange} style={{margin:'20px auto'}}>
                                    { userName !== undefined? userName.charAt(0)+" " 
                                    // +item.name.charAt(11)
                                    :null} 
                                    </Avatar>
                                </div>
                              
                            </Col>
                     
                        </Row>
                        <Row>
                            <Col className="profile-usertitle">
                                <h4 className="StudHeadText text-center">{name}</h4>
                            </Col>
                        </Row>
                        <Row><Col> <h6 className="studEmail text-center pt-2">{email}</h6></Col></Row>
                        <Row>
                            <Col> 
                                <h6 className="StudHeadText viewProfileText text-center pt-2">
                                    <Link to={"/UserProfileSetting"}> <SettingsIcon className="pr-2"/>View Profile</Link>
                                </h6>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <div className=" studentNotifctionWp text-center mt-3 mr-4 pb-5">
                                <button onClick={() => setVisible(true)} style={{border:'none',backgroundColor:'transparent'}}>
                                <Badge badgeContent={0} color="secondary">
                                    <NotificationsIcon/>
                                </Badge>
                                </button>
                                <button  onClick={() => setEventVisible(true)} style={{border:'none',backgroundColor:'transparent'}} className="ml-4">
                                <Badge badgeContent={0} color="secondary">
                                    <EventIcon/>
                                </Badge>
                                </button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="alertPopClass">
                                 <Alert variant="primary" color="primary" isOpen={visible} toggle={onDismiss}>
                                    Notification alert
                                </Alert>
                            </Col>
                            <Col md={12} className="alertPopClass">
                                <Alert variant="primary" color="primary" isOpen={eventvisible} toggle={onEventDismiss}>
                                Event Alert
                                </Alert>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={9} md={7}>
                    <Row> 
                        <Col md={6} className="StudColCardWp">
                            <Card  className="FirstRowCard pb-4">
                                <CardBody>
                                <Row>
                                    <Col>
                                        <CardTitle className="Titlehd text-uppercase text-muted mb-0">Total Courses Enrolled</CardTitle>
                                            <span className="countText font-weight-bold mb-0">{enroll}</span>
                                        </Col>
                                    <div className="col-auto">
                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                    <Link className="uiIcon" to={"/Enrollment"}> <ListIcon/> </Link>
                                    </div>
                                    </div>
                                </Row>
                                </CardBody>                   
                            </Card>
                        </Col>
                        <Col md={6}  className="StudColCardWp">
                            <Card className="FirstRowCard">
                                <CardBody>
                                    <Row>
                                        <Col>
                                        <CardTitle className="Titlehd text-uppercase text-muted mb-0">Total Post On Forum</CardTitle>
                                            <span className="countText font-weight-bold mb-0">{post}</span>
                                        </Col>
                                        <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                        <QuestionAnswerIcon/>
                                        </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CardText className="cardsbText mb-1">Replies  </CardText>
                                            <CardText  className="subCountText">{reply}</CardText>
                                        </Col>
                                        <Col>
                                        
                                        </Col>
                                    </Row>
                                </CardBody>                   
                        </Card>
                </Col>
                        {/* <Col md={6}>
                            <Card  className="FirstRowCard">
                                <CardBody>
                                    <Row>
                                        <Col>
                                        <CardTitle className="Titlehd text-uppercase text-muted mb-0">Asked Questions</CardTitle>
                                            <span className="countText font-weight-bold mb-0">{qs}</span>

                                        </Col>
                                        <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                        <Link  className="uiIcon" to={"/AskQuestion"}><LiveHelpIcon/></Link>
                                        </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CardText className="cardsbText mb-1">Replied Answer </CardText>
                                            <CardText  className="subCountText">{qsReply}</CardText>
                                        </Col>
                                        <Col>
                                            <CardText className="cardsbText mb-1">Not Replied Yet</CardText>
                                            <span className="subCountText">{notReply}</span>
                                        </Col>
                                    </Row>
                                </CardBody>                   
                                </Card>
                        </Col> */}
                </Row>
                {/* <Row className="studDashSecondRowWp">
                <Col md={6}  className="StudColCardWp">
                    <Card className="secondRowCard">
                        <CardBody>
                            <Row>
                                <Col>
                                <CardTitle className="Titlehd text-uppercase text-muted mb-0">Total Test</CardTitle>
                                    <span className="countText font-weight-bold mb-0">{chapter+independent+course}</span>

                                </Col>
                                <div className="col-auto">
                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <Link className="uiIcon" to={"/AssignedTest"}><PostAddIcon/></Link>
                                </div>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <CardText className="cardsbText mb-1">Chapter Test</CardText>
                                    <CardText  className="subCountText">{chapter}</CardText>
                                </Col>
                                <Col>
                                    <CardText className="cardsbText mb-1">Course Test</CardText>
                                    <span className="subCountText">{course}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CardText className="cardsbText mb-1">Independent Test</CardText>
                                    <CardText  className="subCountText">{independent}</CardText>
                                </Col>
                                <Col>
                                    <CardText className="cardsbText mb-1">Attempted Test</CardText>
                                    <span className="subCountText">{test}</span>
                                </Col>
                            </Row>
                        </CardBody>                   
                        </Card>
                </Col>
                </Row>*/}
                </Col>
            </Row> 
         
        </Container>
    );
}

const mapStoreToProps = (state) => {
    //debugger
    // console.log(state,"datadatadata");
    return{
      Profile: state.profile.profile
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    setProfile:(profile)=>dispatch(setProfile(profile))
  })
  export default connect(mapStoreToProps, mapDispatchToProps)(StudentDashboard);