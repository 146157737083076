import React from 'react';
import '../../../Containers/Student/student.css'

const Circle = ({number,Labeltext}) => {
  return (
    <div className="circlebg">
     <h3>{number}</h3>
     <h5>{Labeltext}</h5>
    </div>
  );
};

export default Circle;