import React from 'react'
import {Grid} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row,Col} from 'reactstrap';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
//import VisibilityIcon from '@material-ui/icons/Visibility';
// import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import WebIcon from '@material-ui/icons/Web';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SaveIcon from '@material-ui/icons/Save';
import {URL} from '../../../ServerCall/Config';
// import swal from "sweetalert";
import TextFormatIcon from '@material-ui/icons/TextFormat';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';

function QuestionsTab(props) {
  //debugger
  //alert(props.timeLimitOption);

  const [questions, setQuestions]= React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [Showvalue, setShowValue] = React.useState(false);
  const [state, setState] = React.useState({
    checkedH:false,
    checkedT:false,
    timeLimit:null,
    score:null
  });
    const [errorScore, setErrorScore] = React.useState('');
    //const [errorTime, setErrorTime] = React.useState('');

     const formvalid = () =>{
      //debugger
        if(state.score!==""&&state.score!==null)
          setErrorScore("");
        else
          setErrorScore("Please enter score!");
        if(state.score === null)
            return false;
        else
        return true;
              
    } 

  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    //debugger
    setValue(event.target.value);

    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeText = (e) => {
    //debugger
    setState({ ...state, [e.target.name]: e.target.value});
  }


  const handleCheckChieldElement = (event,text) => {
    //debugger
    var optionsOfQuestion = [...questions];
    optionsOfQuestion.map((ques, i)=>
    ques.options.map(op=>{
      if ( op.optionText === text)
      op.isChecked =  event.target.checked
    //this.setState({fruites: fruites})
    }))
    setQuestions(optionsOfQuestion);
  }

  React.useEffect(()=>{
        //debugger
      //console.log("getQuestions",props.getQuestions);
    // if(props.getQuestions !== undefined){
    //   //console.log(props.formData.questions.length);
    //   if(props.questions.length !== 0){
    //     setNewQuestion(props.getQuestions);
    //    // setQuestions([{questionText: "Question", options : [{optionText: "Option 1"}], open: false}]);
    //   } else{
    //    // setQuestions(props.formData.questions)
    //   }
    //   //setLoadingFormData(false)
    // } 
    setFormData(props.formData)
  }, [props.formData])
  

  function saveQuestions(){
    const userId = Auth.getUserId()
    const isValid = formvalid();
    if(isValid){
      if(questions.length > 0 || props.sectionNameId !== null){
          var option = [];
          var answer = [];

          questions&&questions.map(qs=>{
              qs.options&&qs.options.map(op=>{
                option.push(op.optionText)
                  if(op.isChecked == true&& state.checkedH)
                  {
                    answer.push(op.optionText)
                  }
              })
          })
          if(value !== ''){
              answer.push(value);
          }
          if(answer.length > 0){
          let questionBank = {
            "facultyUserId": userId,
            "courseId":props.courseId,
            "question":questions[0].questionText,
            "images": "",
            "files": "",
            "questionType":questions[0].answer !== undefined?"paragraph":!state.checkedH?"single choice":"multiple choice",
            "minOptions": questions[0].answer === undefined?questions[0].options.length:" ",
            "options": option,
            "answer": questions[0].answer === undefined?answer:answer = [],
            "timeLimit": state.timeLimit,
            "score": state.score,
        }
   // console.log(questionBank);
        //setQuestions([]);
        axiosApiInstance.post(`${URL}/question`,questionBank)
        .then(res =>{
         // debugger
        let Id = res.data.Question._id;
        props.handleUseEffect(Id);
        let sectionQuestion = [Id];
        if(props.sectionGetName !==null && props.sectionNameId !== ''){

          let question = {
            "sectionName":props.sectionGetName,
            "questions.$.questions":sectionQuestion
          }

          //console.log(question)
          axiosApiInstance.put(`${URL}/addQuestionTest/${props.sectionNameId}`,question)
          .then(res=>{
            //console.log(res,"testIds added")
           // swal("Question Added successfully", "Add more questions!", 'success')
          }).catch(err=>{
            console.log(err);
          });
        }
        else{
          //debugger
          //alert(props.testId);
          let qn = {"questions":sectionQuestion};
          let questions = [qn];
          axiosApiInstance.put(`${URL}/addQuestionByTestId/${props.testId}`,{"questions":questions})
          .then(res=>{
            // console.log(res,"testIds added")
          }).catch(err=>{
            console.log(err);
          });
        }
          setQuestions([]);
          setState({timeLimit:null,score:null});
        }).catch(err=>{
          console.log(err,"err")
        });
      }
      else{
        alert("Please select answer.")
      }
    }
  }
    // else{
    //   alert("Please enter score");
    // }
  }

  function addMoreQuestionField(){
      expandCloseAll();
      setShowValue(false)

      setQuestions(questions=> [...questions, {questionText: "Question", options : [{optionText: "Option 1",isChecked: false}], open: true}]);
  }

  function addTextQuestionField(){
    expandCloseAll();
    setShowValue(false)
    setQuestions(questions=> [...questions, {questionText: "Question", answer : "Enter your answer", open: true}]);
  }

  function deleteQuestion(i){
    //debugger
    let qs = [...questions]; 
    if(questions.length > 0){
      qs.splice(i, 1);
    }
    setQuestions(qs)
  }

  function handleOptionValue(text,i, j){
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].options[j].optionText = text;
    //newMembersEmail[i]= email;
      setQuestions(optionsOfQuestion);
  }

  function handleQuestionValue(text, i){
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].questionText = text;
      setQuestions(optionsOfQuestion);
  }

 function onDragEnd(result) {
  if (!result.destination) {
    return;
  }
  var itemgg = [...questions];

  const itemF = reorder(
    itemgg,
    result.source.index,
    result.destination.index
  );

  setQuestions(itemF);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  function showAsQuestion(i){
    let qs = [...questions];  
     qs[i].open = false;
     setQuestions(qs);
  }

  function addOption(i){
    var optionsOfQuestion = [...questions];
    if(optionsOfQuestion[i].options.length < 5){
      optionsOfQuestion[i].options.push({optionText: "Option " + (optionsOfQuestion[i].options.length + 1),isChecked:false})
    } else{
      console.log("Max  5 options ");  
    }
    setQuestions(optionsOfQuestion)
  }

  function removeOption(i, j){
    var optionsOfQuestion = [...questions];
    if(optionsOfQuestion[i].options.length > 1){
      optionsOfQuestion[i].options.splice(j, 1);
      setQuestions(optionsOfQuestion)
      console.log(i + "__" + j);
    }   
  }

  function expandCloseAll(){
    let qs = [...questions]; 
     for (let j = 0; j < qs.length; j++) {  
      qs[j].open = false;
     }
     setQuestions(qs);
  }

  function handleExpand(i){
    let qs = [...questions]; 
    for (let j = 0; j < qs.length; j++) {
      if(i ===j ){
        qs[i].open = true;
 
      } else{
        qs[j].open = false;
       }
    }
     setQuestions(qs);
  }

  function questionsUI(){

      return  questions.map((ques, i)=> (
        
        <Draggable key={i} draggableId={i + 'id'} index={i}>
            {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className="QtabWp">
                <div style={{marginBottom: "15px"}}>
                  <div style={{width:'100%', marginBottom: '-7px' }}>
                    <DragIndicatorIcon style={{transform: "rotate(-90deg)", color:'#DAE0E2'}} fontSize="small"/>
                  </div>
                  {/* <Row>
                    <Col className="headingTxt">Question Set Form</Col>
                  </Row> */}

                  <Accordion onChange={()=>{handleExpand(i)}} expanded={questions[i].open}>
                    <AccordionSummary            
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      elevation={1} style={{width:'100%'}}
                    >
                    { !questions[i].open ? (
                    <div style={{display: 'flex',flexDirection:'column', alignItems:'flex-start', marginLeft: '3px', paddingTop: '15px', paddingBottom: '15px'}}>
                      {/* <TextField id="standard-basic" label=" " value="Question" InputProps={{ disableUnderline: true }} />  */}
                      
                      <Typography variant="subtitle1" style={{marginLeft: '0px'}}>{ques.questionText}</Typography>
                      {/* {ques.questionImage !==""?(
                        <div style={{height:'10px'}}>
                          <img src={ques.questionImage} width="400px" height="auto" />
                        </div>
                        ): ""
                      }            */}
                      {!ques.answer? ques.options.map((op, j)=>(
                      <div key={j}>
                        <div style={{display: 'flex'}}>
                        <FormControl component="fieldset">
                            {!state.checkedH?
                                <RadioGroup aria-label="" name="option" value={value} onChange={handleChange}>
                                  <FormControlLabel value={ques.options[j].optionText}  control={<Radio style={{marginRight: '3px', }} />} />
                                </RadioGroup> :
                                  <FormControlLabel  control={<Checkbox checked={ques.options[j].isChecked} onChange={(e)=>{handleCheckChieldElement(e,ques.options[j].optionText,i, j)}} name={ques.options[j].optionText} color="primary" /> }/>
                            }
                          </FormControl>
                        </div>
                        <div style={{height:'0px'}}>
                          {op.optionImage !==""?(
                            <img src={op.optionImage} width="160px" height="auto" />
                          ): ques.answer.map((ans, j)=>(
                            <div key={j}>
                              <div style={{display: 'flex'}}>
                              <FormControlLabel disabled control={<TextField style={{marginRight: '3px', }} />} label={
                                  <Typography style={{color: '#555555'}}>
                                    {ques.answer}
                                  </Typography>
                                } />
                              </div>
          
                            </div>
                          )) }
                        </div>
                      </div>
                      )):""}  
                    </div>            
                    ): ""}   
                    </AccordionSummary>
                    <AccordionDetails>
                    <div  className="textareaWp" style={{display: 'flex',flexDirection:'column', alignItems:'flex-start', marginLeft: '15px', marginTop:'-15px',width:'100%'}}>
                      <div style={{display:'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Typography style={{marginTop:'20px',marginRight:'5px'}}></Typography>
                        <TextField 
                              fullWidth={true} 
                              placeholder="Input your question title here" 
                              style={{marginBottom: '18px'}}  
                              // rows={2}
                              rowsMax={20}
                              multiline={true}
                              value={ques.questionText}
                              variant="outlined"
                              // variant="filled"
                            onChange={(e)=>{handleQuestionValue(e.target.value, i)}}
                            clasName="QtabTxtfield"
                        />
                        {/* <IconButton aria-label="upload image" onClick={()=>{uploadImage(i, null)}}>
                              <CropOriginalIcon />
                        </IconButton> */}
                      </div>
                      
                      <div style={{width: '100%'}}>
                        {!ques.answer? ques.options.map((op, j)=>(
                        
                        <div key={j}>
                              <div  style={{display:'flex',width:'50%', flexDirection:'row', marginLeft:'-12.5px', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px'}}>
                                
                                  {!state.checkedH?
                                      <RadioGroup aria-label="" name="option" value={value} onChange={handleChange}>
                                        <FormControlLabel value={ques.options[j].optionText}  control={<Radio />}/>
                                      </RadioGroup> :
                                        <FormControlLabel  control={<Checkbox checked={ques.options[j].isChecked} onChange={(e)=>{handleCheckChieldElement(e,ques.options[j].optionText,i, j)}} name={ques.options[j].optionText} color="primary" /> }/>
                                  }
                                  <TextField 
                                    fullWidth={true} 
                                    placeholder="Please enter a name for this option." 
                                    style={{marginTop: '5px'}} 
                                    value={ques.options[j].optionText}
                                    onChange={(e)=>{handleOptionValue(e.target.value, i, j)}}
                                    variant="outlined"
                                  />
                                  {/* <IconButton aria-label="upload image" onClick={()=>{uploadImage(i, j)}}>
                                    <CropOriginalIcon />
                                  </IconButton> */}
                                  <IconButton aria-label="delete" onClick={()=>{removeOption(i, j)}}>
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                        </div>
                        )): 
                          
                          <div>
                              {!state.checkedT?
                                <div  className="smallText" style={{display:'flex', width:'100%' ,flexDirection:'row', marginLeft:'-12.5px', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px'}}>
                                
                                    <TextField 
                                      fullWidth={true} 
                                      placeholder="Enter your answer" 
                                      style={{marginTop: '5px',marginLeft:'30px'}} 
                                      value={ques.answer}
                                      variant="outlined"
                                      // onChange={(e)=>{handleOptionValue(e.target.value, i, j)}}
                                    />
                                  </div>
                                :
                                <div  style={{display:'flex', width:'100%' ,flexDirection:'row', marginLeft:'-12.5px', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: '5px'}}>
                                  <TextareaAutosize
                                    fullWidth={true} 
                                    placeholder="Enter your answer" 
                                    style={{marginTop: '5px',marginLeft:'30px'}} 
                                    value={ques.answer}
                                    variant="outlined"
                                    rowsMin={3} 
                                    className="LongAns"
                                    // onChange={(e)=>{handleOptionValue(e.target.value, i, j)}}
                                  />
                                </div>
                                
                                }
                                
                          </div>
                        
                        }
                      </div>  
                      
                      
                      {!ques.answer && ques.options.length < 5 ? (
                        <div>
                        
                        
                          <Button size="small" onClick={()=>{addOption(i)}} style={{textTransform: 'none', marginLeft:"-5px"}}>
                            Add New Option
                          </Button>
                        
                        </div>
                      ): ""}

                      {/* <Typography variant="body2" style={{color: 'grey'}}>{!ques.answer?"You can add maximum 5 options. If you want to add more then change in settings. Multiple choice single option is availible":""}</Typography> */}
                      <Typography variant="body2" style={{color: 'grey'}}>{!ques.answer?"You can add maximum 5 options.":""}</Typography>
                    </div>
                    </AccordionDetails>

                    <Divider />
                    
                    <AccordionActions>   
                        {!ques.answer?
                          <>
                          <Switch
                            checked={state.checkedH}
                            onChange={handleChange}
                            name="checkedH"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            color="primary"
                              />
                          <span className="AnswerChoiceText">Multiple answers</span> 
                          </>:null
                          }
                        
                          <Row>
                            {props.timeLimitOption === "Question wise"?
                              <Col>
                                <Typography variant="body2" style={{color: 'grey'}}>{"Time Limit(minutes)*"}</Typography>
                                <TextField value={state.timeLimit} name="timeLimit" variant="outlined"  placeholder="Time Limit"   onChange={(e) => { handleChangeText(e) }} />
                              {/* {
                                errorTime !== "" ?
                                <Label  style={{ fontSize: 12, color: "red" }}>{errorTime}</Label>: null
                              } */}
                              </Col>
                              :null
                            }   
                              <Col>
                                <Typography variant="body2" style={{color: 'grey'}}>{"Score*"}</Typography>
                                <TextField value={state.score} name="score"  variant="outlined" placeholder="Score"  onChange={(e) => { handleChangeText(e) }} />
                                {
                                    errorScore !== "" ?
                                    <label style={{ fontSize: 12, color: "red" }}>
                                    { errorScore }</label>: null
                                }
                              </Col>
                          </Row> 
                          {ques.answer ?    
                            <div>
                              <Switch
                                checked={state.checkedT}
                                onChange={handleChange}
                                name="checkedT"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color="primary"
                              />
                              <span>Long answer</span> 
                            </div>:null
                          }
                          <IconButton aria-label="View" onClick={()=>{showAsQuestion(i)}}>
                            <WebIcon className="webIcn"/>
                          </IconButton>

                          {/* <IconButton aria-label="Copy" onClick={()=>{copyQuestion(i)}}>
                            <FilterNoneIcon />
                          </IconButton> */}
                          
                          <Divider orientation="vertical" flexItem/>

                          <IconButton aria-label="delete" onClick={()=>{deleteQuestion(i)}}>
                            <DeleteOutlineIcon />
                          </IconButton>

                          {/* <IconButton aria-label="Image">
                            <MoreVertIcon />
                          </IconButton> */}
                    </AccordionActions>
                  </Accordion>
                </div>
              </div>
          </div>
        )}
        </Draggable>
        
      )
      )
    }
  

  const Nav =()=> {
  setShowValue(true)
  }

  const Navhide = ()=>{
    setShowValue(false)
  }
  const showSectionModal = () =>{
    props.handleShow(true)
  }

  return (
       <div style={{marginTop:'15px', marginBottom: '7px', paddingBottom:"30px"}}>
           <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            >
              
             <Grid item xs={12} sm={12} style={{width: '100%'}}>
                  <Grid style={{paddingTop: '10px'}}>
                    <div>
                    {/* <ImageUplaodModel handleImagePopOpen={openUploadImagePop} handleImagePopClose={()=>{setOpenUploadImagePop(false)}} updateImageLink={updateImageLink} contextData={imageContextData}/> */}

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {questionsUI()}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div>  
                    <Row>
                        <Col>
                            {Showvalue? <Row>
                              {/* <Col md={4}></Col> */}
                            <Col><Button variant="contained" color="primary"  className="primarybtn pribtnWp" onClick={Navhide}><AddIcon/></Button></Col></Row> :
                              <Row>
                                {/* <Col md={4}></Col> */}
                                <Col md={8} 
                                // style={{margin:'0 auto',paddingLeft:'4%'}}
                                >
                               { questions.length<=0 ?<>
                                  <Button color="primary"  variant="contained" onClick={Nav}><AddIcon/> Add new</Button></>:
                                  <><Button startIcon={<SaveIcon/>} onClick={saveQuestions}  variant="contained" color="secondary" className="ml-2">Save Question</Button></>
                               }
                                </Col>
                              </Row>
                            }
                            {Showvalue?<div className="AddOptionWp"> 
                              <Row>
                                <Col className="mt-1">
                                  <Button className="AddOptionBtnClass"
                                  variant="contained" 
                                  onClick={addMoreQuestionField}
                                  startIcon={<RadioButtonCheckedIcon />}
                                  >Choice</Button>
                                  {/* <Button className="AddOptionBtnClass"
                                  startIcon={<TextFormatIcon/>}
                                  onClick={addTextQuestionField}
                                  >Text</Button> */}
                                  <Button className="AddOptionBtnClass"
                                  startIcon={<FilterNoneIcon/>}
                                  onClick={showSectionModal}
                                  >Section</Button>
                                </Col>
                              </Row>
                           </div>:null}
                                </Col>
                            </Row>                     
                      </div>
                    </div>
                  </Grid>        
              </Grid>           
           </Grid>
       </div>
  );
}
export default QuestionsTab;
