import React from 'react';
import {Row,Col,Container,Button}from 'react-bootstrap';
import { CardTitle, Input, FormGroup, Label } from 'reactstrap';
import FlyerPage from "../Flyer/flyer";
import flyr from "../Flyer/flyr.jpg";
import '../../../Containers/Student/student.css';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import moment from 'moment';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import FileViewerModal from './FileViewerModal';
import VideoPlayerModal from './VideoPlayerModal';
import {URL} from '../../../ServerCall/Config';
import swal from 'sweetalert';
import DoneIcon from '@material-ui/icons/Done';
import Auth from '../../../ServerCall/Auth';
import {withRouter} from 'react-router-dom';
import Loader from '../../../Components/Common/Loader/Loader';
import ModuleModal from './ModuleModal';
import parse from 'html-react-parser';

class CourseDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            studentUserId: Auth.getUserId(),
            role: Auth.getUserRole(),
            courseId:'',
            batch:[],
            batchId:null,
            status:'',
            startDate: moment().format('YYYY-MM-DD'),
            batchDate:'',
            endDate:"",
            startTime: new Date(),
            courseData: {},
            isCourseEnrolled: true,
            openFileViewerModal: false,
            openVideoPlayer: false,
            fileUrl: "",
            videoUrl: "",
            lessonName: "",
            chapterId: "",
            lessonId: "",
            courseTest: [],
            courseHistoryData: [],
            completedCourse: [],
            coursePercentage: {},
            isCourseScheduleForStudent:false,
            loading:false,
            show:false,
            moduleId:null,
            reqBatchName:'',
            fileType:'',
            showCompleted: true
        }
    }
    getCourse = () =>{
        //debugger
        const {courseId,scheduleId} = this.state;
        var url ='';
        //debugger
        if(this.state.role==='student'){
            // debugger
            url = `${URL}/courseDetails/${courseId}`;
        }
        else{
            if(this.state.role==='faculty')
            {
                if(this.props.match.params.batchId != undefined && this.props.match.params.batchId != "null" )
                {
                    let scId = this.props.match.params.batchId;
                    url = `${URL}/getCourseByCourseIdScheduleId/${courseId}/${scId}`;
                }else{
                    url = `${URL}/course/${courseId}`;
                }
            }
            else{url = `${URL}/course/${courseId}`;}
       }
         axiosApiInstance.get(url)
        // axiosApiInstance.get(`${URL}/course/${courseId}`)
        .then(async(res)=>{
            if(res.status === 200){
                //  debugger
                // console.log(res,"courseDetails");
                this.setState({loading:false});
                if(res.data.Course1 !== undefined){
                    let course = res.data.Course1;
                    let enrolled = res.data.checkCourse.course;
                    let isSchedule = res.data.checkCourseSchedule;
                    // console.log("course1",res.data);
                    await this.setState({courseData: course, isCourseEnrolled: enrolled, isCourseScheduleForStudent: isSchedule});
                }
                else if(res.data.Course2 !== undefined && this.state.role==="student"){
                    // debugger
                    let course = res.data.Course2;
                    let enrolled = res.data.checkCourse.course;
                    let isSchedule = res.data.checkCourseSchedule;
                    //  console.log("course2",res.data);
                   await this.setState({courseData: course, isCourseEnrolled: enrolled, isCourseScheduleForStudent: isSchedule});
                   let changeBatchTime = new Date(new Date(course.startDate) - 7 * 24 * 60 * 60 * 1000);
                  this.setState({batchDate:changeBatchTime,batchId:course._id});
                   
                }

                else if(res.data.Course2 !== undefined && this.state.role==="faculty"){
                    // debugger
                    let course = res.data.Course2;
                    let isSchedule = res.data.checkCourseSchedule;
                    //  console.log("course2",res.data);
                   await this.setState({courseData: course, isCourseScheduleForStudent: isSchedule});
                //    let changeBatchTime = new Date(new Date(course.startDate) - 7 * 24 * 60 * 60 * 1000);
                //   this.setState({batchDate:changeBatchTime,batchId:course._id});
                   
                }

                else{
                    let course = res.data.Course;
                    let isSchedule = res.data.checkCourseSchedule;
                    // console.log("course3",res.data);
                    await this.setState({courseData: course, isCourseScheduleForStudent: isSchedule});
                }
            }else{
                swal(res.data.message, "", "error")
                this.setState({loading:false});
            }
        }).catch(err => {
            console.log(err)
        })
    }
    // getCourseTest = () => {
    //     const {courseId} = this.state;
    //     axios.get(`${URL}/testByCourseId/${courseId}`, config)
    //     .then(res =>{
    //         debugger
    //         if(res.data.status === 200){
    //             this.setState({courseTest: res.data.Test})
    //         }else if(res.data.status === 500){
    //             alert(res.data.message)
    //         }
    //     })
    // }
    batchRequestCall = () => {
        // debugger
        let {courseId, courseData, batchId, reqBatchName} = this.state;
        let batchdata = {
            "userId": Auth.getUserId(),
            "courseId": courseId,
            "currentBatchId": courseData._id ,
            "currentBatchName": courseData.batchName,
            "reqBatchId": courseData._id === batchId ? (null) :(batchId),
            "reqBatchName": reqBatchName
           }
        //    console.log("change batch data: ",batchdata)
           if(reqBatchName===courseData.batchName || reqBatchName==="")
            {
                swal("Same Batch Selected!","Cant send Change Batch Request for Same Batch","error")
                return false;
            }
           else 
           {
           axiosApiInstance.post(`${URL}/createBatchRequest`,batchdata)
           .then(res=>{
            //   debugger
            // console.log("res",res);
            if(res.status === 201){
                let statusDetail = res.data.course;
                this.setState({batchId:statusDetail.reqBatchId,status:statusDetail.status});  
                swal("Change Batch Request Sent","","success")
            }
           })
           .catch(err=>{
               console.log("err",err);
           })
        // alert(reqBatchName)
            }
    }

    onchangeEvent(e){
        //debugger
      const { name,value} = e.target;
      const selectedIndex = e.target.options.selectedIndex;
      let textValue = e.target.options[selectedIndex].getAttribute('data-key');
      this.setState({[name]: value,reqBatchName:textValue});

    }

    async componentDidMount (){
    //  debugger
       let date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
       let Id = this.props.courseId;
       await this.setState({courseId:Id});
    //    console.log(date,"date");
    //  if(this.state.role != "faculty"){
    //     let schId = this.props.scheduleId;
    // }
    // else{
    //     debugger
    //     let schId = this.props.match.params.batchId;
    //     this.setState({scheduleId:schId})
    // }
        // this.getCourse();
        // this.getBatch();
        // await this.getBatchRequest();
        // //this.getCourseTest()
        await this.setState({loading:true});
        await this.getBatch();

        //await this.getBatch(Id);
    }

    componentDidUpdate(prevProps, prevState){
        // debugger
        if(prevState.courseId !== this.state.courseId){
            
            //debugger//this.setState({scheduleId:prevProps.scheduleId});
            this.getCourse();
            this.getBatch();
            this.getBatchRequest();
            //this.getCourseTest()
            this.setState({loading:true});
        }
        if(prevState.courseData !== this.state.courseData){
            this.checkCourseHistoryAvailability();
            //this.setState({loading:true});
        }
    }

    getBatchRequest = () => {
        // debugger
        const {courseId} = this.state;
        this.setState({loading:false});
        axiosApiInstance.get(`${URL}/getChangeBatchStatus/${courseId}`)
        .then(res=>{
            // debugger
           if(res.status === 200){
            let statusDetail = res.data.data[0];
            this.setState({batchId:statusDetail.reqBatchId,status:statusDetail.status});  
        }
        else{
            // debugger
            this.setState({loading:false});
            // this.setState({batchId:'',status:''});   // Aditya: commented so that it doesn't clear batch id::: temporary fix :::
        }
        })
        .catch(err=>{
            // debugger
            this.setState({loading:false});
            console.log("err",err);
        })

    }

    getBatch = () =>{
        //debugger
        const {courseId} = this.state;
        this.setState({loading:false});
        axiosApiInstance.get(`${URL}/getBatchNameList/${courseId}`)
        .then(res=>{
            //this.setState({loading:false});
           // debugger
            if(res.status === 200){
              let batchData = res.data.data;
              this.setState({batch:batchData});
            }
            // console.log("getBatchNameList",res);
        })
        .catch(err=>{
            //debugger
            this.setState({loading:false});
            console.log("err",err);
        })

        
    }

    checkCourseHistoryAvailability = (chapterId) => {
        this.setState({loading:false});
        const {courseId, studentUserId, isCourseEnrolled} = this.state;
        axiosApiInstance.get(`${URL}/courseHistoryByCidSid/${courseId}/${studentUserId}`)
        .then(res=>{
            // debugger
            if(res.status === 204 && isCourseEnrolled){
                this.createCourseHistory(chapterId);
            }else if(res.status === 200){
                let history = res.data.CourseHistory;
                this.setState({courseHistoryData: history});
                this.bindCourseHistory(chapterId);
            }else{
                //console.log(res.data.message)
            }
        }).catch(err=>{
            this.setState({loading:false});
            console.log(err);
        })
    }
    
    createCourseHistory = (chapterId) => {
        const {courseId, courseData, studentUserId} = this.state;
        let data = {
            studentUserId: studentUserId,
            courseId: courseId,
            sessions: [],
            totalTimeSpent: 0,
            courseDuration: courseData.duration
        }
        axiosApiInstance.post(`${URL}/courseHistory`, data)
        .then(res=>{
            if(res.status === 200){
                let history = res.data.CourseHistory;
                this.setState({courseHistoryData: history});
                this.bindCourseHistory(chapterId);
            }else{
                swal(res.data.message, "", "error")
            }
        }).catch(err=>{
            console.log(err);
        }) 
    }

    enrollmentClick = () =>{
        const {courseId,startDate,endDate} = this.state;
        let duration = {startDate,endDate};
        let enrollmentArray = [];
        enrollmentArray.push({courseId:courseId,status:true,duration});
        // debugger
        swal("Added course to cart", "", "success", { button: { text: "View Cart", className: "primarybtn" } })
            .then(res => { 
                // console.log(res); 
                if (res) { this.props.history.push("/Payment") } });
        // axiosApiInstance.put(`${URL}/addEnrollment/${courseId}`,{enrollment:enrollmentArray})
        // .then(res=>{
        //     debugger
        //     console.log("enrollcourse",res);
        //     if(res.status === 200){
        //         this.props.history.push(`/Enrollment`)
        //     }else{
        //         swal(res.data.message, "", "error")
        //     }
        // }).catch(err=>{
        //     console.log(err);
        // })
    }

    closeFileModal(type){
        if(this.state.role === 'superAdmin' || this.state.role === 'faculty' )
        {
            if(type == "pdf"){this.setState({openFileViewerModal: false})
         }else{this.setState({openVideoPlayer: false})}
        }
        else{
            const {courseData} = this.state;
            if(courseData.courseType === "Prerecorded" && type === "pdf" && this.state.showCompleted === true){
                swal("Have you completed the lesson?", {
                    buttons: ["No", "Yes"],
                })
                .then((result) => {
                    if(result) {
                        this.callCourseHistoryUpdate(true, type)
                    } else {
                        this.callCourseHistoryUpdate(false, type)
                    }
                });
            }
            else{
                if(type == "pdf"){this.setState({openFileViewerModal: false})
                }else{this.setState({openVideoPlayer: false})}
            }
        }
    }

    callCourseHistoryUpdate = (flag, type) => {
        const {courseId, studentUserId, chapterId, lessonId, startTime} = this.state;
        let data = {
            courseId: courseId,
            studentId: studentUserId,
            chapterId: chapterId,
            lessonId: lessonId,
            startTime: startTime,
            completed: flag,
            lastReferTime: new Date()
        }
        // debugger
        axiosApiInstance.put(`${URL}/updateCourseHistory`, data)
        .then(async res=>{
            if(res.status === 200){
                await this.checkCourseHistoryAvailability(chapterId);
                await this.percentageCal();
            }else{
                swal(res.data.message, "", "error")
            }
        }).catch(err=>{
            console.log(err);
        })
        if(type == "pdf"){this.setState({openFileViewerModal: false})
        }else{this.setState({openVideoPlayer: false})}
    }

    startTestAlert = () => {
        let chapterId = this.state.moduleId;
        swal("Are you sure you want to start test?", {
            buttons: ["No", "Yes"],
        })
        .then((result) => {
            if(result) {
                // this.props.history.push(`/Test/${chapterId}/chapterId`);
                 this.props.history.push(`/TestInstruction/${chapterId}/chapterId`);
                
            } else {
               // alert(result)
            }
        });
    }

   startTestPopup = () => {
     this.setState({show:true});
    }

    selectedModule = async(e) =>{
        const { name,value} = e.target;
        await this.setState({[name]: value});  
    }

    // startTestAlert = () => {
    //     let chapterId = this.state.moduleId;
    //     swal("Are you sure you want to start test?", {
    //         buttons: ["No", "Yes"],
    //     })
    //     //await this.setState({completedCourse: courseHistory, coursePercentage: obj});
    // }

     addModalClose=() =>{ 
         this.setState({show:false});
     }
    
    bindCourseHistory = async(chapterId) => {
        const {courseHistoryData, courseData, isCourseEnrolled} = this.state;
        var lessonCompleted = null, lessonLength = null, obj = {};
        if(isCourseEnrolled && courseHistoryData.sessions.length > 0){
            const isExist = courseHistoryData.sessions.find(history => history.chapterId === chapterId);
            if(isExist !== undefined) {
                var courseHistory = courseHistoryData.sessions.find(history => history.chapterId === chapterId).lessonsId;
                lessonLength = courseData.chapter.find(chapter => chapter._id === chapterId).lessons.length;
                lessonCompleted = courseHistory.filter(course => course.completed === true).length;
                obj = {noOfLessons: lessonLength, completedLessons: lessonCompleted, chapterId: chapterId}
            }
        }        
        await this.setState({completedCourse: courseHistory, coursePercentage: obj});
    }

    checkIsComplete = (lessID) => {
        const {completedCourse} = this.state;
        completedCourse && completedCourse.map(data=>{
            if(data.lessonsId === lessID){
                if(data.completed === false){
                    this.setState({showCompleted: true});
                }else if (data.completed === true){
                    this.setState({showCompleted: false});
                }
            }
        })
    }

    percentageCal = () => {
        const {coursePercentage} = this.state;
        return(
            <span className="float-right">{parseFloat((coursePercentage.completedLessons/coursePercentage.noOfLessons)*100).toFixed(2) + "%" }</span>
        );
    }

    render(){
        const{isCourseEnrolled, status, batchId, batchDate, isCourseScheduleForStudent, role,batch, openFileViewerModal, openVideoPlayer, fileUrl, videoUrl, lessonName, courseData, completedCourse, coursePercentage, fileType, showCompleted, courseId }=this.state;
        return (
            <Container fluid className=" pl-0 pr-0 CourseDetail">
                {isCourseEnrolled || role =="superAdmin" || role === 'faculty'?(<Col md={12}  className="pl-0 pr-0 " style={{margin:'0 auto'}}>
                {this.state.loading ?
                        <Loader/>
                        : <div></div>
                        }
                    <Card className="p-4 shadow">
                        <Row>
                            <Col>
                            <p className="mt-3 pl-1"> 
                                <h4 className="hdtxt ">Course Detail {courseData.courseType !== undefined? <span className="teamName pl-2">({courseData.courseType})</span>:null}</h4>
                              
                            </p>
                            </Col>
                        </Row>
                        {Object.keys(courseData).length !== 0 ?
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <Col>
                                        <div>
                                            <Card className="CourseListCardWp pb-4 pt-5" style={{border:'none'}}>
                                                <Row>
                                                    <Col md={4} style={{margin:'15px auto'}}>
                                                            <img src={courseData.imageOrVideoPath.default} style={{width: "100%"}}  alt=""/>
                                                            {/* <img src="D:\Work\Files\WhatsApp Unknown 2021-06-07 at 17.31.07" style={{width: "100%"}}  alt=""/> */}
                                                    </Col>
                                                    <Col md={8} className="CourseHeadDetails"><h3 className="prdctDetlTxt"><a href="#">{courseData.courseName}</a></h3>
                                                    <p className="Coursedescription"><span>{parse(courseData.description)}</span></p>
                                                    {/* <span className="teamName">- By {courseData.mainFaculty.facultyFullName}</span> */}
                                                    {/* <p className="Link"><span>Remove</span><span>Move to Whishlist </span></p>  */}
                                                        <p className="mt-3 pl-1"> 
                                                            <span className="teamName">duration - <span className="boldfont pl-2">{courseData.duration} </span></span> {"  "}
                                                            <span className="teamName ">Last updated - <span className="boldfont pl-2"> {moment(courseData.updatedAt).format('MM/YYYY')}</span> </span> 
                                                        </p>
                                                        {courseData.startDate !== undefined && isCourseScheduleForStudent === true ?
                                                        <p className="mt-3 pl-1"> 
                                                            <span className="teamName">Start date - <span className="boldfont pl-2">{moment(courseData.startDate).format('DD/MM/YYYY')}</span></span> {"  "}
                                                            <span className="teamName">End date   -<span className="boldfont pl-2">{moment(courseData.endDate).format('DD/MM/YYYY')}</span></span> 
                                                        </p>
                                                        :null
                                                        }
                                                    
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <CourseList/>
                                    </Col>
                                </Row> */}
								{courseId === "608c7c0c4cdaee118883f02b" || courseId === "608c7a2a4cdaee118883f02a" ? null: 
                                <Row disabled={isCourseEnrolled && isCourseScheduleForStudent || courseData.courseType==="Prerecorded" ?false:true} className="mt-2">
                                    <Col md={12}>
                                        <Accordion>
                                            <Card>
                                            {courseData.chapter && courseData.chapter.map((chapter,key,index)=>
                                            <>
                                            <Card.Header>
                                                <Row>
                                                    {/* new Date()).format('YYYY-MM-DD')  <=  moment(chapter.releaseDate).format('YYYY-MM-DD') */}
                                                    <Col md={7}>
                                                        <Accordion.Toggle as={Button} variant="link" eventKey={chapter._id} data-target={key} aria-controls={key}
                                                            onClick={ () => this.bindCourseHistory(chapter._id)}>{chapter.chapterName}</Accordion.Toggle>
                                                    </Col>
                                                    {isCourseEnrolled ?
                                                    <Col md={5}>
                                                        <Row>                                                            
                                                            {Object.keys(coursePercentage).length !== 0 && coursePercentage.chapterId === chapter._id ?
                                                            <Col className="percentTxt" md={5}>
                                                                {this.percentageCal()}
                                                            </Col> : null }
                                                        </Row>
                                                        </Col>: null }
                                                </Row>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={chapter._id} id={key}>
                                                    <Card.Body>
                                                        <Row className="pt-3 pb-3 pl-2">
                                                                <Col md={2}>  {chapter.thumbnailImgUrl !== undefined? <div> <img src={chapter.thumbnailImgUrl} style={{width: "100%"}}  alt=""/></div>:null }</Col>
                                                                {/* <Col md={2}>  {chapter.thumbnailImgUrl !== undefined? <div> <img src={flyr} style={{width: "100%"}}  alt=""/></div>:null }</Col> */}
                                                            <Col md={10} style={{fontSize:'14px'}}> 
                                                                 {chapter.chapterDescription !== undefined? <div>{chapter.chapterDescription}</div>:null }
                                                            </Col>
                                                        </Row>  
                                                     
                                                      
                                                    {chapter.lessons && chapter.lessons.map(item=>
                                                        <div className="card-body testDetail" style={{ position:'relative'}}>
                                                            <table className="tblClass">
                                                                <tr>
                                                                    {item.lessonName + " "}
                                                                    
                                                                    {isCourseEnrolled || role === "superAdmin" ||  role === 'faculty'?
                                                                    <div style={{display:'inline'}}>
                                                                       {/* {courseId === "608c7c0c4cdaee118883f02b" || courseId === "608c7a2a4cdaee118883f02a" ? null: */}
                                                                       <td className="testDetailCol">
                                                                        {item.videoUrl.length > 0
                                                                            ? item.videoUrl && item.videoUrl.map(vUrl=>
                                                                            
                                                                            <PlayCircleOutlineIcon className="mr-3 videoIcn" style={{marginleft:'-2'}} 
                                                                                onClick={() => {
                                                                                    // debugger
                                                                                    if(courseData.courseType==='Prerecorded' || role==="superAdmin" || role==="faculty"){
                                                                                        this.checkIsComplete(item._id); 
                                                                                        this.setState({ openVideoPlayer: true, 
                                                                                            videoUrl: vUrl.url, 
                                                                                            fileType: item.filesUrl,
                                                                                            lessonName:item.lessonName, 
                                                                                            chapterId: chapter._id, 
                                                                                            lessonId: item._id, 
                                                                                            startTime: new Date()});
                                                                                    }
                                                                                    else if(chapter.releaseDate!=="" && chapter.releaseDate!==undefined && chapter.releaseDate!==null && moment(new Date()).format('YYYY-MM-DD')  >=  moment(chapter.releaseDate).format('YYYY-MM-DD')  
                                                                                    && this.state.batchId!="" && this.state.batchId!=null && this.state.batchId!=undefined )
                                                                                    {
                                                                                        this.checkIsComplete(item._id); 
                                                                                        this.setState({ openVideoPlayer: true, 
                                                                                            videoUrl: vUrl.url, 
                                                                                            fileType: item.filesUrl,
                                                                                            lessonName:item.lessonName, 
                                                                                            chapterId: chapter._id, 
                                                                                            lessonId: item._id, 
                                                                                            startTime: new Date()});
                                                                                    }else{swal("Lesson not released yet","","info")}}} />)
                                                                            : item.filesUrl.length > 0 ? item.filesUrl && item.filesUrl.map(fUrl=> 
                                                                                <InsertDriveFileIcon file={fUrl.url} className="pdfIcn mr-3" style={{marginleft:'-2'}}
                                                                                onClick={() => {
                                                                                    // debugger;
                                                                                    if(courseData.courseType==='Prerecorded' || role==="superAdmin" || role==="faculty"){
                                                                                        this.checkIsComplete(item._id); 
                                                                                        this.setState({ openFileViewerModal: true,
                                                                                                        fileType: fUrl.url.split('.').pop(),
                                                                                                        fileUrl: fUrl.url,
                                                                                                        lessonName:item.lessonName, 
                                                                                                        chapterId: chapter._id, 
                                                                                                        lessonId: item._id, 
                                                                                                        startTime: new Date()});
                                                                                    }
                                                                                    else if(chapter.releaseDate!=="" && chapter.releaseDate!==undefined && chapter.releaseDate!==null && 
                                                                                        moment(new Date()).format('YYYY-MM-DD')  >=  moment(chapter.releaseDate).format('YYYY-MM-DD')  
                                                                                        && this.state.batchId!="" && this.state.batchId!=null && this.state.batchId!=undefined )
                                                                                    {
                                                                                        this.checkIsComplete(item._id); 
                                                                                        this.setState({ openFileViewerModal: true,
                                                                                                        fileType: fUrl.url.split('.').pop(),
                                                                                                        fileUrl: fUrl.url,
                                                                                                        lessonName:item.lessonName, 
                                                                                                        chapterId: chapter._id, 
                                                                                                        lessonId: item._id, 
                                                                                                        startTime: new Date()});
                                                                                    }
                                                                                    else{swal("Lesson not released yet","","info")}}} />)
                                                                            : null}
                                                                        </td>
                                                                        {/* } */}
                                                                        
                                                                        {/* {item.deliveryType === "Meeting Url" ?
                                                                        <div style={{display:'inline'}}>
                                                                          { role === "student" && isCourseScheduleForStudent === false?
                                                                            null
                                                                           :<td className="testDetailCol">
                                                                              <span className="pl-2 spnText"> {moment(item.schedule[0].scheduleDate).format('DD/MM/YYYY')}</span>
                                                                              <span className="pl-2 spnText">{item.schedule[0].scheduleTime}</span>
                                                                              <a href={item.meetingUrl} className="liveLink pl-2">Click here to join</a> 
                                                                            </td>
                                                                          }
                                                                        </div>
                                                                        : null} */}
                                                                        {/* {completedCourse && completedCourse.map(c => c.lessonsId === item._id ?
                                                                        <div className="CheckIcn">
                                                                            {c.completed ?
                                                                            <DoneIcon className="CheckIcn"/> : null}
                                                                        </div>
                                                                            : null)} */}
                                                                    </div>
                                                                     : null}
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                                </>
                                            )}
                                            </Card>
                                        </Accordion>
                                    </Col>
                                    {/* {courseId === "608c7c0c4cdaee118883f02b" || courseId === "608c7a2a4cdaee118883f02a"?null: */}
                                        <Col className="mt-3" >
                                        {isCourseEnrolled && this.state.role==="student" && courseData.chapter.length >= 2 ?
                                            <Button onClick={() => this.startTestPopup()} className="float-right">Start Test</Button>
                                        : null }
                                        </Col>
                                    {/* } */}
                                </Row>
								}
                            </Col>
                            {/* {isCourseEnrolled  || role =="superAdmin" || role === 'faculty' ? null :
                               <><Col md={4}>
                                    <Card className="priceTotl shadow-sm p-3" >
                                        <CardTitle className="applyCoupn"><BeenhereIcon/>Enroll now</CardTitle>
                                        <Row>
                                            <Col className="mt-2">
                                                    <Button className="w-100" onClick={this.enrollmentClick}>
                                                    Add to Cart
                                                </Button>
                                                </Col>
                                        </Row>
                                    </Card>
                                </Col> 
                                </> 
                            } */}
                               {isCourseEnrolled === true && isCourseScheduleForStudent === true && this.state.role==="student"?
                                <Col md={4}>
                                    <Card className="priceTotl shadow p-4" >
                                        <CardTitle className="applyCoupn bluetext font-weight-bold"  >Welcome Back!</CardTitle>
                                        
                                        <FormGroup>
                                        {/* <Row><h6 className=" bluetext ">&nbsp;&nbsp;Current Batch:  </h6><h5 className="">&nbsp;{courseData.batchName} </h5></Row> */}
                                                {/* <Label for="exampleSelect">Select</Label> */}
                                                        <select id="Dropdown" name="batchId" className="form-control"  value={batchId}
                                                                onChange={ (e) => this.onchangeEvent(e)}
                                                                  disabled={moment(new Date()).format('YYYY-MM-DD')  <=  moment(batchDate).format('YYYY-MM-DD') && status !== "Pending" && status !== "Approved" && status !== "Decline"?false:true}
                                                                >
                                                                {/* <option selected>Select batch</option>  */}
                                                                {
                                                                    batch && batch.map(select => {
                                                                        // debugger
                                                                        // 
                                                                    
                                                                // }
                                                                if(select.length<=0)
                                                                {return (
                                                                    <option>No Batch available</option>
                                                                )}
                                                                else{
                                                                    // if(select._id!=courseData._id){
                                                                    return (
                                                                    <option selected={select[0]} data-key={select.batchName} value={select._id} >{select.batchName}</option>
                                                                )
                                                            // }
                                                        }
                                                                    })
                                                                }
                                                        </select>
                                                </FormGroup>
                                        <CardTitle className="applyCoupn"><BeenhereIcon className="mr-2"/>  
                                        {/* <strong>{courseData.batchName}</strong>  */}
                                        Batch Allocated for This Course</CardTitle>
                                        <Row>
                                            <Col className="mt-2">
                                                <Button className="w-100" onClick={this.batchRequestCall}  disabled={moment(new Date()).format('YYYY-MM-DD')  <=  moment(batchDate).format('YYYY-MM-DD') && status !== "Pending" && status !== "Approved" && status !== "Decline" && this.state.reqBatchName!="No Batch available"?false:true}>
                                                    Make Change Batch Request
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-center mt-3">
                                                <div  className="center-block text-center">
                                                    {status === "Pending"?<Button className="text-center mr-3 " variant="primary">Pending</Button>:null}    
                                                    {status === "Decline"?<Button className="text-center mr-3 " variant="danger">Declined</Button>:null}
                                                    {status === "Approved"?<Button className="text-center mr-3 " variant="success">Approved</Button>:null}        
                                                     {/* {status !== "Pending" && status === ''?null:<><Button className="text-center mr-3 btnblue">Approve</Button>          
                                                     <Button className="text-center " variant="danger" style={{fontWeight:'bold'}}>Decline</Button></>} */}
                                                    
                                                </div>
                                            </Col>
                                            </Row>
                                    </Card>
                                </Col>
                                :null}
                        </Row> : <Row><Col></Col></Row>}
                        {/* <FileViewer/> */}
                    <hr/>
                </Card>
                </Col>)
                    : (<FlyerPage courseId={this.state.courseId} />)
                }
            <div><FileViewerModal fileUrl={fileUrl} fileType={fileType} lessonTitle={lessonName} show={openFileViewerModal} showSwal={showCompleted} onHide={ () => this.closeFileModal("pdf")}/></div>
            <div><VideoPlayerModal videoUrl={videoUrl} fileType={fileType} courseType={courseData.courseType} lessonTitle={lessonName} show={openVideoPlayer} isLessonComplete={this.callCourseHistoryUpdate} showSwal={showCompleted} onHide={ () => this.closeFileModal("video")}/></div>
            <ModuleModal show={this.state.show} moduleId={this.state.moduleId} onHide={this.addModalClose} selectedModule={this.selectedModule} moduleData={courseData.chapter} startTestAlert={this.startTestAlert} onClickCancelButton={this.onClickCancel} />
        </Container>
        );
    }
}
export default withRouter(CourseDetail);
