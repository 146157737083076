import React from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import user from '../../../Images/AVATARCIRCLE.png'
const ImgUpload =({
    onChange,
    src
  })=>
    <label htmlFor="photo-upload" className="custom-file-upload fas ProfileUpload">
      <div className="img-wrap img-upload" >
        <img for="photo-upload" src={src}/>
      </div>
      <input id="photo-upload" type="file"  className ="fileInputClass" onChange={onChange}/> 
    </label>
  const Profile =({
    onSubmit,
    src,
    name,
    status,
  })=>
    <div>
      <form onSubmit={onSubmit}>
        {/* <h1>Profile Card</h1> */}
        <label className="custom-file-upload fas">
          <div className="img-wrap" >
            <img for="photo-upload" src={src}/>
          </div>
        </label>
        {/* <div className="name">{name}</div>
        <div className="status">{status}</div>
        <button type="submit" className="edit">Edit Profile </button> */}
      </form>
    </div>
       
        
  const Edit =({
    onSubmit,
    children,
  })=>
    <div className="cardProf">
      <form onSubmit={onSubmit}>
        {/* <h1>Profile Card</h1> */}
          {children}
        {/* <button type="submit" className="save">Save </button> */}
      </form>
    </div>
  
  export default class ProfileImg extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      file: '',
      imagePreviewUrl: this.props.profileImage,
      name:'',
      status:'',
      active: 'edit'
      }
      console.log("this.props.profileImage",this.props);
    }
    async componentWillReceiveProps(nextProps){
     // debugger
      if(nextProps.profileImage !== this.props.profileImage){
        await this.setState({ imagePreviewUrl: nextProps.profileImage });
      }
    }
    photoUpload = e =>{
      //debugger
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
        this.props.imageFile(file,reader.result);
      }
      reader.readAsDataURL(e.target.files[0]);
    }
    editName = e =>{
      const name = e.target.value;
      this.setState({
        name,
      });
    }
    
    editStatus = e => {
      const status = e.target.value;
      this.setState({
        status,
      });
    }
    
    handleSubmit= e =>{
      e.preventDefault();
      let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
      this.setState({
        active: activeP,
      })
    }
    
    render() {
      const {imagePreviewUrl, 
             name, 
             status, 
             active} = this.state;
      return (
        <div>
          {(active === 'edit')?(
            <Edit onSubmit={this.handleSubmit}>
              <ImgUpload onChange={this.photoUpload} src={imagePreviewUrl}/>
            </Edit>
          ):(
            <Profile 
              onSubmit={this.handleSubmit} 
              src={imagePreviewUrl} 
              />)}
          
        </div>
      )
    }
  }