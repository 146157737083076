import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MediaCard from '../Student/Course/MediaCard';
import axiosApiInstance from '../../ServerCall/Axios.Instance';
import { Redirect } from 'react-router-dom';
import {URL} from '../../ServerCall/Config';
// import parse from 'html-react-parser';
import parse from 'react-html-parser';
import SearchBar from "material-ui-search-bar";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';


import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button,Row,Col
} from 'reactstrap';
import java from '../../Images/java.png'
import { CropLandscapeOutlined } from '@material-ui/icons';
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#4c8db5',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color:'#fff',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const handleOnSearch = (string, results) => {
  // onSearch will have as the first callback parameter
  // the string searched and for the second the results.
  // console.log(string, results)
}

const handleOnHover = (result) => {
  // the item hovered
  // console.log(result)
}

const handleOnSelect = (item) => {
  // the item selected
  console.log(item.name)
}

const handleOnFocus = () => {
  console.log('Focused')
}

const swapPosition = (arr, index1, index2) => {
  [arr[index1], arr[index2]] = [arr[index2], arr[index1]]
  // console.log(arr);
}

//export default class ControlledAccordions() {
  export default class CourseSection extends React.Component{
  // const [expanded, setExpanded] = React.useState('panel1');

  constructor(props) {
    super(props);
    this.state = {
      Coursedata:[],
      isRedirect: false,
      courseId:'',
      itemsPerPage : 8,
      page :1,
      noOfPages : null,
      searchSug: [],
      imgSelCourse: '',
      sessionId: "",
      filter:'',
      filteredCourseData:[],
      expanded:true
    }
  }


  handleChange = (panel,i) =>{
    // debugger
    const {Coursedata} = this.state;
    for(var j = 0; j<Coursedata.length; j++){
    if(  j === i ){
      Coursedata[j].expanded = !panel;
    }
    else{
      Coursedata[j].expanded = false;
    }
   }
   this.setState({
    Coursedata
  });
  };

  redirect = (Id) => {
    this.setState({ isRedirect: true,courseId:Id });
  }
  getCourseData () {
    axiosApiInstance.get(`${URL}/course/getCategoryWithCourses`)
    .then(res =>{
      //debugger
      if(res.status === 200){
        const newCourse = res.data.data.map((file) => {
            return {...file, expanded:false};
        });
        newCourse[0].expanded = true;
        this.setState({Coursedata: newCourse });

        let array = [];
        newCourse.map(category=>{
          category.courses.map(course=>{
              // console.log(course.courseName);
              array.push({name:course.courseName});
            }
          )
        })
        this.setState({searchSug: array});
      }else{
       // swal(res.data.message, "", "error")
      }
      let id = localStorage.getItem('courseId');
      this.setState({sessionId: id});
      //debugger
      // let id = this.props.courseId;    
      //console.log(id);
      if(this.state.sessionId != null && this.state.sessionId != ""){
        this.selctedCourse(id);
        // const course = {"name":id};
        // this.searchCourse(course);
      }
    }).catch(err => {
      console.log(err)
    })
    
  }

  selctedCourse=(id)=>{
    //debugger
    axiosApiInstance.get(`${URL}/course/${id}`)
    .then(response =>{
     // debugger
     // console.log(response.data.Course);
      let data = response.data.Course;
      this.setState({filteredCourseData: data});
      // const courseName = {"name":data};
      // this.searchCourse(courseName);
      this.setState({imgSelCourse: response.data.Course.imageOrVideoPath.default});
     // console.log(this.state.filteredCourseData);
    }).catch(err => {
      console.log(err)
    })
  }

  searchCourse=(value)=>{
    //debugger;
    let matchCourse = value.name;
    this.state.Coursedata && this.state.Coursedata.map((category, iCat)=>{
      category.courses.map((course, index)=>{
          if(course.courseName === matchCourse){
            //debugger;
            // this.state.Coursedata[iCat].expanded = false;
            // this.filterCourse(iCat);
            // let length = category.courses.length;
            let courseArray = category.courses;
            swapPosition(courseArray, 0, index);
            // this.handleChange(false,iCat);
            swapPosition(this.state.Coursedata, 0, iCat);
            this.handleChange(false,0);
          }
        }
      )
    })
    // const val = parseInt(value);
    // this.handleChange(false,val);
  }

  filterCourse = (catInd) =>{
    let category = [];
    this.state.Coursedata && this.state.Coursedata.map((cat, indexCat)=>{
      if(indexCat === catInd){
        category.push(cat);
      }
      // category.courses.map((course,ind)=>{

      // })
    })
    this.setState({Coursedata: category});
  }

  updateSeach=(val)=>{
    this.setState({searchVal: val});
  }

  componentDidMount(){
    this.getCourseData();
    // this.selctedCourse();
    // if(this.props.location.search!=""){
    //   let param = this.props.location.search;
    //   const id = param.toString().slice(1);
    //   localStorage.setItem("courseId", id);
    //   console.log(id);
    // }
  }


  render(){
    const{searchSug, filteredCourseData, imgSelCourse}=this.state;
  return (
    <div>
      {/* <SearchBar
        value={searchVal}
        onChange={(newValue) => this.setState({ searchVal: newValue })}
        onRequestSearch={() => this.searchCourse(searchVal)}
      /> */}
      <div className="w-100 clearfix">
      <div className="searchbardiv float-right" style={{ width: 300}}>
          <ReactSearchAutocomplete
            items={searchSug}
            onSearch={this.handleChange}
            onHover={handleOnHover}
            onSelect={this.searchCourse}
            // onFocus={handleOnFocus}
            autoFocus
          />
        </div>
        {this.state.sessionId != null && this.state.sessionId != "" 
            ?(<Row>
              <Col md={3}>
                <MediaCard courseDetail={() =>this.redirect(filteredCourseData._id)} title={filteredCourseData.courseName}
                  // text={parse(filteredCourseData.description)}
                 // text={i.description}
                  image={imgSelCourse
                  }
                  //image={require('../../../Images/python.png')}
                />
                {this.state.isRedirect ? <Redirect to={`/TabCourseForum/${this.state.courseId}`} /> : null }
              </Col>
            </Row>)
            :null}
            </div>
        
      {/* <div className='autocomplete_list'>
      {searchSug
        .filter(({ name }) => searchVal === '' || name.toLowerCase().includes(searchVal.toLowerCase()))
        .map(value => (
          <div className='autocomplete_item' onClick={() => this.updateSeach(value.name)} key={value.name} tabIndex='0'>
            <span>{value.name}</span>
          </div>
        ))}
      </div> */}
      <hr/>
      {this.state.Coursedata&&this.state.Coursedata.map((item,index) =>
      <Row>
        <Col className="zindex0">
      <Accordion className="AccordionWpr" square expanded={item.expanded} onChange={ () =>this.handleChange(item.expanded,index)}>
        <AccordionSummary aria-controls={item.categoryName} id={item.categoryName}   expandIcon={<ExpandMoreIcon className="ExpandmoreIcn"/>}>
          <Typography>{item.categoryName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Row>
            {item.courses&&item.courses.map(i =>
              <Col md={3} className="CourseWpCol mb-3">
                <MediaCard courseDetail={() =>this.redirect(i._id)} title={i.courseName}
                  text={parse(i.description)}
                 // text={i.description}
                  image={i.imageOrVideoPath.default}
                  //image={require('../../../Images/python.png')}
                />
                {this.state.isRedirect ? <Redirect to={`/TabCourseForum/${this.state.courseId}`} /> : null }
              </Col>
            )}
          </Row>        
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Col>
      </Row>
      )}
      {/* <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"   expandIcon={<ExpandMoreIcon className="ExpandmoreIcn"/>}>
          <Typography>Course 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row>
             <Col md={4}>
                  <Card>
                  <CardImg top width="100%" src={java} alt="Card image cap" />
                  <CardBody>
                    <CardTitle tag="h5">Card title</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle>
                    <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                    <Button>Button</Button>
                  </CardBody>
                </Card>
              </Col>
          </Row>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon className="ExpandmoreIcn"/>}>
          <Typography>Course 3</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row>
              <Col md={4}>
                    <Card>
                    <CardImg top width="100%" src={java} alt="Card image cap" />
                    <CardBody>
                      <CardTitle tag="h5">Card title</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle>
                      <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                      <Button>Button</Button>
                    </CardBody>
                  </Card>
                </Col>
            </Row>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
 }
}
