import React from "react";
import {Modal,Form, Button,Container,Row,Col,FormGroup} from 'react-bootstrap';
import Table from  '../../../Components/Admin/Course/Table';
import   '../../../Components/Admin/Course/Table.css';

const headerStyle = {
    backgroundColor: '#4c8db5',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698'
  }

const StudentModal=({
    show,
    onHide,
    scheduleStudent,
    columns
    }) =>  {   
    return(
      <Modal 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          className="modalWp"show={show}
          onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Student List
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container className="FormWp">
          <Row className="mb-4 mt-4">
                  <Col className="tableWp">
                        <Row>
                            <Col className="DashboardTbl scheduleTbl">
                              <Table data={scheduleStudent} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle} rowStyle={rowStyle}                                   
                                  // actions={
                                  //   [
                                  //     {
                                  //       icon: 'edit',
                                  //       tooltip: 'Edit',
                                  //       onClick: (event, rowData) => {
                                  //         this.props.history.push(`/CourseMaster?scheduleId=${rowData.id}`)
                                  //       }
                                  //     }
                                  //   ]
                                  // }
                              />
                            </Col>
                        </Row>        
                  </Col>
                </Row>
          </Container>
          </Modal.Body>
        </Modal>  
    )
}

export default StudentModal




