import React,{useState} from 'react'
import { FormGroup } from '@material-ui/core';
import { SettingsPhoneOutlined } from '@material-ui/icons';
import{Modal,Button,Form,InputGroup, } from 'react-bootstrap';
import {Label,Input,Container,Row,Col , Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle} from 'reactstrap';
import {Link} from 'react-router-dom';
import PaginationLine from  '../../../Components/Student/Course/PaginationLine';
//import Pagination from '@material-ui/lab/Pagination';
import AvatarMan from '../../../Images/AvatarMan.png'
import {URL} from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import Auth from '../../../ServerCall/Auth';
import TextEditor from './TextEditor'
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import RichTextEditor from 'react-rte';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    }
 
  }));
const userId = Auth.getUserId();
const userName = Auth.getUserName();
const role = Auth.getUserRole();

 const Stud_Forum =(props) =>{
    //   debugger
    //  console.log("Props from Student Forum:",props)
     const classes = useStyles();
     const [show,setShow]=useState(false);
     const [forum,setForum]=useState('');
    //  const [courseScheduleId,setcourseScheduleId]=useState();
     const [heading,setHeading] = useState('');
     const [editorMessage, setEditorMessage] = useState('');
     const handleClose =() => setShow(false);
     const handleShow = () => setShow(true);
     const [value,setValue] = useState(RichTextEditor.createEmptyValue());
     const [page, setPage] = useState(1);
     const [itemsPerPage ,setitemsPerPage] = useState(5);
     const [noOfPages, setnoOfPages] = useState('');
     const [scheduleId,setScheduleId]=useState(props.batchId);
     let [filteredSearchData,setfilteredSearchData]= useState([]);
     const [filterValue,setfilter]=useState('');
     const [questions,setquestions]=useState([]);
     const [selectedValue,setselectedValue] = useState([]);
     const[reply,setreply]=useState([]);

     const handleChange = (event, value) => {
         //debugger
        setPage(value);
      };

    //   let scheduleId=null;

      const getCourseScheduleId= async ()=>{
        // debugger
        if(role==="faculty")
		{
			// let id=props.batchId;
			// await setScheduleId(s)
            await getForum(scheduleId);
			// alert(id)
		}
        else
		{axiosApiInstance.get(`${URL}/courseScheduleByCourseIdUser/${props.courseId}`)
		.then(async (res)=>{
            // debugger
           // console.log(res)
            if(res.status===200){
                let schId=res.data.Course._id;
                await setScheduleId(schId)
                await getForum(schId);
                // console.log("ScheduleId",scheduleId);
            }
            else{
                // debugger
                    await setScheduleId(null);
                    await getForum(null);

                    // scheduleId=null;
                }
		})}
        // getForum(scheduleId);
        }

        const getForum=(cscheduleId)=>{
            // debugger
            let id = props.courseId;
            let sid=cscheduleId
            axiosApiInstance.get(`${URL}/forum/${id}/${sid}`)
             .then((res) => {
                //  debugger
              // console.log(res,"res from student forum");
               setForum(" ");
               if (res.status === 200) {
                 let forumData = res.data.forum[0];
                 setForum(forumData);
                 let pages = Math.ceil(
                   forumData.questions.length / itemsPerPage
                 );
                 //console.log(pages,"pages");
                 setnoOfPages(pages);
                 const lowercasedFilter = filterValue.toLowerCase();
                 var filteredData = forumData.questions.filter((f) =>
                   f.heading.toLowerCase().includes(lowercasedFilter)
                 );
                 setfilteredSearchData(filteredData);

                 // var selectedValue=rep.filter( result=>
                 //     result.rep.length == 6
                 //     );
                 //     setselectedValue(selectedValue)
                 //     console.log(forumData.questions,"selectedValue **");
               }
             })
             .catch((err) => {
               console.log(err);
             });
        }
    
     React.useEffect(()=>{
            getCourseScheduleId();
            // const intervalId = setInterval(() => {
            //     getForum();
            // },1000);

            // return ()=>{
            //     intervalId();
            // }
    },[show,filterValue]);

    function savePostHandle(){
        // debugger
    
        let obj = {
            "courseId":props.courseId,
            "questions":[{
                "date":new Date().toISOString(),
                "heading":heading,
                "message":editorMessage,
                "name":userName,
                "studentId":userId,
            }],"courseScheduleId":scheduleId
         }
        axiosApiInstance.post(`${URL}/forum`,obj)
        .then(async(res)=>{
            // debugger
            //console.log("res",res);
            setShow(false);
            if(res.status === 200){
                //debugger
               // setValue(RichTextEditor.createEmptyValue());
               swal("Post added successfully", "", "success");
                let forumData = res.data.forum;
                await setForum(...forum,forumData);
             }
        })
        .catch(err=>{
            console.log(err);
        })
    }

    function onChangeEdit(value){
       // console.log("valueNewvalue",value);
        setEditorMessage(value);
    }

    function onChange(e){
        setHeading(e.target.value);
    }


   const handleSearchChange = (event) =>{
       //debugger
     setfilter(event.target.value);


    }

    const handleChangeFilter = (event) =>{
        //debugger
        setselectedValue(event.target.value);
        //console.log("forum.questions",forum.questions);
        if(event.target.value === "4"){ 
            var data = '';
            data = forum.questions.filter(question => question.reply.length === 0);
            // setreply( data )
            setfilteredSearchData(data);
            //console.log(data);
        }
        else if(event.target.value === "3"){
            let filteredConvos = forum.questions.filter((convo) => {
                return convo.reply.some((participant) => {
                  return participant.corretAnswer === false;
                })
              })
           // console.log("filteredConvos",filteredConvos);
            setfilteredSearchData(filteredConvos);
        }
        else if(event.target.value === "2"){
            let filteredConvos = forum.questions.filter((convo) => {
                return convo.reply.some((participant) => {
                  return participant.corretAnswer === true;
                })
              })
            //console.log("filteredConvos",filteredConvos);
            setfilteredSearchData(filteredConvos);
        }
        else{
           // debugger
            setfilteredSearchData(forum.questions); 
        }
        // var result = forum.questions.map(item => (
        //     item.reply.filter(child => child.corretAnswer === false
        //     )
        // ));
       // console.log(result.filter(item => item.length > 0),"result");

    }
    function getTime(cretedDate){
       // debugger
        var datePast = new Date(cretedDate);
        var dateNow = new Date();

        var seconds = Math.floor((dateNow - (datePast))/1000);
        var minutes = Math.floor(seconds/60);
        var hours = Math.floor(minutes/60);
        var days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
       // console.log("days",days,"hr",hours,"min",minutes);
        return <span className="text-secondary timeTxtclass pl-3  pr-2">{days === 0 &&  hours !== 0? hours +"hour" + minutes +"minutes ago":hours === 0 && days === 0  ? minutes +"minutes ago": days > 0 ?  moment(new Date(cretedDate)).format('MMMM Do YYYY, h:mm:ss a') :  days +"day" + hours +"hour" + minutes +"minutes ago"  }</span>;
    }

   // console.log(scheduleId);

     return(
        <Container className="mb-4">
        <div className="main-body p-0">
            <div className="inner-wrapper">
                <div className="inner-main mt-4">
                    {/* <!-- Inner main header --> */}
                    <Row className="mt-3">
                        <Col md={2} className="mb-2">
                            <a className="nav-link nav-icon   rounded-circle nav-link-faded mr-3 d-md-none" href="#"><i class="material-icons">arrow_forward_ios</i></a>
                            <select className="custom-select custom-select-sm w-auto mr-1"
                             value={selectedValue} 
                             reply={reply}
                             onChange={handleChangeFilter}
                             >
                                <option value="1">All</option>
                                <option value="2">Solved</option>
                                <option value="3">Unsolved</option>
                                <option value="4" >No Replies Yet</option>
                            </select>
                        </Col>
                        <Col md={3} className="mb-2">
                        <Button className="btn btn-primary has-icon btn-block " onClick={handleShow}>
                          <AddIcon/>
                            NEW DISCUSSION
                        </Button>
                        </Col>
                        <Col md={3} className="mb-2"></Col>
                        <Col className="col-md-4 float-right">
                        <Form.Row>
                            <Form.Group as={Col} className="mb-0">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Here.."
                                        value={filterValue}
                                        onChange={handleSearchChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                     </Col>
                    </Row>
                    {/* <div className="inner-main-header">
                    </div> */}
                    <div className="inner-main-body p-2 p-sm-3 mt-3 forum-content">
                        {forum.questions&&forum.questions&&filteredSearchData&&filteredSearchData.slice(((page - 1)*(itemsPerPage)), (page)*(itemsPerPage)).map((item,i)=>
                             <Card className="mb-2" key={i}>
                                        <Link 
                                            //to={`/ForumDetail/${props.courseId}`}
                                             to={role ==="faculty" ? { pathname: `/ForumDetail/${props.courseId}/${scheduleId}/${item._id}`,
                                                state: {
                                                batchId:scheduleId,
                                                questionId: item._id
                                                }}
                                                : { pathname: `/ForumDetail/${props.courseId}`,
                                                state: {
                                                batchId:scheduleId,
                                                questionId: item._id
                                                }}} className="text-body">
                                <CardBody  className=" p-2 p-sm-3">
                                    <div class="media forum-item">
                                        <div className="pr-3">
                                            <Avatar className={classes.orange}>
                                            {item.name.charAt(0)+" "
                                            // +item.name.charAt(11)
                                            } 
                                            </Avatar>
                                        </div>
                                        {/* <a href="#">
                                             <Avatar>H</Avatar>
                                            <img src={AvatarMan}  class="mr-3 rounded-circle" width="50" alt="User" />
                                            </a> */}
                                        <div className="media-body">
                                            <h6  className="text-body">
                                                {/* <Link 
                                            //to={`/ForumDetail/${props.courseId}`}
                                             to={{ pathname: `/ForumDetail/${props.courseId}`,
                                                state: {
                                                questionId: item._id
                                                }}} className="text-body"> */}
                                                    {item.heading}
                                                    {/* </Link> */}
                                                    </h6>
                                            {/* <CardText dangerouslySetInnerHTML={{__html: item.heading}} /> */}
                                            <CardSubtitle className="mb-2 itemClass text-muted">
                                            created by <a href="#" className="bluetext">{item.name}</a>  
                                                {getTime(item.date)}
                                                {/* Latest replied <a href="#" className="bluetext pl-2">drewdan</a>
                                                <span className="text-secondary  pl-3 font-weight-bold">13 minutes ago</span> */}
                                            </CardSubtitle>
                                        </div>
                                        <div className="text-muted small text-center align-self-center">
                                            {/* <span className="d-none d-sm-inline-block"><i className="far fa-eye"></i> 19</span> */}
                                            <span><i className="far fa-comment ml-2 mr-2"></i>{item.reply.length}</span>
                                        </div>
                                    </div>
                             </CardBody>
                             </Link>
                         </Card>
                        )}
                       
                        <Row>
                            <Col className="mt-3"> 
                            <PaginationLine
                                noOfPages={noOfPages}
                                page={page}
                                handlePageChange={handleChange}
                            />
                             {/* <Pagination 
                               count={noOfPages}
                                page={page}
                                defaultPage={1}
                                color="primary"
                                showFirstButton
                                showLastButton primary variant="outlined"  onChange={handleChange}/> */}
                            </Col>
                        </Row>
                       
                    </div>

                </div>
                {/* <!-- /Inner main --> */}
            </div>
                <Modal show={show} onHide={handleClose} size="lg"> 
                    <Modal.Header closeButton>
                            <Modal.Title>New Discussion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <FormGroup>
                        <Label>Subject</Label>
                        <Input type="text" className="form-control" id="threadTitle" placeholder="Enter subject"  onChange={onChange} autofocus="" />
                    </FormGroup>
                    <div className="pt-3">
                        <Label>Message</Label>
                        <TextEditor onChangeEdit={onChangeEdit} editorValue={value} />
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={savePostHandle}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
                </div>
        </Container>
     );

 }
 export default Stud_Forum;